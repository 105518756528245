import Swal from 'sweetalert2'
import { api } from '../api/Middleware'
import { CATEGORIES_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetOne = async (id, setCategorie, setIsLoading) => {
  try {
    let response = await api.get(CATEGORIES_URL + id + '/')
    if (response.status === 200) setCategorie(response.data)
  } catch (error) {
    //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
    /*  if(error.status !== 401){
             
             
         }  */
  }
  setIsLoading(false)
}

export const GetAll = async (
  setCategories,
  setErrors,
  setIsLoading,
  returnData = false
) => {
  try {
    let response = await api.get(`${CATEGORIES_URL}`)
    if (response.status === 200) {
      response.data = response.data.sort((a, b) => a.sequence - b.sequence)
      if (returnData !== false) return response.data
      setCategories(response.data)
    }
  } catch (error) {
    setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
  }

  setIsLoading(false)
}

export const Create = async (
  data,
  setProduct,
  setNotify,
  setError,
  setShowPlansModal,
  returnData = false,
  submitBtn = false
) => {
  try {
    let response = await api.post(CATEGORIES_URL, data)
    if (response.status === 200) {
      if (returnData) return response.data

      setProduct(response.data)
      setNotify({
        title: 'Sucesso!',
        message: 'Categoria cadastrado com sucesso!',
        type: 'success',
        visible: true
      })
      setTimeout(() => {
        window.location.href = APP_BASE_URL + 'categories'
      }, 3000)
      //window.history.pushState(null, '', window.location.href + response.data.id);
    }
  } catch (error) {
    if (returnData) return error.response
    if (submitBtn?.current) {
      submitBtn.current.innerHTML = 'Salvar'
      submitBtn.current.removeAttribute('disabled')
    }
    if (error.response?.status === 400) {
      setError('name', {
        message: error.response.data.message,
        type: 'custom'
      })
    } else if (error.response?.status === 401) {
      window.location.href = APP_BASE_URL
    } else if (error.response?.status === 402) {
      Swal.fire({
        title: 'Oops!',
        html: error.response.data,
        icon: 'warning',
        buttons: true,
        confirmButtonText: `Fazer upgrade agora!`,
        cancelButtonText: 'Adiar',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary)'
      }).then(value => {
        if (value.isConfirmed) {
          setShowPlansModal(true)
        }
      })
    } else {
      setNotify({
        title: 'Oops!',
        message: ERROR_MESSAGES.categories.update,
        type: 'error',
        visible: true
      })
    }
    //if(error.status !== 401) throw error.response;
  }
}

export const UpdateSequence = async (id, data) => {
  try {
    let response = await api.put(`${CATEGORIES_URL}update_sequence/${id}/`, data)
    return response.data
  } catch(error) {
    throw error
  }
}

export const Update = async (id, data, setProduct, setNotify) => {
  try {
    let response = await api.put(CATEGORIES_URL + id + '/', data)
    if (response.status === 200) {
      setProduct(response.data)
      setNotify({
        title: 'Sucesso!',
        message: 'Categoria atualizada com sucesso!',
        type: 'success',
        visible: true
      })
      setTimeout(() => {
        window.location.href = APP_BASE_URL + 'categories'
      }, 3000)
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: 'Oops!',
      message: ERROR_MESSAGES.products.update,
      type: 'error',
      visible: true
    })
  }
}

export const ChangeStatus = async (data, setNotify) => {
  try {
    let response = await api.put(CATEGORIES_URL + data.id + '/', {
      status: parseInt(data.status)
    })
    if (response.status === 200) {
      setNotify({
        title: 'Sucesso!',
        message: 'Status atualizado com sucesso!',
        type: 'success',
        visible: true
      })
      return true
    }
  } catch (error) {
    setNotify({
      title: 'Oops!',
      message: ERROR_MESSAGES.orders.status,
      type: 'error',
      visible: true
    })
    return false
  }
}

export const Delete = async (id, setNotify, setProgress) => {
  setProgress(true)

  try {
    let ids = [id]

    let response = await api.delete(CATEGORIES_URL + 'delete_multiple/', {
      data: { ids: ids }
    })
    if (response.status === 200) {
      setNotify({
        title: 'Sucesso!',
        message: 'Operação realizada com sucesso!',
        type: 'success',
        visible: true
      })
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: 'Oops!',
      message: error.response.data,
      type: 'error',
      visible: true
    })
  }

  setProgress(false)
}
