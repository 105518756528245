import React, { useRef, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import NewOrder from '../Orders/NewOrder'

export default function MultiDragComponent ({
  data,
  setData,
  classes,
  setShowOrderModal,
  orderData,
  componentRef,
  handlePrint,
  tracking,
  setTracking,
}) {
  const [selectedItems, setSelectedItems] = useState([])
  const _selectedItems = useRef([])
  const moveToNextColumn = () => {
    if (_selectedItems.current.length === 0) {
      return // Verifica se há itens selecionados
    }

    const selectedItemId = _selectedItems.current[0] // Pega o primeiro item selecionado

    // Encontra a coluna atual do item selecionado
    const currentColumn = Object.values(data).find(column =>
      column.items.some(item => item.id == selectedItemId)
    )

    // Verifica se o item não está na última coluna
    const columnKeys = Object.keys(data)
    const currentColumnIndex = columnKeys.findIndex(
      key => key === currentColumn.id
    )
    const nextColumnIndex = currentColumnIndex + 1

    if (nextColumnIndex < columnKeys.length) {
      const nextColumnId = columnKeys[nextColumnIndex]
      const nextColumn = data[nextColumnId]

      // Move o item para a próxima coluna
      const updatedCurrentItems = currentColumn.items.filter(
        item => item.id != selectedItemId
      )

      const selectedItem = currentColumn.items.find(
        item => item.id == selectedItemId
      )

      const updatedNextItems = [...nextColumn.items, selectedItem]

      setData(prevData => ({
        ...prevData,
        [currentColumn.id]: { ...currentColumn, items: updatedCurrentItems },
        [nextColumn.id]: { ...nextColumn, items: updatedNextItems }
      }))

      // Limpa a seleção
      _selectedItems.current = []
    }
  }

  const handleDragStart = start => {
    const itemId = start?.draggableId
    if (!selectedItems.includes(itemId)) {
      setSelectedItems([itemId])
    }
  }

  const onDragEnd = result => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const sourceColumn = data[source.droppableId]
    const destColumn = data[destination.droppableId]

    if (source.droppableId === destination.droppableId) {
      const items = reorder(sourceColumn.items, source.index, destination.index)
      const newColumn = {
        ...sourceColumn,
        items
      }

      setData(prevColumns => ({
        ...prevColumns,
        [newColumn.id]: newColumn
      }))
    } else {
      const movedItems = selectedItems.map(id =>
        sourceColumn.items.find(item => String(item.id) === id)
      )
      const newSourceItems = [
        ...sourceColumn.items.filter(
          item => !selectedItems.includes(String(item.id))
        )
      ]
      const newDestItems = [...destColumn.items]

      newDestItems.splice(destination.index, 0, ...movedItems)

      setData(prevColumns => ({
        ...prevColumns,
        [sourceColumn.id]: { ...sourceColumn, items: newSourceItems },
        [destColumn.id]: { ...destColumn, items: newDestItems }
      }))
    }

    setSelectedItems([])
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
      <div style={{ display: 'flex' }}>
        {Object.values(data).map(column => (
          <div
            key={column.id}
            className={classes}
            style={{ margin: 8, minHeight: '100vh' }}
          >
            <h5 className='py-3 text-white'>{column.title}</h5>

            <Droppable droppableId={column.id} style={{ minHeight: '100vh' }}>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {column.items.map((item, index) =>
                    item.id ? (
                      <Draggable
                        key={String(item.id)}
                        draggableId={String(item.id)}
                        index={index}
                        isDragDisabled={
                          !selectedItems.includes(String(item.id)) &&
                          selectedItems.length > 0
                        }
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            id={item.id}
                            className='card-dragable'
                            style={{
                              userSelect: 'none',
                              marginBottom: 10,
                              borderRadius: 20,

                              color: 'white',
                              ...provided.draggableProps.style
                            }}
                            onClick={() => {
                              const newSelectedItems = [...selectedItems]
                              if (newSelectedItems.includes(String(item.id))) {
                                const index = newSelectedItems.indexOf(
                                  String(item.id)
                                )
                                newSelectedItems.splice(index, 1)
                              } else {
                                newSelectedItems.push(String(item.id))
                              }
                              setSelectedItems(newSelectedItems)
                            }}
                          >
                            <NewOrder
                              value={item}
                              index={index}
                              handleMoveToNextColumn={() => {
                                let id = String(item.id)
                                if (!_selectedItems.current.includes(id)) {
                                  _selectedItems.current = [id]
                                }
                                moveToNextColumn()
                              }}
                              column={column.id}
                              setShowOrderModal={setShowOrderModal}
                              orderData={orderData}
                              componentRef={componentRef}
                              handlePrint={handlePrint}
                              tracking={tracking}
                              setTracking={setTracking}
                            />
                          </div>
                        )}
                      </Draggable>
                    ) : (
                      <>
                        <NewOrder
                          value={item}
                          index={index}
                          handleMoveToNextColumn={() => {
                            let id = String(item.id)
                            if (!_selectedItems.current.includes(id)) {
                              _selectedItems.current = [id]
                            }
                            moveToNextColumn()
                          }}
                          column={column.id}
                        />
                      </>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </div>
    </DragDropContext>
  )
}
