import { api } from '../api/Middleware'
import { DOMAINS_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'


export const Create = async (data) => {
    try {
        let response = await api.post(DOMAINS_URL, data);
        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        console.log(error)
        return error.response;
    }
}

// export const Update = async (id, data, setUser, setNotify) => {
//     try {
//         let response = await api.put(USERS_URL + id + '/', data);
//         if (response.status === 200) {
//             setNotify({ title: 'Sucesso!', message: 'Usuário atualizado com sucesso!', type: 'success', visible: true });
//             setTimeout(() => { }, [
//                 window.location.href = APP_BASE_URL + 'users'
//             ], 3000)
//         }

//     } catch (error) {
//         //if(error.status !== 401) throw error.response;
//         setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
//     }
// }

// export const Delete = async (id, setNotify, setProgress) => {
//     setProgress(true);

//     try {
//         let ids = [id];

//         let response = await api.delete(USERS_URL + 'delete_multiple/', { data: { 'ids': ids } });
//         if (response.status === 200) {
//             setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
//             setTimeout(() => {
//                 window.location.reload();
//             }, 3000)
//         }

//     } catch (error) {
//         //if(error.status !== 401) throw error.response;
//         setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
//     }

//     setProgress(false);
// }