import React, { useCallback, useEffect, useRef } from "react";
import tt from "@tomtom-international/web-sdk-maps";
import * as ttServices from "@tomtom-international/web-sdk-services";
import "../css/tomtom.css";
import "@tomtom-international/web-sdk-plugin-searchbox/dist/SearchBox.css";
import SearchBox from "@tomtom-international/web-sdk-plugin-searchbox";
import { AppSettings } from "../App";

export default function DeliveryFeeBySearchArea({
  data,
  deliveryCity,
  deliveryArea,
  setValue,
}) {
  const map = useRef();
  const popup = useRef();
  const geometriesZoomLevelSliderEl = useRef(11);
  const ttSearchBox = useRef();
  const POLYGON_ID = "POLYGON_1";
  const OUTLINE_ID = "OUTLINE_1";
  const NO_POLYGON_MESSAGE =
    "For the given result there is no polygon attached.";

  function clearLayer(layerID) {
    if (map.current.getLayer(layerID)) {
      map.current.removeLayer(layerID);
      map.current.removeSource(layerID);
    }
  }

  function clearPopup() {
    popup.current.remove();
  }

  function renderPolygon(searchResult, additionalDataResult) {
    let geoJson = additionalDataResult && additionalDataResult.geometryData;
    if (!geoJson) {
      throw Error(NO_POLYGON_MESSAGE);
    }

    map.current.addLayer({
      id: POLYGON_ID,
      type: "fill",
      source: {
        type: "geojson",
        data: geoJson,
      },
      paint: {
        "fill-color": "#ffffff",
        "fill-opacity": 0.25,
      },
    });

    map.current.addLayer({
      id: OUTLINE_ID,
      type: "line",
      source: {
        type: "geojson",
        data: geoJson,
      },
      paint: {
        "line-color": "#004B7F",
        "line-width": 1,
      },
    });
    
    let boundingBox = searchResult.boundingBox || searchResult.viewport;
    boundingBox = new tt.LngLatBounds([
      [boundingBox.topLeftPoint.lng, boundingBox.btmRightPoint.lat],
      [boundingBox.btmRightPoint.lng, boundingBox.topLeftPoint.lat],
    ]);
    if (searchResult.address && searchResult.address.municipality) {
      deliveryArea.current =
        additionalDataResult.geometryData.features[0].geometry.coordinates[0];
    }
    deliveryCity.current = searchResult.address.municipality
    ttSearchBox.current.setValue("");
    map.current.fitBounds(boundingBox, { padding: 100, linear: true });
  }

  function showPopup(searchResult) {
    let resultName =
      searchResult.address && searchResult.address.freeformAddress;
    if (!searchResult.position) {
      return;
    }

    let resultPosition = {
      lng: searchResult.position.lng,
      lat: searchResult.position.lat,
    };

    setValue("areaName", resultName);

    let popupResultName = "<strong>" + resultName + "</strong>";
    let popupLatLon =
      "<div>" + resultPosition.lat + ", " + resultPosition.lng + "</div>";
    popup.current.setHTML("<div>" + popupResultName + popupLatLon + "</div>");
    popup.current.setLngLat([resultPosition.lng, resultPosition.lat]);
    popup.current.addTo(map.current);
  }

  function showLoadingPopup() {
    popup.current.setHTML("<strong>Carregando...</strong>");
    if (!popup.current.isOpen()) {
      popup.current.setLngLat(map.current.getCenter());
      popup.current.addTo(map.current);
    }
  }

  function showStartSearchingPopup() {
    popup.current
      .setLngLat(map.current.getCenter())
      .setHTML("<strong>Faça uma busca...</strong>");
    if (!popup.current.isOpen()) {
      popup.current.addTo(map.current);
    }
  }

  function loadPolygon(searchResult) {
    if (!searchResult) {
      return;
    }

    return new Promise(function (resolve) {
      clearLayer(POLYGON_ID);
      clearLayer(OUTLINE_ID);
      showLoadingPopup();
      resolve();
    })
      .then(function () {
        let polygonId =
          searchResult &&
          searchResult.dataSources &&
          searchResult.dataSources.geometry.id;
        if (!polygonId) {
          throw Error(NO_POLYGON_MESSAGE);
        }

        return ttServices.services.additionalData({
          key: process.env.REACT_APP_TOMTOM_MAPS_KEY,
          geometries: [polygonId],
          geometriesZoom: geometriesZoomLevelSliderEl.current.value,
        });
      })
      .then(function (additionalDataResponse) {
        let additionalDataResult =
          additionalDataResponse &&
          additionalDataResponse.additionalData &&
          additionalDataResponse.additionalData[0];
        renderPolygon(
          searchResult,
          additionalDataResult,
          POLYGON_ID,
          OUTLINE_ID
        );
        showPopup(searchResult);
      })
      .catch(function (error) {
        console.log(error);
        clearPopup();
        if (error.message) {
          //errorHint.setMessage(error.message)
        }
      });
  }

  const handleSearchBox = useCallback(() => {
    ttSearchBox.current = new SearchBox(ttServices.services, {
      searchOptions: {
        key: process.env.REACT_APP_TOMTOM_MAPS_KEY,
        language: "pt-BR",
        countrySet: "BR",
      },
      filterSearchResults: function (searchResult) {
        return Boolean(
          searchResult.dataSources &&
            searchResult.dataSources.geometry &&
            searchResult.dataSources.geometry.id
        );
      },
      labels: {
        noResultsMessage: "Nenhum resultado encontrado...",
        placeholder: "Busque um bairro...",
      },
    });

    document
      .getElementById("searchBoxPlaceholder")
      .appendChild(ttSearchBox.current.getSearchBoxHTML());

    ttSearchBox.current.on("tomtom.searchbox.resultselected", function (event) {
      /*  places.push(event.data.result)
      places.map((result, index) =>
        loadPolygon(
          places[index],
          `POLYGON-${Slugfy(result.address.municipalitySubdivision)}`,
          `OUTLINE-${Slugfy(result.address.municipalitySubdivision)}`
        )
      ) */
      loadPolygon(event.data.result);
    });

    ttSearchBox.current.on("tomtom.searchbox.resultsfound", function (event) {
      handleEnterSubmit(event, loadPolygon.bind(this));
    });

    ttSearchBox.current.on("tomtom.searchbox.resultscleared", function () {
      /*  clearLayer(POLYGON_ID)
      clearLayer(OUTLINE_ID) */
      showStartSearchingPopup();
    });
  }, []);

  useEffect(() => {
    const initMap = async () => {
      const apiKey = process.env.REACT_APP_TOMTOM_MAPS_KEY;
      let center =
        data && data.delivery_area
          ? data.delivery_area[0]
          : [
              AppSettings.store.address.longitude,
              AppSettings.store.address.latitude,
            ];
      const mapInstance = tt.map({
        key: apiKey,
        container: "map",
        center: center,
        zoom: 12,
        dragPan: true,
      });

      mapInstance.addControl(new tt.NavigationControl());

      createMarker(
        mapInstance,
        [
          AppSettings.store.address.longitude,
          AppSettings.store.address.latitude,
        ],
        "Minha Loja"
      );

      popup.current = new tt.Popup({
        className: "tt-popup",
        closeOnClick: false,
      });

      handleSearchBox();

      map.current = mapInstance;
    };
    window.handleEnterSubmit = window.handleEnterSubmit || handleEnterSubmit;
    initMap();

    setTimeout(() => {
      if (data) {
        map.current.addLayer({
          id: "ID1",
          type: "line",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [data.delivery_area],
              },
            },
          },

          paint: {
            "line-color": "#004B7F",
            "line-width": 1,
          },
        });
        
        let popupResultName = "<strong>" + data.area_name + "</strong>";
        popup.current.setHTML("<div>" + popupResultName + "</div>");
        popup.current.setLngLat(data.delivery_area[3]);
        popup.current.addTo(map.current);
      }
    }, 1000);

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  });

  function handleEnterSubmit(t, e, a, s) {
    t.data &&
      "submit" === t.data.metadata.triggeredBy &&
      (t.data.results && t.data.results.fuzzySearch.results[0]
        ? e.call(this, t.data.results.fuzzySearch.results[0], s)
        : a.setMessage("No result found"));
  }

  const createMarker = useCallback((map, position, popupText) => {
    let markerElement = document.createElement("div");
    markerElement.className = "marker";
    let markerContentElement = document.createElement("div");
    markerContentElement.className = "marker-content";
    markerElement.appendChild(markerContentElement);
    let iconElement = document.createElement("div");
    iconElement.className = "marker-icon";
    iconElement.style.backgroundImage = `url(/imgs/map-pointer.png)`;

    markerContentElement.appendChild(iconElement);
    let popup = new tt.Popup({ offset: 30 }).setText(popupText);

    new tt.Marker({ element: markerElement, anchor: "bottom" })
      .setLngLat(position)
      .setPopup(popup)
      .addTo(map);
  }, []);

  return (
    <div id="map" className="map map-search">
      <div
        id="foldable"
        className="tt-overlay-panel -left-top -medium js-foldable"
      >
        <form>
          <label id="searchBoxPlaceholder" className="tt-form-label">
            Busca por bairro ou cidade
          </label>
        </form>
      </div>
    </div>
  );
}
