import Swal from "sweetalert2";
import { api } from "../api/Middleware";
import { COUPONS_URL, CATEGORIES_URL, APP_BASE_URL } from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";
import { GetAll as GetAllCategories } from "../services/CategoriesServices";

export const GetOne = async (id, setCoupon, setIsLoading) => {
  try {
    let response = await api.get(COUPONS_URL + id + "/");
    if (response.status === 200) {
      setCoupon(response.data);
      setIsLoading(false);
    }
  } catch (error) {
    //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
    /*  if(error.status !== 401){
             
             
         }  */
  }
};

export const GetAll = async (
  setCoupons,
  setErrors,
  setIsLoading,
  returnData = false,
  fields = false
) => {
  let response = await api.get(`${COUPONS_URL}${fields ? fields : ""}`);
  try {
    if (response.status === 200) {
      if (returnData) return response.data;
      setCoupons(response.data);
    }
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = APP_BASE_URL;
    } else {
      if (returnData) throw error;
      setErrors({ title: "Oops!", message: ERROR_MESSAGES.promotions.get });
    }
  }
  if (!returnData) setIsLoading(false);
};
export const Create = async (
  data,
  setNotify,
  setError,
  setIsLoading,
  setShowModal
) => {
  setIsLoading(true);
  try {
    let response = await api.post(COUPONS_URL, data);
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Cupom cadastrado com sucesso!",
        type: "success",
        visible: true,
      });
      setShowModal(false);
    }
  } catch (error) {
    console.log(error);
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: "Oops!",
      message: ERROR_MESSAGES.products.update,
      type: "error",
      visible: true,
    });
  }
  setTimeout(() => {
    window.location.href = "/coupons";
  }, 1000);
  setIsLoading(false);
};

export const Update = async (
  id,
  data,
  setCoupon,
  setNotify,
  setIsLoading,
  setShowModal
) => {
  setIsLoading(true);
  try {
    let response = await api.put(COUPONS_URL + id + "/", data);
    if (response.status === 200) {
      setCoupon(response.data);
      setNotify({
        title: "Sucesso!",
        message: "Cupom atualizado com sucesso!",
        type: "success",
        visible: true,
      });
      setShowModal(false);
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: "Oops!",
      message: ERROR_MESSAGES.products.update,
      type: "error",
      visible: true,
    });
  }
  setTimeout(() => {
    window.location.reload();
  }, 1500);
  setIsLoading(false);
};

export const Delete = async (data, setNotify, setProgress) => {
  setProgress(true);
  try {
    let ids = [];
    data.map((product) => ids.push(product.id));
    let response = await api.delete(COUPONS_URL + "delete_multiple/", {
      data: { ids: ids },
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Operação realizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }
  setProgress(false);
};

export const ChangeStatus = async (data, setNotify, setProgress) => {
  setProgress(true);

  try {
    let response = await api.put(COUPONS_URL + data.id + "/", {
      status: parseInt(data.status),
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Status atualizado com sucesso!",
        type: "success",
        visible: true,
      });
      setProgress(false);
      return true;
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: ERROR_MESSAGES.orders.status,
      type: "error",
      visible: true,
    });
    setProgress(false);
    return false;
  }
};

export const GetValidOnly = async () => {
  let response = await api.get(`${COUPONS_URL}valid_only/`);
  try {
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = APP_BASE_URL;
    } else {
        Swal.fire("Oops!", error.response.data, 'error')
    }
  }
};
