import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { Button, Card, Col, Accordion, ListGroup } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  CreateStore,
  UpdateStore,
  FindPlace,
} from "../services/ConfigurationsServices";
import { Hash } from "../helpers/Helpers";
import DefaultLoader from "../jsx/components/Loader";
import { GetOne } from "../services/StoreServices";
import UserContext from "../contexts/UserContext";
import AlertResponses from "../jsx/components/Alert";
import { AppSettings } from "../App";
import Swal from "sweetalert2";

const styles = {
  thumb: {
    maxWidth: 250,
    maxHeight: 165,
  },
  imageDefault: {
    height: 200,
    width: 200,
    margin: "auto",
  },
  logo: {
    maxWidth: 200,
    maxHeight: 200,
    margin: "auto",
  },
  splashScreen: {
    width: "100%",
    margin: "auto",
    minHeight: 500,
    objectFit: "contain",
    border: "1px solid #eaeaea",
    boxShadow: "0px 12px 23px 0px rgb(62 63 122 / 4%)",
  },
  info: {
    fontSize: "1.25rem",
    verticalAlign: "sub",
  },
};

export default function StoreDetails(props) {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const {
    register: registerStore,
    handleSubmit: handleSubmitStore,
    setValue: setValueStore,
    control,
    clearErrors: clearErrorsStore,
    setError: setErrorStore,

    formState: { errors: errorsStore },
  } = useForm();

  const [store, setStore] = useState(
    props.location.store ? props.location.store : ""
  );
  const [image, setImage] = useState(null);
  const [collapsed, setCollapsed] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState(null);
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (!id) {
      setStore("");
      setIsLoading(false);
    } else if (!props.location.store) {
      let cc = false;
      if (window.location.href.includes("?cc=true")) {
        cc = true;
      }
      GetOne(id, setStore, setErrors, setIsLoading, cc);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user && user?.superuser_level != (1 || 2)) {
      window.location.href = "/";
    }
  }, [user]);

  const imagePreview = (image, set) => {
    if (image?.size > 2000000) {
      Swal.fire(
        "Atenção!",
        "Imagem muito pesada! Por favor, insira imagens até 2MB.",
        "info"
      );
      return;
    }
    let url = URL.createObjectURL(image);
    set(url);
  };

  const onSubmitStore = (value) => {
    let form = new FormData();
    let obj = {};
    let counter = 0;

    document.querySelectorAll(".input-opening-hours").forEach((input) => {
      obj[input.name] = input.value;
      if (!input.value) counter++;
    });

    if (counter === 14) {
      setErrorStore("openingHours", {
        message: "",
        type: "required",
      });

      setTimeout(() => {
        clearErrorsStore("openingHours");
      }, 4000);
      return;
    }

    if (value.title) form.append("title", value.title);
    if (value.name) form.append("title", value.name);
    if (store.title && store.title === "Matriz")
      form.append("title", store.title);

    form.append("razaoSocial", value.razaoSocial);
    form.append("name", store.main ? value.name : "");
    form.append("email", value.email);
    form.append("description", store.main ? value.description : "");
    form.append("openingHours", JSON.stringify(obj));
    form.append("company_type", value.companyType);
    form.append("plan_id", user.plan_id);

    if (value.cnpj) {
      value.cnpj = value.cnpj.replace(/[^0-9]/g, "");
    }

    if (address || AppSettings.store.address) {
      let add = AppSettings.store.address;
      form.append(
        "address",
        JSON.stringify({
          street: address?.address?.street
            ? address.address.street
            : add.street,
          number: value.number ? value.number : add.number,
          complement: value?.complement,
          neighborhood: address?.address?.district
            ? address.address.district
            : add.neighborhood,
          city: address?.address?.city ? address.address.city : add.city,
          state: address?.address?.state ? address.address.state : add.state,
          zipcode: value.zipcode
            ? value.zipcode.replace("-", "")
            : setErrorStore("zipcode", { type: "required", message: "" }),
          latitude: address?.position?.lat
            ? address.position.lat
            : add.latitude,
          longitude: address?.position?.lng
            ? address.position.lng
            : add.longitude,
          complete_address: address.title
            ? `${address.address.street}, ${value.number}, ${
                address.address.district
              }, ${address.address.city} - ${address.address.stateCode}, ${
                address.address.postalCode ? address.address.postalCode : ""
              }, ${address.address.countryName}`
            : add.complete_address,
        })
      );
    }

    let phone = document.querySelector("#phone").value;
    let cellPhone = document.querySelector("#cellPhone").value;

    if (phone != ("" || null || undefined)) {
      form.append("phone", phone.replace(/[^0-9]/g, ""));
    } else {
      form.append(
        "phone",
        value.phone == undefined || "" ? "" : value.phone.replace(/[^0-9]/g, "")
      );
    }

    if (cellPhone != ("" || null || undefined)) {
      form.append("cellPhone", cellPhone.replace(/[^0-9]/g, ""));
    } else {
      form.append(
        "cellPhone",
        value.cellPhone == undefined || ""
          ? ""
          : value.cellPhone.replace(/[^0-9]/g, "")
      );
    }

    value.image.length > 0 && form.append("image", value.image[0]);
    //form.append("store", process.env.REACT_APP_DOMAIN);
    form.append("user", user.user_id);
    form.append("url", AppSettings.store.url);
    form.append("main", AppSettings.store.main);

    if (store.id) {
      UpdateStore(store.id, form, setNotify);
    } else if (user?.plan_id > 3) {
      form.append("cnpj", value.cnpj);
      CreateStore(form, setNotify, false, false);
    }
  };

  return isLoading ? (
    <DefaultLoader />
  ) : (
    <Col id="store-details-page" xl="12" className="d-inline-block">
      <Card className="mb-3 align-items-center">
        <Card.Header className="m-auto flex-column">
          <Card.Title>
            {store.main ? "Dados Loja Principal" : "Dados Filial"}
          </Card.Title>
        </Card.Header>
        <Card.Body className="card-body w-100 p-3 d-flex flex-column align-items-center">
          <form className="w-100" onSubmit={handleSubmitStore(onSubmitStore)}>
            <div className="form-row flex-column align-items-center mb-5">
              {store.image || image ? (
                <img
                  className="img-fluid"
                  src={
                    image === null
                      ? Hash("400x400", "store/" + store.image.split("/").pop())
                      : image
                  }
                  alt="image"
                  style={styles.imageDefault}
                />
              ) : (
                <div
                  className="image-default rounded-circle"
                  style={styles.imageDefault}
                >
                  <i className="ti-image"></i>
                </div>
              )}

              <div className="input-group mt-3 w-50">
                <div className="input-group-prepend">
                  <span className="input-group-text">Upload</span>
                </div>
                <div className="custom-file">
                  <input
                    accept="image/*"
                    type="file"
                    className="custom-file-input"
                    {...registerStore("image", {
                      required: store.id ? false : true,
                    })}
                    onChange={(e) => imagePreview(e.target.files[0], setImage)}
                  />
                  <label className="custom-file-label text-left">
                    Imagem do Local...
                  </label>
                </div>
              </div>
              {errorsStore.image && (
                <small className="mt-3 text-danger d-block">
                  {errorsStore.image.type === "required" &&
                    "Por favor, selecione uma imagem!"}
                </small>
              )}
            </div>
            <div className="form-row">
              <div className="form-group col-md-8 d-inline-block">
                <label className="pl-2">Razão Social</label>
                <input
                  {...registerStore("razaoSocial", {
                    required: true,
                  })}
                  className="form-control text-black"
                  placeholder="Razão social"
                  defaultValue={store ? store.razaoSocial : ""}
                />
                {errorsStore.razaoSocial && (
                  <small className="ml-2 text-danger">
                    Por favor, preencha este campo!
                  </small>
                )}
              </div>
              <div className="form-group col-md-4 d-inline-block">
                <label className="pl-2">CNPJ</label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="cnpj"
                  defaultValue={
                    store && store.cnpj ? setValueStore("cnpj", store.cnpj) : ""
                  }
                  render={({ field: { onChange, defaultValue, name } }) => (
                    <NumberFormat
                      defaultValue={store && store.cnpj ? store.cnpj : ""}
                      format="##.###.###/####-##"
                      placeholder="CNPJ"
                      className="form-control text-black"
                      onChange={(e) =>
                        e.target.value.length > 8 &&
                        setValueStore("cnpj", e.target.value)
                      }
                      disabled={id ? true : false}
                    />
                  )}
                />
                {errorsStore.cnpj && (
                  <small className="ml-2 text-danger">
                    {errorsStore.cnpj.type === "required" &&
                      "Por favor, preencha este campo!"}
                  </small>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                {!store.main ? (
                  <>
                    <label className="pl-2">Identificação da Loja</label>
                    <input
                      {...registerStore("title", {
                        required: true,
                        maxLength: 30,
                      })}
                      className="form-control text-black"
                      defaultValue={store ? store.title : ""}
                      placeholder="Nome interno da loja"
                    />
                  </>
                ) : (
                  <>
                    <label className="pl-2">Nome do estabelecimento</label>
                    <input
                      {...registerStore("name", {
                        required: true,
                        maxLength: 30,
                      })}
                      className="form-control text-black"
                      defaultValue={store ? store.name : ""}
                      placeholder="Nome do estabelecimento"
                    />
                  </>
                )}

                {errorsStore.name && (
                  <small className="ml-2 text-danger">
                    {errorsStore.name.type === "required"
                      ? "Por favor, preencha este campo!"
                      : "Este campo deve conter no máximo 30 caracteres!"}
                  </small>
                )}
                {errorsStore.title && (
                  <small className="ml-2 text-danger">
                    {errorsStore.title.type === "required"
                      ? "Por favor, preencha este campo!"
                      : "Este campo deve conter no máximo 30 caracteres!"}
                  </small>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="pl-2">Tipo da empresa</label>
                <select
                  defaultValue={store ? store.company_type : ""}
                  className="form-control"
                  {...registerStore("companyType", { required: true })}
                >
                  <option value="option" disabled>
                    Selecione uma opção
                  </option>
                  <option value="MEI">MEI</option>
                  <option value="INDIVIDUAL">Empresa Individual</option>
                  <option value="LIMITED">Empresa Limitada</option>
                  <option value="ASSOCIATION">Associação (S.A)</option>
                </select>

                {errorsStore.companyType && (
                  <small className="ml-2 text-danger">
                    {errorsStore.companyType.type === "required" &&
                      "Por favor, selecione uma opção!"}
                  </small>
                )}
              </div>
            </div>
            <div className="form-row">
              <label className="pl-2">Contato</label>
              <div className="form-group col-md-12 d-flex flex-row">
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="cellPhone"
                  defaultValue={
                    store && store.cellPhone
                      ? setValueStore("cellPhone", store.cellPhone)
                      : ""
                  }
                  render={({ field: { onChange, defaultValue, name } }) => (
                    <NumberFormat
                      id="cellPhone"
                      defaultValue={
                        store && store.cellPhone ? store.cellPhone : ""
                      }
                      format="(##) #####-####"
                      className="form-control text-black col-sm-6 mb-2 mr-1"
                      placeholder="Celular"
                      onChange={(e) =>
                        e.target.value.length > 10 &&
                        setValueStore("cellPhone", e.target.value)
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, defaultValue, name } }) => (
                    <NumberFormat
                      id="phone"
                      defaultValue={store ? store.phone : ""}
                      format="(##) ####-####"
                      className="form-control text-black col-sm-6 mb-2"
                      placeholder="Telefone Fixo"
                      // onChange={(e) =>
                      //     e.target.value.length > 8 &&
                      //     setValueStore("phone", e.target.value)
                      // }
                      // {...registerStore("phone")}
                    />
                  )}
                />
              </div>
              {errorsStore.cellPhone && (
                <small className="ml-3 text-danger">
                  {errorsStore.cellPhone.type === "required" &&
                    "Por favor, preencha este campo!"}
                </small>
              )}
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="pl-2">E-mail</label>
                <input
                  className="form-control text-black"
                  defaultValue={store ? store.email : ""}
                  {...registerStore("email", {
                    required: true,
                    pattern: /^[a-zA-Z0-9\_\-\.]+@[a-zA-Z0-9]+.[a-zA-Z]/,
                  })}
                  placeholder="E-mail para contato"
                />
                {errorsStore.email && (
                  <small className="ml-2 text-danger">
                    {errorsStore.email.type === "required"
                      ? "Por favor, preencha este campo!"
                      : "Preencha corretamente o e-mail de contato!"}
                  </small>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="pl-2"></label>
                <div className="accordion__item">
                  <Accordion
                    className="accordion accordion-active-header"
                    defaultActiveKey="1"
                  >
                    <Accordion.Toggle
                      as={Card.Text}
                      className={`accordion__header ${
                        collapsed === 0 ? "collapsed" : ""
                      } accordion__header--primary p-2 mb-0`}
                      eventKey="0"
                      onClick={() =>
                        collapsed ? setCollapsed(0) : setCollapsed(1)
                      }
                    >
                      <span className="accordion__header--icon"></span>
                      <span className="accordion__header--text">
                        Horário de funcionamento
                      </span>
                      <span className="accordion__header--indicator "></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      className="accordion__body"
                      eventKey="0"
                    >
                      <Card.Body className="opening-hours text-center">
                        <div className="form-row int">
                          <div className="form-group int-one mr-2">
                            <label className="d-block text-left">
                              Intervalo 1
                            </label>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  format="##:## - ##:##"
                                  placeholder="Segunda-feira"
                                  className="form-control text-black input-opening-hours"
                                  style={{ maxWidth: "100%" }}
                                  name="monday"
                                  defaultValue={store.openingHours?.monday}
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("monday", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("monday", {
                                        invalid: false,
                                      });
                                      setValueStore("monday", e.target.value);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.monday?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                          <div className="form-group int-two">
                            <label className="d-block text-left">
                              Intervalo 2
                            </label>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="mondayInt2"
                                  defaultValue={store.openingHours?.mondayInt2}
                                  format="##:## - ##:##"
                                  placeholder="Segunda-feira"
                                  className="form-control text-black input-opening-hours"
                                  style={{ maxWidth: "100%" }}
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("mondayInt2", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("mondayInt2", {
                                        invalid: false,
                                      });
                                      setValueStore(
                                        "mondayInt2",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.mondayInt2?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="form-row int">
                          <div className="form-group int-one mr-2">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="tuesday"
                                  defaultValue={store.openingHours?.tuesday}
                                  format="##:## - ##:##"
                                  placeholder="Terça-feira"
                                  className="form-control text-black input-opening-hours"
                                  style={{ maxWidth: "100%" }}
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("tuesday", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("tuesday", {
                                        invalid: false,
                                      });
                                      setValueStore("tuesday", e.target.value);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.tuesday?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                          <div className="form-group int-two">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="tuesdayInt2"
                                  defaultValue={store.openingHours?.tuesdayInt2}
                                  format="##:## - ##:##"
                                  placeholder="Terça-feira"
                                  className="form-control text-black input-opening-hours"
                                  style={{ maxWidth: "100%" }}
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("tuesdayInt2", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("tuesdayInt2", {
                                        invalid: false,
                                      });
                                      setValueStore(
                                        "tuesdayInt2",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.tuesdayInt2?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="form-row int">
                          <div className="form-group int-one mr-2">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="wednesday"
                                  defaultValue={store.openingHours?.wednesday}
                                  format="##:## - ##:##"
                                  placeholder="Quarta-feira"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("wednesday", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("wednesday", {
                                        invalid: false,
                                      });
                                      setValueStore(
                                        "wednesday",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.wednesday?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>

                          <div className="form-group int-two mr-2">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="wednesdayInt2"
                                  defaultValue={
                                    store.openingHours?.wednesdayInt2
                                  }
                                  format="##:## - ##:##"
                                  placeholder="Quarta-feira"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("wednesdayInt2", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("wednesdayInt2", {
                                        invalid: false,
                                      });
                                      setValueStore(
                                        "wednesdayInt2",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.wednesdayInt2?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="form-row int">
                          <div className="form-group int-one mr-2">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="thursday"
                                  defaultValue={store.openingHours?.thursday}
                                  format="##:## - ##:##"
                                  placeholder="Quinta-feira"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("thursday", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("thursday", {
                                        invalid: false,
                                      });
                                      setValueStore("thursday", e.target.value);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.thursday?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>

                          <div className="form-group int-two">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="thursdayInt2"
                                  defaultValue={
                                    store.openingHours?.thursdayInt2
                                  }
                                  format="##:## - ##:##"
                                  placeholder="Quinta-feira"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("thursdayInt2", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("thursdayInt2", {
                                        invalid: false,
                                      });
                                      setValueStore(
                                        "thursdayInt2",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.thursdayInt2?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="form-row int">
                          <div className="form-group int-one mr-2">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="friday"
                                  defaultValue={store.openingHours?.friday}
                                  format="##:## - ##:##"
                                  placeholder="Sexta-feira"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("friday", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("friday", {
                                        invalid: false,
                                      });
                                      setValueStore("friday", e.target.value);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.friday?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>

                          <div className="form-group int-two">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="fridayInt2"
                                  defaultValue={store.openingHours?.fridayInt2}
                                  format="##:## - ##:##"
                                  placeholder="Sexta-feira"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("fridayInt2", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("fridayInt2", {
                                        invalid: false,
                                      });
                                      setValueStore(
                                        "fridayInt2",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.fridayInt2?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="form-row int">
                          <div className="form-group int-one mr-2">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="saturday"
                                  defaultValue={store.openingHours?.saturday}
                                  format="##:## - ##:##"
                                  placeholder="Sábado"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("saturday", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("saturday", {
                                        invalid: false,
                                      });
                                      setValueStore("saturday", e.target.value);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.saturday?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                          <div className="form-group int-two">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="saturdayInt2"
                                  defaultValue={
                                    store.openingHours?.saturdayInt2
                                  }
                                  format="##:## - ##:##"
                                  placeholder="Sábado"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("saturdayInt2", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("saturdayInt2", {
                                        invalid: false,
                                      });
                                      setValueStore(
                                        "saturdayInt2",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.saturdayInt2?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="form-row int">
                          <div className="form-group int-one mr-2">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="sunday"
                                  defaultValue={store.openingHours?.sunday}
                                  format="##:## - ##:##"
                                  placeholder="Domingo"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("sunday", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("sunday", {
                                        invalid: false,
                                      });
                                      setValueStore("sunday", e.target.value);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.sunday?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                          <div className="form-group int-two">
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, defaultValue, name },
                              }) => (
                                <NumberFormat
                                  name="sundayInt2"
                                  defaultValue={store.openingHours?.sundayInt2}
                                  format="##:## - ##:##"
                                  placeholder="Domingo"
                                  className="form-control text-black input-opening-hours"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length > 1 &&
                                      e.target.value.replace(/[^0-9]/g, "")
                                        .length < 7
                                    ) {
                                      setErrorStore("sundayInt2", {
                                        invalid: true,
                                      });
                                    } else {
                                      setErrorStore("sundayInt2", {
                                        invalid: false,
                                      });
                                      setValueStore(
                                        "sundayInt2",
                                        e.target.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errorsStore?.sundayInt2?.invalid && (
                              <small className="ml-2 text-danger">
                                Por favor, preencha corretamente este campo!
                              </small>
                            )}
                          </div>
                        </div>

                        <small className="mt-2">
                          <i
                            className="ti-info-alt text-info mr-1"
                            style={styles.info}
                          ></i>{" "}
                          Para fechado, basta deixar o campo do dia em branco.
                        </small>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
                {errorsStore.openingHours && (
                  <small className="ml-2 mb-2 text-danger">
                    {errorsStore.openingHours.type === "required" &&
                      "Por favor, preencha seu horário de atendimento!"}
                  </small>
                )}
              </div>
              {errorsStore.status && (
                <small className="ml-2 mb-2 text-danger">
                  {errorsStore.expiration.type === "required" &&
                    "Por favor, preencha corretamente este campo!"}
                </small>
              )}
            </div>
            <div className="form-row position-relative">
              <div className="col-lg-10 col-md-9 col-sm-12">
                <label className="pl-2">Endereço</label>
                <div className="form-group">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    defaultValue={
                      store.address ? store.address.complete_address : ""
                    }
                    {...registerStore("address", { required: true })}
                    onClick={() => {
                      let d = document.querySelector("#location-suggestions");
                      if (d.classList.value.includes("d-none")) {
                        d.classList.remove("d-none");
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value.length > 3) {
                        FindPlace(e.target.value, setNotify).then((res) => {
                          setSuggestions(res.items);
                        });
                      }
                    }}
                  />
                </div>
                {errorsStore.address && (
                  <small className="ml-2 mb-2 text-danger">
                    {errorsStore.address.type === "required" &&
                      "Por favor, preencha corretamente este campo!"}
                  </small>
                )}
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12">
                <label className="pl-2">Nº</label>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={store.address ? store.address.number : ""}
                    {...registerStore("number", { required: true })}
                  />
                  {errorsStore.number && (
                    <small className="ml-2 mb-2 text-danger">
                      {errorsStore.number.type === "required" &&
                        "Por favor, preencha corretamente este campo!"}
                    </small>
                  )}
                </div>
              </div>

              <div id="location-suggestions">
                <ListGroup as="ul">
                  {suggestions &&
                    suggestions.map((v, i) => (
                      <ListGroup.Item
                        key={i}
                        as="li"
                        className="mt-0"
                        onClick={() => {
                          document
                            .querySelector("#location-suggestions")
                            .classList.add("d-none");
                          document.querySelector(
                            'input[name="address"]'
                          ).value = `${v.title}`;
                          if (v.address && v.address.postalCode) {
                            document.querySelector(
                              'input[name="zipcode"]'
                            ).value = `${v.address.postalCode}`;
                            setValueStore("zipcode", v.address.postalCode);
                          }

                          if (v.address && v.address.houseNumber) {
                            document.querySelector(
                              'input[name="number"]'
                            ).value = `${v.address.houseNumber}`;
                            setValueStore("number", v.address.houseNumber);
                          }

                          setValueStore("address", v.title);
                          setAddress(v);
                        }}
                      >
                        {`${v.title}`}
                      </ListGroup.Item>
                    ))}
                  {document.querySelector("#location-suggestions ul li") !==
                    null && (
                    <span
                      className="badge"
                      onClick={() => {
                        let d = document.querySelector("#location-suggestions");
                        if (!d.classList.value.includes("d-none")) {
                          d.classList.add("d-none");
                        }
                      }}
                    >
                      Fechar
                    </span>
                  )}
                </ListGroup>
              </div>
            </div>
            <div className="form-row position-relative">
              <div className="col-lg-6 col-md-6 col-sm-12 pl-2">
                <div className="d-flex justify-content-between">
                  <label className="pl-2">CEP</label>
                  <label>
                    <a
                      href="https://www2.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm"
                      target={"_blank"}
                      className="pr-3"
                    >
                      Não sei o CEP
                    </a>
                  </label>
                </div>
                <Controller
                  name="zipcode"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    store && store.address.zipcode
                      ? setValueStore("zipcode", store.address.zipcode)
                      : ""
                  }
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      format="#####-###"
                      mask="_"
                      className="form-control p-2"
                      defaultValue={
                        store && store.address.zipcode
                          ? store.address.zipcode
                          : ""
                      }
                      placeholder={"Digite seu CEP"}
                      onChange={(e) => {
                        if (
                          e.target.value.length === 9 &&
                          !e.target.value.includes("_")
                        ) {
                          setValueStore("zipcode", e.target.value);
                          if (errors?.zipcode) clearErrorsStore("zipcode");
                        }
                      }}
                    />
                  )}
                />

                {errorsStore.zipcode && (
                  <div className="col-lg-12 text-center">
                    <small className="text-danger">
                      {errorsStore.zipcode.type === "required" &&
                        "Por  favor, preencha o CEP!"}
                      {errorsStore.zipcode.type === "invalid" &&
                        "Preencha corretamente seu CEP!"}
                    </small>
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label className="pl-2">Complemento</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Complemento"
                    defaultValue={
                      store && store.address.complement
                        ? store.address.complement
                        : ""
                    }
                    {...registerStore("complement")}
                  />
                </div>
              </div>
            </div>
            {store.main && (
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="pl-2">Sobre</label>
                  <textarea
                    defaultValue={store ? store.description : ""}
                    className="form-control"
                    placeholder="Breve história da empresa..."
                    {...registerStore("description", { required: true })}
                    rows={6}
                  ></textarea>
                </div>
                {errorsStore.description && (
                  <small className="ml-2 mb-2 text-danger">
                    {errorsStore.description.type === "required" &&
                      "Por favor, preencha este campo!"}
                  </small>
                )}
              </div>
            )}

            <Button type="submit" className="btn btn-block btn-primary">
              {isLoading ? <DefaultLoader color={"white"} /> : "Salvar"}
            </Button>
          </form>
        </Card.Body>
      </Card>
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
    </Col>
  );
}
