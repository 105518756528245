import React, { useCallback, useEffect, useRef, useState } from "react";
import { parseCookies } from "nookies";
import { api } from "./api/Middleware";
import { STORE_URL } from "./api/Urls";
import Markup from "./jsx";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ValidateRecaptcha } from "./services/StoreServices";

export const AppSettings = {};
export const Theme = {};

const App = () => {
  const { token: _token } = parseCookies();
  const pathRef = useRef(window.location.pathname);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(true);
  const storeData = localStorage.getItem("r_storeData");

  const GetConfigs = useCallback(async (user) => {
    try {
      const response = await api.get(`${STORE_URL}${user.store_id}/`);
      if (response.status === 200) {
        const obj = response.data;
        Object.assign(AppSettings, {
          store: obj,
          logo: obj.logo,
          logo_dark: obj.logo_dark,
          moneyFormat: {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "BRL",
          },
          facebookAppId: "4433810360015071",
          googlepAppId:
            "361008005922-bncl10nuk4d2rfi7bamoqhhr1kldd3o0.apps.googleusercontent.com",
          hereApiKey: "-qm6pEDnHjBi5jzKNtCdp6KTpmyx4qyRhD-dYij5LVg",
          mapBoxApiKey:
            "pk.eyJ1Ijoic2VyZ2lvemFuaW5vdHRpIiwiYSI6ImNrOXJsdnN5YzB2cHAzZG8zd2thcmd2bzEifQ.sZA22f03xHvpGceZtbxc3A",
        });

        const colors = obj.customization.colors;
        Object.assign(Theme, {
          colors: {
            theme: colors.theme,
            background: colors.background,
            primary: colors.primary,
            primaryHover: colors.primaryHover,
            tertiary: colors.tertiary,
            titles: colors.titles,
            secondary: colors.subtitles,
            success: "#10ac84",
            value: colors.value,
          },
        });

        document.documentElement.style.setProperty("--success", "#10ac84");
      }
      window.history.pushState(null, "", window.location.origin + "/");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      if (AppSettings.store && AppSettings.store.configs.length === 0) {
        document.querySelector("#tutorial-component span")?.click();
      } else if (
        AppSettings.store &&
        AppSettings.store.configs.length > 0 &&
        AppSettings.store.configs[0].show_tutorial_initial === 0
      ) {
        document.querySelector("#tutorial-component span")?.click();
      }
    }
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha();
    const res = await ValidateRecaptcha({ token: token });
    if (!res) window.location.href = "https://google.com";
  }, [executeRecaptcha]);

  useEffect(() => {
    console.log(process.env);
    // handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    if (!_token && !window.location.href.includes("/cadastro")) {
      if (pathRef.current !== "/login") {
        window.location.assign(`/login`);
        return;
      }
    } else if (_token && storeData) {
      if (pathRef.current !== "/cadastro") {
        window.location.assign(`/cadastro`);
        return;
      }
    }

    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      GetConfigs(user);
    } else {
      setIsLoading(false);
    }

    const body = document.querySelector("body");
    if (body) {
      body.setAttribute("data-typography", "poppins");
      body.setAttribute("data-theme-version", "light");
      body.setAttribute("data-layout", "vertical");
      body.setAttribute("data-nav-headerbg", "color_1");
      body.setAttribute("data-headerbg", "color_1");
      body.setAttribute("data-sidebar-style", "full");
      body.setAttribute("data-sibebarbg", "color_1");
      body.setAttribute("data-primary", "color_1");
      body.setAttribute("data-sidebar-position", "fixed");
      body.setAttribute("data-header-position", "fixed");
      body.setAttribute("data-container", "wide");
      body.setAttribute("direction", "ltr");

      if (window.innerWidth < 768) {
        body.setAttribute("data-sidebar-style", "overlay");
      } else {
        body.setAttribute("data-sidebar-style", "mini");
      }
    }

    const savePathBeforeUnload = () => {
      sessionStorage.setItem("previousPath", pathRef.current);
    };

    const restorePreviousPathOnLoad = () => {
      const previousPath = sessionStorage.getItem("previousPath");
      if (previousPath && pathRef.current !== previousPath) {
        window.history.replaceState(null, "", previousPath);
      }
    };

    if (_token) {
      window.addEventListener("beforeunload", savePathBeforeUnload);
      window.addEventListener("load", restorePreviousPathOnLoad);
    }

    return () => {
      if (_token) {
        window.removeEventListener("beforeunload", savePathBeforeUnload);
        window.removeEventListener("load", restorePreviousPathOnLoad);
      }
    };
  }, [_token, storeData, GetConfigs]);

  return <Markup isLoading={isLoading} token={_token} />;
};

export default App;