import Swal from 'sweetalert2'
import { api } from '../api/Middleware'
import {
  VW_ITEMS,
  PRODUCTS_URL,
  CATEGORIES_URL,
  VARIATIONS_URL
} from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetOne = async (id, setProduct) => {
  try {
    let response = await api.get(PRODUCTS_URL + id + '/')
    if (response.status === 200) {
      setProduct(response.data)
    }
  } catch (error) {
    //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
    /*  if(error.status !== 401){
             
             
         }  */
  }
}

export const GetAll = async (
  setProducts,
  setIsLoading,
  search = false,
  returnData = false
) => {
  let response = await api.get(`${PRODUCTS_URL}${search ? search : ''}`)
  try {
    if (response.status === 200) {
      if (returnData) return response.data
      setProducts(response.data)
    }
  } catch (error) {
    if (returnData) return error.response
    if (error.response.status !== 401) throw error.response.data
  }
  if (!returnData) setIsLoading(false)
}

export const Create = async (
  data,
  setProduct,
  setNotify,
  setShowPlansModal,
  returnData = false,
  submitBtn=false
) => {
  try {
    let response = await api.post(PRODUCTS_URL, data)
    if (response.status === 200) {
      if (returnData) return response.data
      if (response.data.product.code === 200) {
        if (response.data.variations && response.data.variations.code === 200) {
          setProduct(response.data.product.data)
          setNotify({
            title: 'Sucesso!',
            message: 'Produto cadastrado com sucesso!',
            type: 'success',
            visible: true
          })
          setTimeout(() => {
            window.location.assign(`/products`)
          }, 2000)
        } else if (
          response.data.variations &&
          response.data.variations.code === 206
        ) {
          setProduct(response.data.product.data)
          Swal.fire({
            title: 'Cadastrado com ressalvas',
            html: response.data.variations.data,
            icon: 'success',
            buttons: true,
            confirmButtonText: `Entendi`,
            showCancelButton: false,
            confirmButtonColor: '#2bc155'
          }).then(value => {
            if (value.isConfirmed) {
              window.location.assign(`/products`)
            }
          })
        } else {
          setProduct(response.data.product.data)
          setNotify({
            title: 'Sucesso!',
            message: 'Produto cadastrado com sucesso!',
            type: 'success',
            visible: true
          })
          setTimeout(() => {
            window.location.assign(`/products`)
          }, 2000)
        }
      }

      //window.history.pushState(null, '', window.location.href + response.data.id);
    }
  } catch (error) {
    if (returnData) return error.response
    //if(error.status !== 401) throw error.response;
    
    if (error.response?.status === 402) {
      Swal.fire({
        title: 'Oops!',
        html: error.response.data,
        icon: 'warning',
        buttons: true,
        confirmButtonText: `Fazer upgrade agora!`,
        cancelButtonText: 'Adiar',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary)'
      }).then(value => {
        if (value.isConfirmed) {
          setShowPlansModal(true)
        } else {
          window.location.assign(`/products`)
        }
      })
    } else {
      setNotify({
        title: 'Oops!',
        message: error.response.data,
        type: 'error',
        visible: true
      })
      let btn = document.querySelector('.btn-submit')
      btn.removeAttribute('disabled')
    }
    
    if(submitBtn){
      submitBtn.current.innerHTML = 'Salvar'
      submitBtn.current.removeAttribute('disabled')
    }
  }
}

export const Update = async (
  id,
  data,
  setProduct,
  setNotify,
  setShowPlansModal,
  returnData = false,
  submitBtn=false
) => {
  try {
    let response = await api.put(PRODUCTS_URL + id + '/', data)
    if (response.status === 200) {
      if (returnData) return response.data
      setProduct(response.data)
      setNotify({
        title: 'Sucesso!',
        message: 'Produto atualizado com sucesso!',
        type: 'success',
        visible: true
      })
       setTimeout(() => {
        window.location.assign(`/products`)
      }, 2000)
    } else if (response.status === 206) {
      setProduct(response.data)
      Swal.fire({
        title: 'Cadastrado com ressalvas',
        html: response.data,
        icon: 'success',
        buttons: true,
        confirmButtonText: `Fazer upgrade agora!`,
        cancelButtonText: 'Adiar',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary)'
      }).then(value => {
        if (value.isConfirmed) {
          setShowPlansModal(true)
        } else {
          window.location.assign(`/products`)
        }
      })
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    if (returnData) return error
    if (error.response.status === 402) {
      Swal.fire({
        title: 'Oops!',
        text:
          error.response.data?.length && error.response.data?.length > 0
            ? error.response.data[0]
            : error.response.data,
        icon: 'info',
        confirmButtonText: `Fazer upgrade agora!`,
        cancelButtonText: 'Adiar',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary)'
      }).then(value => {
        if (value.isConfirmed) {
          setShowPlansModal(true)
        }
      })
    } else {
      setNotify({
        title: 'Oops!',
        message: error.response.data,
        type: 'error',
        visible: true
      })
    }

    if(submitBtn?.current){
      submitBtn.current.innerHTML = 'Salvar'
      submitBtn.current.removeAttribute('disabled')
    }
  }
}

export const Delete = async (data, setNotify) => {
  try {
    let ids = []
    data.map(product => ids.push(product.id))
    let response = await api.delete(PRODUCTS_URL + 'delete_multiple/', {
      data: { ids: ids }
    })
    if (response.status === 200) {
      setNotify({
        title: 'Sucesso!',
        message: 'Operação realizada com sucesso!',
        type: 'success',
        visible: true
      })
      setTimeout(() => {
        window.location.assign(`/products`)
      }, 2500)
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: 'Oops!',
      message: error.response.data,
      type: 'error',
      visible: true
    })
  }
}

export const GetCategories = async (setCategories, returnData = false) => {
  try {
    let response = await api.get(CATEGORIES_URL)
    if (response.status === 200) {
      if (returnData) return response.data
      setCategories(response.data)
    }
  } catch (error) {
    if (error.status !== 401) throw error.response
  }
}

export const CopyProducts = async (data, setNotify, setShowModal) => {
  try {
    let response = await api.post(PRODUCTS_URL + 'copy/', data)
    if (response.status === 200) {
      setNotify({
        title: 'Sucesso!',
        message: 'Produtos copiados com sucesso!',
        type: 'success',
        visible: true
      })
    }
  } catch (error) {
    console.log(error.response.data)
    setNotify({
      title: 'Oops!',
      message: error.response.data,
      type: 'error',
      visible: true
    })
  } finally {
    setShowModal(false)
  }
}

export const DeleteVariationGroup = async (id, setNotify) => {
  try {
    let response = await api.delete(`${VARIATIONS_URL}${id}/`)
    if (response.status === 200) {
      setNotify({
        title: 'Sucesso!',
        message: 'Grupo excluído com sucesso!',
        type: 'success',
        visible: true
      })
    }
  } catch (error) {
    setNotify({
      title: 'Oops!',
      message: error.response.data,
      type: 'error',
      visible: true
    })
  }
}

export const DeleteVariationOption = async (id, setNotify) => {
  try {
    let response = await api.delete(`${VARIATIONS_URL}delete_option/${id}/`)
    if (response.status === 200) {
      setNotify({
        title: 'Sucesso!',
        message: 'Opção excluída com sucesso!',
        type: 'success',
        visible: true
      })
      // setTimeout(() => {
      //   window.location.assign('/products')
      // }, 3000)
    }
  } catch (error) {
    setNotify({
      title: 'Oops!',
      message: error.response.data,
      type: 'error',
      visible: true
    })
  }
}
