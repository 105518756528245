import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import Select from "react-select";
import Swal from "sweetalert2";
import SearchBox from "@tomtom-international/web-sdk-plugin-searchbox";
import * as ttServices from "@tomtom-international/web-sdk-services";

import { CreateStore } from "../../../../services/ConfigurationsServices";
import { Hash, RemoveAccents } from "../../../../helpers/Helpers";
import ModalComponent from "../../Modal";
import TermsOfUse from "../../ModalData/TermsOfUse";
import { CheckCnpj } from "../../../../services/StoreServices";
import { GetUserLatLngByIp } from "../../../../services/LocationServices";

const StepTwo = (props) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const addressData = useRef();
  const next = useRef();
  const submit = useRef();
  const ttSearchBox = useRef();

  const [number, setNumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [image, setImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const businessCategories = useRef([
    { value: "restaurante arabe", label: "Restaurante Árabe" },
    { value: "restaurante asiatico", label: "Restaurante Asiático" },
    { value: "restaurante tradicional", label: "Restaurante Tradicional" },
    { value: "cafeteria", label: "Cafeteria" },
    { value: "casa de carnes", label: "Casa de Carnes" },
    { value: "casa de sucos", label: "Casa de Sucos" },
    { value: "restaurante chines", label: "Restaurante Chinês" },
    { value: "conveniencia", label: "Conveniência" },
    { value: "doces & bolos", label: "Doces & Bolos" },
    { value: "hamburguer", label: "Hambúrguer" },
    { value: "restaurante italiano", label: "Restaurante Italiano" },
    { value: "restaurante japones", label: "Restaurante Japonês" },
    { value: "lanches", label: "Lanches" },
    { value: "restaurante mexicano", label: "Restaurante Mexicano" },
    { value: "padaria", label: "Padaria" },
    { value: "pastel", label: "Pastel" },
    { value: "pizza", label: "Pizza" },
    { value: "peixaria", label: "Peixaria" },
    { value: "restaurante saudavel", label: "Restaurante Saudável" },
    { value: "sorvetes", label: "Sorvetes" },
    { value: "restaurante tailandes", label: "Restaurante Tailandês" },
    { value: "restaurante vegano", label: "Restaurante Vegano" },
    { value: "restaurante vegetariano", label: "Restaurante Vegetariano" },
    { value: "petshop", label: "PetShop" },
  ]);
  const incomeValueOptions = [
    { value: "5000", label: "até 5 mil" },
    { value: "10000", label: "de 5 mil a 10 mil" },
    { value: "20000", label: "de 10 mil a 20 mil" },
    { value: "50000", label: "de 20 mil a 50 mil" },
    { value: "100000", label: "de 50 mil a 100 mil" },
    { value: "250000", label: "de 100 mil a 250 mil" },
    { value: "500000", label: "de 250 mil até 1 milhão" },
    { value: "1000000", label: "de 1 milhão até 5 milhões" },
    { value: "5000000", label: "acima de 5 milhões" },
  ];

  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const modalProps = {
    title: "Termos de uso do Pralevar Plataforma de Delivery",
    content: <TermsOfUse setShowModal={setShowModal} />,
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
    size: "xl",
  };

  const imagePreview = (image, set) => {
    if (
      !image.type.includes("image/png") &&
      !image.type.includes("image/jpeg") &&
      !image.type.includes("image/jpg")
    ) {
      Swal.fire(
        "Atenção!",
        "Formato de imagem inválido! Por favor, insira uma imagem no formato JPEG/JPG ou PNG.",
        "info"
      );
      return;
    }
    if (image?.size > 2000000) {
      Swal.fire(
        "Atenção!",
        "Imagem muito pesada! Por favor, insira imagens até 2MB.",
        "info"
      );
      return;
    }
    let url = URL.createObjectURL(image);
    set(url);
  };

  const onSubmit = async (value) => {
    let form = new FormData();
    let incomeValue = value.incomeValue.value;

    submit.current = document.querySelector('button[type="submit"]');
    if (submit.current) {
      submit.current.innerText = "Cadastrando sua loja...";
      submit.current.setAttribute("disabled", true);
    }

    if (incomeValue.includes("R$")) {
      incomeValue = incomeValue.replace(/[^0-9,]/g, "").replace(",", ".");
    }

    form.append("razaoSocial", value.razaoSocial);
    form.append("company_type", value.companyType);
    form.append("income_value", incomeValue);
    form.append("name", value.name);
    form.append("title", "Matriz");
    form.append("email", value.email);
    // form.append("description", value.description);
    /* form.append("openingHours", JSON.stringify(obj)) */
    form.append("main", 1);
    form.append("status", 1);
    value.image.length > 0 && form.append("image", value.image[0]);
    form.append(
      "url",
      RemoveAccents(value.name.replaceAll(" ", "")).toLowerCase() +
        ".pralevar.app"
    );
    form.append("terms_of_use", 1);

    if (value.cnpj) {
      value.cnpj = value.cnpj.replace(/[^0-9]/g, "");
    }

    form.append("cnpj", value.cnpj);

    if (!addressData.current) {
      Swal.fire(
        "Oops!",
        "Por favor, insira o endereço de sua loja utilizando a busca.",
        "warning"
      );
      return;
    } else if (
      !addressData.current.latitude ||
      !addressData.current.longitude
    ) {
      Swal.fire(
        "Oops!",
        "Por favor, insira o endereço de sua loja utilizando a busca.",
        "warning"
      );
      return;
    }

    form.append(
      "address",
      JSON.stringify({
        street: addressData.current.street,
        number: addressData.current.streetNumber,
        complement: value?.complement,
        neighborhood: addressData.current.neighborhood,
        city: addressData.current.city,
        state: addressData.current.state,
        zipcode: addressData.current.zipcode
          ? addressData.current.zipcode.replace("-", "")
          : setError("zipcode", { type: "required", message: "" }),
        latitude: addressData.current.latitude,
        longitude: addressData.current.longitude,
        complete_address: `${addressData.current.street}, ${
          addressData.current.streetNumber
        }, ${addressData.current.neighborhood}, ${addressData.current.city} - ${
          addressData.current.state
        }, ${
          addressData.current.zipcode ? addressData.current.zipcode : ""
        }, Brasil`,
      })
    );

    let phone = document.getElementsByName("phone")[0].value;
    let cellPhone = document.getElementsByName("cellPhone")[0].value;

    if (phone) {
      form.append("phone", phone.replace(/[^0-9]/g, ""));
    } else {
      form.append(
        "phone",
        value.phone == undefined || "" ? "" : value.phone.replace(/[^0-9]/g, "")
      );
    }

    if (cellPhone) {
      form.append("cellPhone", cellPhone.replace(/[^0-9]/g, ""));
    } else {
      form.append(
        "cellPhone",
        value.cellPhone == undefined || ""
          ? ""
          : value.cellPhone.replace(/[^0-9]/g, "")
      );
    }

    CreateStore(form, setNotify, true, true)
      .then((res) => {
        localStorage.setItem("r_storeData", JSON.stringify(res));
        next.current.click();
      })
      .catch((err) => {
        if (submit.current) {
          submit.current.innerText = "Avançar";
          submit.current.removeAttribute("disabled");
        }
        Swal.fire(
          "Oops!",
          err.response.data.detail || err.response.data,
          "warning"
        );
        setIsLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      let submit = document.querySelector('button[type=submit]');
      if(submit){
        submit.click();
      }
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("r_storeData"))) {
      next.current.click();
    }

    GetUserLatLngByIp();
  }, []);

  useEffect(() => {
    const initSearchBox = () => {
      let searchOptions = {
        key: process.env.REACT_APP_TOMTOM_MAPS_KEY,
        minNumberOfCharacters: 3,
        language: "pt-BR",
        limit: 5,
        countrySet: "BR",
      };

      ttSearchBox.current = new SearchBox(ttServices.services, {
        searchOptions: searchOptions,
        filterSearchResults: function (searchResult) {
          return Boolean(
            searchResult.address &&
              searchResult.position &&
              searchResult.position.lat
          );
        },
        labels: {
          noResultsMessage: "Nenhum resultado encontrado...",
          placeholder: "Endereço do seu negócio...",
        },
      });

      ttSearchBox.current.on("tomtom.searchbox.resultselected", (data) => {
        addressData.current = {
          street: data.data.result.address?.streetName,
          streetNumber: data.data.result.address?.streetNumber || "",
          neighborhood: data.data.result.address?.municipalitySubdivision,
          city: data.data.result.address.municipality,
          state: data.data.result.address.countrySubdivisionName,
          zipcode: data.data.result.address?.extendedPostalCode,
          latitude: data.data.result.position?.lat || null,
          longitude: data.data.result.position?.lng || null,
        };

        if (data.data.result.address?.extendedPostalCode) {
          setValue("zipcode", data.data.result.address.extendedPostalCode);
        }
        if (data.data.result.address?.streetNumber) {
          setValue("number", data.data.result.address.streetNumber);
        }
      });

      document
        .getElementById("searchBoxPlaceholder")
        .appendChild(ttSearchBox.current.getSearchBoxHTML());
    };

    let searchBoxPlaceholder = document.getElementById("searchBoxPlaceholder");
    if (searchBoxPlaceholder) {
      initSearchBox();
    }
  }, []);

  return (
    <section>
      <h3 className="mb-0 text-center">Dados da empresa</h3>
      <div className="row">
        <form onSubmit={handleSubmit(onSubmit)} id="stepTwo" className="w-100" onKeyDown={handleKeyDown}>
          <div className="form-row flex-column align-items-center my-5 mx-auto">
            {image ? (
              <img
                className="img-fluid rounded-circle"
                src={
                  image === null
                    ? Hash("400x400", "store/" + image.split("/").pop())
                    : image
                }
                alt="image"
                style={styles.imageDefault}
              />
            ) : (
              <div
                className="image-default rounded-circle"
                style={styles.imageDefault}
              >
                <i className="ti-image"></i>
              </div>
            )}

            <div className="input-group mt-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Upload</span>
              </div>
              <div className="custom-file">
                <input
                  accept="image/*"
                  type="file"
                  className="custom-file-input"
                  {...register("image", {
                    required: true,
                  })}
                  onChange={(e) => imagePreview(e.target.files[0], setImage)}
                />
                <label className="custom-file-label text-left">
                  Imagem do Local...
                </label>
              </div>
            </div>
            {errors.image && (
              <small className="mt-3 text-primary d-block">
                {errors.image.type === "required" &&
                  "Por favor, selecione uma imagem!"}
              </small>
            )}
          </div>
          <div className="col-lg-8 mb-2 d-inline-block">
            <div className="form-group">
              <input
                {...register("razaoSocial", { required: true })}
                className="form-control text-black"
                placeholder="Razão social"
                defaultValue={""}
              />
            </div>
            {errors.razaoSocial && (
              <small className="ml-2 text-primary">
                {errors.razaoSocial.type === "required" &&
                  "Por favor, preencha este campo!"}
              </small>
            )}
          </div>
          <div className="col-lg-4 mb-2 d-inline-block">
            <div className="form-group">
              <Controller
                control={control}
                rules={{ required: true }}
                name="cnpj"
                render={({ fields }) => (
                  <NumberFormat
                    format="##.###.###/####-##"
                    placeholder="CNPJ"
                    className="form-control text-black"
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      if (e.target.value.length > 8) {
                        setValue("cnpj", e.target.value);
                        clearErrors("cnpj");
                      }

                      if (e.target.value.length === 14) {
                        let cnpj = e.target.value;
                        CheckCnpj(cnpj);
                      }
                    }}
                    {...fields}
                  />
                )}
              />
            </div>
            {errors.cnpj && (
              <small className="ml-2 text-primary">
                {errors.cnpj.type === "required" &&
                  "Por favor, preencha este campo!"}
              </small>
            )}
          </div>
          <div className="col-lg-12 mb-2">
            <div className="form-group">
              <select
                className="form-control"
                {...register("companyType", { required: true })}
                defaultValue={""}
              >
                <option value={""} disabled>
                  Tipo da empresa
                </option>
                <option value="MEI">MEI</option>
                <option value="INDIVIDUAL">Empresa Individual</option>
                <option value="LIMITED">Empresa Limitada</option>
                <option value="ASSOCIATION">Associação (S.A)</option>
              </select>

              {errors.companyType && (
                <small className="ml-2 text-primary">
                  {errors.companyType.type === "required" &&
                    "Por favor, selecione uma opção!"}
                </small>
              )}
            </div>
          </div>
          <div className="col-lg-12 mb-2">
            <div className="form-group">
              <input
                {...register("name", {
                  required: true,
                  maxLength: 30,
                })}
                className="form-control text-black"
                defaultValue={""}
                placeholder="Nome da marca"
              />
              {errors.name && (
                <small className="ml-2 text-primary">
                  {errors.name.type === "required"
                    ? "Por favor, preencha este campo!"
                    : "Este campo deve conter no máximo 30 caracteres!"}
                </small>
              )}
            </div>
          </div>
          <div className="col-lg-6 d-inline-block mb-2">
            <Controller
              control={control}
              name="categories"
              rules={{
                validate: (value) =>
                  (value && value.length > 0) ||
                  "Selecione pelo menos uma categoria",
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  options={businessCategories.current}
                  instanceId="categories-select"
                  placeholder="Categorias do negócio"
                  value={field.value || []} // Garantindo que o valor inicial seja um array
                />
              )}
            />

            {/* {errors.categories && (
              <small className="ml-2 text-primary">
                {errors.categories.type === "required" &&
                  "Por favor, selecione uma ou mais categorias!"}
              </small>
            )} */}
          </div>
          <div className="col-lg-6 d-inline-block mb-2">
            <Controller
              control={control}
              rules={{ required: true }}
              name="incomeValue"
              render={({ field }) => (
                <Select
                  {...field}
                  options={incomeValueOptions}
                  className="rounded-form-control"
                  placeholder="Faturamento médio mensal..."
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                />
              )}
            />
            {errors.incomeValue && (
              <small className="ml-2 text-primary">
                {errors.incomeValue.type === "required" &&
                  "Por favor, informe seu faturamento médio mensal!"}
              </small>
            )}
          </div>

          <div className="col-lg-6 mb-2 d-inline-block">
            <div className="form-group">
              <NumberFormat
                defaultValue={""}
                format="(##) #####-####"
                className="form-control text-black"
                placeholder="Celular"
                onChange={(e) =>
                  e.target.value.length > 10 &&
                  setValue("cellPhone", e.target.value)
                }
                {...register("cellPhone")}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2 d-inline-block">
            <div className="form-group">
              <NumberFormat
                defaultValue={""}
                format="(##) ####-####"
                className="form-control text-black"
                placeholder="Telefone Fixo"
                onChange={(e) =>
                  e.target.value.length > 8 && setValue("phone", e.target.value)
                }
                {...register("phone")}
              />
            </div>
          </div>
          <div className="col-lg-12 mb-2">
            <div className="form-group">
              <input
                className="form-control text-black"
                defaultValue={""}
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9\_\-\.]+@[a-zA-Z0-9]+.[a-zA-Z]/,
                })}
                placeholder="E-mail para contato"
              />
              {errors.email && (
                <small className="ml-2 text-primary">
                  {errors.email.type === "required"
                    ? "Por favor, preencha este campo!"
                    : "Preencha corretamente o e-mail de contato!"}
                </small>
              )}
            </div>
          </div>

          <div className="col-lg-9 mb-2 d-inline-block">
            <div className="form-group">
              <label id="searchBoxPlaceholder" className="w-100"></label>
            </div>
          </div>
          <div className="col-lg-3 mb-2 d-inline-block">
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                placeholder="Nº"
                defaultValue={number}
                {...register("number", { required: true })}
              />
            </div>
            {errors.number && (
              <small className="ml-2 text-primary">
                {errors.number.type === "required" &&
                  "Por favor, preencha este campo!"}
              </small>
            )}
          </div>
          <div className="col-lg-6 mb-2 d-inline-block">
            <Controller
              name="zipcode"
              control={control}
              rules={{ required: true }}
              defaultValue={zipcode}
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  format="#####-###"
                  mask="_"
                  className="form-control p-2"
                  placeholder={"CEP"}
                  onChange={(e) => {
                    if (
                      e.target.value.length === 9 &&
                      !e.target.value.includes("_")
                    ) {
                      setValue("zipcode", e.target.value);
                      if (errors.zipcode) clearErrors("zipcode");
                    }
                  }}
                />
              )}
            />

            {errors.zipcode && (
              <div className="col-lg-12 text-center">
                <small className="text-primary">
                  {errors.zipcode.type === "required" &&
                    "Por  favor, preencha o CEP!"}
                  {errors.zipcode.type === "invalid" &&
                    "Preencha corretamente seu CEP!"}
                </small>
              </div>
            )}
          </div>
          <div className="col-lg-6 mb-2 d-inline-block">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Complemento"
                defaultValue={""}
                {...register("complement")}
              />
            </div>
          </div>
          {/* <div className="col-lg-12 mb-2">
            <div className="form-group">
              <textarea
                defaultValue={""}
                className="form-control"
                placeholder="Breve história da empresa..."
                {...register("description", { required: true })}
                rows={6}
              ></textarea>
            </div>
            {errors.description && (
              <small className="ml-2 text-primary">
                {errors.description.type === "required" &&
                  "Por favor, preencha este campo!"}
              </small>
            )}
          </div> */}
          <div className="col-lg-6 mb-3 d-inline-block">
            <div className="form-group">
              <label className="text-label">Termos de uso</label>
              <div className="d-flex flex-wrap mt-1 ml-2">
                <button
                  type="button"
                  className="btn btn-sm btn-block btn-info"
                  onClick={() => setShowModal(true)}
                >
                  Ler os termos de uso
                </button>
                <div className="custom-control custom-checkbox mr-3 my-1">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    defaultChecked={false}
                    id={"terms-of-use"}
                    defaultValue=""
                    {...register("termsOfUse", { required: true })}
                  />
                  <label
                    className="custom-control-label ml-2 mt-3"
                    htmlFor={`terms-of-use`}
                  >
                    Declaro que Li e aceito os termos de uso
                  </label>
                </div>
              </div>
              {errors.termsOfUse && (
                <small className="ml-2 mb-2 text-primary">
                  {errors.termsOfUse.type === "required" &&
                    "Para continuar com a criação da sua conta, você deve aceitar os termos de uso."}
                </small>
              )}
            </div>
          </div>
          <div className="col-lg-12 text-center">
            <button type="submit" className="btn btn-primary ml-3">
              Avançar
            </button>
          </div>
        </form>
      </div>
      {showModal && <ModalComponent props={modalProps} />}
    </section>
  );
};

const styles = {
  info: {
    fontSize: "1.25rem",
    verticalAlign: "sub",
  },
  imageDefault: {
    height: 200,
    width: 200,
    margin: "auto",
  },
};

export default StepTwo;
