import Swal from "sweetalert2";
import { api } from "../api/Middleware";
import {
  DELIVERY,
  PLACES_API,
  APP_BASE_URL,
  REVERSE_GEOCODE,
  INTEGRATIONS_URL,
} from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const GetOne = async (id, setUser, setValue, setIsLoading) => {
  try {
    let response = await api.get(DELIVERY + id + "/");
    if (response.status === 200) {
      setValue("birthday", response.data.birthday);
      setValue("phone", response.data.phone);
      setValue("cpf", response.data.cpf);

      setUser(response.data);
    }
  } catch (error) {
    //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
    /*  if(error.status !== 401){
             
             
         }  */
  }
  setIsLoading(false);
};

export const GetAll = async (setDeliveryFee, setErrors, setIsLoading) => {
  try {
    let response = await api.get(`${DELIVERY}`);
    if (response.status === 200) setDeliveryFee(response.data);
  } catch (error) {
    setErrors({ title: "Oops!", message: ERROR_MESSAGES.categories.get });
  }

  setIsLoading(false);
};

export const Create = async (
  data,
  setNotify,
  setError,
  setIsLoading,
  setShowModal
) => {
  try {
    let response = await api.post(DELIVERY, data);
    console.log(response);
    if (response.status === 200 || response.status === 201) {
      setShowModal(false);
      setNotify({
        title: "Sucesso!",
        message: "Bairro(s) cadastrado(s) com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {}, [(window.location.href = "/delivery-fee")], 4000);
    }
  } catch (error) {
    if (error.response.status === 400) {
      setError("name", {
        message: error.response.data,
        type: "custom",
      });
    } else if (error.response.status === 401) {
      window.location.href = APP_BASE_URL;
    } else {
      setNotify({
        title: "Oops!",
        message: ERROR_MESSAGES.categories.update,
        type: "error",
        visible: true,
      });
    }
    //if(error.status !== 401) throw error.response;
  }finally{
    setIsLoading(false)
  }
};

export const Update = async (id, data, setNotify, setShowModal,setIsLoading) => {
  try {
    let response = await api.put(DELIVERY + id + "/", data);
    if (response.status === 200) {
      setShowModal(false);
      setNotify({
        title: "Sucesso!",
        message: "Taxa atualizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: "Oops!",
      message: ERROR_MESSAGES.products.update,
      type: "error",
      visible: true,
    });
  }finally{
    setIsLoading(false)
  }
};

export const ChangeStatus = async (data, setNotify, setProgress) => {
  setProgress(true);
  try {
    let response = await api.put(DELIVERY + data.id + "/", {
      status: parseInt(data.status),
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Status atualizado com sucesso!",
        type: "success",
        visible: true,
      });
      setProgress(false);
      return true;
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: ERROR_MESSAGES.orders.status,
      type: "error",
      visible: true,
    });
    setProgress(false);
    return false;
  }
};

export const Delete = async (id, setNotify, setProgress) => {
  setProgress(true);

  try {
    let ids = id.map((value) => value.id);

    let response = await api.delete(DELIVERY + "delete_multiple/", {
      data: { ids: ids },
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Operação realizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }

  setProgress(false);
};

/* export const FindPlace = async (text, setNotify) => {
    try {
        let response = await api.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json??country=br&proximity=ip&types=neighborhood&language=pt&access_token=${AppSettings.mapBoxApiKey}`);

        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        console.log(error)
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
        if (error.response.status !== 401) {
            //return Alert.alert('Oops!', ERROR_MESSAGES.generic);
        }
    }
} */

export const FindPlace = async (text, setNotify) => {
  try {
    let response = await api.get(
      `${PLACES_API}lang=pt&in=countryCode:BRA&limit=5&apiKey=${process.env.REACT_APP_HERE_API_KEY}&q=${text}`
    );
    if (response.status === 200) {
      return response.data.items;
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }
};

export const FindPlaceByCoords = async (lat, lng, setNotify) => {
  try {
    let response = await api.get(
      `${REVERSE_GEOCODE}${lat},${lng}.json?key=${process.env.REACT_APP_TOMTOM_MAPS_KEY}&language=pt-BR&entityType=Municipality%2CMunicipalitySubdivision`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    setNotify({
      title: "Oops!",
      message: error.response,
      type: "error",
      visible: true,
    });
  }
};

export const GetIfoodDeliveryQuote = async (user_id) => {
  try {
    let response = await api.get(
      `${INTEGRATIONS_URL}ifood/delivery_availabilities/?user_id=${user_id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return Swal.fire({
      title: "Oops!",
      html: error.response.data,
      icon: "error",
    });
    // setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
  }
};

export const IfoodRequestDriver = async (payload) => {
  try {
    let response = await api.post(
      `${INTEGRATIONS_URL}ifood/request_driver/`,
      payload
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
     Swal.fire('Oops!', error.response.data, 'warning');
    return false;
  }
};

export const GetIfoodReasons = async (request_id) => {
  try {
    let response = await api.get(
      `${INTEGRATIONS_URL}ifood/cancellation_reasons/?request_id=${request_id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    Swal.fire({
      title: "Oops!",
      html: error.response.data,
      icon: "warning",
    });
    return false;
  }
};

export const IfoodCancelDriver = async (payload) => {
  try {
    let response = await api.post(
      `${INTEGRATIONS_URL}ifood/cancel_request/`,
      payload
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
     Swal.fire('Oops!', error.response.data, 'warning');
    return false;
  }
};
