import React, { useEffect, useState, useContext } from "react";

import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

import UserContext from "../../../contexts/UserContext";
import { useForm } from "react-hook-form";
import DefaultLoader from "../Loader";
import { CreateNewAgent } from "../../../services/AiServices";

export default function NewAIAgent(props) {
  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    
    const payload = {
      agent_name: data.agent_name,
      type: data.type,
      status: 0,
    };

    let res = await CreateNewAgent(payload);
    if (res) {
      props.setNotify({
        title: "Sucesso!",
        message:
          "Nossa equipe entrará em contato com você em até 2h para dar prosseguimento ao seu agente!",
        type: "success",
        visible: true,
      });
      props.setShowModal(false)
      setTimeout(() => {
        window.location.href = '/ai'
      }, 3000);
    }else{
      setIsLoading(false)
    }
  };

  useEffect(() => {
    console.log(props);
    if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    }
  }, []);

  return (
    <div id="register-new-ai-agent">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Nome do agente</label>
            <input
              {...register("agent_name", {
                required: true,
                maxLength: 20,
              })}
              className="form-control text-black"
              defaultValue={""}
            />
            {errors.name && (
              <small className="ml-2 text-danger">
                {errors.name.type === "required"
                  ? "Por favor, preencha este campo!"
                  : "Este campo deve conter no máximo 20 caracteres!"}
              </small>
            )}
          </div>

          <div className="form-group col-md-12">
            <label>Tipo</label>
            <select className="form-control" defaultValue={1} {...register("type")}>
              <option value="1">Whatsapp</option>
            </select>
          </div>
          {/* <div className="form-group col-md-12">
            <label>Status</label>
            <select className="form-control" name="status" defaultValue={1}>
              <option value="1">Ativo</option>
              <option value="2">Desativado</option>
            </select>
          </div> */}
        </div>

        <Button type="submit" className="btn btn-block btn-primary">
          {isLoading ? <DefaultLoader color={"white"} /> : "Criar Solicitação"}
        </Button>
      </form>
    </div>
  );
}
