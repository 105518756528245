import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTablev2 from "../jsx/components/Datatable";
import DefaultLoader from "../jsx/components/Loader";
import Swal from "sweetalert2";
import AlertResponses from "../jsx/components/Alert";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import ModalComponent from "../jsx/components/Modal";
import Tutorial from "../jsx/components/Tutorial";
import { DeleteAction, GetActions } from "../services/WaBottyServices";
import WhatsappActionsUpsert from "./WhatsappActionsUpsert";

const ActionButtons = ({ row, onEdit, onDelete }) => {
  return (
    <div className="d-flex">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-edit-${row.id}`}>Editar</Tooltip>}
      >
        <Button
          type="button"
          className="btn btn-info shadow btn-xs sharp mr-1"
          onClick={() => onEdit(row)}
        >
          <i className="fa fa-pencil"></i>
        </Button>
      </OverlayTrigger>

      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-delete-${row.id}`}>Deletar</Tooltip>}
      >
        <Button
          type="button"
          className="btn btn-primary shadow btn-xs sharp"
          onClick={() => onDelete(row.id)}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default function WhatsappActions(props) {
  const [whatsappActions, setWhatsappActions] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const data = useRef();
  const insertOrUpdate = useRef();

  useEffect(() => {
    let payload = `?uid=${props.account.uid}&aid=${props.account.id}`;
    GetActions(payload).then((actions) => {
      setWhatsappActions(actions);
      setIsLoading(false);
    });
  }, []);

  const deleteRows = (state) => {
    if (
      state.selectedRows !== (null || undefined) &&
      state.selectedRows.length > 0
    ) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  };

  const modalProps = {
    title:
      insertOrUpdate.current === "insert"
        ? "Nova resposta automática"
        : "Atualizar resposta automática",
    content: (
      <WhatsappActionsUpsert
        data={data.current}
        insertOrUpdate={insertOrUpdate}
        setNotify={setNotify}
        setShowModal={setShowModal}
      />
    ),
    size: "xl",
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Esta ação não poderá ser desfeita.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
      cancelButtonColor: "var(--dark)",
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then((res) => {
      if (res.isConfirmed) {
        let response = DeleteAction(id, setNotify);

        setNotify({
          title: "Sucesso!",
          message: "Resposta deletada com sucesso!",
          type: "success",
          visible: true,
        });
        setTimeout(() => {
          window.location.href = "/whatsapp";
        }, 3500);
      }
    });
  };

  const handleEdit = (row) => {
    insertOrUpdate.current = "update";
    data.current = row;
    setShowModal(true);
  };

  const actions = (row) => (
    <ActionButtons row={row} onEdit={handleEdit} onDelete={handleDelete} />
  );

  const columns = [
    {
      name: "Nome",
      selector: (row) => (
        <Link
          to="#"
          onClick={() => {
            insertOrUpdate.current = "update";
            data.current = row;
            setShowModal(true);
          }}
        >
          {row.name}
        </Link>
      ),
      sortable: true,
      maxWidth: "15%",
    },

    {
      name: "Palavras-chave",
      selector: (row) => row.keywords,
      maxWidth: "27.5%",
      wrap: false,
    },
    {
      name: "Mensagem",
      selector: (row) => {
        try {
          return JSON.parse(row.message)["text"];
        } catch (error) {
          return "";
        }
      },
      maxWidth: "27.5%",
    },

    {
      name: "Criado em",
      selector: (row) => new Date(row.create_date).toLocaleString("pt-BR"),
      maxWidth: "20%",
      sortable: true,
    },
    {
      name: "Ações",
      selector: (row) => actions(row),
      maxWidth: "10%",
      allowOverflow: true,
    },
  ];

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <>
          <Tutorial videoId={"FsYXmvwkpTM"} padding={"0 15px"} />
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
              <div className="col-sm-12 col-md-8">
                <h2 className="pl-4">Respostas automáticas</h2>
              </div>

              <div className="col-sm-12 col-md-4">
                {showDeleteButton && (
                  <Button
                    className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                    onClick={() =>
                      Swal.fire({
                        title: "Você tem certeza?",
                        text: "Todos os arquivos selecionados serão deletados. Esta ação é irreversível!",
                        icon: "warning",
                        buttons: true,
                        confirmButtonText: `Continuar a ação`,
                        cancelButtonText: `Cancelar`,
                        showCancelButton: true,
                        confirmButtonColor: "red",
                      }).then((value) => {
                        // if (value) Delete(selectedRows, setNotify, setProgress);
                      })
                    }
                  >
                    <span>DELETAR SELECIONADOS</span>
                  </Button>
                )}
                <Button
                  className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                  onClick={() => {
                    insertOrUpdate.current = "insert";
                    data.current = props.account;
                    setShowModal(true);
                  }}
                >
                  <span>ADICIONAR</span>
                </Button>
              </div>
            </div>
          </div>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}

          <DataTablev2
            data={whatsappActions}
            columns={columns}
            title="  "
            selectable={false}
            onSelectedRowsChange={deleteRows}
            showDeleteButton={showDeleteButton}
            filterByField={{
              labels: ["Nome"],
              fields: ["name"],
            }}
          />

          {progress && <ProgressBarComponent />}

          {showModal && <ModalComponent props={modalProps} />}
        </>
      )}
    </>
  );
}
