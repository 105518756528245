export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL;

export const DEBUG = process.env.REACT_APP_DEBUG;

export const LOGIN_URL = BASE_URL + "auth/";
export const REFRESH_TOKEN_URL = BASE_URL + "refresh_token/";
export const CATEGORIES_URL = BASE_URL + "categories/";
export const PRODUCTS_URL = BASE_URL + "products/";
export const PROMOTIONS_URL = BASE_URL + "promotions/";
export const USERS_URL = BASE_URL + "users/";
export const VALIDATE_CPF_URL = BASE_URL + "users/validate_cpf/";
export const USER_ADDRESSES = BASE_URL + "addresses/";
export const COUPONS_URL = BASE_URL + "coupons/";
export const USER_COUPONS = BASE_URL + "user_coupons/";
export const DELIVERY = BASE_URL + "delivery/";
export const AUTH = BASE_URL + "auth/";
export const CONTACT_URL = BASE_URL + "contact/";
export const ORDERS_URL = BASE_URL + "orders/";
export const PAYMENTS_URL = BASE_URL + "payments/";
export const CUSTOMER_PAYMENTS_URL = BASE_URL + "customer_payments/";
export const PAYMENT_METHODS_URL = BASE_URL + "payment_methods/";
export const PAYMENT_GATEWAY_ACCOUNTS_URL =
  BASE_URL + "payment_gateway_accounts/";
export const REVERSE_GEOCODE =
  "https://api.tomtom.com/search/2/reverseGeocode/";
// export const PLACES_API =
//   "https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?";
export const PLACES_API = "https://geocode.search.hereapi.com/v1/geocode?";
export const GEOCODER_API = "https://geocoder.ls.hereapi.com/6.2/geocode.json?";
export const STORE_URL = BASE_URL + "store/";
export const STORE_CONFIGS_URL = BASE_URL + "store_configs/";
export const INTEGRATIONS_URL = BASE_URL + "integrations/";
export const REPORT_PROBLEMS_URL = BASE_URL + "report_problems/";
export const VW_ITEMS = BASE_URL + "dbviews/";
export const VW_ORDERS = BASE_URL + "dbvieworders/";
export const VW_HOME = BASE_URL + "analytics/data_dashboard/";
export const ANALYTICS_URL = BASE_URL + "analytics/";
export const CUSTOMIZATIONS_URL = BASE_URL + "customizations/";
export const VARIATIONS_URL = BASE_URL + "variations/";
export const VARIATION_OPTIONS_URL = BASE_URL + "variation_options/";
export const DOMAINS_URL = BASE_URL + "domains/";
export const RESERVATIONS_URL = BASE_URL + "reservations/";
export const NEW_ORDERS_URL = process.env.REACT_APP_CURRENT_ORDERS;
export const SUPPORT_URL = BASE_URL + "support/";
export const CELERY_PROGRESS_URL = BASE_URL + "celery-progress/";
export const WABOTTY_URL = BASE_URL + "wabotty/";
export const WABOTTY_API = process.env.REACT_APP_BOTTY_URL;
export const SECURITY_URL = BASE_URL + "security/";
export const CAMPAIGNS_URL = BASE_URL + "campaigns/";
export const CAMPAIGN_LISTS_URL = BASE_URL + "campaign_lists/";
export const AI_URL = BASE_URL + "ai/";
export const QRCODE_URL = BASE_URL + "qrcode/";
export const CASHBACK_PROGRAMMES_URL = BASE_URL + "cashback_programmes/";

//Images Url's
export const IMAGES_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;
export const IMAGES_URL = process.env.REACT_APP_IMAGES_BASE_URL;
export const THUMBOR_URL = process.env.REACT_APP_THUMBOR_URL;

export const FRONT_REVALIDATE_DATA =
  STORE_URL + `get_front_data/?cc=1&store_id=`;

//Ifood Integration
export const IFOOD_IMAGES_FILE =
  process.env.REACT_APP_API_HOST + "files/ifood/";
export const IFOOD_IMAGES_URL = "https://static-images.ifood.com.br/pratos/";

//DEV
export const LOCAL_IMAGES_BASE_URL = "http://127.0.0.1:6998";
