import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";

import { ChangeStatus, GetOne } from "../services/OrdersServices";
import ErrorsComponent from "../jsx/components/Errors";

import { OrderToPrint } from "./OrderToPrint";
import { Hash, removeFromCurrentOrders } from "../helpers/Helpers";

import OrderIdData from "../jsx/components/Sego/Analytics/OrderIdData";
import DefaultLoader from "../jsx/components/Loader";

import { PauseBot } from "../services/WaBottyServices";
import UserContext from "../contexts/UserContext";
import RequestDriver from "../jsx/components/ModalData/RequestDriver";
import ModalComponent from "../jsx/components/Modal";
import DeliveryCancellation from "../jsx/components/ModalData/DeliveryCancellation";
import AppContext from "../contexts/AppContext";

export default function OrderId(props) {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const { ifoodShipping } = useContext(AppContext);
  const [order, setOrder] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const [errors, setErrors] = useState(null);
  const componentRef = useRef();
  const history = useHistory();

  useEffect(() => {
    let orderId;
    if (id || props.orderData) {
      if (props.orderData) {
        orderId = props.orderData.id;
        GetOne(props.orderData.order_number, setOrder, setIsLoading, setErrors);
      } else {
        orderId = id;
        GetOne(id, setOrder, setIsLoading, setErrors);
      }
      let trackings = localStorage.getItem("ifoodTrackings");
      if (trackings) {
        console.log(orderId);
        trackings = JSON.parse(trackings);
        let orderTracking = trackings.filter(
          (track) => track.orderId === orderId
        );

        if (orderTracking.length > 0) {
          props.setTracking(orderTracking[0]);
        }
      }
      let modalOrder = document.querySelector("#modalOrder");
      if (modalOrder) {
        document
          .querySelector("#modalOrder")
          ?.parentElement.removeAttribute("tabindex");
      }
    } else {
      window.location.href = "/orders";
    }
  }, []);

  useEffect(() => {
    let print = parseInt(
      new URLSearchParams(window.location.search).get("print")
    );
    let btn = document.getElementById("printOrder");

    if (btn && print && print == 1) {
      document.getElementById("printOrder").click();
      history.replace(id);
    }
  }, [document.getElementById("printOrder")]);

  const orderToPrintMemo = useMemo(() => {
    return <OrderToPrint order={order} ref={componentRef} />;
  }, [order]);

  const orderTo = {
    delivery: "PEDIDO PARA ENTREGA",
    take_away: (
      <>
        PEDIDO PARA{" "}
        <span
          style={{
            borderBottom: "3px solid var(--primary)",
          }}
        >
          {" "}
          RETIRAR
        </span>
      </>
    ),
    table: (
      <>
        PEDIDO NA
        <span
          style={{
            borderBottom: "3px solid var(--primary)",
          }}
        >
          {" "}
          MESA {order.qrtb}
        </span>
      </>
    ),
  };

  const handlePauseBot = async (e) => {
    if(e.target){
      e.target.disabled = true;
      e.target.innerText = "Aguarde...";
    }

    let payload = {
      phone: order.user__phone,
    };
    let res = await PauseBot(payload);
    try {
      if (res.status === 200) {
        let wrapper = document.querySelector("#pause-bot-wrapper");
        if (wrapper) {
          wrapper.firstChild.innerText = "Autoatendimento Pausado!";
        }
        Swal.fire(
          "Sucesso!",
          "O Autoatendimento foi pausado por 10 minutos com sucesso!",
          "success"
        );
        
        
      } else {
        Swal.fire(
          "Oops",
          "Ocorreu um problema inesperado ao tentar pausar o Autoatendimento, por favor, tente novamente daqui alguns minutos. Se o problema persistir, contacte nosso suporte.",
          "info"
        );
      }
    } catch (error) {
      Swal.fire(
        "Oops",
        "Ocorreu um problema inesperado ao tentar pausar o Autoatendimento, por favor, tente novamente daqui alguns minutos. Se o problema persistir, contacte nosso suporte.",
        "error"
      );
    }finally{
      if(e.target){
        e.target.disabled = false;
        e.target.innerText = "Pausar Autoatendimento";
      }
    }
  };

  const modalProps = {
    title: "Solicitar entregador",
    content: (
      <RequestDriver
        order={order}
        setTracking={props.setTracking}
        setShowModal={setShowModal}
      />
    ),
    btnLabel: "Salvar",
    size: "lg",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const modalCancellationProps = {
    title: "Cancelar Entrega",
    content: (
      <DeliveryCancellation
        request_id={props?.tracking?.id}
        setTracking={props.setTracking}
        setShowModal={setShowCancellationModal}
      />
    ),
    btnLabel: "Salvar",
    showModal: showCancellationModal,
    setShowModal: setShowCancellationModal,
    showFooter: false,
  };

  const TrackDelivery = () => {
    return (
      <iframe
        title="Rastrear entrega"
        src={props.tracking.trackingUrl}
        style={{ border: "0px #ffffff none", overflowY: "auto" }}
        height="600px"
        width="100%"
        allowFullScreen
      ></iframe>
    );
  };

  const handleCancelOrder = async () => {
    await Swal.fire({
      title: "Motivo",
      input: "textarea",
      inputAttributes: {
        rows: "4",
      },
      inputPlaceholder:
        "Informe o motivo. Essa informação será enviada como notificação para o cliente.",
      confirmButtonText: "Cancelar o pedido",
      cancelButtonText: "Voltar",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",

      inputValidator: (value) => {
        if (!value) {
          return "Por favor, preencha o motivo.";
        }

        Swal.showLoading();
        return new Promise((resolve, reject) => {
          let payload = {
            id: order.id,
            status: 5,
            cancellation_reason:
              value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
          };
          ChangeStatus(payload, null, true).then((res) => {
            if (res) {
              let card = document.querySelector(
                `[data-rbd-draggable-id="${order.id}"]`
              );
              if (card) card.classList.add("d-none");
              removeFromCurrentOrders(order.id);
              Swal.fire(
                "Sucesso!",
                "Pedido cancelado com sucesso!",
                "success"
              ).then((value) => {
                resolve();
                if (value.isConfirmed) {
                  props.setShowModal(false);
                }
              });
            } else {
              reject();
            }
          });
        });
      },
    });
  };

  const modalTrackingProps = {
    title: "Rastrear entrega",
    content: <TrackDelivery />,
    btnLabel: "Salvar",
    size: "xl",
    showModal: showTrackingModal,
    setShowModal: setShowTrackingModal,
    showFooter: false,
  };

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <div className="row">
          {errors !== null ? (
            <ErrorsComponent args={errors} />
          ) : (
            <>
              <div className="col-xl-9 col-xxl-8">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header p-0 pb-3">
                          <h4 className="fs-20 text-black mb-4">
                            {orderTo[order.order_to]}
                          </h4>
                          <ReactToPrint
                            trigger={() => (
                              <button
                                className="i-false btn btn-success"
                                id="printOrder"
                              >
                                <i
                                  className="ti-printer"
                                  style={{
                                    fontSize: "1.25rem",
                                    marginRight: "1rem",
                                  }}
                                ></i>
                                <span className="align-middle">
                                  IMPRIMIR PEDIDO
                                </span>
                              </button>
                            )}
                            content={() => componentRef.current}
                          />
                        </div>

                        <div className="row pt-3 justify-content-between">
                          <div className="col-md-4">
                            <div className="d-flex flex-column align-items-center mb-md-0 mb-4">
                              {order.user__photo ? (
                                <img
                                  src={Hash("150x150", order.user__photo)}
                                  alt=""
                                  className="rounded-circle mr-3"
                                  style={{
                                    height: 125,
                                    width: 125,
                                    objectFit: "contain",
                                  }}
                                />
                              ) : (
                                <div style={styles.photoDefault}>
                                  <i className="ti-user" style={styles.i}></i>
                                </div>
                              )}

                              <div className="">
                                <h4 className="fs-20 text-black text-capitalize mt-3">
                                  {order.qrtb
                                    ? order.customer_name
                                    : order.name}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="row align-items-center">
                              {!order.qrtb && (
                                <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                                  <div className="d-flex">
                                    <svg
                                      className="mr-3 min-w32"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M22.9993 17.4712V20.7831C23.0006 21.0906 22.9375 21.3949 22.814 21.6766C22.6906 21.9583 22.5096 22.2112 22.2826 22.419C22.0556 22.6269 21.7876 22.7851 21.4958 22.8836C21.2039 22.9821 20.8947 23.0187 20.5879 22.991C17.1841 22.6219 13.9145 21.4611 11.0418 19.6019C8.36914 17.9069 6.10319 15.6455 4.40487 12.9781C2.53545 10.0981 1.37207 6.81909 1.00898 3.40674C0.981336 3.10146 1.01769 2.79378 1.11572 2.50329C1.21376 2.2128 1.37132 1.94586 1.57839 1.71947C1.78546 1.49308 2.03749 1.31221 2.31843 1.18836C2.59938 1.06451 2.90309 1.0004 3.21023 1.00011H6.52869C7.06551 0.994834 7.58594 1.18456 7.99297 1.53391C8.4 1.88326 8.66586 2.36841 8.74099 2.89892C8.88106 3.9588 9.14081 4.99946 9.5153 6.00106C9.66413 6.39619 9.69634 6.82562 9.60812 7.23847C9.51989 7.65131 9.31494 8.03026 9.01753 8.33042L7.61272 9.73245C9.18739 12.4963 11.4803 14.7847 14.2496 16.3562L15.6545 14.9542C15.9552 14.6574 16.3349 14.4528 16.7486 14.3648C17.1622 14.2767 17.5925 14.3089 17.9884 14.4574C18.992 14.8312 20.0348 15.0904 21.0967 15.2302C21.6341 15.3058 22.1248 15.576 22.4756 15.9892C22.8264 16.4024 23.0128 16.9298 22.9993 17.4712Z"
                                        stroke="#566069"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    <span className="text-black font-w500">
                                      <NumberFormat
                                        format="(##) #####-####"
                                        mask="_"
                                        displayType="text"
                                        defaultValue={order.user__phone}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}

                              {order.order_to === "delivery" && (
                                <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                                  <div className="d-flex">
                                    <svg
                                      className="mr-3 min-w32"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M28.0005 13.3335C28.0005 22.6668 16.0005 30.6668 16.0005 30.6668C16.0005 30.6668 4.00049 22.6668 4.00049 13.3335C4.00049 10.1509 5.26477 7.09865 7.51521 4.84821C9.76564 2.59778 12.8179 1.3335 16.0005 1.3335C19.1831 1.3335 22.2353 2.59778 24.4858 4.84821C26.7362 7.09865 28.0005 10.1509 28.0005 13.3335Z"
                                        stroke="#3E4954"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M16.0005 17.3335C18.2096 17.3335 20.0005 15.5426 20.0005 13.3335C20.0005 11.1244 18.2096 9.3335 16.0005 9.3335C13.7913 9.3335 12.0005 11.1244 12.0005 13.3335C12.0005 15.5426 13.7913 17.3335 16.0005 17.3335Z"
                                        stroke="#3E4954"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>

                                    <span className="text-black font-w500">
                                      {order.address.complete_address}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {order.order_to === "delivery" && (
                                <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                                  <div className="d-flex align-items-center">
                                    <i
                                      className="las la-home icon-color-default mr-3"
                                      style={styles.i}
                                    ></i>
                                    <span className="text-black font-w500 text-capitalize">
                                      {order.address.complement}
                                    </span>
                                  </div>
                                </div>
                              )}

                              {order.reference !== "" &&
                                order.order_to === "delivery" && (
                                  <div className="col-xl-6 col-xxl-12 col-lg-6 mb-2">
                                    <div className="d-flex align-items-center">
                                      <i
                                        className="las la-map-pin icon-color-default mr-3"
                                        style={styles.i}
                                      ></i>
                                      <span className="text-black font-w500 text-capitalize">
                                        {order.address.reference}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              {order.observations != (null || "") &&
                                order.order_to === "delivery" && (
                                  <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                                    <div className="d-flex align-items-center">
                                      <i
                                        className="las la-info-circle icon-color-default mr-3"
                                        style={styles.i}
                                      ></i>
                                      <span className="text-black font-w500 text-capitalize">
                                        {order.observations}
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <OrderIdData order={order} />
                </div>
              </div>
              <div className="col-xl-3 col-xxl-4">
                <div className="row">
                  <div
                    className="col-xl-12 col-sm-6"
                    style={{ height: "fit-content" }}
                  >
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h4 className="fs-20 font-w600">Histórico</h4>
                      </div>
                      <div className="card-body pb-0">
                        <div className="widget-timeline-icon2">
                          <ul className="timeline">
                            {order.status.map((value, index) => (
                              <li key={index}>
                                <div
                                  className={`icon bg-${
                                    value.name === "Finalizado"
                                      ? "success"
                                      : "primary"
                                  }`}
                                />
                                <Link
                                  className="timeline-panel text-muted"
                                  to="#"
                                >
                                  <h4 className="mb-2 ">{value.name}</h4>
                                  <p className="fs-15 mb-0 ">
                                    {new Date(
                                      value.created_at
                                    ).toLocaleString()}
                                  </p>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {user.plan_id > 1 && ifoodShipping.current ? (
                      order.order_to === "delivery" && !props.tracking ? (
                        <div id="" className="text-center mb-3">
                          <button
                            type="button"
                            className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                            onClick={() => setShowModal(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                              fill="#fff"
                              width={20}
                              className="mr-2"
                            >
                              <path d="M328 56C328 42.75 338.7 32 352 32H393.3C417.1 32 438.3 47.09 446.1 69.6L454.2 92.88L505.2 67.38C509.7 65.16 514.6 64 519.6 64H528C536.8 64 544 71.16 544 80V144C544 152.8 536.8 160 528 160H519.6C514.6 160 509.7 158.8 505.2 156.6L470.3 139.2L500 224.4C503.1 224.1 507.1 224 512 224C555.7 224 595.3 241.5 624.1 269.8C633.6 279.1 633.7 294.3 624.4 303.8C615.1 313.2 599.9 313.4 590.5 304.1C570.2 284.2 542.6 272 512 272C459.3 272 414.1 308.5 403.1 357.6C401.1 366.1 400 374.9 400 384H256C256 437 213 480 160 480C106.1 480 64 437 64 384H32C14.33 384 0 369.7 0 352V320C0 249.3 57.31 192 128 192H224C241.7 192 256 206.3 256 224V288C256 305.7 270.3 320 288 320H365.3C382.2 281.5 413.7 250.8 452.9 235.3L400.8 85.37C399.7 82.16 396.7 80 393.3 80H352C338.7 80 328 69.26 328 56L328 56zM208 384H112C112 410.5 133.5 432 160 432C186.5 432 208 410.5 208 384zM224 96C241.7 96 256 110.3 256 128C256 145.7 241.7 160 224 160H128C110.3 160 96 145.7 96 128C96 110.3 110.3 96 128 96H224zM416 384C416 330.1 458.1 288 512 288C565 288 608 330.1 608 384C608 437 565 480 512 480C458.1 480 416 437 416 384zM512 432C538.5 432 560 410.5 560 384C560 357.5 538.5 336 512 336C485.5 336 464 357.5 464 384C464 410.5 485.5 432 512 432z" />
                            </svg>
                            Solicitar Entregador{" "}
                          </button>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success btn-block d-flex justify-content-center align-items-center mb-3"
                          onClick={() => setShowTrackingModal(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="mr-2"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8.887 6.748c-.163 0 -.337 .016 -.506 .057c-.172 .041 -.582 .165 -.838 .562l-.014 .02l-.607 1.05c-.307 .205 -.534 .46 -.693 .717l-.014 .02l-2.572 4.65a4.009 4.009 0 0 0 -.274 .494l-.006 .01a3.99 3.99 0 0 0 -.363 1.672a4 4 0 0 0 8 0v-1h2v1a4 4 0 1 0 7.635 -1.67l-.004 -.012a4.008 4.008 0 0 0 -.274 -.494l-2.572 -4.65l-.014 -.02a2.337 2.337 0 0 0 -.693 -.716l-.607 -1.051l-.014 -.02c-.256 -.397 -.667 -.52 -.838 -.562a2.225 2.225 0 0 0 -.664 -.051a2.06 2.06 0 0 0 -.68 .156c-.184 .081 -.638 .327 -.754 .889l-.007 .037l-.14 1.1c-.22 .283 -.374 .64 -.374 1.064v1h-2v-1c0 -.424 -.154 -.781 -.373 -1.064l-.14 -1.1l-.008 -.037c-.116 -.562 -.57 -.808 -.754 -.889a2.06 2.06 0 0 0 -.68 -.156a2.374 2.374 0 0 0 -.158 -.006zm-1.887 7.252a2 2 0 1 1 -1.838 1.209l.19 -.342c.36 -.523 .964 -.867 1.648 -.867zm10 0c.684 0 1.288 .344 1.648 .867l.19 .342a2 2 0 1 1 -1.838 -1.209z" />
                          </svg>
                          Rastrear pedido{" "}
                        </button>
                      )
                    ) : (
                      ""
                    )}
                    {user.plan_id > 2 && !order.qrtb && (
                      <div id="pause-bot-wrapper" className="text-center">
                        <button
                          type="button"
                          className="btn btn-info btn-block d-flex justify-content-center align-items-center"
                          onClick={handlePauseBot}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="mr-2"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 4h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2z" />
                            <path d="M17 4h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2z" />
                          </svg>{" "}
                          Pausar Autoatendimento{" "}
                        </button>
                        <small className="fs-13 m-2">
                          *Pausar autoatendimento no whatsapp por 10minutos
                        </small>
                      </div>
                    )}
                    <hr></hr>
                    <h4 className="text-center">Cancelamento</h4>
                    <button
                      type="button"
                      className="btn btn-primary btn-block d-flex justify-content-center align-items-center my-3"
                      onClick={handleCancelOrder}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="mr-2"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                          fill="currentColor"
                          strokeWidth="0"
                        />
                      </svg>
                      Cancelar pedido{" "}
                    </button>
                    {user.plan_id > 1 &&
                      order.order_to === "delivery" &&
                      props.tracking && (
                        <button
                          type="button"
                          className="btn btn-secondary btn-block d-flex justify-content-center align-items-center my-3"
                          onClick={() => setShowCancellationModal(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="mr-2"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                              fill="currentColor"
                              strokeWidth="0"
                            />
                          </svg>
                          Cancelar entrega iFood{" "}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="d-none">{orderToPrintMemo}</div>
          {showModal && <ModalComponent props={modalProps} />}
          {showTrackingModal && <ModalComponent props={modalTrackingProps} />}
          {showCancellationModal && (
            <ModalComponent props={modalCancellationProps} />
          )}
        </div>
      )}
    </>
  );
}

const styles = {
  photoDefault: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "130px",
    height: "130px",
    borderRadius: "75px",
    background: "#efefef",
    margin: "auto",
  },
  i: {
    fontSize: "32px",
    color: "#3d4465",
  },
};
