import Swal from "sweetalert2";
import { api } from "../api/Middleware";
import { CAMPAIGNS_URL, CAMPAIGN_LISTS_URL, WABOTTY_API } from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const GetBottyCampaigns = async (
  api_key,
  setCampaigns,
  isLoading,
  setIsLoading
) => {
  try {
    let response = await api.get(
      WABOTTY_API + `api/get/wa.campaigns/?secret=${api_key}`
    );
    if (response.status === 200) {
      setCampaigns(response.data.data);
      return;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    }
  } finally {
    if (isLoading) setIsLoading(false);
  }
};

export const GetBottyAccounts = async (api_key, setAccounts, returnData) => {
  try {
    let response = await api.get(
      WABOTTY_API + `api/get/wa.accounts/?secret=${api_key}`
    );
    if (response.status === 200) {
      if(returnData) return response.data.data
      setAccounts(response.data.data);
      return;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    }
  }
};

export const CreateBottyCampaign = async (form) => {
  try {
    let response = await api.post(WABOTTY_API + `api/send/whatsapp.bulk`, form);
    if (response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    }
  }
};
export const GetCampaigns = async (
  setCampaigns,
  listTotalRows,
  params,
  isLoading,
  setIsLoading,
  returnData = false
) => {
  try {
    let response = await api.get(CAMPAIGNS_URL + params);
    if (listTotalRows) listTotalRows.current = response.data.count;
    if (returnData) {
      return response.data;
    } else {
      setCampaigns(response.data.results);
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    }
  } finally {
    if (isLoading) setIsLoading(false);
  }
};

export const CreateCampaign = async (
  form,
  setNotify,
  setProgress,
  returnData = false
) => {
  setProgress(true);
  try {
    let response = await api.post(CAMPAIGNS_URL, form);

    if (returnData) return response.data;

    setNotify({
      title: "Sucesso!",
      message: "Campanha criada com sucesso!",
      type: "success",
      visible: true,
    });

    setTimeout(() => {
      window.location.href = "/campaigns";
    }, 3000);
  } catch (error) {
    console.log(error);
    if (error?.response?.status > 401) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    } else {
      // localStorage.clear();
      // window.location.href = "/login";
    }
  } finally {
    setProgress(false);
  }
};

export const UpdateCampaign = async (
  id,
  form,
  setNotify,
  setProgress,
  returnData = false
) => {
  setProgress(true);
  try {
    let response = await api.put(`${CAMPAIGNS_URL}${id}/`, form);

    if (returnData) return response.data;

    setNotify({
      title: "Sucesso!",
      message: "Dados atualizados com sucesso!",
      type: "success",
      visible: true,
    });

    setTimeout(() => {
      window.location.href = "/campaigns";
    }, 3000);
  } catch (error) {
    console.log(error);
    if (error?.response?.status > 401) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    } else {
      // localStorage.clear();
      // window.location.href = "/login";
    }
  } finally {
    setProgress(false);
  }
};

export const DeleteCampaigns = async (data, setNotify, setProgress) => {
  setProgress(true);
  try {
    let ids = [];
    data.map((product) => ids.push(product.id));
    let response = await api.delete(CAMPAIGNS_URL + "delete_multiple/", {
      data: { ids: ids },
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Operação realizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.href = "/campaigns";
      }, 3000);
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }
  setProgress(false);
};

export const GetCampaignLists = async (
  setCampaignLists,
  listTotalRows,
  params,
  returnData = false
) => {
  try {
    let response = await api.get(CAMPAIGN_LISTS_URL + params);
    if (listTotalRows) listTotalRows.current = response.data.count;
    if (returnData) {
      return response.data;
    } else {
      setCampaignLists(response.data.results);
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    }
  }
};

export const CreateCampaignList = async (payload) => {
  try {
    let response = await api.post(CAMPAIGN_LISTS_URL, payload);
    return response.data;
  } catch (error) {
    if (error?.response?.status === 400) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    }
  }
};

export const UpdateCampaignList = async (payload) => {
  try {
    let response = await api.put(
      CAMPAIGN_LISTS_URL + `${payload.id}/`,
      payload
    );
    return response.data;
  } catch (error) {
    if (error?.response?.status >= 400 && error?.response?.status < 500) {
      Swal.fire("Oops!", error.response.data, "info");
      return;
    } else {
      Swal.fire("Oops!", ERROR_MESSAGES.campaignLists.update, "error");
      return;
    }
  }
};

export const DeleteCampaignLists = async (data, setNotify, setProgress) => {
  setProgress(true);
  try {
    let ids = [];
    data.map((product) => ids.push(product.id));
    let response = await api.delete(CAMPAIGN_LISTS_URL + "delete_multiple/", {
      data: { ids: ids },
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Operação realizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.href = "/campaigns";
      }, 3000);
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }
  setProgress(false);
};

export const ResendCampaign = async (payload) => {
  try {
    let response = await api.post(CAMPAIGNS_URL + 'start_botty_campaign/', payload);
    return response.data;
  } catch (error) {
    if (error?.response?.status === 400) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    }
  }
};

export const GetBottyReports = async (cid, setReports, returnData=false) => {
  try {
    let response = await api.get(CAMPAIGNS_URL + `botty_reports/?cid=${cid}`);
    if (response.status === 200) {
      if(returnData) return response.data
      setReports(response.data);
      return;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      Swal.fire("Oops!", error.response.data, "error");
      return;
    }
  }
};
