import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownButton, Button, Card, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import AlertResponses, { AlertV2 } from "../jsx/components/Alert";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import UserContext from "../contexts/UserContext";
import {
  GetAll,
  ChangeStatus,
  Delete,
  UpdateSequence,
} from "../services/CategoriesServices";
import { Hash } from "../helpers/Helpers";
import DefaultLoader from "../jsx/components/Loader";
import Tutorial from "../jsx/components/Tutorial";
import { DraggableComponentV1 } from "../jsx/components/DraggableComponents/DraggableComponentV1";

export default function Categories() {
  const { user } = useContext(UserContext);
  const [categories, setCategories] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const [progress, setProgress] = useState(false);

  const history = useHistory();

  //TODO: no datatable nao filtra pelo campo created_at por causa do formato da data q no campo é 2021-08-03 e o user
  // digita 30/08/2021
  useEffect(() => {
    GetAll(setCategories, setErrors, setIsLoading);
  }, []);

  /* const deleteitems = state => {
    if (
      state.selecteditems !== (null || undefined) &&
      state.selecteditems.length > 0
    ) {
      setSelecteditems(state.selecteditems)
      setShowDeleteButton(true)
    } else {
      setShowDeleteButton(false)
    }
  } */

  const status = {
    0: {
      id: 0,
      label: "Desativada",
      type: "light",
    },
    1: {
      id: 1,
      label: "Ativa",
      type: "success",
    },
  };
  const moveCard = useCallback((dragIndex, hoverIndex, catId) => {
    let sequence = hoverIndex + 1;

    UpdateSequence(catId, { sequence: sequence }).then((res) => {
      setTimeout(() => {
        setNotify({
          title: "Sucesso!",
          message: "Ordem alterada com sucesso!",
          type: "success",
          visible: true,
        });
      }, 500);
    });
    setCategories((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const CardComponent = ({ item, index }) => (
    <>
      <td>
        {" "}
        <i
          className="las la-grip-vertical p-1 pl-4"
          style={{ cursor: "grab" }}
        ></i>
      </td>
      <td>
        {" "}
        <img
          className="card-img img-fluid"
          src={
            item.thumb ? Hash("100x100", item.thumb) : "/imgs/default_img.png"
          }
          style={styles.thumb}
        />
      </td>

      <td>
        <h6>{item.name}</h6>
      </td>
      <td>
        {" "}
        <DropdownButton
          variant=""
          style={styles.dropdown}
          title={item.status === 1 ? "Ativa" : "Desativada"}
          className={`badge badge-${
            item.status === 1 ? "success" : "light"
          } badge-sm light badge-delivery-status badge-${item.id}`}
          onSelect={(e) => {
            ChangeStatus({ id: item.id, status: e }, setNotify).then((res) => {
              if (res) {
                document
                  .querySelector(`.badge-${item.id}`)
                  .classList.remove(
                    `badge-${item.status === 1 ? "success" : "light"}`
                  );
                document
                  .querySelector(`.badge-${item.id}`)
                  .classList.add(`badge-${status[e].type}`);
                document.querySelector(`.badge-${item.id} button`).innerText =
                  status[e].label;
              }
            });
          }}
        >
          {Object.values(status).map((value, i) => (
            <Dropdown.Item key={i} eventKey={value.id}>
              {value.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </td>
      <td>
        {" "}
        <DropdownButton
          variant=""
          style={styles.dropdown}
          title="Ações"
          className={`badge badge-primary badge-sm light badge-delivery-status ml-4`}
          onSelect={(e) => {}}
        >
          <Link
            to={{
              pathname: `/categorie-details/${item.id}`,
              order: item,
            }}
            className="text-danger dropdown-item"
          >
            Editar
          </Link>
          <Dropdown.Item
            onClick={() =>
              Swal.fire({
                title: "Você tem certeza?",
                html: "Todos os produtos, promoções e cupons desta categoria <span class='text-primary'>TAMBÉM SERÃO DELETADOS</span>. <br><b class='text-primary'>Esta ação é irreversível!<b></br>",
                icon: "warning",
                buttons: true,
                confirmButtonText: `Continuar ação`,
                cancelButtonText: `Cancelar`,
                showCancelButton: true,
                confirmButtonColor: "red",
              }).then((value) => {
                if (value.isConfirmed) {
                  Delete(item.id, setNotify, setProgress);
                }
              })
            }
          >
            Excluir
          </Dropdown.Item>
        </DropdownButton>
      </td>
    </>
  );

  const renderCategories = useCallback((item, index) => {
    return (
      <DraggableComponentV1
        key={item.id}
        index={index}
        id={item.id}
        children={<CardComponent item={item} index={index} />}
        moveCard={moveCard}
        componentType="tr"
      />
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <div id="categories-page" className="item">
            <Tutorial videoId={"HHKaJTZExcI"} padding={"0 30px"} />
            <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
              <div className="col-xl-4 col-xxl-5 col-sm-12">
                <h2>Categorias</h2>
              </div>

              <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                <Button
                  className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                  onClick={() => history.push("/categorie-details/")}
                >
                  <span>ADICIONAR</span>
                </Button>
              </div>
            </div>
          </div>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
          <Card className="mt-3">
            <Card.Body>
              {categories ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Ordenar</th>
                      <th>Imagem</th>
                      <th>Nome</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((item, index) =>
                      renderCategories(item, index)
                    )}
                  </tbody>
                </Table>
              ) : (
                <h6>Nenhuma categorias cadastrada no momento...</h6>
              )}
            </Card.Body>
          </Card>

          {progress && <ProgressBarComponent />}
        </DndProvider>
      )}
    </>
  );
}

const styles = {
  thumb: {
    maxWidth: 75,
    maxHeight: 75,
    objectFit: "cover",
  },
};
