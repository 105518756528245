import React,{useContext} from 'react'
import { useForm } from 'react-hook-form'
import { CreateSupportTicket } from '../../../services/StoreServices'
import UserContext from '../../../contexts/UserContext'

export default function SupportTicket (props) {

  const {user} = useContext(UserContext)

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = data => {
    let payload = {
      subject: data.subject,
      message: data.message,
      user_name: `${user.first_name} ${user?.last_name}`,
      user_id: user.user_id,
      status: 1,
      last_update: new Date(),
      store: user.store_id
    }
    CreateSupportTicket(payload)
  }

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-group'>
            <label>Assunto</label>
            <input
              type='text'
              className='form-control'
              placeholder='Assunto:'
              disabled={props?.data ? true : false}
              defaultValue={props?.data ? props.data.subject : ''}
              {...register('subject', { required: true, maxLength: 80 })}
            />
            {errors.subject && (
              <small className='ml-2 text-danger'>
                {errors.subject.type === 'required' &&
                  'Por favor, preencha este campo!'}
                {errors.subject.type === 'maxLength' &&
                  'Este campo deve conter no máximo 80 caracteres!'}
              </small>
            )}
          </div>
          <div className='form-group'>
          <label>Mensagem</label>
            <textarea
              id='email-compose-editor'
              className='textarea_editor form-control'
              rows='10'
              placeholder='Relate seu problema...'
              disabled={props?.data ? true : false}
              defaultValue={props?.data ? props.data.message : ''}
              {...register('message', { required: true })}
            ></textarea>
             {errors.message && (
              <small className='ml-2 text-danger'>
                {errors.message.type === 'required' &&
                  'Por favor, preencha este campo!'}
              </small>
            )}
          </div>
          {
            props?.data?.response &&
            <div className='form-group'>
            <label>Resposta</label>
              <textarea
                id='email-compose-editor'
                className='textarea_editor form-control bg-transparent'
                rows='10'
                disabled={props?.data ? true : false}
                defaultValue={props?.data ? props.data.response : ''}
              ></textarea>
               
            </div>
          }
          <div className='text-center mb-3'>
          <small>Faça um upgrade para obter mais recursos e suporte em tempo real via Chat &#128512;</small>
          </div>
          <div>
            <button type='submit' className='btn btn-primary' disabled={props?.data ? true : false}>
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
