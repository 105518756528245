import React, {
  useEffect,
  useState,
  createRef,
  useRef,
  useMemo,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "react-bootstrap";

import { Theme } from "../App";
import Errors from "../jsx/components/Errors";
import AlertResponses from "../jsx/components/Alert";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import { IMAGES_URL } from "../api/Urls";
import UserContext from "../contexts/UserContext";
import { Create, Update, GetOne, GetAll } from "../services/PaymentServices";

export default function PaymentMethodsDetails(props) {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(false);

  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const options = useRef([
    {
      label: "À vista",
      value: "À vista",
    },
    {
      label: "Débito",
      value: "Débito",
    },
    {
      label: "Crédito",
      value: "Crédito",
    },
    {
      label: "Refeição",
      value: "Refeição",
    },
    {
      label: "Alimentação",
      value: "Alimentação",
    },
  ]);

  const nameOptions = useRef([
    {
      label: "Dinheiro",
      value: "DINHEIRO",
    },
    {
      label: "Mastercard",
      value: "MASTERCARD",
    },
    {
      label: "Visa",
      value: "VISA",
    },
    {
      label: "Amex",
      value: "AMEX",
    },
    {
      label: "Elo",
      value: "ELO",
    },
    {
      label: "Hipercard",
      value: "HIPERCARD",
    },
    {
      label: "Alelo",
      value: "ALELO",
    },
    {
      label: "Sodexo",
      value: "SODEXO",
    },
  ]);

  const thumbs = [
    "american-express.png",
    "dinheiro.png",
    "mastercard.png",
    "visa.png",
    "alelo.png",
    "sodexo.png",
    "elo.png",
    "hipercard.png",
  ];

  useEffect(() => {
    if (id) {
      GetOne(id, setPaymentMethods, setIsLoading);
    } else {
      setIsLoading(false);
    }
  }, []);

  const override = css`
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 20%;
  `;

  const onSubmit = (data) => {

    if (id !== undefined) {
      Update(id, data, setPaymentMethods, setNotify, setProgress);
    } else {
      data["store_id"] = user.store_id;
      Create(data, setPaymentMethods, setNotify, setError, setProgress);
    }
  };

  const Thumbs = useMemo(() => {
    return thumbs.map((thumb, index) => (
      <div
        className="col-xl-6 col-lg-6 col-md-6 col-xxl-6 mb-4 d-inline-block text-center"
        key={index}
      >
        <img src={`https://${IMAGES_URL}payment_methods/${thumb}`} width="60" />
        <div className="form-check">
          <input
            id={`input-${index}`}
            className="form-check-input"
            type="radio"
            defaultValue={
              paymentMethods.length > 0 && paymentMethods.thumb === thumb
                ? paymentMethods.thumb
                : thumb
            }
            defaultChecked={
              paymentMethods.length > 0 && paymentMethods.thumb === thumb
                ? true
                : false
            }
            {...register("thumb", { required: true })}
          />
          <label className="form-check-label" htmlFor={`input-${index}`}>
            Selecionar
          </label>
        </div>
      </div>
    ));
  }, [thumbs]);

  return (
    <>
      <div className="row">
        {isLoading ? (
          <BeatLoader
            color={Theme.colors.primary}
            loading={isLoading}
            css={override}
            size={12}
          />
        ) : (
          <div className="col-lg-12">
            {
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-4 col-md-4 col-xxl-4">
                      <h3 className="m-4 text-center">Ícone</h3>
                      {Thumbs}
                      {errors.thumb && (
                        <small className="ml-4 mb-2 text-danger">
                          {errors.thumb.type === "required" &&
                            "Por favor, selecione uma ícone!"}
                        </small>
                      )}
                    </div>
                    <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="">
                            {paymentMethods.length > 0
                              ? paymentMethods[0].name
                              : "Novo Método de Pagamento"}
                          </h3>
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Nome</label>
                                  <Select
                                    {...register("name", { required: true })}
                                    options={nameOptions.current}
                                    defaultValue={
                                      paymentMethods.length > 0
                                        ? {
                                            value: paymentMethods[0].name,
                                            label: paymentMethods[0].name,
                                          }
                                        : ""
                                    }
                                    onChange={(e) => setValue("name", e.value)}
                                    className="rounded-form-control"
                                    placeholder="Selecione uma opção..."
                                  />
                                  {errors.name && (
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.name.type === "required" &&
                                        "Por favor, selecione uma opção!"}
                                    </small>
                                  )}
                                </div>

                                {errors.price && (
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.price.type === "required" &&
                                      "Por favor, preencha este campo!"}
                                  </small>
                                )}
                              </div>
                              <div className="form-group">
                                <label>Operação</label>
                                <Select
                                  {...register("operation", { required: true })}
                                  options={options.current}
                                  defaultValue={
                                    paymentMethods.length > 0
                                      ? {
                                          value: paymentMethods[0].operation,
                                          label: paymentMethods[0].operation,
                                        }
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setValue("operation", e.value)
                                  }
                                  className="rounded-form-control"
                                  placeholder="Selecione uma opção..."
                                />
                                {errors.operation && (
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.operation.type === "required" &&
                                      "Por favor, selecione uma opção!"}
                                  </small>
                                )}
                              </div>

                              <div className="form-group">
                                <label>Tipo</label>
                                <div className="form-check mb-2">
                                  <input
                                    id="cash"
                                    className="form-check-input"
                                    type="radio"
                                    defaultValue="Dinheiro"
                                    defaultChecked={
                                      paymentMethods.length > 0 &&
                                      paymentMethods[0].type === "Dinheiro"
                                        ? true
                                        : false
                                    }
                                    {...register("type", { required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="cash"
                                  >
                                    Dinheiro
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    id="card"
                                    className="form-check-input"
                                    type="radio"
                                    defaultValue="Cartão"
                                    defaultChecked={
                                      paymentMethods.length > 0 &&
                                      paymentMethods[0].type === "Cartão"
                                        ? true
                                        : false
                                    }
                                    {...register("type", { required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="card"
                                  >
                                    Cartão
                                  </label>
                                </div>
                                {errors.type && (
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.type.type === "required" &&
                                      "Por favor, selecione uma opção!"}
                                  </small>
                                )}
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Status</label>
                                  <select
                                    className="form-control text-black"
                                    defaultValue={
                                      paymentMethods.length > 0
                                        ? paymentMethods[0].status
                                        : ""
                                    }
                                    {...register("status", { required: true })}
                                  >
                                    <option value={1}>Ativo</option>
                                    <option value={0}>Desativado</option>
                                  </select>
                                </div>
                                {errors.status && (
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.status.type === "required" &&
                                      "Por favor, preencha este campo!"}
                                  </small>
                                )}
                              </div>
                              <Button
                                type="submit"
                                className="btn btn-block btn-primary"
                              >
                                Salvar
                              </Button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {progress && <ProgressBarComponent />}
            {notify.visible && (
              <AlertResponses
                title={notify.title}
                message={notify.message}
                type={notify.type}
                visible={notify.visible}
                setNotify={setNotify}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

const styles = {
  image: {
    maxWidth: 330,
    maxHeight: 250,
    width: "100%",
    objectFit: "cover",
  },
};
