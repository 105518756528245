import Swal from "sweetalert2";
import { api } from "../api/Middleware";
import { CASHBACK_PROGRAMMES_URL } from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const GetCashbackProgram = async (setCashbackProgram, setIsLoading) => {
  setIsLoading(true)
  try {
    let response = await api.get(`${CASHBACK_PROGRAMMES_URL}`);
    if (response.status === 200) {
      setCashbackProgram(response.data);
    }
  } catch (error) {
    console.log(error);
    let message = error.response.data || ERROR_MESSAGES.default;
    Swal.fire("Oops!", message, "error");
    return false;
  }finally{
    setIsLoading(false)
  }
};

export const CreateNewCashbackProgram = async (payload) => {
  try {
    let response = await api.post(`${CASHBACK_PROGRAMMES_URL}`, payload);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    let message = error.response.data || ERROR_MESSAGES.default;
    Swal.fire("Oops!", message, "error");
    return false;
  }
};

export const DisableCashbackProgram = async (id, payload) => {
  try {
    let response = await api.put(`${CASHBACK_PROGRAMMES_URL}${id}/`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    let message = error.response.data || ERROR_MESSAGES.default;
    Swal.fire("Oops!", message, "error");
    return false;
  }
};

export const UpdateCashbackProgram = async (id, payload) => {
  try {
    let response = await api.put(`${CASHBACK_PROGRAMMES_URL}${id}/`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    let message = error.response.data || ERROR_MESSAGES.default;
    Swal.fire("Oops!", message, "error");
    return false;
  }
};
