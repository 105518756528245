import React, { useState, useMemo } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import NumberFormat from "react-number-format";

const DatatablePaginated = ({
  data,
  columns,
  title,
  selectable,
  onSelectedRowsChange,
  filterByField,
  filterDefaultField,
  totalRows,
  isLoading,
  handlePageChange,
  searchBarType = 1,
  handleClickSearch = false,
  componentName = false,
}) => {
  const [selectableRow, setSelectableRow] = useState(selectable);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState(
    filterDefaultField !== undefined ? filterDefaultField : "name"
  );
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const FilterComponent = ({ filterText, onFilter, onClear, setFilter }) => (
    <>
      {searchBarType === 1 ? (
        <div className="col-auto" style={{ zIndex: 1 }}>
          <div className="input-group mb-2">
            <i className="ti-search" style={styles.icon}></i>
            <input
              type="text"
              className="form-control"
              placeholder={`Filtrar por ${
                filterBy === filterByField.fields[0]
                  ? filterByField.labels[0]
                  : filterByField.labels[1]
              }`}
              value={filterText}
              onChange={onFilter}
              style={styles.inputSearch}
              autoFocus
            />
            <div className="dropdown">
              <DropdownButton
                id="dropdown-button-drop-right"
                variant=""
                title=""
                style={styles.dropdownArrow}
                onSelect={setFilter}
              >
                <Dropdown.Item eventKey={filterByField.fields[0]}>
                  Filtrar por {filterByField.labels[0]}
                </Dropdown.Item>
                {filterByField.fields.length > 1 && (
                  <Dropdown.Item eventKey={filterByField.fields[1]}>
                    Filtrar por {filterByField.labels[1]}
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-auto search-order-wrapper" style={{ zIndex: 1 }}>
          <div className="input-group mb-2">
            <NumberFormat
              id={`input-search-${componentName}`}
              format="(##) #####-####"
              className="form-control"
              defaultValue={""}
              placeholder={`Filtrar por ${
                filterBy === filterByField.fields[0]
                  ? filterByField.labels[0]
                  : filterByField.labels[1]
              }`}
              style={styles.inputSearch}
            />

            <div className="dropdown">
              <DropdownButton
                id="dropdown-button-drop-right"
                variant=""
                title=""
                style={styles.dropdownArrow}
                onSelect={setFilter}
              >
                <Dropdown.Item eventKey={filterByField.fields[0]}>
                  Filtrar por {filterByField.labels[0]}
                </Dropdown.Item>
                {filterByField.fields.length > 1 && (
                  <Dropdown.Item eventKey={filterByField.fields[1]}>
                    Filtrar por {filterByField.labels[1]}
                  </Dropdown.Item>
                )}

                {/* <Dropdown.Item eventKey="categorie_name">Filtrar por Categoria</Dropdown.Item> */}
              </DropdownButton>
            </div>
            {/* <div className="input-group-append cursor-pointer" onClick={onClear}>
                      <div className="input-group-text">
                          <i className="ti-close"></i>
                      </div>
                  </div> */}
          </div>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={`tooltip-bottom`}>
                Clique aqui para buscar o{" "}
                {filterBy === filterByField.fields[0]
                  ? filterByField.labels[0]
                  : filterByField.labels[1]}{" "}
                do cliente.
              </Tooltip>
            }
          >
            <Button id={`btn-search-${componentName}`} className="btn btn-sm" onClick={handleClickSearch}>
              <i className="ti-search"></i>
            </Button>
          </OverlayTrigger>
        </div>
      )}
    </>
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilterBy(
          filterDefaultField !== undefined ? filterDefaultField : "name"
        );
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        setFilter={(e) => setFilterBy(e)}
      />
    );
  }, [filterText, filterBy]);

  createTheme("solarized", {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  });

  const customNoDataComponent = () => {
    return (
      <div className="mb-4">Nenhuma informação disponível no momento...</div>
    );
  };

  const options = {
    title: title,
    columns: columns,
    data: filterText
      ? data.filter(
          (item) =>
            item[filterBy] &&
            item[filterBy].toLowerCase().includes(filterText.toLowerCase())
        )
      : data,
    pagination: true,
    paginationServer: true,
    fixedHeader: true,
    subHeader: true,
    subHeaderComponent: subHeaderComponentMemo,
    paginationResetDefaultPage: resetPaginationToggle,
    selectableRowsVisibleOnly: false,
    onSelectedRowsChange: onSelectedRowsChange,
    progressPending: isLoading,
    paginationTotalRows: totalRows,
    onChangePage: handlePageChange,
    noDataComponent: customNoDataComponent(),
  };

  if (selectableRow !== undefined) options["selectableRows"] = true;

  return (
    <div className="container-fluid h-100 pt-0">
      <DataTable {...options} />
    </div>
  );
};

const styles = {
  inputSearch: {
    paddingLeft: "2rem",
    borderTopLeftRadius: "1.25rem",
    borderBottomLeftRadius: "1.25rem",
    height: 45
  },
  icon: {
    position: "absolute",
    left: "2.5%",
    top: "28%",
    bottom: 0,
    zIndex: 10,
    margin: "auto",
  },
  dropdownArrow: {
    position: "absolute",
    right: "0",
    maxWidth: "40px",
    zIndex: "1000",
  },
};

export default DatatablePaginated;
