import axios from "axios";
import { BASE_URL, APP_BASE_URL } from "./Urls";
import jwt_decode from "jwt-decode";
import { parseCookies } from "nookies";

export const api = axios.create({
  baseURL: BASE_URL,
  params: {
    t: new Date().getTime(),
  },
});

export const VerifyIfTokenNotExpired = (token) => {
  let tokenDecoded = jwt_decode(token);

  if (!tokenDecoded.is_superuser) {
    localStorage.clear();
    window.location.href = APP_BASE_URL + "/login";
  }

  if (!sessionStorage.getItem("plan_id")) {
    sessionStorage.setItem("plan_id", tokenDecoded.plan_id);
  }
  return tokenDecoded.exp > Date.now() / 1000;
};

api.interceptors.request.use(
  function (config) {
    const endpoints = [
      "/security/",
      "api/v1/store/configs",
      "api/v1/store/?",
      "notifications/current_orders/",
      "files/ifood/",
      "/auth/",
      "requests/index/login/",
      "api.tomtom.com",
      "api/revalidate",
      "celery-progress/",
      "users/validate_cpf",
      "users/create_first_admin",
      "store/check_cnpj",
      "viacep.com.br",
      "store/create_main_store",
      "hereapi.com",
      "app.wabotty.com.br",
    ];
    const found =
      endpoints.filter((url) => config.url.includes(url)).length > 0;

    switch (true) {
      case found:
        delete config.headers.Authorization;
        break;

      case found && config.method === "post":
        delete config.headers.Authorization;
        break;

      default:
        let { token: token } = parseCookies();
        try {
          let tokenDecoded = jwt_decode(token);
          if (!tokenDecoded.is_superuser) {
            localStorage.clear();
            window.location.href = APP_BASE_URL + "/login";
            return;
          }
        } catch (error) {
          localStorage.clear();
          window.location.href = APP_BASE_URL + "/login";
          return;
        }

        config.headers.Authorization = `Bearer ${token}`;
        break;
    }
    return config;
  },
  function (error) {
    alert(`Ocorreu um erro em sua solicitação. Erro: ${error}`);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    //console.log(error.response)
    /*  if (error.response.status === 401) {
       localStorage.clear();
       window.location.href = APP_BASE_URL + '/login'
     } */

    return Promise.reject(error);
  }
);
