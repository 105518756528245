import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";

export default function DefaultLoader({ isLoading, color, cs = false }) {

  const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  'margin-top: 20%;';
  ${cs ? cs : ''}
`;

  return (
    <BeatLoader color={color !== undefined ? color : "var(--primary)"} loading={isLoading} css={override} size={12} />
  )
}