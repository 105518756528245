import Swal from "sweetalert2";
import { api } from "../api/Middleware";
import {
  STORE_URL,
  INTEGRATIONS_URL,
  SUPPORT_URL,
  STORE_CONFIGS_URL,
  SECURITY_URL,
} from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const GetOne = async (
  store_id,
  setStore,
  setErrors,
  setIsLoading,
  clean_cache = false,
  returnData = false
) => {
  try {
    let response = await api.get(`${STORE_URL}${store_id}/?cc=${clean_cache}`);
    if (response.status === 200) {
      if (returnData) return response.data;
      setStore(response.data);
      setIsLoading(false);
    }
  } catch (error) {
    setErrors({ title: "Oops!", message: ERROR_MESSAGES.store.get });
  }
};

export const List = async (
  url,
  setStores,
  setErrors,
  setIsLoading,
  clean_cache = false
) => {
  try {
    let response = await api.get(
      `${STORE_URL}list_stores/?url=${url}&cc=${clean_cache}`
    );
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        response.data = response.data.reverse();
      }

      setStores(response.data);
    }
  } catch (error) {
    setErrors({ title: "Oops!", message: ERROR_MESSAGES.store.get });
  } finally {
    if (setIsLoading) {
      setIsLoading(false);
    }
  }
};

export const GetAvailableApps = async (
  setAvailableApps,
  setErrors,
  setIsLoading,
  returnData = false
) => {
  try {
    let response = await api.get(`${INTEGRATIONS_URL}available_apps/`);

    if (response.status === 200) {
      let data = response.data.sort((a, b) => a.name.localeCompare(b.name));

      if (returnData) {
        return data;
      }

      setAvailableApps(data);
    }
  } catch (error) {
    setErrors({ title: "Oops!", message: ERROR_MESSAGES.store.get });
  } finally {
    setIsLoading(false);
  }
};

export const GetIntegrations = async (
  setIntegrations,
  setErrors,
  setIsLoading = null
) => {
  try {
    let response = await api.get(`${INTEGRATIONS_URL}`);
    if (response.status === 200) {
      setIntegrations(response.data);
    }
  } catch (error) {
    setErrors({ title: "Oops!", message: ERROR_MESSAGES.store.get });
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};

export const CreateOrUpdateIntegration = async (
  id = null,
  data,
  setNotify,
  returnData = false
) => {
  if (id) {
    try {
      let response = await api.put(`${INTEGRATIONS_URL}${id}/`, data);
      if (response.status === 200 || response.status === 201) {
        if (returnData) return response.data;
        setNotify({
          title: "Sucesso!",
          message: "Integração atualizada com sucesso!",
          type: "success",
          visible: true,
        });
        setTimeout(() => {
          window.location.href = "/integrations";
        }, 1500);
      }
    } catch (error) {
      if (returnData) return error?.response;
      setNotify({
        title: "Oops!",
        message: ERROR_MESSAGES.integrations.create,
        type: "error",
        visible: true,
      });
    }
  } else {
    try {
      let response = await api.post(`${INTEGRATIONS_URL}`, data);
      if (response.status === 200 || response.status === 201) {
        if (returnData) return response.data;
        setNotify({
          title: "Sucesso!",
          message: "Integração cadastrada com sucesso!",
          type: "success",
          visible: true,
        });
        setTimeout(() => {
          window.location.href = "/integrations";
        }, 1500);
      }
    } catch (error) {
      if (returnData) return error?.response;
      setNotify({
        title: "Oops!",
        message: ERROR_MESSAGES.integrations.create,
        type: "error",
        visible: true,
      });
    }
  }
};

export const ChangeIntegrationStatus = async (id, data, setNotify) => {
  try {
    let response = await api.put(`${INTEGRATIONS_URL}${id}/`, data);
    if (response.status === 200 || response.status === 201) {
      setNotify({
        title: "Sucesso!",
        message: "Integração atualizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.href = "/integrations";
      }, 2500);
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: ERROR_MESSAGES.integrations.create,
      type: "error",
      visible: true,
    });
  }
};

export const DeleteIntegration = async (id, setNotify) => {
  try {
    let response = await api.delete(`${INTEGRATIONS_URL}${id}/`);
    if (response.status >= 200 && response.status < 300) {
      setNotify({
        title: "Sucesso!",
        message: "Integração deletada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.href = "/integrations";
      }, 2000);
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: ERROR_MESSAGES.integrations.delete,
      type: "error",
      visible: true,
    });
  }
};

export const CheckCnpj = async (cnpj) => {
  try {
    let response = await api.get(`${STORE_URL}check_cnpj/?cnpj=${cnpj}`);
    if (response.status === 200) return response;
  } catch (error) {
    console.log(error);
    if (error.response.status === 403) {
      Swal.fire({
        title: "Oops!",
        html: error.response.data,
        icon: "info",
        buttons: true,
        confirmButtonText: `Fazer Login agora`,
        cancelButtonText: "Cancelar",
        showCancelButton: true,
        confirmButtonColor: "var(--primary)",
      }).then((value) => {
        if (value.isConfirmed) {
          window.location.href = "/login";
        }
      });
    }
  }
};

export const CreateSupportTicket = async (data) => {
  try {
    let response = await api.post(`${SUPPORT_URL}`, data);
    if (response.status === 200 || response.status === 201) {
      Swal.fire({
        title: "Sucesso!",
        html: "Cadastramos seu ticket de support, em até 24h você será notificado.",
        icon: "success",
      }).then((value) => {
        if (value.isConfirmed) {
          window.location.reload();
        }
      });
    }
  } catch (error) {
    console.log(error.response);
    Swal.fire({
      title: "Oops!",
      html: error.response.data,
      icon: "warning",
    }).then((value) => {
      if (value.isConfirmed) {
        window.location.reload();
      }
    });
  }
};

export const GetSupportTickets = async (
  setSupportTickets,
  setNotify,
  setIsLoading
) => {
  try {
    let response = await api.get(`${SUPPORT_URL}`);
    if (response.status === 200) {
      setSupportTickets(response.data.results);
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  } finally {
    setIsLoading(false);
  }
};

export const DontShowTutorialInitial = async (id, data, setShowModal) => {
  try {
    let response;
    if (id) {
      response = await api.put(`${STORE_CONFIGS_URL}${id}/`, data);
    } else {
      response = await api.post(`${STORE_CONFIGS_URL}`, data);
    }
    if (response.status === 200 || response.status === 201) {
      setShowModal(false);
      Swal.fire(
        "Sucesso!",
        "Não mostraremos mais o vídeo inicial, mas caso queira assisti-lo novamente, ele ficará disponível no menu principal abaixo do link 'Meu Plano'.",
        "success"
      ).then((res) => {
        if (res.isConfirmed) {
          window.location.reload();
        }
      });
    }
  } catch (error) {
    console.log(error);
    Swal.fire(
      "Oops!",
      "Não conseguimos concluir esta ação, por favor, tente novamente daqui alguns minutos, caso o problema persista, contacte nosso setor de suporte.",
      "warning"
    );
  }
};

export const CheckAvailability = async (store_id) => {
  try {
    let res = await api.get(`${STORE_URL}availability/?store_id=${store_id}`);
    return res.data;
  } catch (error) {
    return false;
  }
};

export const OpenStore = async () => {
  try {
    let response = await api.post(`${STORE_CONFIGS_URL}open_store/`);
    if (response.status === 200) {
      Swal.fire("Sucesso!", "Loja aberta para receber pedidos!", "success");
    }
  } catch (error) {
    Swal.fire("Oops!", ERROR_MESSAGES.store.open, "warning");
  }
};

export const CloseStore = async (data) => {
  try {
    let response = await api.post(`${STORE_CONFIGS_URL}close_store/`, data);
    if (response.status === 200) {
      console.log(response.data);
      Swal.fire("Sucesso!", response.data, "success").then((res) => {
        if (res.isConfirmed) window.location.reload();
      });
    }
  } catch (error) {
    Swal.fire("Oops!", ERROR_MESSAGES.store.pauseOrders, "warning");
  }
};

export const ValidateRecaptcha = async (data) => {
  try {
    let response = await api.post(`${SECURITY_URL}`, data);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const GetIfoodShippingIntegration = async () => {
  try {
    let response = await api.get(`${INTEGRATIONS_URL}ifood/shipping/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const WACampaignsTermsOfUse = async (id, data, setShowModal) => {
  try {
    let response;
    if (id) {
      response = await api.put(`${STORE_CONFIGS_URL}${id}/`, data);
    } else {
      response = await api.post(`${STORE_CONFIGS_URL}`, data);
    }
    if (response.status === 200 || response.status === 201) {
      setShowModal(false);
      Swal.fire(
        "Sucesso!",
        "Você aceitou os termos de uso e está apto a utilizar a ferramenta!",
        "success"
      ).then((res) => {
        if (res.isConfirmed) {
          window.location.href = "/campaigns"
        }
      });
    }
  } catch (error) {
    console.log(error);
    Swal.fire(
      "Oops!",
      "Não conseguimos concluir esta ação, por favor, tente novamente daqui alguns minutos, caso o problema persista, contacte nosso setor de suporte.",
      "warning"
    );
  }
};
