import React, {
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Button } from "react-bootstrap";
import Select from "react-select";
import SearchBox from "@tomtom-international/web-sdk-plugin-searchbox";
import * as ttServices from "@tomtom-international/web-sdk-services";
import BeatLoader from "react-spinners/BeatLoader";

import DefaultLoader from "../jsx/components/Loader";
import UserContext from "../contexts/UserContext";
import { FilterUsersBy } from "../services/UsersServices";
import Swal from "sweetalert2";
import {
  CreateCampaignList,
  UpdateCampaignList,
} from "../services/CampaignServices";

export default function CampaignLists(props) {
  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const [filterOne, setFilterOne] = useState(
    props.data ? props.data.filter_one : { label: "", value: "" }
  );
  const [filterTwo, setFilterTwo] = useState(
    props.data && props.data?.filter_two
      ? props.data.filter_two
      : { label: "", value: "" }
  );
  const [result, setResult] = useState({
    total: props.data ? props.data.customers_total : 0,
    data: props.data ? props.data.customers : [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const ttSearchBox = useRef();
  const filterByMainAddress = useRef();

  const onSubmit = async (data) => {
    if (result.total === 0) {
      Swal.fire(
        "Oops!",
        "Listas devem conter ao menos 1 cliente para serem criadas! Selecione os filtros e clique em buscar para prosseguir corretamente.",
        "info"
      );
      return;
    }

    let filter_two = "";
    let extra_data = {};

    if (
      data.filter_two &&
      data.filter_two.value === "main_address_in" &&
      filterByMainAddress.current
    ) {
      filter_two = {
        label: "Endereço principal na cidade ou bairro...",
        value: "main_address_in",
      };

      extra_data.state = filterByMainAddress.current.state;
      extra_data.city = filterByMainAddress.current.city;

      if (filterByMainAddress.current?.neighborhood) {
        extra_data.neighborhood = filterByMainAddress.current.neighborhood;
      }
    }

    if (data.average_ticket)
      extra_data.average_ticket = data.average_ticket.replace(/[^0-9]/g, "");
    if (data.consumed_more_than)
      extra_data.consumed_more_than = data.consumed_more_than.replace(
        /[^0-9]/g,
        ""
      );
    if (data.consumed_less_than)
      extra_data.consumed_less_than = data.consumed_less_than.replace(
        /[^0-9]/g,
        ""
      );

    let payload = {
      name: data.name,
      filter_one: data.filter_one,
      filter_two: filter_two,
      customers: result.data,
      customers_total: result.total,
      extra_data: extra_data,
      store: user.store_id,
    };

    let response;
    if (props.data && props.data.id) {
      payload.id = props.data.id;
      response = await UpdateCampaignList(payload);
    } else {
      response = await CreateCampaignList(payload);
    }

    if (response) {
      props.setShowModal(false);
      props.setNotify({
        title: "Sucesso!",
        message: `Lista ${
          props.data?.id ? "atualizada" : "criada"
        } com sucesso!`,
        type: "success",
        visible: true,
      });

      setTimeout(() => {
        window.location.href = "/campaigns";
      }, 3500);
    }
  };

  const handleSearchClients = async () => {
    let filterOne = document.querySelector('input[name="filter_one"]')?.value;
    if (!filterOne) {
      Swal.fire(
        "Atenção!",
        "Escolha o filtro principal desejado para filtrar sua lista corretamente. ",
        "warning"
      );
      return;
    }
    document.querySelector(".default-text").classList.add("d-none");
    document.querySelector(".loader-text").classList.remove("d-none");
    document.querySelector(".search-button").setAttribute("disabled", true);

    let filterTwo = document.querySelector('input[name="filter_two"]').value;

    let averageTicket = document.querySelector(
      'input[name="average_ticket"]'
    )?.value;
    let consumedMoreThan = document.querySelector(
      'input[name="consumed_more_than"]'
    )?.value;
    let consumedLessThan = document.querySelector(
      'input[name="consumed_less_than"]'
    )?.value;
    let ordersAbove = document.querySelector(
      'input[name="orders_above"]'
    )?.value;
    if (averageTicket) averageTicket = averageTicket.replace(/[^0-9]/g, "");
    if (consumedMoreThan)
      consumedMoreThan = consumedMoreThan.replace(/[^0-9]/g, "");
    if (consumedLessThan)
      consumedLessThan = consumedLessThan.replace(/[^0-9]/g, "");

    let payload = {
      filter_one: filterOne,
      filter_two: filterTwo || "",
      average_ticket_value: averageTicket || "",
      consumed_more_than: consumedMoreThan || "",
      consumed_less_than: consumedLessThan || "",
      orders_above: ordersAbove || "",
      neighborhood: filterByMainAddress.current?.neighborhood || "",
      city: filterByMainAddress.current?.city || "",
      state: filterByMainAddress.current?.state || "",
    };
    let queryString = new URLSearchParams(payload).toString();
    let response = await FilterUsersBy(queryString);

    document.querySelector(".loader-text").classList.add("d-none");
    document.querySelector(".default-text").classList.remove("d-none");
    document.querySelector(".search-button").removeAttribute("disabled");

    setResult(response);
  };

  const options = [
    {
      value: "all",
      label: "Todos clientes",
    },
    {
      value: "no_orders",
      label: "Nenhum pedido realizado",
    },
    {
      value: "no_orders_last_30_days",
      label: "Nenhum pedido nos últimos 30 dias",
    },
    {
      value: "no_orders_last_60_days",
      label: "Nenhum pedido nos últimos 60 dias",
    },
    {
      value: "no_orders_last_90_days",
      label: "Nenhum pedido nos últimos 90 dias",
    },
    {
      value: "average_ticket",
      label: "Ticket médio acima de",
    },
    {
      value: "consumed_more_than",
      label: "Já consumiu acima de",
    },
    {
      value: "consumed_less_than",
      label: "Consumiu menos que",
    },
    {
      value: "orders_above",
      label: "Já pediu pelo menos 'x' vezes",
    },
  ];

  const defaultValueExtraData = () => {
    if (filterOne.value === "average_ticket") {
      return props.data.extra_data?.average_ticket;
    } else if (filterOne.value === "consumed_more_than") {
      return props.data.extra_data?.consumed_more_than;
    } else if (filterOne.value === "consumed_more_than") {
      return props.data.extra_data?.consumed_less_than;
    }
  };

  useEffect(() => {
    const initSearchBox = () => {
      ttSearchBox.current = new SearchBox(ttServices.services, {
        searchOptions: {
          key: process.env.REACT_APP_TOMTOM_MAPS_KEY,
          minNumberOfCharacters: 3,
          language: "pt-BR",
          limit: 5,
          countrySet: "BR",
        },
        filterSearchResults: function (searchResult) {
          return Boolean(
            searchResult.dataSources &&
              searchResult.dataSources.geometry &&
              searchResult.dataSources.geometry.id
          );
        },
        labels: {
          noResultsMessage: "Nenhum resultado encontrado...",
          placeholder: "Busque um bairro ou cidade...",
        },
      });

      ttSearchBox.current.on("tomtom.searchbox.resultselected", (data) => {
        if (data.data.result.entityType === "Municipality") {
          filterByMainAddress.current = {
            city: data.data.result.address.municipality,
            state: data.data.result.address.countrySubdivisionName,
          };
        } else if (data.data.result.entityType === "MunicipalitySubdivision") {
          filterByMainAddress.current = {
            neighborhood: data.data.result.address.municipalitySubdivision,
            city: data.data.result.address.municipality,
            state: data.data.result.address.countrySubdivisionName,
          };
        }
      });

      document
        .getElementById("searchBoxPlaceholder")
        .appendChild(ttSearchBox.current.getSearchBoxHTML());

      let searchInput = document.querySelector(".tt-search-box-input");
      if (
        props.data?.filter_two &&
        props.data?.filter_two.value === "main_address_in" &&
        searchInput
      ) {
        if (props.data.extra_data?.neighborhood) {
          searchInput.value = `${props.data.extra_data.neighborhood}, ${props.data.extra_data.city}`;
        } else if (props.data.extra_data?.city) {
          searchInput.value = `${props.data.extra_data.city}`;
        }

        filterByMainAddress.current = {
          neighborhood: props.data.extra_data?.neighborhood || "",
          city: props.data.extra_data.city,
          state: props.data.extra_data.state,
        };
      }
    };

    let searchBoxPlaceholder = document.getElementById("searchBoxPlaceholder");
    if (searchBoxPlaceholder) {
      initSearchBox();
    }
  }, [filterTwo]);

  return (
    <div className="basic-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Nome</label>
            <input
              {...register("name", {
                required: true,
                maxLength: 100,
              })}
              className="form-control text-black"
              defaultValue={props.data ? props.data.name : ""}
            />
            {errors.name && (
              <small className="ml-2 text-danger">
                {errors.name.type === "required"
                  ? "Por favor, preencha este campo!"
                  : "Este campo deve conter no máximo 100 caracteres!"}
              </small>
            )}
          </div>

          <div className="form-group col-md-12">
            <label>Filtro principal</label>
            <Controller
              name={"filter_one"}
              control={control}
              rules={{ required: true }}
              defaultValue={props.data ? props.data.filter_one : ""}
              render={({ field }) => (
                <Select
                  {...field}
                  options={options}
                  className="rounded-form-control"
                  placeholder="Selecione uma opção..."
                  onChange={(e) => {
                    field.onChange(e);
                    setFilterOne(e);
                  }}
                  value={field.value}
                />
              )}
            />

            {errors.filter_one && (
              <small className="ml-2 text-danger">
                {errors.filter_one.type === "required" &&
                  "Por favor, selecione um filtro!"}
              </small>
            )}
          </div>
          {(filterOne.value === "average_ticket" ||
            filterOne.value === "consumed_more_than" ||
            filterOne.value === "consumed_less_than") && (
            <div className="form-group col-md-12">
              <label>Valor</label>
              <Controller
                name={filterOne.value}
                control={control}
                rules={{ required: true }}
                defaultValue={props.data ? defaultValueExtraData() : ""}
                render={({ field }) => (
                  <NumberFormat
                    decimalSeparator=","
                    prefix="R$"
                    placeholder="Digite um valor..."
                    className="form-control text-black"
                    {...field}
                  />
                )}
              />

              {errors[filterOne.value] && (
                <small className="ml-2 mb-2 text-danger">
                  {errors[filterOne.value].type === "required" &&
                    "Por favor, preencha este campo!"}
                </small>
              )}
            </div>
          )}
          {filterOne.value === "orders_above" && (
            <div className="form-group col-md-12">
              <input
                type="number"
                className="form-control text-black"
                placeholder="Quantas vezes?"
                defaultValue={props.data ? props.data?.orders_above : ""}
                {...register("orders_above")}
              />
            </div>
          )}
          {filterOne.value === "main_address_in" && (
            <div className="form-group col-md-12">
              <label id="searchBoxPlaceholder" className="w-100">
                Buscar por bairro ou cidade
              </label>
            </div>
          )}

          <div className="form-group col-md-12">
            <label>Filtro secundário</label>
            <Controller
              name={"filter_two"}
              control={control}
              defaultValue={props.data ? props.data.filter_two : ""}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    {
                      label: "Nenhum filtro secundário",
                      value: "",
                    },
                    {
                      label: "Endereço principal na cidade ou bairro...",
                      value: "main_address_in",
                    },
                  ]}
                  className="rounded-form-control"
                  placeholder="Selecione uma opção..."
                  onChange={(e) => {
                    field.onChange(e);
                    setFilterTwo(e);
                  }}
                  value={field.value}
                />
              )}
            />
          </div>
          {filterTwo.value === "main_address_in" && (
            <div className="form-group col-md-12">
              <label id="searchBoxPlaceholder" className="w-100">
                Buscar por bairro ou cidade
              </label>
            </div>
          )}
          <div className="form-group col-md-12 d-flex">
            <div className="col-md-7 px-1">
              <label>Total de clientes filtrados</label>
              <input
                {...register("total_customers")}
                type="text"
                className="form-control"
                value={result.total}
                readOnly
              />
            </div>
            <div className="col-md-5 px-0 align-self-end">
              <Button
                type="button"
                className="btn btn-md btn-info mx-auto search-button"
                onClick={handleSearchClients}
              >
                <span className="loader-text d-none">
                  Aguarde{" "}
                  <BeatLoader
                    color={"#fff"}
                    loading={true}
                    size={8}
                    css={`
                      margin-top: 8px;
                    `}
                  />
                </span>
                <span className="default-text">
                  <i className="ti-search font-weight-bold pr-2"></i> Buscar
                  Clientes
                </span>
              </Button>
            </div>
          </div>
        </div>

        <Button type="submit" className="btn btn-block btn-primary">
          {isLoading ? <DefaultLoader color={"white"} /> : "Salvar"}
        </Button>
      </form>
    </div>
  );
}
