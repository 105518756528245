import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Picker } from "emoji-picker-element";

import AlertResponses from "../jsx/components/Alert";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import UserContext from "../contexts/UserContext";
import DefaultLoader from "../jsx/components/Loader";
import { Hash, MoneyFormat, Slugfy } from "../helpers/Helpers";
import {
  CreateCampaign,
  GetCampaignLists,
  GetBottyAccounts,
  UpdateCampaign,
} from "../services/CampaignServices";
import { Button } from "react-bootstrap";
import { GetAll as GetProducts } from "../services/ProductsServices";
import WhatsAppChat from "../jsx/components/WhatsAppChat";
import { AppSettings } from "../App";
import { GetAll as GetPromotions } from "../services/PromotionsServices";
import { GetValidOnly } from "../services/CouponsServices";
import Swal from "sweetalert2";

export default function CampaignDetails(props) {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [campaign, setCampaign] = useState(props.location?.campaign || "");
  const [mediaType, setMediaType] = useState(
    props.location?.campaign ? props.location?.campaign.media_type : "text"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [selected, setSelected] = useState();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const _data = useRef();
  const _lists = useRef();
  const picker = useRef(new Picker());

  const [accounts, setAccounts] = useState([]);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const status = {
    connected: "Conectado",
  };

  const mediaTypeLegend = {
    product: "Produto",
    promotion: "Promoção",
    coupon: "Cupom",
  };

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if(user?.plan_id < 3){
      window.location.assign('/')
      return
    }
    
    if (!user.botty_token) {
      Swal.fire("Oops!", "Você não tem acesso a este recurso!", "error");
      return;
    } else {
      GetBottyAccounts(user.botty_token, null, true).then((res) => {
        if (id && campaign) {
          let account = res.filter(
            (data) => data.unique === campaign.wa_account_unique
          )[0];
          let options = {
            value: account.unique,
            label: (
              <div>
                {account.phone}{" "}
                <span
                  className={`badge badge-sm ${
                    status[account.status] ? "badge-success" : "badge-primary"
                  }`}
                >
                  {status[account.status]
                    ? status[account.status]
                    : "Indisponível"}
                </span>
              </div>
            ),
          };
          setValue("waAccount", options);
        }
        setAccounts(res);
      });
    }

    let textarea = document.querySelector(".description");
    let bubble = document.querySelector(".chat-bubble .text");

    textarea.addEventListener("input", function (event) {
      let text = event.target.value.replace(/\n/g, "<br>");

      if (mediaType !== "text" && selected && selected?.image) {
        bubble.innerHTML = text;
      } else {
        bubble.innerHTML = text;
      }
    });
  }, []);

  useEffect(() => {
    if (mediaType !== "text" && mediaType !== "media") {
      loadInitialData(mediaType);
    }
  }, [mediaType]);

  const getLists = async () => {
    let res = await GetCampaignLists(null, null, `?fields=id,name`, true);

    if (res.length > 0) {
      _lists.current = res;
      let t = res.map((list) => {
        return { label: list.name, value: list.id };
      });
      if (id && campaign) {
        let list = _lists.current.filter(
          (data) => data.id === campaign.campaign_list
        )[0];

        setValue("campaignList", { label: list.name, value: list.id });
      }
      return t;
    } else {
      return [{ label: "", value: "" }];
    }
  };

  const loadInitialData = async (option) => {
    let res;
    if (option === "product") {
      res = await GetProducts(null, null, null, true);
    } else if (option === "promotion") {
      res = await GetPromotions(null, null, null, true);
    } else if (option === "coupon") {
      res = await GetValidOnly();
    }

    if (res.length > 0) {
      _data.current = res;
      let options = res.map((data) => {
        if (mediaType === "coupon") {
          if (id && campaign && campaign?.item_id) {
            let item = res.filter(
              (item) => item.id === parseInt(campaign.item_id)
            )[0];
            setValue(mediaType, { label: item.name, value: item.id });
          }

          return {
            label: data.name,
            value: data.id,
          };
        } else {
          if (id && campaign && campaign?.item_id) {
            let item = res.filter(
              (item) => item.id === parseInt(campaign.item_id)
            )[0];
            setValue(mediaType, { label: item.name, value: item.id });
          }
          return {
            label: (
              <div>
                <img
                  src={
                    data?.image
                      ? Hash(
                          "45x45",
                          data.image.includes("/images/")
                            ? data.image.replace("/images/", "")
                            : data.image
                        )
                      : "/imgs/default_img.png"
                  }
                  width={45}
                  height={45}
                  className="mr-2"
                  style={{ borderRadius: 5, objectFit: "cover" }}
                  alt=""
                />
                {data.name}
              </div>
            ),
            value: data.id,
          };
        }
      });
      setData(options);
    } else {
      setData([{ label: "", value: "" }]);
    }
    if (id && campaign && campaign?.item_id)
      waContent(null, null, parseInt(campaign.item_id));
    setLoading(false);
  };

  const getData = (inputValue, item) => {
    console.log(inputValue);
    return new Promise((resolve) => {
      if (!inputValue) {
        resolve(data);
      } else {
        const filteredItems = data.filter((item) =>
          item.label.props.children[1].toLowerCase().includes(inputValue.toLowerCase())
        );
        resolve(filteredItems);
      }
    });
  };

  const imagePreview = (image) => {
    if (image?.size > 2000000) {
      Swal.fire('Atenção!', 'Por favor, insira imagens até 2MB.', 'info')
      return
    }
    let url = URL.createObjectURL(image);
    let obj = { image: url };

    setSelected(obj);
  };

  const onSubmit = async (data) => {
    if (!user.botty_token) {
      Swal.fire("Oops!", "Você não tem acesso a este recurso!", "error");
      return;
    }

    let form = new FormData();
    form.append("name", data.name);
    form.append("bt", user.botty_token);
    form.append("wa_phone", data.waAccount.label.props.children[0]);
    form.append("wa_account_unique", data.waAccount.value);
    form.append("campaign_list", data.campaignList.value);
    form.append("media_type", data.mediaType);
    form.append("message", data.message);
    form.append("store", user.store_id);
    if (selected && selected?.id) form.append("item_id", selected.id);
    let url;

    if (mediaType === "media" && data?.image.length > 0) {
      form.append("image", data.image[0]);
    } else if (
      mediaType !== "text" &&
      mediaType !== "coupon" &&
      selected?.image
    ) {
      if (
        !selected.image.includes("https://") &&
        selected.image.includes("pralevar.s3.amazonaws.com")
      ) {
        url = `https://${selected.image}`;
      } else if (
        !selected.image.includes("https://") &&
        !selected.image.includes("pralevar.s3.amazonaws.com")
      ) {
        url = `https://pralevar.s3.amazonaws.com/${selected.image}`;
      } else {
        url = selected.image;
      }

      form.append("image_url", url);
    }

    if (id) {
      form.append("id", id);

      let res = await UpdateCampaign(id, form, null, setProgress, true);
      if (res) {
        setNotify({
          title: "Sucesso!",
          message: "Campanha atualizada com sucesso!",
          type: "success",
          visible: true,
        });

        setTimeout(() => {
          window.location.href = "/campaigns";
        }, 3000);
      }
    } else {
      let res = await CreateCampaign(form, null, setProgress, true);
      
      if (res) {
        setNotify({
          title: "Sucesso!",
          message: "Campanha criada com sucesso!",
          type: "success",
          visible: true,
        });

        setTimeout(() => {
          window.location.href = "/campaigns";
        }, 3000);
      }
    }
  };

  const options = accounts.map((acc) => ({
    value: acc.unique,
    label: (
      <div>
        {acc.phone}{" "}
        <span
          className={`badge badge-sm ${
            status[acc.status] ? "badge-success" : "badge-primary"
          }`}
        >
          {status[acc.status] ? status[acc.status] : "Indisponível"}
        </span>
      </div>
    ),
  }));

  const emojiPicker = (e) => {
    document.querySelector(".description").value += e.detail.unicode;
    let chatBubble = document.querySelector(".chat-bubble .text");
    if (chatBubble) {
      chatBubble.append(e.detail.unicode);
    }
  };

  const removeEmojiPicker = () => {
    let element = document.querySelector("emoji-picker");
    if (element) {
      element.remove();
    }
    picker.current.removeEventListener("emoji-click", emojiPicker);
  };

  const handleEmojiPicker = () => {
    picker.current.addEventListener("emoji-click", emojiPicker);

    let element = document.querySelector("emoji-picker");
    if (element) {
      element.remove();
      picker.current.removeEventListener("emoji-click", emojiPicker);
    } else {
      document.querySelector(".message-box").append(picker.current);
    }

    document
      .querySelector(".description")
      .addEventListener("click", removeEmojiPicker);
  };

  const waContent = (e, field, item_id = false) => {
    if (e && field) field.onChange(e);
    if (!item_id) item_id = e?.value;
    let text = "";
   
    let item = _data.current.filter((item) => item.id === item_id)[0];

    let description = document.querySelector(".description");
    let chatBubble = document.querySelector(".chat-bubble .text");

    if (description) {
      if (mediaType === "coupon") {
        let value = item.rules?.discount_by_value
          ? MoneyFormat(item.rules.discount_by_value)
          : "";
        let priceAbove = item.rules?.price_above
          ? MoneyFormat(item.rules?.price_above)
          : "";

        text = `Oii {{nome_cliente}}, você ganhou ${
          item.rules?.discount_by === "value"
            ? value
            : `${item.rules?.discount_by_percentage}%`
        } de desconto!\n\nUtilize o cupom: *${
          item.name
        }* em sua próxima compra.\n\n${
          priceAbove ? `Pedido mínimo de: ${priceAbove}\n` : ""
        }Válido até: ${new Date(item.expiration).toLocaleString()}`;

        text = id && campaign ? decodeURIComponent(campaign.message) : text;
        description.value = text;
        if (chatBubble) {
          chatBubble.innerHTML = text.replace(/\n/g, "<br>");
        }
        setValue("message", text);
      } else {
        text = `Oii {{nome_cliente}}, temos uma super oferta para você!\n\n${
          item.name
        } - por apenas R$${item.promotional_price || item.price}\n\n${
          item.description
        }\n\nPeça agora em: https://${AppSettings.store.url}/${Slugfy(
          item.name
        )}-em-${Slugfy(AppSettings.store.address.city)}-${item.id}
`;
        text = id && campaign ? decodeURIComponent(campaign.message) : text;
        description.value = text;
        setValue("message", text);

        if (chatBubble) {
          chatBubble.innerHTML = text.replace(/\n/g, "<br>");
        }
      }
    }

    setSelected(item);
  };

  return (
    <>
      <div className="row">
       {/*  {isLoading ? (
          <DefaultLoader isLoading={isLoading} />
        ) : ( */}
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div
                    className={`col-xl-2 col-lg-4 col-md-4 col-xxl-4 wa-phone-simulator`}
                  >
                    <WhatsAppChat image={selected?.image} />
                    {mediaType === "media" && (
                      <>
                        <div className="input-group mt-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">Upload</span>
                          </div>
                          <div className="custom-file">
                            <input
                              accept="image/*"
                              type="file"
                              className="custom-file-input"
                              ref={register}
                              {...register("image", {
                                required: mediaType === "media" ? true : false,
                              })}
                              onChange={(e) => imagePreview(e.target.files[0])}
                            />
                            <label className="custom-file-label">
                              Selecione...
                            </label>
                          </div>
                        </div>
                        {errors.image && (
                          <small className="ml-2 text-danger">
                            {errors.image.type === "required" &&
                              "Por favor, selecione uma imagem!"}
                          </small>
                        )}
                      </>
                    )}
                  </div>

                  <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="">
                          {campaign ? campaign.name : "Nova Campanha"}
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="basic-form">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Tipo de envio</label>
                                <select
                                  className="form-control text-black"
                                  {...register("mediaType", { required: true })}
                                  defaultValue={mediaType}
                                  onChange={(e) => {
                                    let description =
                                      document.querySelector(".description");

                                    let chatBubble =
                                      document.querySelector(
                                        ".chat-bubble .text"
                                      );
                                    if (description.value) {
                                      description.value = "";
                                    }
                                    if (chatBubble.innerHTML) {
                                      let img =
                                        document.querySelector(
                                          ".chat-bubble img"
                                        );
                                      if (img) img.setAttribute("src", "");
                                      chatBubble.innerHTML = "";
                                    }
                                    setMediaType(e.target.value);
                                  }}
                                >
                                  <option value="text">
                                    Mensagem de texto
                                  </option>
                                  <option value="media">
                                    Mensagem com imagem
                                  </option>
                                  <option value="product">
                                    Produto cadastrado
                                  </option>
                                  <option value="promotion">Promoção</option>
                                  <option value="coupon">
                                    Cupom de desconto
                                  </option>
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>Conta</label>
                                <Controller
                                  name="waAccount"
                                  control={control}
                                  rules={{ required: true }}
                                  defaultValue={""}
                                  render={({ field }) => (
                                    <Select
                                      options={options}
                                      className=""
                                      placeholder="Selecione uma conta..."
                                      menuPortalTarget={
                                        typeof window !== "undefined"
                                          ? document.body
                                          : ""
                                      }
                                      styles={{
                                        ...customStyles,
                                        menuPortal: (provided) => ({
                                          ...provided,
                                          zIndex: 999999999,
                                        }),
                                      }}
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              {errors.waAccount && (
                                <small className="ml-2 mb-2 text-danger">
                                  {errors.waAccount.type === "required" &&
                                    "Por favor, selecione uma conta conectada!"}
                                </small>
                              )}
                            </div>

                            {(mediaType === "product" ||
                              mediaType === "promotion" ||
                              mediaType === "coupon") && (
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>{mediaTypeLegend[mediaType]}</label>
                                  <Controller
                                    name={mediaType}
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={""}
                                    render={({ field }) => (
                                      <AsyncSelect
                                        className="rounded-form-control"
                                        placeholder={`Selecione um ${mediaTypeLegend[mediaType]}...`}
                                        cacheOptions
                                        defaultOptions={data}
                                        loadOptions={(inputValue) =>
                                          getData(inputValue, mediaType)
                                        }
                                        onChange={(e) => waContent(e, field)}
                                        value={field.value}
                                        isLoading={loading}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <label>Nome da campanha</label>
                                <input
                                  {...register("name", {
                                    required: true,
                                    maxLength: 80,
                                  })}
                                  className="form-control text-black"
                                  defaultValue={campaign ? campaign.name : ""}
                                />
                                {errors.name && (
                                  <small className="ml-2 text-danger">
                                    {errors.name.type === "required"
                                      ? "Por favor, preencha este campo!"
                                      : "Este campo deve conter no máximo 80 caracteres!"}
                                  </small>
                                )}
                              </div>
                              <div className="form-group col-md-12 pb-2">
                                <label>Lista</label>
                                <Controller
                                  name="campaignList"
                                  control={control}
                                  rules={{ required: true }}
                                  defaultValue={""}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      className="rounded-form-control"
                                      placeholder="Selecione uma lista..."
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={getLists}
                                      onChange={(e) => {
                                        field.onChange(e);
                                      }}
                                      value={field.value}
                                      defaultValue={field.value}
                                    />
                                  )}
                                />
                              </div>

                              {errors.campaignList && (
                                <small className="ml-2 mb-2 text-danger">
                                  {errors.campaignList.type === "required" &&
                                    "Por favor, selecione uma lista!"}
                                </small>
                              )}

                              <div className="form-group col-md-12 position-relative message-box">
                                <label className="d-flex justify-content-between">
                                  Mensagem{" "}
                                  <span
                                    className="emoji-picker-handle cursor-pointer"
                                    onClick={handleEmojiPicker}
                                  >
                                    😄
                                  </span>
                                </label>
                                <textarea
                                  className="form-control text-black description"
                                  rows="8"
                                  defaultValue={
                                    campaign
                                      ? decodeURIComponent(campaign.message)
                                      : ""
                                  }
                                  {...register("message", {
                                    required: true,
                                  })}
                                ></textarea>
                              </div>
                              <small className="text-primary pl-4 mb-4">
                                *Utilize o shortcode {"{{nome_cliente}}"} para
                                inserir o nome de cada cliente ao enviar a
                                mensagem.
                              </small>
                              {errors.message && (
                                <small className="ml-2 mb-2 text-danger">
                                  {errors.message.type === "required" &&
                                    "Por favor, preencha este campo!"}
                                </small>
                              )}
                            </div>

                            <Button
                              type="submit"
                              className="btn btn-block btn-primary"
                              disabled={isLoading ? true : false}
                            >
                               {isLoading? <DefaultLoader color={"white"} /> : !id ? "Enviar Campanha" : "Atualizar"}
                              
                            </Button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {progress && <ProgressBarComponent />}
            {notify.visible && (
              <AlertResponses
                title={notify.title}
                message={notify.message}
                type={notify.type}
                visible={notify.visible}
                setNotify={setNotify}
              />
            )}
          </div>
       {/*  )} */}
      </div>
    </>
  );
}

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "1.25rem",
    borderColor: "#ced4da",
    minHeight: "calc(1.5em + .75rem + 2px)",
    height: "56px",
    padding: "0.375rem 0.75rem",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#495057",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    transition: "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#80bdff",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
    padding: "0",
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: "0",
  }),
};
