import React, { useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useForm } from 'react-hook-form'
import { Create, ValidateCPF } from '../../../../services/UsersServices'
import Swal from 'sweetalert2'
import { setCookie } from 'nookies'

const StepOne = props => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors }
  } = useForm()
  const next = useRef()
  const prev = useRef()
  const submit = useRef()
  const [isLoading, setIsLoading] = useState(false)

  const [notify, setNotify] = useState({
    title: '',
    message: '',
    type: '',
    visible: false
  })

  const onSubmit = data => {
    if (data.email !== data.confirmEmail) {
      setError('confirmEmail', {
        type: 'invalid',
        message: 'Por favor, verifique se o seu e-mail está correto!'
      })
      return
    }

    let store = JSON.parse(localStorage.getItem('r_storeData'))
    submit.current = document.querySelector('button[type="submit"]')

    var form = new FormData()
    form.append('first_name', data.first_name)
    form.append('last_name', data.last_name)
    form.append('email', data.email)
    form.append('cpf', data.cpf.replace(/[^0-9]/g, ''))
    form.append('birthday', data.birthday.split('/').reverse().join('-'))
    form.append('phone', data.phone.replace(/[^0-9]/g, ''))
    form.append('superuser_level', 1)
    form.append('is_superuser', 1)
    form.append('store_id', store.id)
    form.append('stores', store.id)
    form.append('main_store', store.id)

    submit.current.innerText = 'Aguarde...'
    submit.current.setAttribute('disabled', true)

    /*  console.log(data)
     console.log(email.current)
     console.log(form.get('email'))
     return */

    ValidateCPF(form)
      .then(res => {
        if (res.status >= 400) {
          setError('cpf', {
            type: 'invalid',
            message: res.data
          })

          submit.current.innerText = 'Avançar'
          submit.current.removeAttribute('disabled')

          return
        }
        Create(form, null, null, null, true, true)
          .then(resUser => {
            if (resUser.status >= 400) {
              if (resUser.data.email) {
                Swal.fire({
                  title: "Oops!",
                  html: '<b>Este e-mail já esta cadastrado!</b> Tente fazer o login com seu e-mail para acessar a plataforma. Qualquer dúvida entre em contato com nosso suporte pelo ícone do Chat.',
                  icon: "warning"
                })
              }else if (resUser.data.cpf) {
                Swal.fire({
                  title: "Oops!",
                  html: '<b>Este CPF já esta cadastrado!</b> Tente fazer o login com seu e-mail para acessar a plataforma. Qualquer dúvida entre em contato com nosso suporte pelo ícone do Chat.',
                  icon: "warning"
                })
              } else if (resUser.data.phone) {
                Swal.fire({
                  title: "Oops!",
                  html: "<b>Este telefone já esta cadastrado!</b> Tente fazer o login com seu e-mail para acessar a plataforma, ou insira outro telefone. Qualquer dúvida entre em contato com nosso suporte pelo ícone do Chat.",
                  icon: "warning"
                })
              } else {
                Swal.fire(
                  'Oops!',
                  'Por favor, preencha todos os campos corretamente para continuar! Qualquer dúvida entre em contato com nosso suporte pelo ícone do Chat.',
                  'warning'
                )
              }
              setIsLoading(false)
            } else {
              localStorage.setItem('r_userData', JSON.stringify(resUser))
              next.current.click()
              setCookie(undefined, 'token', resUser.access, {
                maxAge: 60 * 60 * 24 * 7 // 7 dias
              })
            }
          })
          .catch(error => {
            console.log(error)
            submit.current.innerText = 'Avançar'
            submit.current.removeAttribute('disabled')
            Swal.fire(
              'Oops!',
              error.response.data.detail || error.response.data,
              'warning'
            )
            setIsLoading(false)
          })
          .finally(() => {
            submit.current.innerText = 'Avançar'
            submit.current.removeAttribute('disabled')
          })
      })
      .catch(error => {
        submit.current.innerText = 'Avançar'
        submit.current.removeAttribute('disabled')

        setError('cpf', {
          type: 'invalid',
          message: error.data
        })
      })
  }

  useEffect(() => {
    document.querySelectorAll('button').forEach(button => {
      if (button.textContent.includes('Next')) {
        next.current = button
        button.style.visibility = 'hidden'
      }
      if (button.textContent.includes('Prev')) {
        prev.current = button
        button.style.visibility = 'hidden'
      }
    })

    if(JSON.parse(localStorage.getItem('r_userData'))){
      next.current.click()
    }
  }, [])

  return (
    <section>
      <h4 className='card-title mb-4 text-center'>Dados do Administrador</h4>
      <div className='row w-100'>
        <form onSubmit={handleSubmit(onSubmit)} id='stepOne' className='w-100'>
          <div className='col-lg-6 mb-2 d-inline-block'>
            <div className='form-group'>
              <label className='text-label'>Primeiro nome</label>
              <input
                className='form-control text-black'
                defaultValue={''}
                {...register('first_name', { required: true })}
              />
              {errors.first_name && (
                <small className='ml-2 text-danger'>
                  {errors.first_name.type === 'required' &&
                    'Por favor, preencha este campo!'}
                </small>
              )}
            </div>
          </div>
          <div className='col-lg-6 mb-2 d-inline-block'>
            <div className='form-group'>
              <label className='text-label'>Sobrenome</label>
              <input
                {...register('last_name', { required: true })}
                className='form-control text-black'
                defaultValue={''}
              />
              {errors.last_name && (
                <small className='ml-2 text-danger'>
                  {errors.last_name.type === 'required' &&
                    'Por favor, preencha este campo!'}
                </small>
              )}
            </div>
          </div>
          <div className='col-lg-6 mb-2 d-inline-block'>
            <div className='form-group'>
              <label className='text-label'>E-mail</label>
              <input
                type='text'
                placeholder='Digite seu e-mail'
                className='form-control'
                {...register('email', {
                  required: true,
                  minLength: 3,
                  pattern: /^[a-zA-Z0-9\._-]+@[a-zA-Z0-9]+.[a-zA-Z]/
                })}
              />
              {errors.email && (
                <small className='text-danger'>
                  {errors.email.type === 'required'
                    ? 'Campo obrigatório!'
                    : 'Digite corretamente seu e-mail!'}
                </small>
              )}
            </div>
          </div>
          <div className='col-lg-6 mb-2 d-inline-block'>
            <label>Confirme o E-mail</label>
            <input
              type='text'
              className='form-control'
              placeholder='Confirme seu e-mail'
              autoComplete='off'
              {...register('confirmEmail', {
                required: true,
                minLength: 3,
                pattern: /^[a-zA-Z0-9\._-]+@[a-zA-Z0-9]+.[a-zA-Z]/
              })}
            />
            {errors.confirmEmail && (
              <small className='text-danger'>
                {errors.confirmEmail.type === 'required' &&
                  'Preencha este campo!'}
                {errors.confirmEmail.type === 'pattern' &&
                  'Verifique se digitou corretamente seu email!'}
                {errors.confirmEmail.type === 'invalid' &&
                  errors.confirmEmail.message}
              </small>
            )}
          </div>
          <div className='col-lg-6 mb-3 d-inline-block'>
            <div className='form-group'>
              <label className='text-label'>CPF</label>
              <NumberFormat
                defaultValue={''}
                format='###.###.###-##'
                className='form-control text-black'
                {...register('cpf', { required: true })}
              />
              {errors.cpf && (
                <small className='text-danger'>
                  {errors.cpf.type === 'required'
                    ? 'Campo obrigatório!'
                    : errors.cpf.type === 'invalid'
                    ? errors.cpf.message
                    : 'CPF inválido!'}
                </small>
              )}
            </div>
          </div>
          <div className='col-lg-6 mb-2 d-inline-block'>
            <div className='form-group'>
              <label className='text-label'>Whatsapp</label>
              <NumberFormat
                defaultValue={''}
                format='(##) #####-####'
                className='form-control text-black'
                onChange={e => setValue('phone', e.target.value)}
                {...register('phone', { required: true })}
              />
              {errors.phone && (
                <small className='ml-2 mb-2 text-danger'>
                  {errors.phone.type === 'required' &&
                    'Por favor, preencha este campo!'}
                </small>
              )}
            </div>
          </div>

          <div className='col-lg-6 mb-3 d-inline-block'>
            <div className='form-group'>
              <label className='text-label'>Aniversário</label>
              <NumberFormat
                defaultValue={''}
                format='##/##/####'
                className='form-control text-black'
                onChange={e => setValue('birthday', e.target.value)}
                {...register('birthday', { required: true })}
              />
              {errors.birthday && (
                <small className='ml-2 mb-2 text-danger'>
                  {errors.birthday.type === 'required' &&
                    'Por favor, preencha este campo!'}
                </small>
              )}
            </div>
          </div>

          <div className='col-lg-12 text-center'>
            <button
              type='button'
              className='btn btn-secondary ml-3'
              onClick={() => prev.current.click()}
            >
              Voltar
            </button>
            <button
              type='submit'
              className='btn btn-primary ml-3'
            >
              Avançar
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default StepOne
