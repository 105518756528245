import React from 'react'
import { Button, Modal } from "react-bootstrap";

export default function ModalComponent({ props }) {

    return (
        <Modal id={props?.id ? props.id : ''} className={`fade`} show={props.showModal} size={props.size}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
                {
                    props.showCloseButton === undefined &&
                    <Button
                    onClick={() => props.setShowModal(false)}
                    variant=""
                    className="close"
                >
                    <span>&times;</span>
                </Button>
                }
                
            </Modal.Header>
            <Modal.Body>
                {props.content}
            </Modal.Body>
            {
                props.showFooter !== undefined && props.showFooter &&
                <Modal.Footer>
                    <Button onClick={() => props.setShowModal(false)}>Fechar</Button>
                    <Button variant="primary">{props.btnLabel}</Button>
                </Modal.Footer>
            }

        </Modal>
    )
}