import React,{useState, useEffect} from "react"


export default function GroupOptions ({ index, setValue,item }) {
  const [minQuantity, setMinQuantity] = useState(0)
  const [maxQuantity, setMaxQuantity] = useState(1)

  useEffect(() => {
    if(item){
      setMinQuantity(item.min)
      setMaxQuantity(item.max)
    }
  },[item])

  return (
    <>
      <div className='form-group col-md-12 pl-0 pt-3'>
        <div className='custom-control custom-checkbox ml-1 mb-2'>
          <input
            type='checkbox'
            name={`group.${index}.requiredChoice`}
            className='custom-control-input'
            id={`group.${index}.requiredChoice`}
            defaultChecked={item && item.requiredChoice ? true : false}
            onChange={e => {
                setValue(`group.${index}.requiredChoice`,e.target.checked  ? 1 : 0)
              if ((e.target.checked && minQuantity === 0)) {
                setValue(`group.${index}.min`, 1)
                setMinQuantity(1)
              }
            }}
          />
          <label
            className='custom-control-label'
            htmlFor={`group.${index}.requiredChoice`}
          >
            Escolha obrigatória?
          </label>
        </div>
        {/* <div className='custom-control custom-checkbox ml-1 mb-2'>
                        <input
                          type='checkbox'
                          id={`group.${index}.multipleChoice`}
                          className='custom-control-input'
                          {...register(`group.${index}.multipleChoice`)}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={`group.${index}.multipleChoice`}
                        >
                          Permitir múltipla escolha?
                        </label>
                      </div> */}

        {/* <div className='custom-control custom-checkbox ml-1 mb-4'>
          <input
            type='checkbox'
            id={`group.${index}.addVariationValueToProduct`}
            className='custom-control-input'
            name={`group.${index}.addVariationValueToProduct`}
            defaultChecked={item && item.addVariationValueToProduct ? true : false}
            onChange={(e) => {
                setValue(`group.${index}.addVariationValueToProduct`,e.target.checked ? 1 : 0)
            }}
          />
          <label
            className='custom-control-label'
            htmlFor={`group.${index}.addVariationValueToProduct`}
          >
            Somar o valor da variação no produto?
          </label>
        </div> */}
      </div>

      <div className='form-group mt-2 ml-1 mb-5'>
        <label className='d-block'>
          Quantas opções podem ser selecionadas?
        </label>
        <div className='d-inline-block'>
          <small>Mínimo</small>
          <div className='qty'>
            <span
              className='qty-subtract'
              onClick={() => {
                let input = document.querySelector(
                  `input[name="group.${index}.requiredChoice"]`
                )
                if (minQuantity >= 1) {
                  if (minQuantity === 1) {
                    if (!input.checked) {
                      let min = minQuantity - 1
                      
                      setValue(`group.${index}.min`, min)
                      setMinQuantity(min)
                    }
                  } else {
                    let min = minQuantity - 1
                    
                    setValue(`group.${index}.min`, min)
                    setMinQuantity(min)
                  }
                }
              }}
              style={{ height: 35 }}
            >
              <i
                className='fa fa-minus'
                style={{
                  marginRight: 'unset',
                  fontSize: '15px'
                }}
              />
            </span>
            <input
              type='text'
              name={`group.${index}.min`}
              id={`minQuantity`}
              value={minQuantity}
              readOnly
            />
            <span
              className='qty-add'
              onClick={() => {
                if (minQuantity < 5 && minQuantity < maxQuantity) {
                  let min = minQuantity + 1
                  setMinQuantity(min)
                  setValue(`group.${index}.min`, min)
                }
              }}
            >
              <i
                className='fa fa-plus'
                style={{
                  marginRight: 'unset',
                  fontSize: '15px'
                }}
              />
            </span>
          </div>
        </div>
        <div className='d-inline-block ml-4'>
          <small>Máximo</small>
          <div className='qty'>
            <span
              className='qty-subtract'
              onClick={() => {
                if (maxQuantity >= 1 && maxQuantity > minQuantity) {
                  let max = maxQuantity - 1
                 
                  setValue(`group.${index}.max`, max)
                  setMaxQuantity(max)
                }
              }}
              style={{ height: 35 }}
            >
              <i
                className='fa fa-minus'
                style={{
                  marginRight: 'unset',
                  fontSize: '15px'
                }}
              />
            </span>
            <input
              type='text'
              name={`group.${index}.max`}
              id={`maxQuantity`}
              value={maxQuantity}
              readOnly
            />
            <span
              className='qty-add'
              onClick={() => {
                if (maxQuantity < 5) {
                  let max = maxQuantity + 1
                 
                  setValue(`group.${index}.max`, max)
                  setMaxQuantity(max)
                }
              }}
            >
              <i
                className='fa fa-plus'
                style={{
                  marginRight: 'unset',
                  fontSize: '15px'
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
