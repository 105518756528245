import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import DefaultLoader from "../jsx/components/Loader";
import Swal from "sweetalert2";
import AlertResponses from "../jsx/components/Alert";
import UserContext from "../contexts/UserContext";

import ModalComponent from "../jsx/components/Modal";
import { FilterUsersBy } from "../services/UsersServices";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import {
  DeleteAccount,
  GetAccount,
  PauseAccount,
  PauseOrActiveAccount,
} from "../services/WaBottyServices";
import WhatsappConnect from "./WhatsappConnect";
import WhatsappActions from "./WhatsappActions";
import WhatsappTemplates from "./WhatsappTemplates";
import Tutorial from "../jsx/components/Tutorial";

export default function Whatsapp() {
  const { user } = useContext(UserContext);
  const [account, setAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const connectOrReconnect = useRef({ connect: false, reconnect: false });

  const status = {
    connected: <span className="badge badge-success">Conectado</span>,
    connecting: <span className="badge badge-info">Conectando</span>,
    disconnecting: <span className="badge badge-primary">Desconectado</span>,
    unknown: <span className="badge badge-dark">Desconhecido</span>,
  };

  const modalConnectProps = {
    title: !showModal.reconnect ? "Conectar Whatsapp" : "Reconectar Whatsapp",
    content: (
      <WhatsappConnect
        data={account}
        connectOrReconnect={connectOrReconnect}
        setNotify={setNotify}
        setShowModal={setShowModal}
      />
    ),
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const handleDeleteAccount = () => {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você está prestes a excluir a conexão do seu Bot de Whatsapp! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir Conta",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAccount({
          uid: account.uid,
          unique: account.unique,
        })
          .then((result) => {
            if (result) {
              setNotify({
                title: "Sucesso!",
                message: "Conexão com whatsApp excluída com sucesso!",
                type: "success",
                visible: true,
              });
              setTimeout(() => {
                window.location.href = "/whatsapp";
              }, 3500);
            }
          })
          .catch((error) => {
            setNotify({
              title: "Oops!",
              message:
                "Ocorreu um erro ao excluir sua conexão com o Whatsapp. Por favor, tente novamente daqui alguns minutos. Se o problema persistir, contacte nosso suporte.",
              type: "error",
              visible: true,
            });
          });
      }
    });
  };

  const handlePauseOrActive = () => {
    Swal.fire({
      title: "Você tem certeza?",
      html:
        account.receive_chats === 1
          ? "Você está prestes a pausar o funcionamento do bot nesta conta para todos os clientes!"
          : "Você tem certeza que deseja ativar o bot nesta conta para todos os clientes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor:
        account.receive_chats === 1 ? "#515151" : "var(--success)",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText:
        account.receive_chats === 1 ? "Pausar o Bot!" : "Ativar Bot",
    }).then((result) => {
      if (result.isConfirmed) {
        PauseOrActiveAccount({
          aid: account.id,
          unique: account.unique,
          status: account.receive_chats === 1 ? 2 : 1,
        })
          .then((result) => {
            if (result) {
              setNotify({
                title: "Sucesso!",
                message:
                  account.receive_chats === 1
                    ? "Conta pausada com sucesso!"
                    : "Conta ativada com sucesso!",
                type: "success",
                visible: true,
              });
              setTimeout(() => {
                window.location.href = "/whatsapp";
              }, 3500);
            } else {
              setNotify({
                title: "Oops!",
                message:
                  "Ocorreu um erro ao pausar o Bot, por favor, tente novamente daqui alguns minutos.",
                type: "error",
                visible: true,
              });
            }
          })
          .catch(() => {
            setNotify({
              title: "Oops!",
              message:
                "Ocorreu um erro ao pausar o Bot, por favor, tente novamente daqui alguns minutos.",
              type: "error",
              visible: true,
            });
          });
      }
    });
  };

  useEffect(() => {
    if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    }

    GetAccount()
      .then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          let created = new Date(res.data[0].created * 1000);
          created.setHours(created.getHours() + 3);
          res.data[0].createdAt = created.toLocaleString();
          setAccount(res.data[0]);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <section id="whatsapp">
          <Tutorial videoId={"dRMP7wzU5PM"} padding={"0 15px"}/>
          <div className="col-xxl-12 pl-0 mb-4">
            <h2>Whatsapp</h2>
          </div>
          <Tabs defaultActiveKey="account" className="mb-3">
            <Tab eventKey="account" title={"Conta"}>
              <div className="row mt-4">
                <div className="col-xl-12 col-xxl-12 col-sm-12 page-header d-flex justify-content-end">
                  <Button
                    className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                    onClick={() => {
                      connectOrReconnect.current = "connect"
                      setShowModal(true);
                    }}
                  >
                    <span>Conectar Conta</span>
                  </Button>
                </div>
              </div>
              {account ? (
                <Col lg={12}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Meu Whatsapp</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Conta</th>
                            <th>Status</th>
                            <th>Data</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {account.id}
                            </td>
                            
                            <td>
                              <b>{account.phone}</b>
                            </td>
                            <td>{status[account.status]}</td>
                            <td>{account.createdAt}</td>

                            <td>
                              <div className="d-flex">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {account.receive_chats === 1
                                        ? "Pausar Bot"
                                        : "Ativar Bot"}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    type="button"
                                    className="btn btn-info shadow btn-xs sharp mr-1"
                                    onClick={handlePauseOrActive}
                                  >
                                    {account.receive_chats === 1 ? (
                                      <i className="fa fa-pause"></i>
                                    ) : (
                                      <i className="fa fa-play"></i>
                                    )}
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      Reconectar
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    type="button"
                                    className="btn btn-success shadow btn-xs sharp mr-1"
                                    onClick={() => {
                                      connectOrReconnect.current = "reconnect"
                                      setShowModal(true);
                                    }}
                                  >
                                    <i className="fa fa-refresh"></i>
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      Deletar conta
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    type="button"
                                    className="btn btn-primary shadow btn-xs sharp"
                                    onClick={handleDeleteAccount}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <h4>Nenhuma conta conectada...</h4>
              )}
            </Tab>
            <Tab eventKey="bot" title="Bot">
              <WhatsappActions account={account} />
            </Tab>
            <Tab eventKey="templates" title="Notificações">
              <WhatsappTemplates account={account} />
            </Tab>
          </Tabs>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
          {showModal && <ModalComponent props={modalConnectProps} />}
          {/* {showAnalyticsModal && <ModalComponent props={modalAnalytics} />} */}
          {progress && <ProgressBarComponent />}
        </section>
      )}
    </>
  );
}
