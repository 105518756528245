import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button, Badge } from "react-bootstrap";
import DatatablePaginated from "../jsx/components/DatatablePaginated";
import { GetContacts, ChangeStatus, Delete } from '../services/ContactServices'
import DefaultLoader from "../jsx/components/Loader";
import Swal from "sweetalert2";
import AlertResponses from "../jsx/components/Alert";
import ModalComponent from "../jsx/components/Modal";
import UserContext from "../contexts/UserContext";
import Tutorial from "../jsx/components/Tutorial";

export default function Contact() {

   const { user } = useContext(UserContext)
   const [contacts, setContacts] = useState(null);
   const dataModal = useRef(null)
   const [showDeleteButton, setShowDeleteButton] = useState(false);
   const [selectedRows, setSelectedRows] = useState(null);
   const [errors, setErrors] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
   const [showModal, setShowModal] = useState(false);
   const [totalRows, setTotalRows] = useState(0);

   useEffect(() => {
      GetContacts(setContacts, setIsLoading, setErrors, setTotalRows)
   }, [])

   const deleteRows = (state) => {
      if (state.selectedRows && state.selectedRows.length > 0) {
         setSelectedRows(state.selectedRows);
         setShowDeleteButton(true);
      } else {
         setShowDeleteButton(false);
      }
   }

   const handlePageChange = page => {
      GetContacts(setContacts, setIsLoading, setErrors, setTotalRows, page)
   };

   const status = {
      0: {
         id: 0,
         label: "Criado",
         type: "light"
      },
      1: {
         id: 1,
         label: "Em andamento",
         type: "info"
      },
      2: {
         id: 2,
         label: "Finalizado",
         type: "danger"
      },

   }


   const BadgeComponentMemo = (row) => {
      return useMemo(() => {
         return (
            <DropdownButton
               variant=''
               style={styles.dropdown}
               title={status[row.status].label}
               className={`badge badge-${status[row.status].type} badge-sm light badge-delivery-status badge-${row.id}`}

               //FALTA CONFIGURAR O DELETE E O CHANGE STATUS
               onSelect={(e) => {
                  ChangeStatus({ id: row.id, status: e }, setNotify).then(res => {
                     if (res) {
                        document.querySelector(`.badge-${row.id}`).classList.remove(`badge-${status[row.status].type}`)
                        document.querySelector(`.badge-${row.id}`).classList.add(`badge-${status[e].type}`)
                        document.querySelector(`.badge-${row.id} button`).innerText = status[e].label
                     }
                  })

               }}
            >
               {Object.values(status).map((value, i) => i !== 5 &&
                  <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
            </DropdownButton>
         )
      }, [])
   }

   const ContactDetails = () => {
      return dataModal.current
   }

   const modalProps = {
      title: 'Mensagem',
      content: <ContactDetails />,
      btnLabel: 'Salvar',
      showModal: showModal,
      setShowModal: setShowModal,
      showFooter: false,
   }

   const columns = [
      {
         name: 'Nome',
         selector: (row) => <p className="mb-0">{row.first_name + ' ' + row.last_name} <br></br>
            <a href={`mailto:${row.email}`}>
               {row.email}
            </a></p>,
         sortable: true,
         maxWidth: '25%'
      },
      {
         name: 'Assunto',
         selector: (row) => row.subject,
         maxWidth: '20%'
      },
      {
         name: 'Mensagem',
         selector: (row) => <a onClick={() => { dataModal.current = row.message; setShowModal(true) }} style={{ cursor: 'pointer' }}>{row.message}</a>,
         maxWidth: '20%'
      },
      {
         name: 'Status',
         selector: (row) => BadgeComponentMemo(row),
         sortable: true,
         maxWidth: '17.5%',
         allowOverflow: true
      },
      {
         name: 'Data',
         selector: (row) => new Date(row.created_at).toLocaleString(),
         sortable: true,
         maxWidth: '17.5%',
         allowOverflow: true
      },
   ];


   return (
      <>

         {
            isLoading ? <DefaultLoader isLoading={isLoading} /> :
               <div className="row">
                  <div className="col-lg-12">
                     <div className="col-xl-12 col-xxl-12 col-sm-12">
                     <Tutorial title="Contatos via site" videoId={'Uau_71I3MTM'} padding={'0 30px'} classes={"my-3"}/>
                        {showDeleteButton &&

                           <Button className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                              onClick={() =>
                                 Swal.fire({
                                    title: "Você tem certeza?",
                                    text:
                                       "Todos os contatos selecionados serão deletados. Esta ação é irreversível!",
                                    icon: "warning",
                                    buttons: true,
                                    confirmButtonText: `Continuar a ação`,
                                    cancelButtonText: `Cancelar`,
                                    showCancelButton: true,
                                    confirmButtonColor: 'red'
                                 }).then((value) => {
                                    if (value.isConfirmed) Delete(selectedRows, setNotify)
                                 })
                              }
                           >
                              <span>DELETAR SELECIONADOS</span>
                           </Button>
                        }
                     </div>
                     <DatatablePaginated
                        data={contacts}
                        columns={columns}
                        title="  "
                        selectable={true}
                        onSelectedRowsChange={deleteRows}
                        showDeleteButton={showDeleteButton}
                        filterDefaultField={'first_name'}
                        filterByField={{ labels: ['Nome', 'Status', 'Tipo'], fields: ['first_name', 'status', 'type'] }}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        handlePageChange={handlePageChange}
                        totalRows={totalRows}
                     />
                  </div>
                  {
                     notify.visible &&
                     <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
                  }

                  {showModal && <ModalComponent props={modalProps} />}
               </div>
         }
      </>
   );
};

const styles = {
   photoDefault: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      height: '50px',
      borderRadius: '25px',
      background: '#efefef'
   },
   i: {
      fontSize: '18px',
      color: '#3d4465'
   },
   dropdown: {
      padding: '5px 15px',
      fontSize: '12px'
   }
}
