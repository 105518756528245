import Swal from "sweetalert2";
import { api } from "../api/Middleware";
import { QRCODE_URL } from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const GetQrcodes = async (
  setQrCodes,
  setTotalRows,
  setIsLoading,
  page = 1
) => {
  try {
    let response = await api.get(`${QRCODE_URL}?page=${page}`);
    if (response.status === 200) {
      setQrCodes(response.data.results);
      setTotalRows(response.data.count);
    }
  } catch (error) {
    console.log(error);
    let message = error.response.data || ERROR_MESSAGES.default;
    Swal.fire("Oops!", message, "error");
    return false;
  } finally {
    setIsLoading(false);
  }
};

export const CreateNewQrCode = async (params) => {
  try {
    let response = await api.post(`${QRCODE_URL}`, params);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    let message = error.response.data || ERROR_MESSAGES.default;
    Swal.fire("Oops!", message, "error");
    return false;
  }
};

export const ChangeStatus = async (data, setNotify, setProgress) => {
  setProgress(true);

  try {
    let response = await api.put(QRCODE_URL + data.id + "/", {
      status: parseInt(data.status),
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Status atualizado com sucesso!",
        type: "success",
        visible: true,
      });
      setProgress(false);
      return true;
    }
  } catch (error) {
    let message = error.response.data || ERROR_MESSAGES.default;

    setNotify({
      title: "Oops!",
      message: message,
      type: "error",
      visible: true,
    });
    setProgress(false);
    return false;
  }
};

export const Delete = async (data, setNotify, setProgress) => {
  setProgress(true);
  try {
    let ids = [];
    data.map((product) => ids.push(product.id));
    let response = await api.delete(QRCODE_URL + "delete_multiple/", {
      data: { ids: ids },
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Operação realizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } catch (error) {
    let message = error.response.data || ERROR_MESSAGES.default;

    setNotify({
      title: "Oops!",
      message: message,
      type: "error",
      visible: true,
    });
  }
  setProgress(false);
};

export const DownloadQrCode = async (key, setNotify) => {
  try {
    let response = await api.get(QRCODE_URL + "download/?key=" + key, {
      responseType: 'blob'
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
    let message = error.response.data || ERROR_MESSAGES.default;

    setNotify({
      title: "Oops!",
      message: message,
      type: "error",
      visible: true,
    });
  }
};
