import React, { useState } from "react";
import ModalComponent from "../Modal";
import { AppSettings } from "../../../App";
import { WACampaignsTermsOfUse } from "../../../services/StoreServices";
import Swal from "sweetalert2";

export default function WhatsappCampaignsTerms({
  title,
  videoId,
  padding,
  classes,
  tutorialTitle,
  badgeClasses,
  dontShowAgain,
  modalTitle,
}) {
  const accepted =
    AppSettings.store &&
    AppSettings.store.configs.length > 0 &&
    AppSettings.store.configs[0].whatsapp_campaigns_terms_of_use
      ? true
      : false;
  const [showModal, setShowModal] = useState(!accepted);

  const handleDontShowAgain = () => {
    if (AppSettings.store.configs[0].id) {
      Swal.fire({
        title: "Atenção!",
        html: "Ao continuar você declara que leu e compreendeu os termos de uso da ferramenta.",
        icon: "info",
        showConfirmButton: true,
        confirmButtonText: "Entendi e concordo",
        cancelButtonText: `Cancelar`,
        showCancelButton: true,
        confirmButtonColor: "blue",
      }).then(function (result) {
        if (result.isConfirmed) {
          let checked = document.querySelector("#dontShowAgain").checked;
          if (!accepted) {
            let currentDate = new Date();

            let data = {
              whatsapp_campaigns_terms_of_use: checked ? 1 : 0,
              wac_terms_of_use_accept_date: new Date(
                currentDate.getTime() - 3 * 60 * 60 * 1000
              ),
              store: AppSettings.store.id,
            };
            WACampaignsTermsOfUse(
              AppSettings.store.configs[0].id,
              data,
              setShowModal
            );
          }
        }
      });
    }
  };

  const ModalContent = () => (
    <>
      {dontShowAgain && (
        <div className="row mt-3">
          <div className="col-lg-12 tutorial-footer flex-column">
            <div>
              <h4 className="text-center">
                Termos de Uso da Ferramenta de Envio de Mensagens em Massa
              </h4>
              <br></br>
              Última atualização: 23/09/2024
              <br></br>
              <br></br>
              1. Aceitação dos Termos
              <br></br>
              <br></br>
              Ao utilizar a ferramenta de envio de mensagens em massa
              ("Ferramenta") disponibilizada pela Pralevar Plataforma de
              Delivery ("Nós", "Nosso" ou "Empresa"), o usuário ("Usuário" ou
              "Você") concorda em cumprir os seguintes Termos de Uso. Leia
              atentamente antes de utilizar a Ferramenta, pois ao acessá-la ou
              utilizá-la, Você está concordando com todos os termos
              estabelecidos.
              <br></br>
              <br></br>
              2. Utilização Responsável da Ferramenta
              <br></br>
              <br></br>
              2.1 A Ferramenta permite o envio de mensagens em massa via
              WhatsApp. No entanto, o Usuário é o único responsável por garantir
              que o uso da Ferramenta esteja em conformidade com as políticas do
              WhatsApp, bem como com as legislações locais e internacionais
              relacionadas a práticas de comunicação e marketing, especialmente
              aquelas que dizem respeito ao envio de mensagens não solicitadas
              (SPAM).
              <br></br>
              <br></br>
              2.2 O uso da Ferramenta para práticas ilegais, abusivas, ou que
              infrinjam as regras do WhatsApp, incluindo mas não se limitando ao
              envio de mensagens não autorizadas, sem o consentimento dos
              destinatários, é estritamente proibido. Isso inclui, mas não se
              limita a:
              <br></br>
              Envio de SPAM; Envio de mensagens automatizadas em grande volume
              sem o devido consentimento dos destinatários; Utilização de
              números adquiridos de forma ilegal ou sem autorização dos
              respectivos proprietários.
              <br></br>
              <br></br>
              3. Riscos e Responsabilidades
              <br></br>
              <br></br>
              3.1 A utilização da Ferramenta para o envio de mensagens em massa
              pode resultar em bloqueio, suspensão ou encerramento da conta de
              WhatsApp do Usuário por parte da plataforma WhatsApp, devido a
              possíveis violações das políticas de uso estabelecidas pela
              empresa Meta (controladora do WhatsApp).
              <br></br>
              <br></br>
              3.2 A Pralevar Plataforma de Delivery não se responsabiliza por
              qualquer bloqueio, suspensão ou encerramento da conta de WhatsApp
              do Usuário em decorrência do uso da Ferramenta. Ao utilizar esta
              Ferramenta, o Usuário está ciente de que a responsabilidade pela
              conformidade com as regras do WhatsApp e pela legalidade do
              conteúdo enviado é exclusivamente sua.
              <br></br>
              <br></br>
              4. Isenção de Responsabilidade
              <br></br>
              <br></br>
              4.1 A Pralevar Plataforma de Delivery não se responsabiliza por
              danos diretos ou indiretos, perdas ou outros prejuízos que possam
              ocorrer em razão do uso inadequado da Ferramenta ou por ações
              tomadas pela Meta (WhatsApp) contra a conta do Usuário, como
              bloqueios, suspensões ou exclusões.
              <br></br>
              <br></br>
              4.2 O uso da Ferramenta é de inteira responsabilidade do Usuário,
              sendo este o único responsável por quaisquer consequências
              derivadas de sua utilização, incluindo a eventual perda de acesso
              ao WhatsApp.
              <br></br>
              <br></br>
              5. Conduta Proibida
              <br></br>
              <br></br>O Usuário concorda em não utilizar a Ferramenta para:
              Envio de mensagens não solicitadas (SPAM) ou que infrinjam os
              direitos de privacidade dos destinatários; Enviar conteúdos que
              possam ser considerados ofensivos, abusivos, ou em violação de
              quaisquer leis ou regulamentos aplicáveis; Manipular, modificar ou
              realizar engenharia reversa na Ferramenta de qualquer maneira.
              <br></br>
              <br></br>
              6. Alterações nos Termos A Pralevar Plataforma de Delivery se
              reserva o direito de modificar os presentes Termos de Uso a
              qualquer momento, sendo de responsabilidade do Usuário verificar
              periodicamente as atualizações. O uso continuado da Ferramenta
              após as modificações constitui a aceitação das mudanças.
              <br></br>
              <br></br>
              7. Encerramento de Acesso Nos reservamos o direito de encerrar ou
              suspender o acesso à Ferramenta por qualquer Usuário que viole
              estes Termos de Uso ou as políticas do WhatsApp, sem aviso prévio.
              <br></br>
              <br></br>
              8. Disposições Finais
              <br></br>
              <br></br>
              Estes Termos de Uso constituem o acordo integral entre a Pralevar
              Plataforma de Delivery e o Usuário quanto ao uso da Ferramenta,
              substituindo quaisquer outros entendimentos ou acordos anteriores.
              <br></br>
              <br></br>
              Caso tenha dúvidas sobre estes Termos de Uso, entre em contato
              conosco pelo e-mail suporte@pralevar.app.
            </div>
            <br></br>
            <div className="custom-control custom-checkbox mr-3">
              <input
                id="dontShowAgain"
                type="checkbox"
                className="custom-control-input"
                defaultChecked={accepted}
                onClick={handleDontShowAgain}
                disabled={accepted}
              />
              <label className="custom-control-label" htmlFor={`dontShowAgain`}>
                Entendi e concordo com os termos de uso da ferramenta
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const tutorialModalProps = {
    title: modalTitle ? modalTitle : "Termos de Uso",
    content: <ModalContent />,
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
    size: "xl",
    showCloseButton: !accepted ? false : undefined,
  };
  return (
    <>
      <div
        id="tutorial-component"
        className={`pb-3 ${title ? "title" : "text-right "} ${
          classes ? classes : ""
        }`}
        style={{ padding: padding }}
      >
        {title && <h2>{title}</h2>}
        <span
          className={`badge badge-outline-primary badge-sm cursor-pointer text-uppercase ${
            badgeClasses ? badgeClasses : ""
          }`}
          style={{ borderRadius: 10 }}
          onClick={() => setShowModal(true)}
        >
          <span className="text-primary align-middle">
            <i className="fa fa-file-text fa-2x mr-2" />
          </span>
          {!tutorialTitle ? "Termos de uso" : tutorialTitle}
        </span>
      </div>
      {showModal && <ModalComponent props={tutorialModalProps} />}
    </>
  );
}
