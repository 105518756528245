import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownButton,
  Button,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";

import OrdersDatatable from "../jsx/components/OrdersDatatable";
import { Get, ChangeStatus, Delete } from "../services/OrdersServices";
import { Hash } from "../helpers/Helpers";
import DefaultLoader from "../jsx/components/Loader";
import AlertResponses from "../jsx/components/Alert";
import ErrorsComponent from "../jsx/components/Errors";
import ModalComponent from "../jsx/components/Modal";
import ProductTypes from "../jsx/components/ModalData/ProductTypes";
import { AppSettings } from "../App";
import Tutorial from "../jsx/components/Tutorial";
import MultiDragComponent from "../jsx/components/DraggableComponents/MultiDragComponent";
import OrderId from "./OrderId";

export default function Orders() {
  const [orders, setOrders] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [tracking, setTracking] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [data, setData] = useState({
    column1: {
      id: "column1",
      title: "Novos pedidos",
      items: [],
    },
    column2: {
      id: "column2",
      title: "Em andamento",
      items: [],
    },
    column3: {
      id: "column3",
      title: "Saiu para entrega | Pronto p/retirada",
      items: [],
    },
  });
  const orderData = useRef(null);
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const setCurrentOrders = () => {
    let currentOrders = localStorage.getItem("currentOrders");
    if (currentOrders) {
      currentOrders = JSON.parse(currentOrders);

      let column1 = [],
        column2 = [],
        column3 = [];

      currentOrders.map((order) => {
        let status = order.status.slice(-1)[0].id;
        if (status === 0 && !data.column1.items.includes(order.id))
          column1.push(order);
        if (status === 2 && !data.column2.items.includes(order.id))
          column2.push(order);
        if (status === 3 && !data.column3.items.includes(order.id))
          column3.push(order);
      });

      if (column1) {
        setData((prevData) => ({
          ...prevData,
          column1: {
            ...prevData.column1,
            items: column1,
          },
        }));
        /* if (data.column1.items.length) {
          setData(prevData => ({
            ...prevData,
            column1: {
              ...prevData.column1,
              items: column1
            }
          }))
        } */
      }

      if (column2) {
        setData((prevData) => ({
          ...prevData,
          column2: {
            ...prevData.column2,
            items: column2,
          },
        }));
      }

      if (column3) {
        setData((prevData) => ({
          ...prevData,
          column3: {
            ...prevData.column3,
            items: column3,
          },
        }));
      }
    }
  };

  //TODO: no datatable nao filtra pelo campo created_at por causa do formato da data q no campo é 2021-08-03 e o user
  // digita 30/08/2021
  useEffect(() => {
    Get(setOrders, setErrors, setIsLoading, setTotalRows);
    const eventListener = (event) => {
      if (event.data.currentOrders) {
        setCurrentOrders();
      }
    };
    window.addEventListener("message", eventListener, false);

    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  const handlePageChange = (page) => {
    Get(setOrders, setErrors, setIsLoading, setTotalRows, page);
  };

  const modalProps = {
    title: "Configurações",
    content: <ProductTypes setNotify={setNotify} setShowModal={setShowModal} />,
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const modalOrderId = {
    id: "modalOrder",
    title: "Pedido",
    content: (
      <OrderId
        orderData={orderData.current}
        tracking={tracking}
        setShowModal={setShowModal}
        setTracking={setTracking}
      />
    ),
    size: "xl",
    showModal: showOrderModal,
    setShowModal: setShowOrderModal,
    showFooter: false,
  };

  const deleteRows = (state) => {
    if (
      state.selectedRows !== (null || undefined) &&
      state.selectedRows.length > 0
    ) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  };

  const status = {
    0: {
      id: 0,
      label: "Criado",
      type: "light",
    },
    1: {
      id: 1,
      label: "Recebido",
      type: "light",
    },
    2: {
      id: 2,
      label: "Em andamento",
      type: "info",
    },
    3: {
      id: 3,
      label: "Saiu para entrega",
      type: "primary",
    },
    4: {
      id: 4,
      label: "Finalizado",
      type: "success",
    },
    5: {
      id: 5,
      label: "Cancelado",
      type: "danger",
    },
    6: {
      id: 6,
      label: "Pronto para retirada",
      type: "primary",
    },
    null: {
      id: 7,
      label: "Indefinido",
      type: "dark",
    },
  };

  const BadgeComponentMemo = (row) => {
    return useMemo(() => {
      row.status = row.status.reverse();
      return (
        <DropdownButton
          variant=""
          style={styles.dropdown}
          title={status[row.status[0].id].label}
          className={`badge badge-${
            status[row.status[0].id].type
          } badge-sm light badge-delivery-status badge-${row.id}`}
          onSelect={(e) => {
            ChangeStatus(
              { id: row.id, status: e, store_name: AppSettings.store.name },
              setNotify
            ).then((res) => {
              if (res) {
                document
                  .querySelector(`.badge-${row.id}`)
                  .classList.remove(`badge-${status[row.status[0].id].type}`);
                document
                  .querySelector(`.badge-${row.id}`)
                  .classList.add(`badge-${status[e].type}`);
                document.querySelector(`.badge-${row.id} button`).innerText =
                  status[e].label;
              }
            });
          }}
        >
          {Object.values(status).map(
            (value, i) =>
              /* i !== 5 && */ i !== 7 && (
                <Dropdown.Item key={i} eventKey={value.id}>
                  {value.label}
                </Dropdown.Item>
              )
          )}
        </DropdownButton>
      );
    }, [deliveryStatus]);
  };

  const columns = [
    {
      name: "Nº",
      selector: (row) => (
        <Link
          to={{
            pathname: `/order-details/${row.order_number}`,
            order: row,
          }}
        >
          {row.order_number}
        </Link>
      ),
      maxWidth: "5%",
    },
    {
      name: "",
      selector: (row) =>
        row.user__photo ? (
          <img
            src={Hash("50x50", row.user__photo)}
            style={styles.photoDefault}
          />
        ) : (
          <div style={styles.photoDefault}>
            <i className="ti-user" style={styles.i}></i>
          </div>
        ),
      maxWidth: "5%",
    },
    {
      name: "Cliente",
      selector: (row) => (
        <Link
          to={{
            pathname: `/order-details/${row.order_number}`,
            order: row,
          }}
        >
          {row.name}
        </Link>
      ),
      sortable: true,
      maxWidth: "27.5%",
    },
    {
      name: "Pagamento",
      selector: (row) =>
        row.payment_method.name ? row.payment_method.name : "Na retirada",
      sortable: true,
      maxWidth: "15%",
    },
    {
      name: "Total",
      selector: (row) => (
        <NumberFormat
          displayType="text"
          decimalScale={2}
          defaultValue={row.total}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$"
          fixedDecimalScale={true}
        />
      ),
      maxWidth: "10%",
    },
    {
      name: "Criado",
      selector: (row) => {
        let date = new Date(row.created_at);
        return date.toLocaleString();
      },
      sortable: true,
      maxWidth: "15%",
    },
    {
      name: "Status",
      selector: (row) => BadgeComponentMemo(row),
      sortable: true,
      maxWidth: "17.5%",
      allowOverflow: true,
    },
    /*  {
       name: 'Ações',
       selector: (row) => actions(row),
       maxWidth: '5%',
       allowOverflow: true
     }, */
  ];

  const handleUpdateOrders = (e) => {
    e.target.classList.add("fa-spin");
    window.location.href = "/";
  };

  if (isLoading) return <DefaultLoader />;

  return (
    <div className="wrapper-tab-order">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-bottom`}>Atualizar pedidos</Tooltip>}
      >
        <Button
          id="btn-sync-orders"
          type="button"
          className="btn btn-success shadow btn-xs sharp"
          onClick={handleUpdateOrders}
        >
          <i className="las la-sync"></i>
        </Button>
      </OverlayTrigger>
      {errors !== null && <ErrorsComponent args={errors} />}

      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}

      <Tabs defaultActiveKey="1" className="mb-3 tab-orders">
        <Tab eventKey="1" title="Pedidos de Hoje">
          <div className="col-lg-12">
            <MultiDragComponent
              classes={"col-lg-4 col-md-4 col-sm-12 card new-order"}
              data={data}
              setData={setData}
              orderData={orderData}
              setShowOrderModal={setShowOrderModal}
              componentRef={componentRef}
              handlePrint={handlePrint}
              tracking={tracking}
              setTracking={setTracking}
            ></MultiDragComponent>
          </div>
        </Tab>
        <Tab eventKey="2" title="Todos os pedidos">
          <div className="row">
            <Tutorial videoId={"ID0XID_0Y_I"} padding={"0 30px"} />
            <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
              <div className="col-xl-4 col-xxl-5 col-sm-12">
                <h2>Todos Pedidos</h2>
              </div>

              <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                {showDeleteButton && (
                  <Button
                    className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                    onClick={() =>
                      Swal.fire({
                        title: "Você tem certeza?",
                        text: "Todos os pedidos selecionados serão deletados. Esta ação é irreversível!",
                        icon: "warning",
                        buttons: true,
                        confirmButtonText: `Continuar a ação`,
                        cancelButtonText: `Cancelar`,
                        showCancelButton: true,
                        confirmButtonColor: "red",
                      }).then((value) => {
                        if (value.isConfirmed) Delete(selectedRows, setNotify);
                      })
                    }
                  >
                    <span>DELETAR SELECIONADOS</span>
                  </Button>
                )}
                <Button
                  className="i-false btn btn-primary btn btn-primary float-right btn btn-primary"
                  href="#"
                  onClick={() => setShowModal(true)}
                >
                  <span>Configurações</span>
                </Button>
              </div>
            </div>
          </div>
          <OrdersDatatable
            data={orders}
            columns={columns}
            title="  "
            selectable={true}
            onSelectedRowsChange={deleteRows}
            showDeleteButton={showDeleteButton}
            filterByField={{
              labels: ["Nº do Pedido", "Nome", "Criado em"],
              fields: ["order_number", "name", "created_at"],
            }}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handlePageChange={handlePageChange}
            totalRows={totalRows}
            setOrders={setOrders}
            setTotalRows={setTotalRows}
            setNotify={setNotify}
          />
        </Tab>
      </Tabs>

      {showModal && <ModalComponent props={modalProps} />}
      {showOrderModal && <ModalComponent props={modalOrderId} />}
    </div>
  );
}

const styles = {
  photoDefault: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    background: "#efefef",
  },
  i: {
    fontSize: "18px",
    color: "#3d4465",
  },
  dropdown: {
    padding: "5px 15px",
    fontSize: "12px",
  },
};
