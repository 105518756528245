import React, { useEffect, useState, useRef } from "react";
import NumberFormat from "react-number-format";

import { Get } from "../services/HomeServices";
import DefaultLoader from "../jsx/components/Loader";
import ErrorsComponent from "../jsx/components/Errors";
import AlertResponses from "../jsx/components/Alert";

export default function Home() {
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const users_total = useRef(null);
  const products_total = useRef(null);
  const data_orders = useRef(null);
  const data_by_month = useRef(null);
  const data_by_week = useRef(null);
  const data_by_day = useRef(null);
  const data_most_orders = useRef(null);

  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  useEffect(() => {
    Get(
      users_total,
      products_total,
      data_orders,
      data_by_month,
      data_by_week,
      data_by_day,
      data_most_orders,
      setErrors,
      setIsLoading
    );
  }, []);

  /* useEffect(() => {
    if(data_by_month.current !== null){
      console.log(data_by_month.current[0].data.data_by_month)
    }
    
  }, [data_by_month]) */

  return (
    <>
      {/* <div className="modal fade show" id="addOrderModalside">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Menus</h5>
              <button type="button" className="close" data-dismiss="modal">
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="text-black font-w500">Food Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Order Date</label>
                  <input type="date" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Food Price</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <button type="button" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <div className="row">
          {/*  <div id='home-top-buttons' className='col-xl-12 col-sm-12'>
            <Form>
              <Form.Check
                type='switch'
                id='custom-switch'
                label='Modo demonstrativo'
                checked={demoData}
                defaultChecked={demoData}
                onChange={e => updateMode(e)}
              />
            </Form>
            <Form>
              <Form.Check
                type='switch'
                id='close-store'
                label='Fechar Loja'
                //checked={}
                defaultChecked={closed.current ? true : false}
                onChange={e => closeStore(e)}
              />
            </Form>
          </div> */}
          {errors !== null && <ErrorsComponent args={errors} />}
          <div className="col-xl-3 col-xxl-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon">
                  <div className="media-body mr-2">
                    <h1 className="text-primary font-w600">
                      {data_orders.current.orders_total}
                    </h1>
                    <span className="ml-0">Pedidos</span>
                  </div>
                  <span className="mr-3 special-icon">
                    <i className="ti-receipt text-primary h1"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon">
                  <div className="media-body mr-2">
                    <h1 className="text-primary font-w600">
                      {products_total.current}
                    </h1>
                    <span className="ml-0">Produtos</span>
                  </div>
                  <span className="mr-3 special-icon">
                    <i className="ti-shopping-cart-full text-primary h1"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon">
                  <div className="media-body mr-2">
                    <h1 className="text-primary font-w600">
                      <NumberFormat
                        displayType="text"
                        decimalScale={2}
                        defaultValue={
                          data_orders.current.orders_total_value === null
                            ? 0
                            : data_orders.current.orders_total_value
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$"
                        fixedDecimalScale={true}
                      />
                    </h1>
                    <span className="ml-0">Faturamento</span>
                  </div>
                  <span className="mr-3 special-icon">
                    <i className="ti-money text-primary h1"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon">
                  <div className="media-body mr-2">
                    <h1 className="text-primary font-w600">
                      {users_total.current}
                    </h1>
                    <span className="ml-0">Clientes</span>
                  </div>
                  <span className="mr-3 special-icon">
                    <i className="ti-user text-primary h1"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
        </div>
      )}
    </>
  );
}
