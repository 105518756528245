import Swal from "sweetalert2";
import { api } from "../api/Middleware";
import { AI_URL } from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const GetAgents = async () => {
  try {
    let response = await api.get(`${AI_URL}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const CreateNewAgent = async (params) => {
  try {
    let response = await api.post(`${AI_URL}`, params);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    let message = error.response.data || ERROR_MESSAGES.default
    Swal.fire("Oops!", message, "error");
    return false;
  }
};
