import { useEffect, useState } from "react";
import { CheckAvailability } from "../../../services/StoreServices";

export default function StoreStatus({ store_id }) {
  const [availability, setAvailability] = useState(true);

  useEffect(() => {
    CheckAvailability(store_id).then((res) => {
      if (!res) {
        setAvailability(false);
      } else {
        setAvailability(res.status);
      }
    });

    const eventListener = (event) => {
      if (event.data.open_store) {
        setAvailability(event.data.open_store.status);
      }
    };

    window.addEventListener("message", eventListener, false);

    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  return availability ? (
    <div className="status-icon open" style={{ background: "var(--teal)" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-bolt"
        style={{ width: "18px" }}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11" />
      </svg>
    </div>
  ) : (
    <div
      className="status-icon closed"
      style={{ background: "var(--primary)" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#fff"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-power"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M7 6a7.75 7.75 0 1 0 10 0" />
        <path d="M12 4l0 8" />
      </svg>
    </div>
  );
}
