import { api } from "../api/Middleware";
import { WABOTTY_URL } from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const GetAccount = async () => {
  try {
    let response = await api.get(`${WABOTTY_URL}get_accounts/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const GetActions = async (params) => {
  try {
    let response = await api.get(`${WABOTTY_URL}get_actions/` + params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const ConnectWhatsappAccount = async (params) => {
  try {
    let response = await api.post(`${WABOTTY_URL}connect_account/`, params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};
export const ReconnectWhatsappAccount = async (params) => {
  try {
    let response = await api.post(`${WABOTTY_URL}reconnect_account/`, params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};
export const GetWhatsappQrCode = async (data) => {
  try {
    let response = await api.post(`${WABOTTY_URL}generate_qr_code/`, data, {
      responseType: "arraybuffer",
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const GetTemplates = async (params) => {
  try {
    let response = await api.get(`${WABOTTY_URL}get_templates/` + params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const PauseOrActiveAccount = async (data) => {
  try {
    let response = await api.post(
      `${WABOTTY_URL}pause_or_active_account/`,
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const DeleteAccount = async (data) => {
  try {
    let response = await api.post(`${WABOTTY_URL}delete_account/`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const AddAutoReply = async (params, setNotify) => {
  try {
    let response = await api.post(`${WABOTTY_URL}add_auto_reply/`, params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }
};

export const UpdateAutoReply = async (params, setNotify) => {
  try {
    let response = await api.put(`${WABOTTY_URL}update_auto_reply/`, params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }
};

export const DeleteAction = async (id, setNotify) => {
  try {
    let response = await api.delete(`${WABOTTY_URL}delete_action/${id}/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    setNotify({
      title: "Oops!",
      message: error?.response?.data || ERROR_MESSAGES.default,
      type: "error",
      visible: true,
    });
    return false;
  }
};

export const UpdateTemplate = async (params, setNotify) => {
  try {
    let response = await api.put(`${WABOTTY_URL}update_template/`, params);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }
};

export const CreateUser = async (data) => {
  try {
    let response = await api.post(WABOTTY_URL, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const ActiveBot = async (data) => {
  try {
    let response = await api.post(`${WABOTTY_URL}active_bot/`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const PauseBot = async (data) => {
  try {
    let response = await api.post(`${WABOTTY_URL}pause_bot/`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};



export const GetContacts = async (page = 1, phone = false) => {
  try {
    let response = await api.get(
      `${WABOTTY_URL}get_contacts/?page=${page}${
        phone ? `&phone=${phone}` : ""
      }`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const SearchContact = async (phone) => {
  try {
    let response = await api.get(
      `${WABOTTY_URL}search_contact/?phone=${phone}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return false
  }
};

export const UpdateContactName = async (payload) => {
  try {
    let response = await api.put(`${WABOTTY_URL}update_contact_name/`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
