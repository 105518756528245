import React, { useState } from 'react'
import ModalComponent from '../Modal'
import { DontShowTutorialInitial } from '../../../services/StoreServices'
import { AppSettings } from '../../../App'

export default function Tutorial ({
  title,
  videoId,
  padding,
  classes,
  tutorialTitle,
  badgeClasses,
  dontShowAgain,
  modalTitle
}) {
  const [showModal, setShowModal] = useState(false)
  const height = window.innerWidth < 576 ? 275 : 500
  const handleDontShowAgain = () => {
    let checked = document.querySelector('#dontShowAgain').checked
    if (AppSettings.store && AppSettings.store.configs.length > 0) {
      let data = {
        show_tutorial_initial: checked ? 1 : 0
      }
      DontShowTutorialInitial(
        AppSettings.store.configs[0].id,
        data,
        setShowModal
      )
    } else {
      let data = {
        show_tutorial_initial: checked ? 1 : 0,
        store: AppSettings.store.id
      }
      DontShowTutorialInitial(null, data, setShowModal)
    }
  }

  const ModalContent = () => (
    <>
      <iframe
        width='100%'
        height={height}
        src={`https://www.youtube-nocookie.com/embed/${videoId}?modestbranding=1&rel=0`}
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;'
        allowFullScreen
      ></iframe>
      {dontShowAgain && (
        <div className='row mt-3'>
          <div className='col-lg-12 tutorial-footer'>
            <a href='https://chrome.google.com/webstore/detail/pralevar-notifica%C3%A7%C3%B5es/ncpkligboimfjakopinicekcfdijgajl?hl=pt-br' target='_blank'>
              <i className='las la-external-link-alt'></i>
              Link da Extensão de notificações
            </a>
            <div className='custom-control custom-checkbox mr-3'>
              <input
                id='dontShowAgain'
                type='checkbox'
                className='custom-control-input'
                defaultChecked={
                  AppSettings.store && AppSettings.store.configs.length > 0
                    ? AppSettings.store.configs[0].show_tutorial_initial
                    : false
                }
                onClick={handleDontShowAgain}
              />
              <label className='custom-control-label' htmlFor={`dontShowAgain`}>
                Não mostrar novamente
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  )

  const tutorialModalProps = {
    title: modalTitle ? modalTitle : 'Tutorial',
    content: <ModalContent />,
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
    size: 'xl'
  }
  return (
    <>
      <div
        id='tutorial-component'
        className={`col-lg-12 pb-3 ${title ? 'title' : 'text-right '} ${
          classes ? classes : ''
        }`}
        style={{ padding: padding }}
      >
        {title && <h2>{title}</h2>}
        <span
          className={`badge badge-outline-primary badge-sm cursor-pointer text-uppercase ${
            badgeClasses ? badgeClasses : ''
          }`}
          style={{ borderRadius: 10 }}
          onClick={() => setShowModal(true)}
        >
          <span className='text-primary align-middle'>
            <i className='fa fa-play-circle fa-2x mr-2' />
          </span>
          {!tutorialTitle ? 'Vídeo Tutorial' : tutorialTitle}
        </span>
      </div>
      {showModal && <ModalComponent props={tutorialModalProps} />}
    </>
  )
}
