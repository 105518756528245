import axios from 'axios'

export const GetUserLatLngByIp = async () => {
  try {
    let response = await axios.get(
      'https://api-bdc.net/data/ip-geolocation-with-confidence?key=bdc_6c6ec1240aa6443fa1ac6314096823a2'
    )
    if (response.status === 200) {
      if (response.data?.confidenceArea) {
        window.userLatLng = `${response.data.confidenceArea[0].latitude},${response.data.confidenceArea[0].longitude}`
      } else {
        window.userLatLng = `${response.data.location.latitude},${response.data.location.longitude}`
      }
    }
  } catch (error) {}
}
