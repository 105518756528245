import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button, Card, Col } from "react-bootstrap";
import Swal from "sweetalert2";

import AlertResponses from '../jsx/components/Alert'
import ProgressBarComponent from '../jsx/components/ProgressBar'
import UserContext from "../contexts/UserContext";
import DefaultLoader from '../jsx/components/Loader'
import ModalComponent from '../jsx/components/Modal'
import { ChangeIntegrationStatus, DeleteIntegration, GetIntegrations } from "../services/StoreServices";
import IfoodIntegration from "./IfoodIntegration";
import Tutorial from "../jsx/components/Tutorial";

export default function Integrations() {

  const { user } = useContext(UserContext)

  const data = useRef();
  const [integrations, setIntegrations] = useState([]);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [progress, setProgress] = useState(false)
  const [showModal, setShowModal] = useState(false);


  const modalProps = {
    title: "Integração Ifood",
    content: <IfoodIntegration data={data.current} setNotify={setNotify} user={user} setShowModal={setShowModal}/>,
    btnLabel: 'Salvar',
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
    size: "xl"
  }

  useEffect(() => {
    GetIntegrations(setIntegrations, setErrors, setIsLoading)
  }, [])

  const status = {
    0: {
      id: 0,
      label: "Desativada",
      type: "light"
    },
    1: {
      id: 1,
      label: "Ativa",
      type: "success"
    },
  }

  const apps = {
    1: {
      name: "Google Analytics 4",
      thumb: 'google.png',
    },
    2: {
      name: "Facebook Pixel",
      thumb: 'facebook.png'
    },
    3: {
      name: "Botão Whatsapp",
      thumb: 'whatsapp.png'
    },
    4: {
      name: "Ifood",
      thumb: 'ifood.png'
    },
    5: {
      name: "Google ADS",
      thumb: 'google-ads.png'
    }
  }

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
            <Tutorial videoId={'dMnFoomOfF0'} padding={'0 30px'}/>
            <div className="item">
              <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                <div className="col-xl-4 col-xxl-5 col-sm-12">
                  <h2>Apps & Integrações</h2>
                </div>

                <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                  <Link to="/available-integrations" className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary">
                    ADICIONAR
                  </Link>
                </div>

              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }
            <>
              {
                integrations.length > 0 ?
                integrations.map((item, index) => (

                  <Col xl='4' key={index} className="d-inline-block" style={{ verticalAlign: 'top' }}>
                    <Card className='mb-3 align-items-center' style={{ minHeight: 235 }}>
                      <img
                        className='img-fluid'
                        src={require(`../images/${apps[item.app_id].thumb}`).default}
                        style={styles.thumb}
                      />
                      <Card.Header className="m-auto">
                        <Card.Title>{apps[item.app_id].name}</Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body d-flex justify-content-between align-items-end">
                        <DropdownButton
                          variant=''
                          style={styles.dropdown}
                          title={item.status === 1 ? 'Ativa' : 'Desativada'}
                          className={`badge badge-${item.status === 1 ? 'success' : 'light'} badge-sm light badge-delivery-status badge-${item.id}`}
                          onSelect={(e) => {
                            ChangeIntegrationStatus(item.id, { status: e }, setNotify).then(res => {
                              if (res) {
                                document.querySelector(`.badge-${item.id}`).classList.remove(`badge-${item.status === 1 ? 'success' : 'light'}`)
                                document.querySelector(`.badge-${item.id}`).classList.add(`badge-${status[e].type}`)
                                document.querySelector(`.badge-${item.id} button`).innerText = status[e].label
                              }
                            })

                          }}
                        >
                          {Object.values(status).map((value, i) =>
                            <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
                        </DropdownButton>

                        <DropdownButton
                          variant=''
                          style={styles.dropdown}
                          title="Ações"
                          className={`badge badge-primary badge-sm light badge-delivery-status ml-4`}

                        >

                          <Link to={{
                            pathname: "/available-integrations",
                            data: item,
                            apps: apps
                          }} className="dropdown-item" >Editar</Link>
                          {
                            item.app_id === 4 && user?.plan_id > 2 &&
                            <Dropdown.Item onClick={() => {
                              data.current = item
                              setShowModal(true)
                            }}
                            >Opções</Dropdown.Item>
                          }
                          <Dropdown.Item onClick={() =>
                            Swal.fire({
                              title: "Você tem certeza?",
                              html:
                                "Deseja realmente deletar esta integração?",
                              icon: "warning",
                              buttons: true,
                              confirmButtonText: `Continuar ação`,
                              cancelButtonText: `Cancelar`,
                              showCancelButton: true,
                              confirmButtonColor: 'red'
                            }).then((value) => {
                              if (value.isConfirmed) {
                                DeleteIntegration(item.id, setNotify, setProgress)
                              }
                            })

                          }
                          >Excluir</Dropdown.Item>
                        </DropdownButton>

                      </Card.Body>
                    </Card>
                  </Col>

                ))
                : 
                <div className="container">
                  <h5 className="px-4">Nenhuma integração cadastrada no momento...</h5>
                </div>
              }
            </>

            {progress && <ProgressBarComponent />}
            {showModal && <ModalComponent props={modalProps} />}

          </>
      }
    </>
  );
}

const styles = {
  thumb: {
    width: 60,
    objectFit: 'contain',
    marginTop: '1rem'
  }
}