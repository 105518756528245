import React, { useState, useMemo, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { Accordion, Card } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import Swal from 'sweetalert2'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Hash, resizeImage } from '../../../helpers/Helpers'
import { DeleteVariationOption } from '../../../services/ProductsServices'
import { UpdateOptionsSequence } from '../../../services/VariationsServices'

export default function VariationItems ({
  productId,
  nestIndex,
  register,
  control,
  errors,
  clearErrors,
  setValue,
  setNotify,
  useFieldArray
}) {
  const [collapsed, setCollapsed] = useState('0')
  const [render, setRender] = useState(0)
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `group.${nestIndex}.items`
  })


  const imagePreview = async (image, index, nestIndex, setValue) => {
    if (image?.size > 1000000) {
      Swal.fire('Atenção!', 'Por favor, insira imagens até 1MB.', 'info')
      return
    }

    let url = URL.createObjectURL(image)
    document.getElementById(`group.${nestIndex}.items.${index}.thumb`).src = url
    let img = await resizeImage(url, image.type)
    setValue(`group.${nestIndex}.items.${index}.thumb`, img.url)
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    let sequence = result.destination.index + 1
    UpdateOptionsSequence(result.draggableId, { sequence: sequence }).then(
      res => {
        setTimeout(() => {
          setNotify({
            title: 'Sucesso!',
            message: 'Ordem alterada com sucesso!',
            type: 'success',
            visible: true
          })
        }, 500)
      }
    )
    move(result.source.index, result.destination.index)
    setRender(current => current + 1)
  }

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#f9f9f9' : '#fff'
  })

  const fieldsMemo = useMemo(() => {

    return fields.map((item, index) => {
      if (typeof item.id === 'number') {
        setValue('id', `group.${nestIndex}.items.${index}.id`)
      }
      return (
        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
          {(provided, snapshot) => (
            <Accordion
              ref={provided.innerRef}
              className='accordion accordion-active-header mb-2'
              defaultActiveKey={`0`}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className={`accordion-${index}`} key={index}>
                <Accordion.Toggle
                  as={Card.Text}
                  className={`accordion__header ${
                    collapsed !== `${index}` ? 'collapsed' : ''
                  } accordion__header--primary p-2 mb-1`}
                  eventKey={`${index}`}
                  onClick={() => setCollapsed(index.toString())}
                >
                  <span className='accordion__header--icon'></span>
                  <span
                    className={`accordion__header--text accordion-text-options-${nestIndex}-${index}`}
                  >
                    <i
                      className='las la-grip-vertical align-middle px-3'
                      style={{ cursor: 'grab' }}
                    ></i>
                    {item && item.name ? item.name : 'Nova'}
                  </span>
                  <span className='accordion__header--indicator '></span>
                </Accordion.Toggle>
                <Accordion.Collapse
                  className='accordion__body'
                  eventKey={`${index}`}
                >
                  <Card.Body className='text-left pt-3 px-0'>
                    <div className='col-lg-12'>
                      <div className='col-lg-4 col-md-12 col-sm-12 d-inline-block align-top px-0 pb-4'>
                        <div className='text-center'>
                          <img
                            id={`group.${nestIndex}.items.${index}.thumb`}
                            className={`img-fluid rounded mt-4 product-image`}
                            src={Hash('135x135', item.thumb)}
                            alt=''
                            style={{
                              width: 135,
                              height: 135,
                              objectFit: 'contain'
                            }}
                          />
                        </div>

                        <div className='input-group mt-3'>
                          <div className='custom-file'>
                            <input
                              accept='.png, .jpg, .jpeg, .webp'
                              type='file'
                              className='custom-file-input'
                              ref={register}
                              {...register(
                                `group.${nestIndex}.items.${index}.image`
                              )}
                              onChange={e => {
                                imagePreview(
                                  e.target.files[0],
                                  index,
                                  nestIndex,
                                  setValue
                                )
                              }}
                            />
                            <label className='custom-file-label'>
                              Selecione...
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-8 col-md-12 col-sm-12 d-inline-block'>
                        <div className='form-group'>
                          <div className='d-flex justify-content-between'>
                            <label className='pl-2'>Nome</label>

                            {index !== 0 && !productId && (
                              <button
                                type='button'
                                className='btn btn-outline-primary btn-xxs mb-2'
                                onClick={() => {
                                  remove(index)
                                  setRender(current => current - 1)
                                }}
                              >
                                Excluir opção
                              </button>
                            )}
                            {productId && (
                              <button
                                type='button'
                                className='btn btn-outline-primary btn-xxs mb-2'
                                onClick={() => {
                                  if (typeof item.id === 'number') {
                                    Swal.fire({
                                      title: 'Você tem certeza?',
                                      html: 'Deseja realmente deletar esta opção? Está ação não poderá ser revertida!',
                                      icon: 'warning',
                                      buttons: true,
                                      confirmButtonText: `Continuar ação`,
                                      cancelButtonText: `Cancelar`,
                                      showCancelButton: true,
                                      confirmButtonColor: 'red'
                                    }).then(value => {
                                      if (value.isConfirmed) {
                                        DeleteVariationOption(
                                          item.id,
                                          setNotify
                                        ).then(() => {
                                          remove(index)
                                          setRender(current => current - 1)
                                        })
                                      }
                                    })
                                  } else {
                                    remove(index)
                                    setRender(current => current - 1)
                                  }
                                }}
                              >
                                Excluir opção
                              </button>
                            )}
                          </div>

                          <input
                            type='text'
                            className='form-control input-rounded'
                            maxLength={80}
                            {...register(
                              `group.${nestIndex}.items.${index}.name`,
                              {
                                required: true,
                                maxLength: 80
                              }
                            )}
                            onChange={e => {
                              if (
                                errors?.group &&
                                errors?.group[nestIndex] &&
                                errors?.group[nestIndex]?.items &&
                                e.target.value.length > 3
                              ) {
                                clearErrors(
                                  `group.${nestIndex}.items.${index}.name`
                                )
                              }
                              document.querySelector(
                                `.accordion-text-options-${nestIndex}-${index}`
                              ).innerHTML = e.target.value
                            }}
                          />
                          {errors?.group &&
                            errors?.group[nestIndex] &&
                            errors?.group[nestIndex]?.items && (
                              <small className='ml-2 text-danger'>
                                {errors?.group[nestIndex]?.items[index] &&
                                  errors?.group[nestIndex]?.items[index].name
                                    .type === 'required' &&
                                  'Por favor, preencha este campo!'}
                                {errors?.group[nestIndex]?.items[index] &&
                                  errors?.group[nestIndex]?.items[index].name
                                    .type === 'maxLength' &&
                                  'Este campo deve conter no máximo 80 caracteres!'}
                              </small>
                            )}
                        </div>

                        <div className='form-group'>
                          <label className='pl-2'>Descrição</label>
                          <textarea
                            type='text'
                            className='form-control'
                            defaultValue={''}
                            placeholder='Opcional...'
                            rows={3}
                            maxLength={150}
                            {...register(
                              `group.${nestIndex}.items.${index}.description`
                            )}
                          />
                        </div>

                        <div className='form-group'>
                          <label className='pl-2'>Valor adicional</label>
                          <Controller
                            render={({ field }) => (
                              <NumberFormat
                                thousandSeparator='.'
                                decimalSeparator=','
                                prefix='R$'
                                className='form-control input-rounded'
                                {...field}
                              />
                            )}
                            {...register(
                              `group.${nestIndex}.items.${index}.additional_price`
                            )}
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </div>
            </Accordion>
          )}
        </Draggable>
      )
    })
  }, [render])

  return (
    <div className='form-group col-md-12'>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <label>Opções</label>
        <button
          type='button'
          className='btn btn-sm btn-outline-primary'
          onClick={() => {
            append({
              name: '',
              description: '',
              additional_price: '',
              thumb: ''
            })
            setRender(current => current + 1)
          }}
        >
          <i className='ti-plus mr-1'></i>
          Nova opção
        </button>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className='accordion__item'
            >
              {fieldsMemo}
              {/* {provided.placeholder} */}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
