import React, { useEffect, useCallback } from "react";

import tt from "@tomtom-international/web-sdk-maps";
import DrawingTools from "@tomtom-international/web-sdk-plugin-drawingtools";
import "../css/tomtom.css";
import { AppSettings } from "../App";
import { FindPlaceByCoords } from "../services/DeliveryServices";

export default function DeliveryFeePerAreaDrawing({
  data,
  deliveryCity,
  deliveryArea,
  drawnAreaObject,
  setNotify,
}) {
  const createMarker = useCallback((map, position, popupText) => {
    let markerElement = document.createElement("div");
    markerElement.className = "marker";
    let markerContentElement = document.createElement("div");
    markerContentElement.className = "marker-content";
    markerElement.appendChild(markerContentElement);
    let iconElement = document.createElement("div");
    iconElement.className = "marker-icon";
    iconElement.style.backgroundImage = `url(/imgs/map-pointer.png)`;

    markerContentElement.appendChild(iconElement);
    let popup = new tt.Popup({ offset: 30 }).setText(popupText);

    new tt.Marker({ element: markerElement, anchor: "bottom" })
      .setLngLat(position)
      .setPopup(popup)
      .addTo(map);
  }, []);

  useEffect(() => {
    const ttDrawingTools = new DrawingTools({
      ttMapsSdk: tt,
      controls: {
        ellipse: false,
        line: false,
      },
    });

    tt.setProductInfo("Pralevar", "1.0");
    let center =
      data.drawn_area && Object.values(data.drawn_area).length > 0
        ? data.drawn_area[0].geometry.coordinates[0][0]
        : [
            AppSettings.store.address.longitude,
            AppSettings.store.address.latitude,
          ];

    const ttMap = tt.map({
      key: process.env.REACT_APP_TOMTOM_MAPS_KEY,
      container: "map",
      center: center,
      zoom: 13,
    });

    ttMap.on("load", function (e) {
      ttMap.addControl(ttDrawingTools, "top-left");

      ttMap.addControl(new tt.NavigationControl());
      createMarker(
        ttMap,
        [
          AppSettings.store.address.longitude,
          AppSettings.store.address.latitude,
        ],
        "Minha Loja"
      );

      if (data.drawn_area && Object.values(data.drawn_area).length > 0) {
        ttDrawingTools.importFeatures(data.drawn_area);
      }
    });

    ttDrawingTools.on("tomtom.drawingtools.created", function (feature) {
      let drawnArea = feature.data.features[0].geometry.coordinates[0];
      deliveryArea.current = drawnArea;
      if (drawnArea[0]) {
        FindPlaceByCoords(drawnArea[0][1], drawnArea[0][0], setNotify)
          .then((res) => {
           /*  
           calcula o raio 
           if (feature.data.features[0].geometry.type === 'Polygon') {
              
              var coordinates = drawnArea;
              var center = calculateCenter(coordinates);
              var radius = calculateRadius(center, coordinates);
              displayRadius(center, radius);
          } */
            if (res)
              deliveryCity.current = res.addresses[0].address.municipality;
          })
          .catch((error) => console.log(error));
      }

      drawnAreaObject.current = ttDrawingTools.exportFeatures();
    });

    ttDrawingTools.on("tomtom.drawingtools.changed", function (feature) {
      let drawnArea = feature.data.features[0].geometry.coordinates[0];
      deliveryArea.current = drawnArea;
      if (drawnArea[0]) {
        FindPlaceByCoords(drawnArea[0][1], drawnArea[0][0], setNotify)
          .then((res) => {
            console.log(res);
            if (res)
              deliveryCity.current = res.addresses[0].address.municipality;
          })
          .catch((error) => console.log(error));
      }

      drawnAreaObject.current = ttDrawingTools.exportFeatures();
    });
    ttDrawingTools.on("tomtom.drawingtools.dragged", function (feature) {
      let drawnArea = feature.data.features[0].geometry.coordinates[0];
      deliveryArea.current = drawnArea;
      if (drawnArea[0]) {
        FindPlaceByCoords(drawnArea[0][1], drawnArea[0][0], setNotify)
          .then((res) => {
            if (res)
              deliveryCity.current = res.addresses[0].address.municipality;
          })
          .catch((error) => console.log(error));
      }

      drawnAreaObject.current = ttDrawingTools.exportFeatures();
    });

    function calculateCenter(coordinates) {
      var lats = coordinates.map(coord => coord[1]);
      var lngs = coordinates.map(coord => coord[0]);
      var centerLat = (Math.min(...lats) + Math.max(...lats)) / 2;
      var centerLng = (Math.min(...lngs) + Math.max(...lngs)) / 2;
      return [centerLng, centerLat];
  }

  function toRadians(degrees) {
      return degrees * Math.PI / 180;
  }

  function calculateDistance(coord1, coord2) {
      var R = 6371e3; // Raio da Terra em metros
      var φ1 = toRadians(coord1[1]);
      var φ2 = toRadians(coord2[1]);
      var Δφ = toRadians(coord2[1] - coord1[1]);
      var Δλ = toRadians(coord2[0] - coord1[0]);

      var a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      var d = R * c;
      return d; // Distância em metros
  }

  function calculateRadius(center, coordinates) {
      var distances = coordinates.map(coord => calculateDistance(center, coord));
      return Math.max(...distances);
  }

  function displayRadius(center, radius) {
      new tt.Marker()
          .setLngLat(center)
          .setPopup(new tt.Popup({ offset: 30 }).setText(`Raio: ${radius.toFixed(2)} metros`))
          .addTo(ttMap)
          .togglePopup();
  }

    /* return () => {
      ttMap.remove()
    } */
  });

  return (
    <>
      <div id="map" className="full-map" height={500} width={500}></div>
    </>
  );
}
