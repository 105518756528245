import { useContext, useEffect, useRef, useState } from "react";
import DatatablePaginated from "../DatatablePaginated";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ActiveBot,
  GetContacts,
  PauseBot,
  SearchContact,
  UpdateContactName,
} from "../../../services/WaBottyServices";
import DefaultLoader from "../Loader";
import NotificationsContext from "../../../contexts/NotificationsContext";
import Swal from "sweetalert2";
import UserContext from "../../../contexts/UserContext";

export default function WhatsappContacts({ store_id, setShowModal }) {
  const { user } = useContext(UserContext);
  const { setNotify } = useContext(NotificationsContext);
  const totalPages = useRef(1);
  const contactsTotalRows = useRef(1);

  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editName, setEditName] = useState(false);

  useEffect(() => {
    GetContacts()
      .then((res) => {
        contactsTotalRows.current = res.total;
        totalPages.current = Math.ceil(res.total / 10);
        setContacts(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handlerPauseOrActive = (row) => {
    let active = parseInt(row.bot);
    Swal.fire({
      title: "Você tem certeza?",
      html: "Você está prestes a pausar o funcionamento do Bot/IA para este contato, <b class='text-info'>ele será reativado automaticamente após 10minutos.</b>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#515151",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Pausar o Bot/IA!",
    }).then((result) => {
      if (result.isConfirmed) {
        PauseBot({
          phone: row.phone,
          store_id: user.store_id,
        }).then((result) => {
          if (result.status === 200) {
            setNotify({
              title: "Sucesso!",
              message: "Bot/IA Pausado para este contato!",
              type: "success",
              visible: true,
            });
            setShowModal(false);
          } else {
            setNotify({
              title: "Oops!",
              message:
                "Ocorreu um erro ao pausar o Bot/IA, por favor, tente novamente daqui alguns minutos.",
              type: "error",
              visible: true,
            });
          }
        });
      }
    });
  };

  const handlerDisable = (row) => {
    let active = parseInt(row.bot);
    Swal.fire({
      title: "Você tem certeza?",
      html: "Você está prestes a <b>desativar</b> o funcionamento do Bot/IA para este contato. ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#515151",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Desativar o Bot/IA!",
    }).then((result) => {
      if (result.isConfirmed) {
        PauseBot({
          phone: row.phone,
          store_id: user.store_id,
          disable: 1,
        }).then((result) => {
          if (result.status === 200) {
            setNotify({
              title: "Sucesso!",
              message:
                "Bot/IA Desativado para este contato! Para voltar o atendimento do Bot/IA você deverá ativar manualmente.",
              type: "success",
              visible: true,
            });
            setShowModal(false);
          } else {
            setNotify({
              title: "Oops!",
              message:
                "Ocorreu um erro ao desativar o Bot/IA, por favor, tente novamente daqui alguns minutos.",
              type: "error",
              visible: true,
            });
          }
        });
      }
    });
  };

  const handlerActive = (row) => {
    let active = parseInt(row.bot);
    Swal.fire({
      title: "Você tem certeza?",
      html: "Você está prestes a <b>ativar</b> o funcionamento do Bot/IA para este contato. ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--success)",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Ativar o Bot/IA!",
    }).then((result) => {
      if (result.isConfirmed) {
        ActiveBot({
          id: row.id,
          phone: row.phone,
          store_id: user.store_id,
          disable: 1,
        }).then((result) => {
          if (result.status === 200) {
            setNotify({
              title: "Sucesso!",
              message: "Bot/IA ativado para este contato! ",
              type: "success",
              visible: true,
            });
            setShowModal(false);
          } else {
            setNotify({
              title: "Oops!",
              message:
                "Ocorreu um erro ao ativar o Bot/IA, por favor, tente novamente daqui alguns minutos.",
              type: "error",
              visible: true,
            });
          }
        });
      }
    });
  };

  const handlerEditName = async (row) => {
    let name = document.querySelector(`#input-${row.id}`).value;

    let payload = {
      id: row.id,
      name: name,
      phone: row.phone,
    };

    let res = await UpdateContactName(payload);
    if (res) {
      setNotify({
        title: "Sucesso!",
        message: "Nome do contato atualizado com sucesso!",
        type: "success",
        visible: true,
      });
      setShowModal(false);
    } else {
      setNotify({
        title: "Oops!",
        message:
          "Ocorreu um erro ao atualizar o nome do contato, por favor, tente novamente.",
        type: "error",
        visible: true,
      });
    }
  };

  const actions = (row) => {
    return (
      <div className="d-flex">
        <button
          id={`btn-active-${row.id}`}
          className="btn sharp"
          onClick={() => handlerActive(row)}
        >
          <i className="fa fa-play"></i>
        </button>

        <button
          id={`btn-pause-${row.id}`}
          className="btn sharp"
          onClick={() => handlerPauseOrActive(row)}
        >
          <i className="fa fa-pause"></i>
        </button>

        <button
          id={`btn-disable-${row.id}`}
          className="btn sharp"
          onClick={() => handlerDisable(row)}
        >
          <i className="fa fa-stop"></i>
        </button>
      </div>
    );
  };

  const status = (row) => {
    return parseInt(row.bot) === 1 ? (
      <span className="badge badge-success">Ativo</span>
    ) : (
      <span className="badge badge-dark">Desativado</span>
    );
  };

  const editNameHandler = (row) => {
    return (
      <div className="d-flex align-items-center position-relative">
        <input
          id={`input-${row.id}`}
          type="text"
          className="form-control"
          defaultValue={row.name}
          readOnly={editName !== row.id ? true : false}
        />
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-bottom`}>Pausar Bot/IA</Tooltip>}
        >
          {editName === row.id ? (
            <i
              className="fa fa-check ml-1 fs-16 cursor-pointer"
              onClick={() => handlerEditName(row)}
            ></i>
          ) : (
            <i
              className="fa fa-edit ml-1 fs-16 cursor-pointer"
              onClick={() => setEditName(row.id)}
            ></i>
          )}
        </OverlayTrigger>
      </div>
    );
  };

  const formatPhone = (phone) => {
    if (phone.includes("+55")) {
      phone = phone.replace("+55", "");
    }

    phone = phone.slice(0, 2) + "9" + phone.slice(2);
    return phone;
  };

  const handleClickSearch = async () => {
    let value = document.querySelector("#input-search-whatsapp-contacts").value;
    if (!value) {
      return Swal.fire(
        "Oops!",
        "Insira o nº do telefone completo para realizar a busca!",
        "info"
      );
    }

    if (value) {
      value = value.replace(/[^0-9]/g, "");
    }

    document
      .querySelector("#btn-search-whatsapp-contacts")
      .setAttribute("disabled", "true");
    document.querySelector("#btn-search-whatsapp-contacts i").className =
      "fa fa-spinner fa-spin";

    let res = await SearchContact(value);

    if (!res) {
      document
        .querySelector("#btn-search-whatsapp-contacts")
        .removeAttribute("disabled");
      document.querySelector("#btn-search-whatsapp-contacts i").className =
        "ti-search";
      return Swal.fire("Oops!", "Nenhum resultado encontrado!", "info");
    }

    document
      .querySelector("#btn-search-whatsapp-contacts")
      .removeAttribute("disabled");
    document.querySelector("#btn-search-whatsapp-contacts i").className =
      "ti-search";

    setContacts(res);
    setNotify({
      title: "Sucesso!",
      message: "Busca realizada com sucesso!",
      type: "success",
      visible: true,
    });
  };

  const columns = [
    {
      name: "Nome",
      selector: (row) => editNameHandler(row),
      maxWidth: "40%",
    },
    {
      name: "Número",
      selector: (row) => formatPhone(row.phone),
      sortable: true,
      maxWidth: "20%",
    },
    {
      name: "Bot/IA",
      selector: (row) => status(row),
      sortable: true,
      maxWidth: "20%",
    },

    {
      name: "Ações",
      selector: (row) => actions(row),
      maxWidth: "20%",
      allowOverflow: true,
    },
  ];

  const handlePageChange = (page) => {
    GetContacts(page).then((res) => {
      setContacts(res.data);
      contactsTotalRows.current = res.total;
      totalPages.current = Math.ceil(res.total / 10);
    });
  };

  return isLoading ? (
    <DefaultLoader />
  ) : (
    <DatatablePaginated
      componentName="whatsapp-contacts"
      data={contacts}
      columns={columns}
      filterDefaultField={"phone"}
      filterByField={{ labels: ["Telefone"], fields: ["phone"] }}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      handlePageChange={handlePageChange}
      totalRows={contactsTotalRows.current}
      searchBarType={2}
      handleClickSearch={handleClickSearch}
    />
  );
}
