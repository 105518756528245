import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button, Card, Col, Accordion } from "react-bootstrap";
import { GetAll, ChangeStatus, Delete } from '../services/VariationsServices'
import { Hash } from '../helpers/Helpers'
import DefaultLoader from '../jsx/components/Loader'
import Swal from "sweetalert2";
import AlertResponses from '../jsx/components/Alert'
import ErrorsComponent from '../jsx/components/Errors'
import NumberFormat from 'react-number-format';
import ModalComponent from '../jsx/components/Modal'
import ProgressBarComponent from '../jsx/components/ProgressBar'
import VariationDetails from './VariationDetails'

export default function Variations() {

  const data = useRef(null);
  const [variations, setVariations] = useState([]);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [collapsed, setCollapsed] = useState(0);
  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);

  //TODO: no datatable nao filtra pelo campo created_at por causa do formato da data q no campo é 2021-08-03 e o user
  // digita 30/08/2021
  useEffect(() => {
    GetAll(setVariations, setErrors, setIsLoading);
  }, [])

  const modalProps = {
    title: 'Variação',
    content: <VariationDetails data={data.current} setNotify={setNotify} setShowModal={setShowModal} />,
    btnLabel: 'Salvar',
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  }

  const status = {
    0: {
      id: 0,
      label: "Desativada",
      type: "light"
    },
    1: {
      id: 1,
      label: "Ativa",
      type: "success"
    },
  }

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
            {
              errors !== null ? <ErrorsComponent args={errors} /> :
                <>
                  <div className="item mt-4">
                    <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                      <div className="col-xl-4 col-xxl-5 col-sm-12">
                        <h2>Variações</h2>
                      </div>

                      <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">

                        <Button className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                          onClick={() => { data.current = null; setShowModal(true); }}>
                          <span>ADICIONAR</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                  {
                    notify.visible &&
                    <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
                  }
                  <>

                    {
                      variations.length > 0 ? variations.map((item, i) => (

                        <Col xl='6' key={i} className="d-inline-block">
                          <Card className='mb-3 align-items-center'>
                            <img
                              className='card-img-top img-fluid'
                              src={item.thumb}
                              style={styles.thumb}
                            />
                            <Card.Header className="m-auto">
                              <Card.Title>{item.data.internalTitle}</Card.Title>
                            </Card.Header>
                            <Card.Body className="card-body w-100">
                              <h4 className="ml-2 mb-3">{item.data.title}</h4>
                              <div className='accordion__item'>
                                <Accordion
                                  className='accordion accordion-active-header'
                                >
                                  {
                                    item.data.items.map((value, ii) => {
                                      return (
                                        <div key={ii}>
                                          <Accordion.Toggle
                                            as={Card.Text}
                                            className={`accordion__header ${collapsed !== `${i}${ii}` ? 'collapsed' : ''} accordion__header--primary p-2 mb-2`}
                                            eventKey={`${ii}`}
                                            onClick={() => setCollapsed(`${i}${ii}`)}

                                          >
                                            <span className='accordion__header--icon'></span>
                                            <span className='accordion__header--text'>{value.name}</span>
                                            <span className='accordion__header--indicator '></span>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            className='accordion__body'
                                            eventKey={`${ii}`}
                                          >
                                            <Card.Body className="text-left pt-3">
                                              <div className="form-group">
                                                <label className="pl-2">Nome</label>
                                                <input type="text" className="form-control input-rounded" value={value.name} readOnly /></div>
                                              <div className="form-group">
                                                <label className="pl-2">Descrição</label>
                                                <input type="text" className="form-control input-rounded" value={value.description} readOnly /></div>
                                              <div className="form-group">
                                                <label className="pl-2">Valor adicional</label>

                                                <NumberFormat
                                                  value={value.additional_price}
                                                  thousandSeparator="."
                                                  decimalSeparator=","
                                                  prefix="R$"
                                                  className="form-control input-rounded"
                                                  readOnly
                                                />
                                              </div>

                                            </Card.Body>

                                          </Accordion.Collapse>
                                        </div>
                                      )
                                    })
                                  }

                                </Accordion>
                              </div>
                              <div className="d-flex justify-content-center">
                                <DropdownButton
                                  variant=''
                                  style={styles.dropdown}
                                  title={item.data.status === 1 ? 'Ativa' : 'Desativada'}
                                  className={`badge badge-${item.data.status === 1 ? 'success' : 'light'} badge-sm light badge-delivery-status badge-${item.id}`}
                                  onSelect={(e) => {
                                    variations[i].data.status = parseInt(e);

                                    ChangeStatus(variations[i], setNotify).then(res => {
                                      if (res) {
                                        document.querySelector(`.badge-${item.id}`).classList.remove(`badge-${item.data.status === 1 ? 'success' : 'light'}`)
                                        document.querySelector(`.badge-${item.id}`).classList.add(`badge-${status[e].type}`)
                                        document.querySelector(`.badge-${item.id} button`).innerText = status[e].label
                                      }
                                    })

                                  }}
                                >
                                  {Object.values(status).map((value, i) =>
                                    <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
                                </DropdownButton>
                                <DropdownButton
                                  variant=''
                                  style={styles.dropdown}
                                  title="Ações"
                                  className={`badge badge-primary badge-sm light badge-delivery-status ml-4`}
                                  onSelect={(e) => {
                                  }}
                                >
                                  <Link
                                    to="#"
                                    onClick={() => { data.current = item; setShowModal(true) }}
                                    className="text-danger dropdown-item">Editar</Link>
                                  {/* <Dropdown.Item onClick={() => Delete(item.id, setNotify, setProgress)}>Excluir</Dropdown.Item> */}
                                </DropdownButton>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>

                      ))
                        :
                        <h5 className="ml-5 mt-3">Nenhuma variação cadastrada.</h5>
                    }
                  </>
                  {progress && <ProgressBarComponent />}

                  {showModal && <ModalComponent props={modalProps} />}
                </>
            }
          </>

      }
    </>
  );
}

const styles = {
  thumb: {
    maxWidth: 250,
    maxHeight: 165
  }
}