import React, { useRef, createContext, useCallback } from "react";

const AppContext = createContext();

export function AppProvider({ children }) {
  const ifoodShipping = useRef(false);
  const currentOrderStatus = useCallback((id, status) => {
    let currentOrders = localStorage.getItem("currentOrders");
    currentOrders = JSON.parse(currentOrders);
    currentOrders = currentOrders.map((order) => {
      if (order.id === id) {
        if (status === 2) {
          let date = new Date();
          date.setUTCHours(date.getUTCHours() - 3);
          date = date.toISOString().slice(0, 19).replace("T", " ");
          order.status.push({ id: 2, name: "Em andamento", created_at: date });
        }
        if (status === 3) {
          let date = new Date();
          date.setUTCHours(date.getUTCHours() - 3);
          date = date.toISOString().slice(0, 19).replace("T", " ");
          order.status.push({
            id: 3,
            name: "Saiu para entrega",
            created_at: date,
          });
        }
      }
      return order; // Retorna a ordem atualizada ou não modificada
    });
    localStorage.setItem("currentOrders", JSON.stringify(currentOrders));
    return;
  }, []);

  const removeOrderFromStorage = (id) => {
    let currentOrders = localStorage.getItem("currentOrders");
    if (currentOrders && currentOrders.length > 0) {
      currentOrders = JSON.parse(currentOrders);
      currentOrders = currentOrders.filter((order) => order.id !== id);
    } else {
      currentOrders = [];
    }
    return currentOrders;
  };

  return (
    <AppContext.Provider
      value={{
        ifoodShipping,
        currentOrderStatus,
        removeOrderFromStorage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContext;
