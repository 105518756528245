import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import NumberFormat from "react-number-format";

import { GetNewOrders } from "../../../services/OrdersServices";
import { Logout } from "../../../services/LoginServices";
import { UpdateStore } from "../../../services/ConfigurationsServices";
import AlertResponses from "../../components/Alert";
import NotificationsContext from "../../../contexts/NotificationsContext";
import UserContext from "../../../contexts/UserContext";
import { Hash } from "../../../helpers/Helpers";
import { AppSettings } from "../../../App";
import AppContext from "../../../contexts/AppContext";
import {
  CloseStore,
  GetIfoodShippingIntegration,
} from "../../../services/StoreServices";
import StoreStatus from "../../components/StoreStatus";
import { IMAGES_URL } from "../../../api/Urls";
import { parseCookies } from "nookies";
import WhatsappContacts from "../../components/WhatsappContacts";
import ModalComponent from "../../components/Modal";

const Header = (props) => {
  const { token } = parseCookies();
  const { ifoodShipping } = useContext(AppContext);
  const { setShowHelpModal, helpData } = useContext(NotificationsContext);
  const { setShowPlansModal, superuser, userPlan } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const user = JSON.parse(localStorage.getItem("user"));
  const dropdown = useRef();
  const [newOrder, setNewOrder] = useState([]);
  const [showWhatsappModal, setShowWhatsappModal] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  // const counter = useRef(0);
  // const variationName = useRef("");
  const ws = useRef(null);

  const user_photo = user?.photo
    ? user.photo
    : user?.user_photo
    ? user.user_photo
    : null;
  const audio = useRef();

  const setCurrentOrders = (currentOrders, res) => {
    if (!currentOrders && res) {
      localStorage.setItem("currentOrders", JSON.stringify(res));
      window.postMessage({ currentOrders: true });
    } else if (currentOrders) {
      currentOrders = JSON.parse(currentOrders);
      let ids = currentOrders.map((v) => v.id);

      if (res) {
        res.map((v) => {
          if (!ids.includes(v.id)) {
            currentOrders.push(v);
          }
        });
      }

      localStorage.setItem("currentOrders", JSON.stringify(currentOrders));
      setTimeout(() => {
        window.postMessage({ currentOrders: true });
      }, 750);
    }
  };

  useEffect(() => {
    GetIfoodShippingIntegration().then((res) => {
      if (res) {
        ifoodShipping.current = true;
      }
    });

    GetNewOrders(null, true, user.store_id).then((res) => {
      let currentOrders = localStorage.getItem("currentOrders");
      if (res) {
        playNotificationSound();
        window.postMessage({ newOrder: true });
        setCurrentOrders(currentOrders, res);
      } else {
        setCurrentOrders(currentOrders, false);
      }
    });
  }, []);

  useEffect(() => {
    const connectWebSocket = () => {
      // const socketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}notifications/?ct=1&token=${token}`;
      const socketUrl = `wss://api.pralevar.app/ws/notifications/?ct=1&token=${token}`;
      
      ws.current = new WebSocket(socketUrl);

      ws.current.onopen = () => {
        ws.current.pingInterval = setInterval(() => {
          ws.current.send(JSON.stringify({ type: "ping" }));
        }, 10000);
      };

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.new_order) {
          GetNewOrders(null, true, user.store_id).then((res) => {
            let currentOrders = localStorage.getItem("currentOrders");
            if (res) {
              playNotificationSound();
              window.postMessage({ newOrder: true });
              setCurrentOrders(currentOrders, res);

              if (window.location.pathname !== "/") {
                return (window.location.href = "/");
              }
            }
          });
        } else if (data.open_store) {
          window.postMessage({ open_store: data.open_store });
        } else if (data.pix_id) {
          window.postMessage({ pix_id: data.pix_id });
        } else if (data.customer_requested_help) {
          helpData.current = data.customer_requested_help;
          setShowHelpModal(true);
        }
      };

      ws.current.onclose = () => {
        console.log("WebSocket closed, retrying...");
        if (ws.current.pingInterval) {
          clearInterval(ws.current.pingInterval);
        }
        setTimeout(() => {
          connectWebSocket();
        }, 5000);
      };

      ws.current.onerror = (error) => {
        ws.current.close();
      };
    };

    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (ws.current.pingInterval) {
        clearInterval(ws.current.pingInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (newOrder.length > 0) {
      localStorage.setItem("currentOrders", JSON.stringify(newOrder));
    }
  }, [newOrder]);

  const onSubmit = (data) => {
    var form = new FormData();

    let obj = {
      from: data.from,
      to: data.to,
    };
    let storeId = user.store_id;

    form.append("deliveryTime", JSON.stringify(obj));
    form.append("store", window.location.host);
    form.append("store_id", storeId);

    UpdateStore(storeId, form, setNotify, false, true, "/").finally(
      () => (dropdown.current.style.display = "none")
    );
  };

  const pauseOrdersSubmit = async (data) => {
    let obj = {
      closing_time: data.pauseOrders || false,
    };

    await CloseStore(obj);
  };

  const closeStoreSubmit = async (data) => {
    let obj = { days: [] };
    document
      .querySelectorAll(".custom-control-input:checked")
      .forEach((el) => obj.days.push(el.name));

    if (obj.days.length > 0) {
      let shifts = data.closedShifts.split(" as ");
      obj.startTime = shifts[0];
      obj.endTime = shifts[1];
    } else {
      obj = false;
    }

    await CloseStore({ closed_shifts: obj });
  };

  useEffect(() => {
    const eventListener = (event) => {
      if (event.data.pauseSound) {
        if (audio.current) {
          audio.current.pause();
          audio.current = false;
        }
      }
    };

    window.addEventListener("message", eventListener, false);

    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  function playNotificationSound() {
    if (
      AppSettings.store &&
      AppSettings.store.configs.length > 0 &&
      AppSettings.store.configs[0].ringtone_type === 2
    ) {
      window.postMessage({ playVoiceRingtone: true });
    } else if (!audio.current) {
      audio.current = new Audio("./sounds/sound1.mp3");
      audio.current.loop = true;
      audio.current.play();
    }
  }

  const handleChecked = (day) => {
    if (AppSettings.store?.configs[0]?.opening_hours?.closedShifts) {
      if (
        AppSettings.store?.configs[0]?.opening_hours?.closedShifts.days.includes(
          day
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const modalProps = {
    title: "Contatos do Whatsapp",
    content: (
      <WhatsappContacts
        storeId={user?.store_id}
        setShowModal={setShowWhatsappModal}
      />
    ),
    size: "lg",
    btnLabel: "Salvar",
    showModal: showWhatsappModal,
    setShowModal: setShowWhatsappModal,
    showFooter: false,
  };

  return (
    <div className="header">
      <div className="header-content z-index999">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div
              id="store-current-plan"
              className="header-left flex-column justify-content-center cursor-pointer"
            >
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize", color: "var(--titles)" }}
              >
                {AppSettings.logo ? (
                  <img
                    className="img-fluid"
                    src={`https://${IMAGES_URL}${AppSettings.logo}`}
                    alt={""}
                    style={{ maxWidth: 150, maxHeight: 65, marginBottom: 5 }}
                  />
                ) : (
                  "Dashboard"
                )}
               
              </div>
              <span
                className="badge badge-primary badge-sm text-white w-100"
                onClick={() => setShowPlansModal(true)}
              >
                Plano: {userPlan.current}
              </span>
            </div>

            {AppSettings.store && (
              <ul className="navbar-nav header-right align-items-center">
                <Dropdown
                  ref={(e) => (dropdown.current = e)}
                  className="nav-item dropdown notification_dropdown rounded-circle"
                  as="li"
                >
                  <Dropdown.Toggle variant="light i-false sharp rounded-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="#1a1a1a"
                      width={21}
                    >
                      <path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu"
                    align="left"
                    style={{ minWidth: "18rem" }}
                  >
                    <form onSubmit={handleSubmit(pauseOrdersSubmit)}>
                      <div className="col-lg-12 mt-4">
                        <h6 className="my-2">Pausar atendimentos por:</h6>
                        <NumberFormat
                          name="pauseOrders"
                          defaultValue={""}
                          format="##:##"
                          placeholder="Minutos ou horas, ex: 00:30"
                          className="form-control text-black input-opening-hours"
                          onChange={(e) => {
                            if (
                              e.target.value.replace(/[^0-9]/g, "").length >
                                1 &&
                              e.target.value.replace(/[^0-9]/g, "").length < 4
                            ) {
                              document
                                .querySelector(".incorrect-time")
                                .classList.remove("d-none");
                            } else {
                              document
                                .querySelector(".incorrect-time")
                                .classList.add("d-none");
                              setValue("pauseOrders", e.target.value);
                            }
                          }}
                        />
                        <small className="incorrect-time text-primary p-2 d-none">
                          Insira o tempo corretamente no formato: 00:30 para
                          minutos ou 01:00 para horas...
                        </small>
                        <button
                          type="submit"
                          className="btn btn-sm btn-block btn-info mt-2"
                        >
                          Pausar
                        </button>
                      </div>
                    </form>
                    <hr></hr>
                    <form onSubmit={handleSubmit(closeStoreSubmit)}>
                      <div className="col-lg-12">
                        <h6>
                          Fechar loja por intervalo<br></br>
                          <small className="mt-1">
                            *Escolha os dias da semana
                          </small>
                        </h6>
                        <div className="d-flex justify-content-center align-items-center my-3">
                          <div className="custom-control custom-checkbox week-days">
                            <input
                              type="checkbox"
                              className="custom-control-input checked-1"
                              id="customCheck1"
                              name="monday"
                              defaultChecked={handleChecked("monday")}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            ></label>
                          </div>
                          <div className="custom-control custom-checkbox week-days">
                            <input
                              type="checkbox"
                              className="custom-control-input checked-2"
                              id="customCheck2"
                              name="tuesday"
                              defaultChecked={handleChecked("tuesday")}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck2"
                            ></label>
                          </div>
                          <div className="custom-control custom-checkbox week-days">
                            <input
                              type="checkbox"
                              className="custom-control-input checked-3"
                              id="customCheck3"
                              name="wednesday"
                              defaultChecked={handleChecked("wednesday")}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck3"
                            ></label>
                          </div>
                          <div className="custom-control custom-checkbox week-days">
                            <input
                              type="checkbox"
                              className="custom-control-input checked-4"
                              id="customCheck4"
                              name="thursday"
                              defaultChecked={handleChecked("thursday")}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck4"
                            ></label>
                          </div>
                          <div className="custom-control custom-checkbox week-days">
                            <input
                              type="checkbox"
                              className="custom-control-input checked-5"
                              id="customCheck5"
                              name="friday"
                              defaultChecked={handleChecked("friday")}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck5"
                            ></label>
                          </div>
                          <div className="custom-control custom-checkbox week-days">
                            <input
                              type="checkbox"
                              className="custom-control-input checked-6"
                              id="customCheck6"
                              name="saturday"
                              defaultChecked={handleChecked("saturday")}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck6"
                            ></label>
                          </div>
                          <div className="custom-control custom-checkbox week-days">
                            <input
                              type="checkbox"
                              className="custom-control-input checked-7"
                              id="customCheck7"
                              name="sunday"
                              defaultChecked={handleChecked("sunday")}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck7"
                            ></label>
                          </div>
                        </div>
                        <div className="input-group">
                          <Controller
                            control={control}
                            rules={{ required: false }}
                            name="closedShifts"
                            defaultValue={
                              AppSettings.store?.configs[0]?.opening_hours
                                ?.closedShifts.startTime &&
                              AppSettings.store?.configs[0]?.opening_hours
                                ?.closedShifts.endTime
                                ? `${AppSettings.store?.configs[0]?.opening_hours?.closedShifts.startTime} as ${AppSettings.store?.configs[0]?.opening_hours?.closedShifts.endTime}`
                                : ""
                            }
                            render={({ field }) => (
                              <NumberFormat
                                {...field}
                                format="##:## as ##:##"
                                placeholder="ex: 15:00 as 18:00"
                                className="form-control text-black input-opening-hours"
                              />
                            )}
                          />
                        </div>
                        <small className="incorrect-time2 text-primary py-3 d-none">
                          Insira o intervalo que deseja fechar a loja!
                        </small>
                        <button
                          type="submit"
                          className="btn btn-sm btn-block btn-primary mt-2"
                        >
                          Salvar
                        </button>
                      </div>
                    </form>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  ref={(e) => (dropdown.current = e)}
                  className="nav-item dropdown notification_dropdown"
                  as="li"
                >
                  <Dropdown.Toggle variant="light i-false sharp rounded-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      fill="#1a1a1a"
                    >
                      <path d="M328 56C328 42.75 338.7 32 352 32H393.3C417.1 32 438.3 47.09 446.1 69.6L454.2 92.88L505.2 67.38C509.7 65.16 514.6 64 519.6 64H528C536.8 64 544 71.16 544 80V144C544 152.8 536.8 160 528 160H519.6C514.6 160 509.7 158.8 505.2 156.6L470.3 139.2L500 224.4C503.1 224.1 507.1 224 512 224C555.7 224 595.3 241.5 624.1 269.8C633.6 279.1 633.7 294.3 624.4 303.8C615.1 313.2 599.9 313.4 590.5 304.1C570.2 284.2 542.6 272 512 272C459.3 272 414.1 308.5 403.1 357.6C401.1 366.1 400 374.9 400 384H256C256 437 213 480 160 480C106.1 480 64 437 64 384H32C14.33 384 0 369.7 0 352V320C0 249.3 57.31 192 128 192H224C241.7 192 256 206.3 256 224V304C256 321.7 270.3 336 288 336H359.3C373.8 289.1 408.5 252.9 452.9 235.3L400.8 85.37C399.7 82.16 396.7 80 393.3 80H352C338.7 80 328 69.26 328 56V56zM208 240H128C83.82 240 48 275.8 48 320V336H214.7C210.4 326.2 208 315.4 208 304V240zM208 384H112C112 410.5 133.5 432 160 432C186.5 432 208 410.5 208 384zM232 112C245.3 112 256 122.7 256 136C256 149.3 245.3 160 232 160H120C106.7 160 96 149.3 96 136C96 122.7 106.7 112 120 112H232zM416 384C416 330.1 458.1 288 512 288C565 288 608 330.1 608 384C608 437 565 480 512 480C458.1 480 416 437 416 384zM512 432C538.5 432 560 410.5 560 384C560 357.5 538.5 336 512 336C485.5 336 464 357.5 464 384C464 410.5 485.5 432 512 432z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu"
                    style={{ minWidth: "15rem" }}
                  >
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="col-lg-12">
                        <h6 className="my-3">Tempo de entrega</h6>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control text-black"
                            placeholder="De:"
                            {...register("from", { required: true })}
                            defaultValue={
                              AppSettings.store?.deliveryTime
                                ? setValue(
                                    "from",
                                    AppSettings.store?.deliveryTime.from
                                  )
                                : ""
                            }
                          />
                          <input
                            type="number"
                            className="form-control text-black"
                            placeholder="À:"
                            {...register("to", { required: true })}
                            defaultValue={
                              AppSettings.store?.deliveryTime
                                ? setValue(
                                    "to",
                                    AppSettings.store?.deliveryTime.to
                                  )
                                : ""
                            }
                          />
                        </div>
                        {errors.from && (
                          <small className="ml-2 text-danger">
                            {errors.from.type === "required" &&
                              "Por favor, preencha todos os campos!"}
                          </small>
                        )}
                        {errors.to && (
                          <small className="ml-2 text-danger">
                            {errors.to.type === "required" &&
                              "Por favor, preencha todos os campos!"}
                          </small>
                        )}
                        <small style={{ color: "var(--titles) !important" }}>
                          OBS: Tempo em minutos
                        </small>
                        <button
                          type="submit"
                          className="btn btn-block btn-sm btn-primary mt-2 px-2"
                        >
                          Salvar
                        </button>
                      </div>
                    </form>
                  </Dropdown.Menu>
                </Dropdown>
                {user.plan_id > 2 && user.botty_token ? (
                  <Button
                    className="i-false sharp rounded-circle"
                    variant="light"
                    onClick={() => setShowWhatsappModal(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-brand-whatsapp"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                      <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
                    </svg>
                  </Button>
                ) : (
                  ""
                )}

                {/*  <Dropdown
                  className="nav-item dropdown notification_dropdown"
                  as="li"
                >
                  <Dropdown.Toggle
                    as="a"
                    variant=""
                    className="nav-link  ai-icon i-false"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <svg
                      width={28}
                      height={28}
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.75 14.8385V12.0463C21.7471 9.88552 20.9385 7.80353 19.4821 6.20735C18.0258 4.61116 16.0264 3.61555 13.875 3.41516V1.625C13.875 1.39294 13.7828 1.17038 13.6187 1.00628C13.4546 0.842187 13.2321 0.75 13 0.75C12.7679 0.75 12.5454 0.842187 12.3813 1.00628C12.2172 1.17038 12.125 1.39294 12.125 1.625V3.41534C9.97361 3.61572 7.97429 4.61131 6.51794 6.20746C5.06159 7.80361 4.25291 9.88555 4.25 12.0463V14.8383C3.26257 15.0412 2.37529 15.5784 1.73774 16.3593C1.10019 17.1401 0.751339 18.1169 0.75 19.125C0.750764 19.821 1.02757 20.4882 1.51969 20.9803C2.01181 21.4724 2.67904 21.7492 3.375 21.75H8.71346C8.91521 22.738 9.45205 23.6259 10.2331 24.2636C11.0142 24.9013 11.9916 25.2497 13 25.2497C14.0084 25.2497 14.9858 24.9013 15.7669 24.2636C16.548 23.6259 17.0848 22.738 17.2865 21.75H22.625C23.321 21.7492 23.9882 21.4724 24.4803 20.9803C24.9724 20.4882 25.2492 19.821 25.25 19.125C25.2486 18.117 24.8998 17.1402 24.2622 16.3594C23.6247 15.5786 22.7374 15.0414 21.75 14.8385ZM6 12.0463C6.00232 10.2113 6.73226 8.45223 8.02974 7.15474C9.32723 5.85726 11.0863 5.12732 12.9212 5.125H13.0788C14.9137 5.12732 16.6728 5.85726 17.9703 7.15474C19.2677 8.45223 19.9977 10.2113 20 12.0463V14.75H6V12.0463ZM13 23.5C12.4589 23.4983 11.9316 23.3292 11.4905 23.0159C11.0493 22.7026 10.716 22.2604 10.5363 21.75H15.4637C15.284 22.2604 14.9507 22.7026 14.5095 23.0159C14.0684 23.3292 13.5411 23.4983 13 23.5ZM22.625 20H3.375C3.14298 19.9999 2.9205 19.9076 2.75644 19.7436C2.59237 19.5795 2.50014 19.357 2.5 19.125C2.50076 18.429 2.77757 17.7618 3.26969 17.2697C3.76181 16.7776 4.42904 16.5008 5.125 16.5H20.875C21.571 16.5008 22.2382 16.7776 22.7303 17.2697C23.2224 17.7618 23.4992 18.429 23.5 19.125C23.4999 19.357 23.4076 19.5795 23.2436 19.7436C23.0795 19.9076 22.857 19.9999 22.625 20Z"
                        fill="#4C8147"
                      />
                    </svg>
                  
                    <span className="badge light text-white bg-primary rounded-circle">
                      {newOrders.length}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right" className="mt-2">
                    <PerfectScrollbar className="widget-media dz-scroll p-3 height380 ps z-index999">
                      <ul className="timeline">
                        {newOrders.length > 0 ? (
                          newOrders.map((value, index) => {
                            return (
                              <Link
                                to={{ pathname: "/order-details/" + value.id }}
                                key={index}
                              >
                                <li>
                                  <div className="timeline-panel pb-0">
                                    <div className="media-body">
                                      <small className="d-block w-100 text-right">
                                        {new Date(
                                          value.created_at
                                        ).toLocaleString("pt-BR")}
                                      </small>
                                      <span className="badge light text-white bg-primary badge-sm mb-2 ml-n1">
                                        Em andamento
                                      </span>
                                      <h6 className="mb-1">
                                        Pedido #{value.id}:
                                      </h6>
                                      <p className="mb-1">{value.name}</p>

                                      <hr></hr>
                                    </div>
                                  </div>
                                </li>
                              </Link>
                            );
                          })
                        ) : (
                          <p>Nenhum pedido disponível no momento.</p>
                        )}
                      </ul>
                      <div
                        className="ps__rail-x"
                        style={{ left: 0, bottom: 0 }}
                      >
                        <div
                          className="ps__thumb-x"
                          tabIndex={0}
                          style={{ left: 0, width: 0 }}
                        />
                      </div>
                      <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                        <div
                          className="ps__thumb-y"
                          tabIndex={0}
                          style={{ top: 0, height: 0 }}
                        />
                      </div>
                    </PerfectScrollbar>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Dropdown className="nav-item dropdown header-profile" as="li">
                  <Dropdown.Toggle
                    as="a"
                    variant=""
                    className="nav-link i-false p-0 c-pointer pointr position-relative"
                  >
                    <img
                      src={
                        user_photo
                          ? Hash("75x75", user_photo.replaceAll('"', ""))
                          : require("../../../images/user.png").default
                      }
                      width={20}
                      alt="profile"
                    />
                    <div className="header-info">
                      <span className="text-black">
                        <strong>
                          {user && user.first_name ? user.first_name : ""}
                        </strong>
                      </span>
                      <p className="fs-12 mb-0">
                        {user && user.superuser_level
                          ? superuser[user.superuser_level]
                          : ""}
                      </p>
                    </div>
                    <StoreStatus store_id={user.store_id} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="right" className="mt-2">
                    <Link
                      to={`/user-details/${
                        user && user.user_id ? user.user_id : ""
                      }`}
                      className="dropdown-item ai-icon"
                    >
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ml-2">Perfil</span>
                    </Link>
                    <Link to="/email-inbox" className="dropdown-item ai-icon">
                      <svg
                        id="icon-inbox"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-success"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                        <polyline points="22,6 12,13 2,6" />
                      </svg>
                      <span className="ml-2">Mensagens</span>
                    </Link>
                    <Link
                      to="#"
                      className="dropdown-item ai-icon my-plan"
                      onClick={() => setShowPlansModal(true)}
                    >
                      <i
                        className="las la-crown text-success"
                        style={{ fontSize: 20 }}
                      ></i>
                      <span className="ml-2">Meu plano</span>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => Logout()}
                      className="dropdown-item ai-icon"
                    >
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ml-2">Sair</span>
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            )}
          </div>
        </nav>
      </div>
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
      {showWhatsappModal && <ModalComponent props={modalProps} />}
    </div>
  );
};

export default Header;
