import React, { useEffect, useState, useRef, useContext } from "react";
import { Create, Update } from '../services/ReservationsServices'
import { GetAll } from '../services/CategoriesServices'
import { useForm, Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { TimePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DefaultLoader from '../jsx/components/Loader'

import { DateTimeToISOFormat } from "../helpers/Helpers";
import UserContext from "../contexts/UserContext";

export default function ReservationDetails(props) {
  const { user } = useContext(UserContext)
  const { register, handleSubmit, watch, setError, setValue, control, formState: { errors } } = useForm();

  const [reservation, setReservation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [erros, setErros] = useState(null)
  const [status, setStatus] = useState('');

  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState('1');

  const [selectedTime, setSelectedTime] = useState("2018-01-01T00:00:00.000Z");

  const radios = [
    { name: 'Mesas', value: '1' },
    { name: 'Produtos', value: '2' },
  ];



  useEffect(() => {

  }, []);


  const onSubmit = (data) => {

  }

  return (
    <div className="basic-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row flex-column">
          <h5>Nova reserva de</h5>
          <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={radio.value === '1' ? 'outline-primary' : 'outline-info'}
                name="radio"
                className="radio-group"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <div className="form-row mt-3">
          <label>Permitir reservar:</label>
          <div className="d-flex flex-wrap mt-1 ml-2">
            <div className="custom-control custom-checkbox mr-3 my-1">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={false}
                id={'monday'}
                defaultValue=""
                {...register("monday")}
              //onClick={() => setDiscountBy('value')}
              />
              <label className="custom-control-label" htmlFor={`monday`}>
                Segunda-feira
              </label>
            </div>
            <div className="custom-control custom-checkbox mr-3 my-1">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={false}
                id={'tuesday'}
                defaultValue=""
                {...register("tuesday")}
              //onClick={() => setDiscountBy('value')}
              />
              <label className="custom-control-label" htmlFor={`tuesday`}>
                Terça-feira
              </label>
            </div>
            <div className="custom-control custom-checkbox mr-3 my-1">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={false}
                id={'wednesday'}
                defaultValue=""
                {...register("wednesday")}
              //onClick={() => setDiscountBy('value')}
              />
              <label className="custom-control-label" htmlFor={`wednesday`}>
                Quarta-feira
              </label>
            </div>
            <div className="custom-control custom-checkbox mr-3 my-1">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={false}
                id={'thursday'}
                defaultValue=""
                {...register("thursday")}
              //onClick={() => setDiscountBy('value')}
              />
              <label className="custom-control-label" htmlFor={`thursday`}>
                Quinta-feira
              </label>
            </div>
            <div className="custom-control custom-checkbox mr-3 my-1">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={false}
                id={'friday'}
                defaultValue=""
                {...register("friday")}
              //onClick={() => setDiscountBy('value')}
              />
              <label className="custom-control-label" htmlFor={`friday`}>
                Sexta-feira
              </label>
            </div>
            <div className="custom-control custom-checkbox mr-3 my-1">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={false}
                id={'saturday'}
                defaultValue=""
                {...register("saturday")}
              //onClick={() => setDiscountBy('value')}
              />
              <label className="custom-control-label" htmlFor={`saturday`}>
                Sábado
              </label>
            </div>
            <div className="custom-control custom-checkbox mr-3 my-1">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={false}
                id={'sunday'}
                defaultValue=""
                {...register("sunday")}
              //onClick={() => setDiscountBy('value')}
              />
              <label className="custom-control-label" htmlFor={`sunday`}>
                Domingo
              </label>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <label>Reservas até às:</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              ampm={false}
              variant="inline"
              label=""
              value={selectedTime}
              onChange={setSelectedTime}
              invalidDateMessage="Formato de hora inválido"
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="form-row mt-3">
          <div className="form-group col-md-12">
            <label>Limite de reservas no dia</label>
            <input
              {...register("dayLimit", { required: true })}
              className="form-control text-black"
              defaultValue={''}
              type={"number"}
            />
            {errors.dayLimit &&
              <small className="ml-2 text-danger"> {errors.dayLimit.type === 'required' && 'Por favor, preencha este campo!'}</small>}
          </div>
        </div>
        <Button type="submit" className="btn btn-block btn-primary">
          {isLoading ? <DefaultLoader color={'white'} /> : 'Salvar'}
        </Button>
      </form>
    </div>
  );
};
