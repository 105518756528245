import React, { useEffect, useState, useMemo, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownButton,
  Button,
  Card,
  Col,
  Tab,
  Tabs,
} from "react-bootstrap";
import Swal from "sweetalert2";

import {
  GetAll,
  ChangeStatus,
  Delete,
  EnableOnlinePayments,
  ActivatePixPayment,
} from "../services/PaymentServices";
import { IMAGES_URL } from "../api/Urls";
import DefaultLoader from "../jsx/components/Loader";
import AlertResponses from "../jsx/components/Alert";
import ErrorsComponent from "../jsx/components/Errors";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import UserContext from "../contexts/UserContext";
import { AppSettings } from "../App";
import { UpdateStore } from "../services/ConfigurationsServices";
import Tutorial from "../jsx/components/Tutorial";

export default function PaymentMethods() {
  const { user } = useContext(UserContext);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const [progress, setProgress] = useState(false);

  const enableBtn = useRef();

  //TODO: no datatable nao filtra pelo campo created_at por causa do formato da data q no campo é 2021-08-03 e o user
  // digita 30/08/2021
  useEffect(() => {
    GetAll(setPaymentMethods, setErrors, setIsLoading);
  }, []);

  const status = {
    0: {
      id: 0,
      label: "Desativada",
      type: "light",
    },
    1: {
      id: 1,
      label: "Ativa",
      type: "success",
    },
  };

  const handleActivatePixPayment = async () => {
    let payload = {
      activate: true,
    };
    setProgress(true);
    try {
      let res = await ActivatePixPayment(payload);
      console.log(res);
      setNotify({
        title: "Sucesso!",
        message: "O pagamento online via pix foi ativado com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.href = "/payment-methods";
      }, 3500);
    } catch (error) {
      console.log(error);
      setNotify({
        title: "Oops!",
        message: error,
        type: "error",
        visible: true,
      });
    }
    setProgress(false);
  };

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <>
          <Tutorial
            title={"Formas de Pagamento"}
            videoId={"N0ye2Y6PmC0"}
            padding={"0 30px"}
            classes={"my-3"}
          />
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
          {progress && <ProgressBarComponent />}

          <Tabs defaultActiveKey="payOnDelivery" className="mb-3">
            <Tab eventKey="payOnDelivery" title="Pagamento na entrega">
              <>
                {errors && <ErrorsComponent args={errors} />}
                <div className="item mt-4">
                  <div className="col-xl-12 col-xxl-12 col-sm-12 page-header justify-content-end">
                    <Button
                      className="i-false btn btn-primary mb-4"
                      href="/payment-method-details/"
                    >
                      <span>ADICIONAR</span>
                    </Button>
                  </div>
                </div>

                <>
                  {paymentMethods.map(
                    (item) =>
                      item.name !== "Pix" && (
                        <Col xl="3" key={item.id} className="d-inline-block">
                          <Card className="mb-3 align-items-center">
                            <img
                              className="card-img-top img-fluid mt-3"
                              src={
                                require(`../images/payment_methods/${item.thumb}`)
                                  .default
                              }
                              style={styles.thumb}
                            />
                            <Card.Header className="m-auto flex-column px-1">
                              <Card.Title>
                                <Link
                                  to={{
                                    pathname:
                                      "/payment-method-details/" + item.id,
                                    methodsAvailable: paymentMethods,
                                  }}
                                >
                                  {item.name}
                                </Link>
                              </Card.Title>
                              <small>{item.operation}</small>
                            </Card.Header>
                            <Card.Body className="card-body d-flex justify-content-between align-items-baseline">
                              <DropdownButton
                                variant=""
                                style={styles.dropdown}
                                title={
                                  item.status === 1 ? "Ativa" : "Desativada"
                                }
                                className={`badge badge-${
                                  item.status === 1 ? "success" : "light"
                                } badge-sm light badge-delivery-status badge-${
                                  item.id
                                }`}
                                onSelect={(e) => {
                                  ChangeStatus(
                                    { id: item.id, status: e },
                                    setNotify
                                  ).then((res) => {
                                    if (res) {
                                      document
                                        .querySelector(`.badge-${item.id}`)
                                        .classList.remove(
                                          `badge-${
                                            item.status === 1
                                              ? "success"
                                              : "light"
                                          }`
                                        );
                                      document
                                        .querySelector(`.badge-${item.id}`)
                                        .classList.add(
                                          `badge-${status[e].type}`
                                        );
                                      document.querySelector(
                                        `.badge-${item.id} button`
                                      ).innerText = status[e].label;
                                    }
                                  });
                                }}
                              >
                                {Object.values(status).map((value, i) => (
                                  <Dropdown.Item key={i} eventKey={value.id}>
                                    {value.label}
                                  </Dropdown.Item>
                                ))}
                              </DropdownButton>
                              <DropdownButton
                                variant=""
                                style={styles.dropdown}
                                title="Ações"
                                className={`badge badge-primary badge-sm light badge-delivery-status ml-4`}
                                onSelect={(e) => {}}
                              >
                                <Link
                                  to={{
                                    pathname: `/payment-method-details/${item.id}`,
                                    order: item,
                                  }}
                                  className="text-danger dropdown-item"
                                >
                                  Editar
                                </Link>
                                <Dropdown.Item
                                  onClick={() =>
                                    Delete(item.id, setNotify, setProgress)
                                  }
                                >
                                  Excluir
                                </Dropdown.Item>
                              </DropdownButton>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                  )}
                  {paymentMethods.length === 0 && (
                    <div className="col-xl-12 col-xxl-12 col-sm-12">
                      <h5 className="ml-3">
                        Você ainda não possuí nenhuma forma de pagamento
                        cadastrada.
                      </h5>
                    </div>
                  )}
                </>
              </>
            </Tab>

            <Tab
              eventKey="payOnApp"
              title={
                <div>
                  {user?.plan_id < 3 ? (
                    <i
                      className="ti-lock text-primary mr-2"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}{" "}
                  Pagamento na plataforma
                </div>
              }
              disabled={user?.plan_id < 3 ? true : false}
            >
              <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12 p-4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="d-flex justify-content-between align-items-center">
                        Pagamento online via cartão de crédito{" "}
                        {AppSettings.store.payment_on_app ? (
                          <span className="badge badge-success ml-2">
                            Ativo
                          </span>
                        ) : (
                          ""
                        )}
                      </h4>

                      {!AppSettings.store.payment_on_app ? (
                        <Button
                          className="i-false btn  btn-primary"
                          ref={(e) => (enableBtn.current = e)}
                          onClick={() => {
                            if (user.plan_id > 3) {
                              enableBtn.current.innerText = "Aguarde...";
                              enableBtn.current.disabled = true;
                              EnableOnlinePayments(
                                AppSettings.store,
                                setNotify,
                                setErrors,
                                setProgress
                              );
                            } else {
                              Swal.fire({
                                title: "Atenção",
                                html: `Ao continuar, você concorda em ativar sua conta de pagamentos online e concorda que o Banco digital Asaas ficará responsável pela gestão de seus recebimentos.`,
                                icon: "info",
                                showConfirmButton: true,
                                showCancelButton: true,
                                confirmButtonText: "Entendi e concordo",
                                cancelButtonText: "Não concordo",
                              }).then((value) => {
                                if (value.isConfirmed) {
                                  enableBtn.current.innerText = "Aguarde...";
                                  enableBtn.current.disabled = true;
                                  EnableOnlinePayments(
                                    AppSettings.store,
                                    setNotify,
                                    setErrors,
                                    setProgress
                                  );
                                }
                              });
                            }
                          }}
                        >
                          <span>Ativar</span>
                        </Button>
                      ) : (
                        <Button
                          className="i-false btn  btn-primary"
                          ref={(e) => (enableBtn.current = e)}
                          onClick={() => {
                            UpdateStore(
                              AppSettings.store.id,
                              { payment_on_app: 0 },
                              null,
                              true,
                              true
                            ).then((res) => {
                              setNotify({
                                title: "Sucesso!",
                                message:
                                  "O pagamento online foi desativado com sucesso!",
                                type: "success",
                                visible: true,
                              });
                              setTimeout(() => {
                                window.location.href = "/payment-methods";
                              }, 3500);
                            });
                          }}
                        >
                          <span>Desativar</span>
                        </Button>
                      )}
                    </div>
                    {!AppSettings.store.payment_on_app ? (
                      <div className="card-body">
                        <p>
                          Caro cliente, O Pralevar possuí parceria com o banco
                          digital Asaas para a gestão de pagamentos online.{" "}
                          <br></br>
                          <b>
                            {" "}
                            O Asaas é autorizado e regulamentado pelo Banco
                            Central.
                          </b>
                        </p>
                        <h5>Informações</h5>
                        <p>
                          <b>1º</b> - Ao clicar em ativar, será criada
                          automaticamente uma conta digital no Asaas.<br></br>
                          <b>2º</b> - Os dados da conta e e-mail de acesso,
                          serão os mesmos dados cadastrados na loja matriz pelo
                          administrador para os planos Básico, Profissional e
                          Rede. Para o plano Franquia, cada franquia terá seu
                          próprio acesso. <br></br>
                          <b>3º</b> - Após habilitado, a nova opção de pagamento
                          será mostrada no seu site automaticamente. O
                          administrador poderá desativar o pagamento online
                          quando desejar.{" "}
                        </p>
                        <h5>Taxas e Bandeiras</h5>
                        <p>
                          <b>1º</b> - Taxa para pagamento online via{" "}
                          <b>cartão de crédito: 3.61% + R$0,49</b> por transação
                          aprovada para recebimento em 30 dias. <br></br>
                          <b>2º</b> - Taxa de antecipação de pagamentos no
                          crédito em 2 dias <b>2%</b>. <br></br>
                          <b>3º</b> - Aceitamos cartões de crédito nacionais e
                          internacionais, das bandeiras: Visa, Mastercard, ELO,
                          American Express, Discover, Hiper.<br></br>
                          <b>4º</b> - No <b>plano básico e profissional</b>{" "}
                          haverá uma taxa de cadastro do Asaas no valor de
                          R$12,90 para ativar seus pagamentos online.
                          <small>
                            {" "}
                            *Esta taxa de cadastro é referente a verificação KYC
                            do Asaas que garante total segurança para você e seu
                            cliente.
                          </small>
                        </p>
                      </div>
                    ) : (
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center ">
                          <h4 className="">
                            Pagamento online via Pix{" "}
                            {AppSettings.store.pix_payment ? (
                              <span className="badge badge-success">Ativo</span>
                            ) : (
                              ""
                            )}
                          </h4>

                          {!AppSettings.store.pix_payment ? (
                            <Button
                              className="i-false btn  btn-primary"
                              ref={(e) => (enableBtn.current = e)}
                              onClick={handleActivatePixPayment}
                            >
                              <span>Ativar</span>
                            </Button>
                          ) : (
                            <Button
                              className="i-false btn  btn-primary"
                              ref={(e) => (enableBtn.current = e)}
                              onClick={() => {
                                setProgress(true);
                                UpdateStore(
                                  AppSettings.store.id,
                                  { pix_payment: 0 },
                                  null,
                                  true,
                                  true
                                ).then((res) => {
                                  setNotify({
                                    title: "Sucesso!",
                                    message:
                                      "O pagamento online via pix foi desativado com sucesso!",
                                    type: "success",
                                    visible: true,
                                  });
                                  setTimeout(() => {
                                    window.location.href = "/payment-methods";
                                  }, 3500);
                                });
                                setProgress(false);
                              }}
                            >
                              <span>Desativar</span>
                            </Button>
                          )}
                        </div>

                        <hr></hr>
                        <Button
                          className="i-false btn btn-info"
                          href="https://www.asaas.com/dashboard/index"
                          target="_blank"
                        >
                          <span>Acessar Painel Financeiro</span>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </>
      )}
    </>
  );
}

const styles = {
  thumb: {
    height: 80,
    maxWidth: 80,
    objectFit: "contain",
  },
};
