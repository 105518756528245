import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import DefaultLoader from "../Loader";
import { UpdateStore } from "../../../services/ConfigurationsServices";
import { AppSettings } from "../../../App";
import NumberFormat from "react-number-format";
import defaultRingtone from "../../../sounds/sound1.mp3";

export default function ProductTypes(props) {
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const submit = useRef();
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const handleClickVoiceApi = () => {
    window.postMessage({ playVoiceRingtone: true });
  };

  const handleClickDefaultRingtone = () => {
    const audio = new Audio(defaultRingtone);
    audio.play();
  };

  const onSubmit = () => {
    let toDelivery = document.querySelector("#to-delivery").checked;
    let takeAway = document.querySelector("#take-away").checked;
    let minimumOrder = document.querySelector("#minimumOrder").value;
    let ringtone = document.querySelector(".ringtone:checked").value;

    if (!toDelivery && !takeAway) {
      setErrors(true);
      setTimeout(() => {
        setErrors(false);
      }, 2500);
    }

    if (minimumOrder) {
      minimumOrder = parseFloat(
        minimumOrder.replace("R$", "").replace(",", ".")
      );
    }

    let data = {
      order_configs: {
        take_away: takeAway ? 1 : 0,
        to_delivery: toDelivery ? 1 : 0,
        minimum_order: minimumOrder,
      },
      ringtone_type: ringtone
    };
    UpdateStore(AppSettings.store.id, data, null, true, true)
      .then((res) => {
        props.setNotify({
          title: "Sucesso!",
          message: "Dados atualizados com sucesso!",
          type: "success",
          visible: true,
        });
        props.setShowModal(false);
        setTimeout(() => {
          window.location.assign("/orders");
        }, 2000);
      })
      .catch((error) => {
        props.setNotify({
          title: "Oops!",
          message: error,
          type: "error",
          visible: true,
        });
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="">
            <label>Toque para novos pedidos</label>
            <div className="d-flex justify-content-between align-items-center">
              <div className="custom-control custom-checkbox ml-1">
                <input
                  type="radio"
                  className="custom-control-input ringtone"
                  id={`default-ringtone`}
                  name="ringtone"
                  defaultChecked={
                    AppSettings?.store?.configs[0]?.ringtone_type === 1
                      ? true
                      : false
                  
                  }
                  value={1}
                  // {...register("requiredChoice")}
                  // onChange={(e) => variation.data.requiredChoice = e.target.checked}
                  // defaultValue={variation.data.requiredChoice}
                  // defaultChecked={variation.data.requiredChoice ? true : false}
                />
                <label className="custom-control-label" htmlFor={`default-ringtone`}>
                  Padrão
                </label>
              </div>
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={handleClickDefaultRingtone}
              >
                <i className="fa fa-play-circle fs-20 text-red mr-2"></i>
                <p className="mb-0">Testar</p>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="custom-control custom-checkbox ml-1">
                <input
                  type="radio"
                  className="custom-control-input ringtone"
                  id={`voice-ringtone`}
                  name="ringtone"
                  defaultChecked={
                    AppSettings?.store?.configs[0]?.ringtone_type === 2
                      ? true
                      : false
                  }
                  value={2}
                />
                <label className="custom-control-label" htmlFor={`voice-ringtone`}>
                  Api de Voz
                </label>
              </div>
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={handleClickVoiceApi}
              >
                <i className="fa fa-play-circle fs-20 text-red mr-2"></i>
                <p className="mb-0">Testar</p>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="mt-3">
            <p>Tipos de pedido aceitos na loja</p>

            <div className="custom-control custom-checkbox ml-1">
              <input
                type="checkbox"
                className="custom-control-input categories-to-copy"
                id={`to-delivery`}
                defaultChecked={AppSettings?.store?.order_configs?.to_delivery}
                // {...register("requiredChoice")}
                // onChange={(e) => variation.data.requiredChoice = e.target.checked}
                // defaultValue={variation.data.requiredChoice}
                // defaultChecked={variation.data.requiredChoice ? true : false}
              />
              <label className="custom-control-label" htmlFor={`to-delivery`}>
                Entrega
              </label>
            </div>
            <div className="custom-control custom-checkbox ml-1">
              <input
                type="checkbox"
                className="custom-control-input categories-to-copy"
                id={`take-away`}
                defaultChecked={AppSettings?.store?.order_configs?.take_away}
              />
              <label className="custom-control-label" htmlFor={`take-away`}>
                Retirada
              </label>
            </div>
            {errors && (
              <small className="mt-3 text-danger d-block">
                Por favor, selecione pelo menos uma opção!
              </small>
            )}
          </div>
          <hr></hr>
          <div className="mt-3">
            <label>Pedido mínimo</label>
            <NumberFormat
              displayType="input"
              decimalScale={2}
              defaultValue={
                AppSettings?.store?.order_configs?.minimum_order
                  ? AppSettings?.store?.order_configs?.minimum_order
                  : 0
              }
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$"
              fixedDecimalScale={true}
              id="minimumOrder"
              className="form-control text-black"
            />
          </div>

          <Button
            type="button"
            className="btn btn-block btn-primary mt-3"
            onClick={onSubmit}
            ref={(e) => (submit.current = e)}
          >
            {isLoading ? <DefaultLoader color={"white"} /> : "Salvar"}
          </Button>
        </div>
      </div>
    </div>
  );
}
