import React, { useEffect, useState, useContext } from "react";

import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

import {
  ConnectWhatsappAccount,
  GetWhatsappQrCode,
  ReconnectWhatsappAccount,
} from "../services/WaBottyServices";
import UserContext from "../contexts/UserContext";
import { timer } from "../helpers/Helpers";

export default function WhatsappConnect(props) {
  const { user } = useContext(UserContext);

  const [whatsappQrCode, setWhatsappQrCode] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    }
  }, []);

  const handleConnect = () => {
    setIsLoading(true);
    ConnectWhatsappAccount({ uid: props.data.uid })
      .then((result) => {
        if (result.status === 200 && result.data?.qrimagelink) {
          GetWhatsappQrCode({ qrstring: result.data.qrstring }).then(
            (result) => {
              setTimeout(() => {
                timer(15, "#timer");
              }, 250);

              let base64Image = Buffer.from(result, "binary").toString(
                "base64"
              );
              setWhatsappQrCode(base64Image);

              setTimeout(() => {
                window.location.reload();
              }, 16000);
            }
          );
        }
        //
      })
      .catch((err) => {
        Swal.fire(
          "error",
          "Ocorreu um erro inesperado ao tentar gerar o QrCode para conexão, por favor, tente novamente. Se o problema persistir, contate nosso setor de suporte.",
          "error"
        );
      });
  };

  const handleReconnect = () => {
    setIsLoading(true);
    ReconnectWhatsappAccount({ uid: props.data.uid, unique: props.data.unique })
      .then((result) => {
        if (result.status === 200 && result.data?.qrimagelink) {
          GetWhatsappQrCode({ qrstring: result.data.qrstring }).then(
            (result) => {
              setTimeout(() => {
                timer(15, "#timer");
              }, 250);

              let base64Image = Buffer.from(result, "binary").toString(
                "base64"
              );
              setWhatsappQrCode(base64Image);

              setTimeout(() => {
                window.location.reload();
              }, 16000);
            }
          );
        }
        //
      })
      .catch((err) => {
        Swal.fire(
          "error",
          "Ocorreu um erro inesperado ao tentar gerar o QrCode para conexão, por favor, tente novamente. Se o problema persistir, contate nosso setor de suporte.",
          "error"
        );
      });
  };

  return (
    <div id="connect-whatsapp-account">
      {!whatsappQrCode ? (
        <div>
          {isLoading ? (
            <div>
              <div className="spinner-border text-primary mb-3" role="status">
                <span className="sr-only"></span>
              </div>
              <p>Gerando o QrCode para conexão...</p>
            </div>
          ) : (
            <div>
              <p className="mt-3">
                {props.connectOrReconnect.current == "connect"
                  ? "Clique no botão para gerar o QrCode de conexão com o WhatsApp."
                  : "Clique no botão reconectar sua conta."}
                <b>
                  <br></br> Escaneie o QRCode no aplicativo.
                </b>{" "}
                Você tem apenas 15 segundos para escanear o QRCode.
              </p>
              <Button
                type="button"
                className="mt-3"
                onClick={
                  props.connectOrReconnect.current === "connect"
                    ? handleConnect
                    : handleReconnect
                }
              >
                Gerar QrCode
              </Button>
            </div>
          )}
        </div>
      ) : (
        <>
          <img src={`data:image/png;base64,${whatsappQrCode}`} alt="" />
          <div className="mt-4 mb-3">
            <h1 id="timer" className=""></h1>
          </div>
          <p className="mt-3">
            Faça a leitura do QrCode utilizando o aplicativo do Whatsapp.
          </p>
        </>
      )}
    </div>
  );
}
