import React, { useEffect, useState, useRef, useContext } from "react";
import NumberFormat from "react-number-format";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";

import ActivityLineChart from "../jsx/components/Sego/Analytics/ActivityLineChart";
import ThreeCharts from "../jsx/components/Charts/ThreeCharts";
import TwoCharts from "../jsx/components/Charts/TwoCharts";
import { GetDataReport } from "../services/AnalyticsServices";
import DefaultLoader from "../jsx/components/Loader";
import AlertResponses from "../jsx/components/Alert";

// Svg
import IcStat3 from "../images/svg/ic_stat3.svg";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";
import Tutorial from "../jsx/components/Tutorial";
import Home from "./Home";
import UserContext from "../contexts/UserContext";
import { Hash } from "../helpers/Helpers";
import { Nav, Tab } from "react-bootstrap";

const RadialBarChart = loadable(() =>
  pMinDelay(import("../jsx/components/Sego/Home/RadialBarChart"), 1000)
);

export default function Analytics() {
  const { user } = useContext(UserContext);
  const orders = useRef(null);
  const users = useRef(null);
  const payments = useRef(null);
  const billingAndOrders = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  let ordersCounter = 1;
  const usersTotal = useRef([]);
  const usersTotalByMonth = useRef([]);
  const usersMonth = useRef([]);

  const billingTotal = useRef([]);
  const billingTotalByMonth = useRef([]);
  const billingMonths = useRef([]);

  const ordersTotal = useRef([]);
  const ordersTotalByMonth = useRef([]);
  const ordersMonths = useRef([]);
  const mediumPriceByMonth = useRef([]);
  const mediumPriceByAllMonths = useRef([]);

  const dataOrders = useRef(null);
  const ordersByMonth = useRef([]);
  const ordersByWeek = useRef([]);
  const ordersByDay = useRef([]);
  const mostOrders = useRef([]);

  const creditCardValues = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const debitCardValues = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const cashValues = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const foodVoucherValues = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const mealTicketValues = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  let counter = 0;

  const months = {
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dez",
  };

  useEffect(() => {
    GetDataReport()
      .then((res) => {
        orders.current = res.most_orders;
        users.current = res.users;
        payments.current = res.payment_analytics;
        billingAndOrders.current = res.billing;

        dataOrders.current = res.orders;
        ordersByMonth.current = res.orders_by_month;
        ordersByWeek.current = res.orders_by_week;
        ordersByDay.current = res.orders_by_day;
        mostOrders.current = res.most_orders;

        if (payments.current.length > 0) {
          payments.current.map((value) => {
            if (value.operation === "Crédito") {
              creditCardValues.current[value.month - 1] = value.billing_by_month
                ? parseFloat(value.billing_by_month.replace(",", "."))
                : 0;
            }
            if (value.operation === "Débito") {
              debitCardValues.current[value.month - 1] = value.billing_by_month
                ? parseFloat(value.billing_by_month.replace(",", "."))
                : 0;
            }

            if (value.operation === "À vista") {
              cashValues.current[value.month - 1] = value.billing_by_month
                ? parseFloat(value.billing_by_month.replace(",", "."))
                : 0;
            }
            if (value.operation === "Alimentação") {
              foodVoucherValues.current[value.month - 1] =
                value.billing_by_month
                  ? parseFloat(value.billing_by_month.replace(",", "."))
                  : 0;
            }
            if (value.operation === "Refeição") {
              mealTicketValues.current[value.month - 1] = value.billing_by_month
                ? parseFloat(value.billing_by_month.replace(",", "."))
                : 0;
            }
          });
        }

        /* Users */
        let uTotal = [];
        let uMonth = [];
        if (users.current.length > 0) {
          users.current.map((value) => {
            uTotal.push(value.users_total);
            uMonth.push(months[value.month]);
          });

          usersTotalByMonth.current.push(uTotal);
          usersMonth.current.push(uMonth);
          usersTotal.current = uTotal.reduce(
            (prev, current) => parseInt(prev) + parseInt(current)
          );
          /* End */
        }

        /* Total billing */
        let bTotal = [];
        let bMonth = [];
        let mPrice = [];
        let oTotal = [];
        let oMonth = [];
        if (billingAndOrders.current.length > 0) {
          billingAndOrders.current.map((value) => {
            if (value.billing_by_month)
              bTotal.push(parseFloat(value.billing_by_month.replace(",", ".")));
            if (value.medium_price_by_month)
              mPrice.push(
                parseFloat(value.medium_price_by_month.replace(",", "."))
              );
            bMonth.push(months[value.month]);
            oTotal.push(value.orders_total);
            oMonth.push(months[value.month]);
          });
          billingTotalByMonth.current.push(bTotal);
          billingMonths.current.push(bMonth);
          billingTotal.current =
            bTotal.length === 0
              ? 0
              : bTotal.reduce(
                  (prev, current) => parseFloat(prev) + parseFloat(current)
                );
          /*  */

          /* Calculo do ticke medio feito: soma do ticket médio mensal dividido pelo total de meses */
          mediumPriceByMonth.current.push(mPrice);
          ordersTotalByMonth.current.push(oTotal);
          ordersMonths.current.push(oMonth);
          ordersTotal.current =
            oTotal.length === 0
              ? 0
              : oTotal.reduce(
                  (prev, current) => parseInt(prev) + parseInt(current)
                );
          mediumPriceByAllMonths.current =
            billingTotal.current / ordersTotal.current;
        }
      })
      .catch((e) => {
        console.log(e);
        setErrors({ title: "Oops!", message: ERROR_MESSAGES.dataReport.get });
      })
      .finally((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <div>
          <Tutorial
            title={"Relatórios"}
            videoId={"an1H9Mc4kRk"}
            classes={"my-3"}
          />
          <Home />
          {user?.plan_id > 1 ? (
            <div className="row">
              <div className="col-xl-7 col-md-7 col-sm-12">
                <div className="card" style={{height: 'fit-content'}}>
                  <Tab.Container defaultActiveKey="monthly">
                    <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                      <div className="mr-auto pr-3">
                        <h4 className="text-black fs-20">Resumo dos pedidos</h4>
                      </div>
                      <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                        <Nav as="ul" className="nav nav-tabs" role="tablist">
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              className="nav-link"
                              eventKey="monthly"
                              role="tab"
                            >
                              Mensal
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              className="nav-link"
                              eventKey="weekly"
                              role="tab"
                            >
                              Semanal
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              className="nav-link"
                              eventKey="today"
                              role="tab"
                            >
                              Hoje
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </div>
                    <div className="card-body">
                      <Tab.Content className="tab-content">
                        <Tab.Pane
                          eventKey="monthly"
                          className="tab-pane fade"
                          id="Monthly"
                        >
                          <div className="row align-items-center">
                            <div className="col-sm-6">
                              <RadialBarChart
                                series={
                                  !ordersByMonth.current ||
                                  !ordersByMonth.current.orders_total_by_month
                                    ? 0
                                    : Math.round(
                                        (ordersByMonth.current
                                          .orders_total_value_by_month /
                                          dataOrders.current
                                            .orders_total_value) *
                                          100
                                      )
                                }
                              />
                              <p className="fs-14 mt-n5 text-center">
                                Percentual do mês em relação ao total de
                                receita.
                              </p>
                            </div>
                            <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                              <h3 className="fs-28 text-black font-w600">
                                <NumberFormat
                                  displayType="text"
                                  decimalScale={2}
                                  defaultValue={
                                    ordersByMonth.current
                                      .orders_total_value_by_month === null
                                      ? 0
                                      : ordersByMonth.current
                                          .orders_total_value_by_month
                                  }
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$"
                                  fixedDecimalScale={true}
                                />
                              </h3>
                              <p className="fs-14">
                                Total de receita do mês atual.
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 mb-md-0 mb-3">
                              <div className="p-3 border rounded">
                                <h3 className="fs-32 text-black font-w600 mb-1">
                                  {ordersByMonth.current.orders_total_by_month}
                                </h3>
                                <span className="fs-16">Pedidos</span>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-md-0 mb-3">
                              <div className="p-3 border rounded">
                                <h3 className="fs-32 text-black font-w600 mb-1">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      ordersByMonth.current
                                        .medium_price_by_month === null
                                        ? 0
                                        : ordersByMonth.current
                                            .medium_price_by_month
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <span className="fs-16">Ticket Médio</span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="weekly"
                          className="tab-pane fade"
                          id="Weekly"
                        >
                          <div className="row align-items-center">
                            <div className="col-sm-6">
                              <RadialBarChart
                                series={
                                  ordersByWeek.current
                                    .orders_total_value_by_week !== null
                                    ? Math.round(
                                        (ordersByWeek.current
                                          .orders_total_value_by_week /
                                          ordersByMonth.current
                                            .orders_total_value_by_month) *
                                          100
                                      )
                                    : 0
                                }
                              />
                              <p className="fs-14 mt-n5 text-center">
                                Percentual da semana em relação ao total do mês.
                              </p>
                            </div>
                            <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                              <h3 className="fs-28 text-black font-w600">
                                <NumberFormat
                                  displayType="text"
                                  decimalScale={2}
                                  defaultValue={
                                    ordersByWeek.current
                                      .orders_total_value_by_week === null
                                      ? 0
                                      : ordersByWeek.current
                                          .orders_total_value_by_week
                                  }
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$"
                                  fixedDecimalScale={true}
                                />
                              </h3>
                              <p className="fs-14">
                                Total de receita dos últimos 7 dias.
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 mb-md-0 mb-3">
                              <div className="p-3 border rounded">
                                <h3 className="fs-32 text-black font-w600 mb-1">
                                  {ordersByWeek.current.orders_total_by_week}
                                </h3>
                                <span className="fs-18">Pedidos</span>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-md-0 mb-3">
                              <div className="p-3 border rounded">
                                <h3 className="fs-32 text-black font-w600 mb-1">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      ordersByWeek.current
                                        .medium_price_by_week === null
                                        ? 0
                                        : ordersByWeek.current
                                            .medium_price_by_week
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <span className="fs-18">Ticket Médio</span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="today"
                          className="tab-pane fade"
                          id="Today"
                        >
                          <div className="row align-items-center">
                            <div className="col-sm-6">
                              <RadialBarChart
                                series={
                                  ordersByDay.current
                                    .orders_total_value_by_day !== null
                                    ? Math.round(
                                        (ordersByDay.current
                                          .orders_total_value_by_day /
                                          ordersByWeek.current
                                            .orders_total_value_by_week) *
                                          100
                                      )
                                    : 0
                                }
                              />
                              <p className="fs-14 mt-n5 text-center">
                                Percentual do dia em relação ao total da semana.
                              </p>
                            </div>
                            <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                              <h3 className="fs-28 text-black font-w600">
                                <NumberFormat
                                  displayType="text"
                                  decimalScale={2}
                                  defaultValue={
                                    ordersByDay.current
                                      .orders_total_value_by_day === null
                                      ? 0
                                      : ordersByDay.current
                                          .orders_total_value_by_day
                                  }
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$"
                                  fixedDecimalScale={true}
                                />
                              </h3>

                              <p className="fs-14">Total de receita hoje.</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 mb-md-0 mb-3">
                              <div className="p-3 border rounded">
                                <h3 className="fs-32 text-black font-w600 mb-1">
                                  {ordersByDay.current.orders_total_by_day}
                                </h3>
                                <span className="fs-18">Pedidos</span>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-md-0 mb-3">
                              <div className="p-3 border rounded">
                                <h3 className="fs-32 text-black font-w600 mb-1">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      ordersByDay.current
                                        .medium_price_by_day === null
                                        ? 0
                                        : ordersByDay.current
                                            .medium_price_by_day
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <span className="fs-18">Ticket Médio</span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </div>
              <div className="col-xl-5 col-md-5 col-sm-12">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card trending-menus">
                      <div className="card-header d-sm-flex d-block pb-0 border-0">
                        <div>
                          <h4 className="text-black fs-20">Mais pedidos</h4>
                        </div>
                      </div>
                      <div className="card-body">
                        {mostOrders.current !== null
                          ? mostOrders.current.map((order, i) => {
                              counter++;
                              return (
                                <div
                                  className="d-flex pb-3 mb-3 border-bottom tr-row align-items-center"
                                  key={i}
                                >
                                  <span className="num">#{counter}</span>
                                  <div className="mr-auto pr-3">
                                    <Link to="/orders">
                                      <h2 className="text-black fs-14">
                                        {order.name}
                                      </h2>
                                    </Link>

                                    <span className="fs-14 text-primary">
                                      Pedido {order.total}x
                                    </span>
                                  </div>
                                  <img
                                    src={Hash("75x75", order.image)}
                                    alt="menu9"
                                    width={60}
                                    className="rounded"
                                  />
                                </div>
                              );
                            })
                          : "Nenhum pedido até o momento."}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {user?.plan_id > 2 ? (
                <>
                  <div className="col-xl-6">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header border-0 pb-0 d-sm-flex d-block">
                            <div>
                              <h4 className="card-title mb-1">
                                Pedidos mensais
                              </h4>
                              {/*  <small className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur
                    </small> */}
                            </div>
                          </div>
                          <div className="card-body revenue-chart px-3 pb-0">
                            <div className="d-flex align-items-end pl-3">
                              <div className="mr-4">
                                <h3 className="font-w600 mb-0">
                                  <img
                                    src={IcStat3}
                                    height={22}
                                    width={22}
                                    className="mr-2 mb-1"
                                    alt="IcStat3"
                                  />
                                  <CountUp
                                    start={1}
                                    end={ordersTotal.current || 0}
                                    duration={5}
                                    suffix=""
                                  />
                                </h3>
                                <small className="text-dark fs-14">
                                  Total de pedidos
                                </small>
                              </div>
                              <div>
                                <h3 className="font-w600 mb-0">
                                  <img
                                    src={IcStat3}
                                    height={22}
                                    width={22}
                                    className="mr-2 mb-1"
                                    alt="IcStat3"
                                  />

                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      mediumPriceByAllMonths.current.length > 0
                                        ? mediumPriceByAllMonths.current
                                        : 0
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <small className="text-dark fs-14">
                                  Ticket médio total
                                </small>
                              </div>
                            </div>

                            <TwoCharts
                              label1="Total de pedidos"
                              label2="Ticket médio"
                              data1={ordersTotalByMonth.current[0]}
                              data2={mediumPriceByMonth.current[0]}
                              categories={ordersMonths.current[0]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header border-0 pb-0 d-sm-flex d-block">
                            <div>
                              <h4 className="card-title mb-1">
                                Faturamento mensal
                              </h4>
                              {/*  <small className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur
                    </small> */}
                            </div>
                          </div>
                          <div className="card-body revenue-chart px-3 pb-0">
                            <div className="d-flex align-items-end pl-3">
                              <div className="mr-4">
                                <h3 className="font-w600 mb-0">
                                  <img
                                    src={IcStat3}
                                    height={22}
                                    width={22}
                                    className="mr-2 mb-1"
                                    alt="IcStat3"
                                  />
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      billingTotal.current.length > 0
                                        ? billingTotal.current
                                        : 0
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <small className="text-dark fs-14">
                                  Faturamento total
                                </small>
                              </div>
                            </div>
                            <ActivityLineChart
                              data={billingTotalByMonth.current}
                              categories={billingMonths.current}
                              title="Faturamento do mês"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header border-0 pb-0 d-sm-flex d-block">
                            <div>
                              <h4 className="card-title mb-1">Pagamento</h4>
                              <small>
                                Relatório de recebimento por cada meio de
                                pagamento
                              </small>
                              {/*  <small className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur
                    </small> */}
                            </div>
                          </div>
                          <div className="card-body revenue-chart px-3 pb-0">
                            <div className="d-flex align-items-end pl-3">
                              <div className="mr-4">
                                <h3 className="font-w600 mb-0">
                                  <img
                                    src={IcStat3}
                                    height={22}
                                    width={22}
                                    className="mr-2 mb-1"
                                    alt="IcStat3"
                                  />
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      cashValues.current &&
                                      cashValues.current.reduce(
                                        (p, c) =>
                                          (p && c) !== (0 || null) && p + c
                                      )
                                        ? cashValues.current.reduce(
                                            (p, c) =>
                                              (p && c) !== (0 || null) && p + c
                                          )
                                        : 0
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <small className="text-dark fs-14">
                                  Dinheiro
                                </small>
                              </div>
                              <div className="mr-4">
                                <h3 className="font-w600 mb-0">
                                  <img
                                    src={IcStat3}
                                    height={22}
                                    width={22}
                                    className="mr-2 mb-1"
                                    alt="IcStat3"
                                  />
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      debitCardValues.current &&
                                      debitCardValues.current.reduce(
                                        (p, c) =>
                                          (p && c) !== (0 || null) && p + c
                                      )
                                        ? debitCardValues.current.reduce(
                                            (p, c) =>
                                              (p && c) !== (0 || null) && p + c
                                          )
                                        : 0
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <small className="text-dark fs-14">
                                  Cartão de débito
                                </small>
                              </div>
                              <div className="mr-4">
                                <h3 className="font-w600 mb-0">
                                  <img
                                    src={IcStat3}
                                    height={22}
                                    width={22}
                                    className="mr-2 mb-1"
                                    alt="IcStat3"
                                  />
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      creditCardValues.current &&
                                      creditCardValues.current.reduce(
                                        (p, c) =>
                                          (p && c) !== (0 || null) && p + c
                                      )
                                        ? creditCardValues.current.reduce(
                                            (p, c) =>
                                              (p && c) !== (0 || null) && p + c
                                          )
                                        : 0
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <small className="text-dark fs-14">
                                  Cartão de crédito
                                </small>
                              </div>
                              <div className="mr-4">
                                <h3 className="font-w600 mb-0">
                                  <img
                                    src={IcStat3}
                                    height={22}
                                    width={22}
                                    className="mr-2 mb-1"
                                    alt="IcStat3"
                                  />
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    defaultValue={
                                      mealTicketValues.current &&
                                      mealTicketValues.current.reduce(
                                        (p, c) =>
                                          (p && c) !== (0 || null) && p + c
                                      )
                                        ? mealTicketValues.current.reduce(
                                            (p, c) =>
                                              (p && c) !== (0 || null) && p + c
                                          )
                                        : 0
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    fixedDecimalScale={true}
                                  />
                                </h3>
                                <small className="text-dark fs-14">
                                  Cartão de Refeição
                                </small>
                              </div>
                              {/* <div>
                      <h3 className="font-w600 mb-0">
                        <img
                          src={IcStat3}
                          height={22}
                          width={22}
                          className="mr-2 mb-1"
                          alt="IcStat3"
                        />
                        <CountUp start={1} end={1245} duration={5} />
                      </h3>
                      <small className="text-dark fs-14">
                        Avg. Sales per day
                      </small>
                    </div> */}
                            </div>
                            <ThreeCharts
                              label1="Total no dinheiro"
                              label2="Total no débito"
                              label3="Total no crédito"
                              label4="Total no refeição"
                              label5="Total no alimentação"
                              data1={cashValues.current}
                              data2={debitCardValues.current}
                              data3={creditCardValues.current}
                              data4={mealTicketValues.current}
                              data5={foodVoucherValues.current}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header border-0 pb-0 d-sm-flex d-block">
                            <div>
                              <h4 className="card-title mb-1">Clientes</h4>
                              {/*  <small className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur
                    </small> */}
                            </div>
                          </div>
                          <div className="card-body revenue-chart px-3 pb-0">
                            <div className="d-flex align-items-end pl-3">
                              <div className="mr-4">
                                <h3 className="font-w600 mb-0">
                                  <img
                                    src={IcStat3}
                                    height={22}
                                    width={22}
                                    className="mr-2 mb-1"
                                    alt="IcStat3"
                                  />
                                  <CountUp
                                    start={1}
                                    end={usersTotal.current}
                                    duration={5}
                                    suffix=""
                                  />
                                </h3>
                                <small className="text-dark fs-14">
                                  Total de clientes
                                </small>
                              </div>
                              {/* <div>
                      <h3 className="font-w600 mb-0">
                        <img
                          src={IcStat3}
                          height={22}
                          width={22}
                          className="mr-2 mb-1"
                          alt="IcStat3"
                        />
                        <CountUp start={1} end={1245} duration={5} />
                      </h3>
                      <small className="text-dark fs-14">
                        Avg. Sales per day
                      </small>
                    </div> */}
                            </div>
                            <ActivityLineChart
                              data={usersTotalByMonth.current}
                              categories={usersMonth.current}
                              title="Novos clientes no mês"
                              chartId="clientes"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="w-100 text-center mt-4">
                  <h4>
                    {" "}
                    <i
                      className="ti-lock text-primary pr-2"
                      aria-hidden="true"
                    ></i>
                    Relatórios avançados
                  </h4>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center mt-4">
              <h4>
                {" "}
                <i className="ti-lock text-primary pr-2" aria-hidden="true"></i>
                Relatórios avançados
              </h4>
            </div>
          )}
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
        </div>
      )}
    </>
  );
}
