import React, { useEffect, useState, useContext } from "react";

import Swal from "sweetalert2";
import { Button, Table } from "react-bootstrap";

import { useForm } from "react-hook-form";
import ifoodLogo from "../../../images/ifood.png";
import {
  GetIfoodReasons,
  IfoodCancelDriver,
} from "../../../services/DeliveryServices";
import NotificationsContext from "../../../contexts/NotificationsContext";

export default function DeliveryCancellation(props) {
  const { setNotify } = useContext(NotificationsContext);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [reasons, setReasons] = useState(false);

  const handleGetCancellationReasons = async () => {
    let res = await GetIfoodReasons(props.request_id);
    if (res) {
      setReasons(res);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    let payload = {
      request_id: props.request_id,
      reason: data.reason,
      code: data.code,
    };
    let res = await IfoodCancelDriver(payload);
    if (res) {
      let trackings = localStorage.getItem("ifoodTrackings");
      if (trackings) {
        trackings = JSON.parse(trackings);
        if (trackings.length > 1) {
          trackings = trackings.filter(
            (track) => track.id !== props.request_id
          );
          localStorage.setItem("ifoodTrackings", JSON.stringify(trackings));
        } else {
          localStorage.removeItem("ifoodTrackings");
        }
      }
      props.setShowModal(false);
      props.setTracking(false);
      setNotify({
        title: "Sucesso!",
        message: "Entrega cancelada com sucesso!",
        type: "success",
        visible: true,
      });
    }
  };

  useEffect(() => {
    handleGetCancellationReasons();
    /* if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    } */
  }, []);

  return (
    <div id="request-cancellation">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group col-md-12 text-center">
            <span id="ifood-request-cancellation-wrapper mb-3">
              <img src={ifoodLogo} width={75} height={50} alt="" />
            </span>
          </div>
          <div className="form-group col-md-12 mt-3">
            <textarea
              className="form-control"
              placeholder="Mensagem para o cliente..."
              rows={3}
              {...register("reason", { required: true })}
            ></textarea>
            {errors.reason && (
              <small className="ml-2 text-danger">
                {errors.reason.type === "required" &&
                  "Por favor, informe uma mensagem para o cliente!"}
              </small>
            )}
          </div>
          <div className="form-group col-md-12">
            <select
              className="form-control"
              {...register("code", { required: true })}
            >
              <option value="">Selecione um motivo</option>
              {reasons &&
                reasons.map((reason, i) => (
                  <option key={i} value={reason.cancelCodeId}>
                    {reason.description}
                  </option>
                ))}
            </select>
            {errors.code && (
              <small className="ml-2 text-danger">
                {errors.code.type === "required" &&
                  "Por favor, selecione uma opção!"}
              </small>
            )}
          </div>
          <Button type="submit" className="btn btn-primary mx-auto">
            {isLoading ? "Cancelando..." : "Cancelar entrega"}
          </Button>
        </div>
      </form>
    </div>
  );
}
