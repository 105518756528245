import React, { useEffect, useState, useRef, useContext } from "react";
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import BeatLoader from "react-spinners/BeatLoader";
import { useForm, Controller } from "react-hook-form";
import { Tab, Nav } from "react-bootstrap";

import { Theme } from "../App";
import { Hash } from "../helpers/Helpers";
import AlertResponses from "../jsx/components/Alert";
import UserContext from "../contexts/UserContext";
import {
  Create,
  Update,
  GetOne,
  GetCategories,
} from "../services/ProductsServices";
import VariationDetails from "./VariationDetails";
import Swal from "sweetalert2";

export default function ProductDetail(props) {
  const { user, setShowPlansModal } = useContext(UserContext);
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [categorie, setCategorie] = useState(null);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: true,
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [activeTab, setActiveTab] = useState(0);

  const [categories, setCategories] = useState(null);
  let [isLoading, setIsLoading] = useState(true);
  const variations = useRef();
  const productCurrentVariations = useRef();
  const submitBtn = useRef();

  useEffect(() => {
    if (id) GetOne(id, setProduct);
    GetCategories(setCategories);
  }, []);

  useEffect(() => {
    let isReady = false;
    if (!isReady && id && product && categories) setIsLoading(false);
    else if (!isReady && !id && categories) setIsLoading(false);
    return () => {
      isReady = true;
    };
  }, [categories, product]);

  useEffect(() => {
    if (product && product.variations) {
      let arr = [];
      product.variations.map((value) => {
        let obj = {
          id: value.id,
          title: value.title,
          requiredChoice: value.requiredChoice,
          multipleChoice: value.multipleChoice,
          addVariationValueToProduct: value.addVariationValueToProduct,
          min: value.min,
          max: value.max,
          status: value.status,
          items: [],
        };

        value.items.map((item) => {
          let dict = {
            id: item.id,
            name: item.name,
            description: item.description,
            additional_price: parseFloat(item.additional_price),
            thumb: item.thumb,
          };
          obj.items.push(dict);
        });
        arr.push(obj);
      });

      productCurrentVariations.current = arr;
    }
  }, [product]);

  const override = css`
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 20%;
  `;

  const imagePreview = (image) => {
    if (!image || image.size > 2000000) {
      Swal.fire(
        "Oops!",
        "Imagem muito pesada, por favor, insira imagens até 2MB!",
        "info"
      );
      return;
    }
    let url = URL.createObjectURL(image);
    setImageFile(url);
  };

  const onSubmit = (data) => {
    submitBtn.current.innerHTML = "Salvando, aguarde...";
    submitBtn.current.setAttribute("disabled", true);

    const form = new FormData();
    form.append("name", data.name);
    form.append("price", data.price);
    form.append("description", data.description);
    data.image.length > 0 && form.append("image", data.image[0]);
    form.append("categorie", data.categorie);
    form.append("status", data.status);
    form.append("featured", data.featured);
    form.append("hide_product_for_qrcode", data.hideProductForQrcode);
    
    variations.current && form.append("variations", variations.current);
    if (data.promotional_price) {
      form.append("promotional_price", data.promotional_price);
    }

    if (id) {
      form.append("id", id);
      Update(
        id,
        form,
        setProduct,
        setNotify,
        setShowPlansModal,
        false,
        submitBtn
      );
    } else {
      form.append("store_id", user.store_id);
      Create(form, setProduct, setNotify, setShowPlansModal, false, submitBtn);
    }
  };

  return (
    <>
      <div className="row">
        {isLoading ? (
          <BeatLoader
            color={Theme.colors.primary}
            loading={isLoading}
            css={override}
            size={12}
          />
        ) : (
          <div className="col-lg-12">
            <div className="card">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12 pt-5 px-5 m-auto">
                  {(product && product.image) || imageFile ? (
                    <img
                      className="img-fluid rounded mt-4 product-image"
                      src={
                        imageFile === null
                          ? Hash(
                              "300x300",
                              "products/" + product.image.split("/").pop()
                            )
                          : imageFile
                      }
                      alt=""
                    />
                  ) : (
                    <div className="image-default rounded-lg">
                      <i className="ti-image"></i>
                    </div>
                  )}

                  <div className="input-group mt-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Upload</span>
                    </div>
                    <div className="custom-file">
                      <input
                        accept=".png, .jpg, .jpeg, .webp"
                        type="file"
                        className="custom-file-input"
                        ref={register}
                        {...register("image")}
                        onChange={(e) => imagePreview(e.target.files[0])}
                      />
                      <label className="custom-file-label">Selecione...</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 col-md-12 col-sm-12 m-auto">
                  <div className="card-header pl-3 pb-0 border-none">
                    <h3 className="">
                      {product ? product.name : "Novo Produto"}
                    </h3>
                  </div>
                  <div className="custom-tab-1">
                    <Tab.Container
                      activeKey={`${activeTab.toString()}`}
                      defaultActiveKey={"0"}
                      onSelect={(key) => setActiveTab(key)}
                    >
                      <Nav as="ul" className="nav-tabs">
                        <Nav.Item as="li">
                          <Nav.Link eventKey={"0"}>
                            Detalhes
                            {/* <i className={`la la-${data.icon} mr-2`} /> */}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey={"1"}>
                            Variações
                            {/* <i className={`la la-${data.icon} mr-2`} /> */}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="pt-4">
                        <Tab.Pane eventKey={`0`}>
                          <div className="card-body">
                            <div className="basic-form">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label>Nome</label>
                                    <input
                                      {...register("name", {
                                        required: true,
                                        maxLength: 80,
                                      })}
                                      className="form-control text-black"
                                      defaultValue={product ? product.name : ""}
                                    />
                                    {errors.name && (
                                      <small className="ml-2 text-danger">
                                        {errors.name.type === "required"
                                          ? "Por favor, preencha este campo!"
                                          : "Este campo deve conter no máximo 80 caracteres!"}
                                      </small>
                                    )}
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label>Categoria</label>
                                    <select
                                      defaultValue={
                                        product ? product.categorie : ""
                                      }
                                      className="form-control text-black"
                                      {...register("categorie", {
                                        required: true,
                                      })}
                                    >
                                      {categories &&
                                        categories.map((_categorie, i) => (
                                          <option
                                            key={i}
                                            value={_categorie.id}
                                            defaultValue={
                                              _categorie.id === categorie &&
                                              _categorie.id
                                            }
                                          >
                                            {_categorie.name}
                                          </option>
                                        ))}
                                    </select>
                                    {errors.categorie && (
                                      <small className="ml-2 text-danger">
                                        {errors.categorie.type === "required" &&
                                          "Por favor, preencha este campo!"}
                                      </small>
                                    )}
                                  </div>

                                  <div className="form-group col-md-12">
                                    <label>Preço Normal</label>
                                    <Controller
                                      name="price"
                                      control={control}
                                      rules={{ required: true }}
                                      defaultValue={
                                        product ? parseFloat(product.price) : ""
                                      }
                                      render={({ field }) => (
                                        <NumberFormat
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          decimalScale={2}
                                          prefix="R$"
                                          className="form-control text-black"
                                          placeholder="preço normal ou preço mínimo..."
                                          {...field}
                                        />
                                      )}
                                    />
                                  </div>
                                  {errors.price && (
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.price.type === "required" &&
                                        "Por favor, preencha este campo!"}
                                    </small>
                                  )}
                                  <div className="form-group col-md-12">
                                    <label>Preço Promocional</label>
                                    <Controller
                                      name="promotional_price"
                                      control={control}
                                      defaultValue={
                                        product && product.promotional_price
                                          ? parseFloat(
                                              product.promotional_price
                                            )
                                          : ""
                                      }
                                      render={({ field }) => (
                                        <NumberFormat
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          decimalScale={2}
                                          prefix="R$"
                                          className="form-control text-black"
                                          placeholder="opcional..."
                                          {...field}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label>Descrição</label>
                                    <textarea
                                      className="form-control text-black"
                                      rows="4"
                                      defaultValue={
                                        product ? product.description : ""
                                      }
                                      {...register("description", {
                                        required: true,
                                      })}
                                    ></textarea>
                                  </div>
                                  {errors.description && (
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.description.type === "required" &&
                                        "Por favor, preencha este campo!"}
                                    </small>
                                  )}
                                </div>

                                <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label>Status</label>
                                    <select
                                      className="form-control text-black"
                                      defaultValue={
                                        product ? product.status : ""
                                      }
                                      {...register("status", {
                                        required: true,
                                      })}
                                    >
                                      <option value={1}>Ativo</option>
                                      <option value={0}>Desativado</option>
                                    </select>
                                  </div>
                                  {errors.status && (
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.status.type === "required" &&
                                        "Por favor, preencha este campo!"}
                                    </small>
                                  )}
                                </div>
                                {
                                  user.plan_id > 2 && 
                                  <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label>Esconder produto no QrCode</label>
                                    <select
                                      className="form-control text-black"
                                      defaultValue={
                                        product ? product.hide_product_for_qrcode : "0"
                                      }
                                      {...register("hideProductForQrcode", {
                                        required: true,
                                      })}
                                    >
                                      <option value={0}>Não</option>
                                      <option value={1}>Sim</option>
                                    </select>
                                  </div>
                                  {errors.hideProductForQrcode && (
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.hideProductForQrcode.type === "required" &&
                                        "Por favor, preencha este campo!"}
                                    </small>
                                  )}
                                </div>
                                }
                                <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label>
                                      Destaque{" "}
                                      <i
                                        className="ti-info-alt"
                                        data-toggle="tooltip"
                                        title="Mostra o produto no banner principal do site"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </label>
                                    <select
                                      className="form-control text-black"
                                      defaultValue={
                                        product ? product.featured : ""
                                      }
                                      {...register("featured", {
                                        required: true,
                                      })}
                                    >
                                      <option value={0}>Não</option>
                                      <option value={1}>Sim</option>
                                    </select>
                                  </div>
                                  {errors.status && (
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.status.type === "required" &&
                                        "Por favor, preencha este campo!"}
                                    </small>
                                  )}
                                </div>

                                <button
                                  ref={(el) => (submitBtn.current = el)}
                                  type="submit"
                                  className="btn btn-block btn-primary btn-submit"
                                >
                                  Salvar Produto
                                </button>
                              </form>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={`1`} className="pb-4">
                          <div className="card-body">
                            <VariationDetails
                              id={id}
                              variations_data={variations}
                              setNotify={setNotify}
                              setActiveTab={setActiveTab}
                              productCurrentVariations={
                                productCurrentVariations
                              }
                            />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>

              {notify.visible && (
                <AlertResponses
                  title={notify.title}
                  message={notify.message}
                  type={notify.type}
                  visible={notify.visible}
                  setNotify={setNotify}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
