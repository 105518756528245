import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

import ErrorsComponent from "../jsx/components/Errors";
import AlertResponses from "../jsx/components/Alert";
import { GetAll } from "../services/CategoriesServices";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import { Create, Update, GetOne } from "../services/PromotionsServices";
import UserContext from "../contexts/UserContext";
import DefaultLoader from "../jsx/components/Loader";
import { Hash } from "../helpers/Helpers";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

export default function PromotionDetails(props) {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [promotion, setPromotion] = useState(null);

  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [erros, setErros] = useState(null);
  const [progress, setProgress] = useState(false);

  const [dailyPromo, setDailyPromo] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    if (user?.plan_id === 1) {
      window.location.assign("/");
      return;
    }

    if (id) {
      GetOne(id, setPromotion, true).then((res) => {
        setPromotion(res);
        setValue("name", res.name);
        setValue("description", res.description);
        setValue("rules", res.rules);
        setValue("price", res.price);
        setValue("category", res.categorie);
        setValue("status", res.status);
        if (res?.rules?.dailyDeal) {
          setDailyPromo(true);
        }
      });
    }
    GetAll(setCategories, setErros, setIsLoading);
  }, []);

  const imagePreview = (image) => {
    if (image?.size > 2000000) {
      Swal.fire(
        "Atenção!",
        "Imagem muito pesada! Por favor, insira imagens até 2MB.",
        "info"
      );
      return;
    }
    let url = URL.createObjectURL(image);
    setImageFile(url);
  };

  const onSubmit = (data) => {
    var form = new FormData();
    let rules = {};

    if (typeof price === "number" || typeof data.price === "number")
      data.price = data.price.toString();
    if (data.price.includes("R$")) {
      data.price = data.price.replace(/[^0-9,]/g, "").replace(",", ".");
    }

    if (
      typeof promotional_price === "number" ||
      typeof data.promotional_price === "number"
    )
      data.promotional_price = data.promotional_price.toString();
    if (data.promotional_price.includes("R$")) {
      data.promotional_price = data.promotional_price
        .replace(/[^0-9,]/g, "")
        .replace(",", ".");
    }

    let obj = { days: [] };
    document
      .querySelectorAll(
        ".custom-checkbox.week-days .custom-control-input:checked"
      )
      .forEach((el) => obj.days.push(el.name));

    if (dailyPromo) {
      let dailyDeal = {
        monday: data?.dailyDealMonday ? true : false,
        tuesday: data?.dailyDealTuesday ? true : false,
        wednesday: data?.dailyDealWednesday ? true : false,
        thursday: data?.dailyDealThursday ? true : false,
        friday: data?.dailyDealFriday ? true : false,
        saturday: data?.dailyDealSaturday ? true : false,
        sunday: data?.dailySunday ? true : false,
      };

      rules.dailyDeal = dailyDeal;
    }

    if (data.validOnlyForDelivery) rules.validOnlyForDelivery = true;
    if (data.validOnlyForTakeAway) rules.validOnlyForTakeAway = true;
    if (data.validForDeliveryAndTakeAway)
      rules.validForDeliveryAndTakeAway = true;

    rules = JSON.stringify(rules);

    form.append("name", data.name);
    form.append("description", data.description);
    form.append("rules", rules);
    form.append("price", data.price);
    form.append("categorie", data.category);
    form.append("status", data.status);
    form.append("hide_product_for_qrcode", data.hideProductForQrcode);

    data.promotional_price &&
      form.append("promotional_price", data.promotional_price);
    data.image.length > 0 && form.append("image", data.image[0]);

    if (id) {
      form.append("id", id);
      Update(id, form, setPromotion, setNotify, setProgress);
    } else {
      form.append("store_id", user.store_id);
      Create(form, setPromotion, setNotify, setError, setProgress);
    }
  };

  return (
    <>
      <div className="row">
        {isLoading ? (
          <DefaultLoader isLoading={isLoading} />
        ) : (
          <div className="col-lg-12">
            {erros && <ErrorsComponent />}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-2 col-lg-4 col-md-4 col-xxl-4">
                    {promotion || imageFile ? (
                      //TODO: VOLTAR PARA O NORMAL DE PRO

                      <img
                        className="img-fluid rounded mt-4 promotion-image"
                        src={
                          imageFile === null
                            ? Hash("350x350", promotion.image)
                            : imageFile
                        }
                        alt="image"
                        style={styles.image}
                      />
                    ) : (
                      /*  <img className="img-fluid rounded mt-4 promotion-image" src={imageFile === null ? Hash('400x400', 'promotions/' + promotion.thumb.split('/').pop()) : imageFile} alt="image" /> */
                      <div className="image-default rounded-lg">
                        <i className="ti-image"></i>
                      </div>
                    )}

                    <div className="input-group mt-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Upload</span>
                      </div>
                      <div className="custom-file">
                        <input
                          accept="image/*"
                          type="file"
                          className="custom-file-input"
                          ref={register}
                          {...register("image", {
                            required: imageFile || id ? false : true,
                          })}
                          onChange={(e) => imagePreview(e.target.files[0])}
                        />
                        <label className="custom-file-label">
                          Selecione...
                        </label>
                      </div>
                    </div>
                    {errors.image && (
                      <small className="ml-2 text-danger">
                        {errors.image.type === "required" &&
                          "Por favor, selecione uma imagem!"}
                      </small>
                    )}
                  </div>

                  <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="">
                          {promotion ? promotion.name : "Nova Promoção"}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="basic-form">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <label>Nome</label>
                                <input
                                  {...register("name", {
                                    required: true,
                                    maxLength: 80,
                                  })}
                                  className="form-control text-black"
                                  defaultValue={promotion ? promotion.name : ""}
                                />
                                {errors.name && (
                                  <small className="ml-2 text-danger">
                                    {errors.name.type === "required"
                                      ? "Por favor, preencha este campo!"
                                      : "Este campo deve conter no máximo 80 caracteres!"}
                                  </small>
                                )}
                              </div>
                              <div className="form-group col-md-12">
                                <label>Categoria</label>
                                <select
                                  //defaultValue={promotion ? promotion.category : ''}
                                  defaultValue={
                                    promotion ? promotion.category : ""
                                  }
                                  className="form-control text-black"
                                  {...register("category", { required: true })}
                                >
                                  {categories &&
                                    categories.map((_category, i) => (
                                      <option key={i} value={_category.id}>
                                        {_category.name}
                                      </option>
                                    ))}
                                </select>
                                {errors.category && (
                                  <small className="ml-2 text-danger">
                                    {errors.category.type === "required" &&
                                      "Por favor, preencha este campo!"}
                                  </small>
                                )}
                              </div>
                              <div className="form-group col-md-12">
                                <label>Preço normal</label>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="price"
                                  defaultValue={
                                    promotion ? promotion.price : ""
                                  }
                                  render={({ field }) => (
                                    <NumberFormat
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={2}
                                      prefix="R$"
                                      className="form-control text-black"
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              {errors.price && (
                                <small className="ml-2 mb-2 text-danger">
                                  {errors.price.type === "required" &&
                                    "Por favor, preencha este campo!"}
                                </small>
                              )}
                              <div className="form-group col-md-12">
                                <label>Preço Promocional</label>
                                <Controller
                                  name="promotional_price"
                                  control={control}
                                  defaultValue={
                                    promotion && promotion.promotional_price
                                      ? parseFloat(promotion.promotional_price)
                                      : ""
                                  }
                                  render={({ field }) => (
                                    <NumberFormat
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={2}
                                      prefix="R$"
                                      className="form-control text-black"
                                      placeholder="opcional..."
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              {user.plan_id > 2 && (
                                <>
                                  <div className="form-group col-md-12">
                                    <label>Esconder produto no QrCode</label>
                                    <select
                                      className="form-control text-black"
                                      defaultValue={
                                        promotion
                                          ? promotion.hide_product_for_qrcode
                                          : "0"
                                      }
                                      {...register("hideProductForQrcode", {
                                        required: true,
                                      })}
                                    >
                                      <option value={0}>Não</option>
                                      <option value={1}>Sim</option>
                                    </select>
                                  </div>
                                  {errors.hideProductForQrcode && (
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.hideProductForQrcode.type ===
                                        "required" &&
                                        "Por favor, preencha este campo!"}
                                    </small>
                                  )}
                                </>
                              )}
                              <div className="form-group col-md-12">
                                <label>Descrição</label>
                                <textarea
                                  className="form-control text-black"
                                  rows="4"
                                  defaultValue={
                                    promotion ? promotion.description : ""
                                  }
                                  {...register("description", {
                                    required: true,
                                  })}
                                ></textarea>
                              </div>
                              {errors.description && (
                                <small className="ml-2 mb-2 text-danger">
                                  {errors.description.type === "required" &&
                                    "Por favor, preencha este campo!"}
                                </small>
                              )}
                              <div className="form-group col-md-12">
                                <label>Regras</label>
                                <div
                                  className="d-flex flex-column mt-1 ml-2 mb-2"
                                  style={{ gap: 15 }}
                                >
                                  <div className="custom-control custom-checkbox mr-3">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={"dailyDeal"}
                                      defaultChecked={dailyPromo}
                                      {...register("dailyDeal")}
                                      onClick={() => setDailyPromo(!dailyPromo)}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`dailyDeal`}
                                    >
                                      Promo do Dia
                                    </label>
                                  </div>
                                  {dailyPromo && (
                                    <div className="mb-4">
                                      <div>
                                        <label>
                                          Escolha o(s) dia(s) para exibir a
                                          promoção
                                        </label>
                                      </div>
                                      <div className="d-flex align-items-center mb-0">
                                        <div className="custom-control custom-checkbox week-days">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checked-1"
                                            id="customCheck1"
                                            name="monday"
                                            defaultChecked={
                                              promotion?.rules?.dailyDeal && promotion?.rules?.dailyDeal.monday
                                                ? true
                                                : false
                                            }
                                            {...register("dailyDealMonday")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck1"
                                          ></label>
                                        </div>
                                        <div className="custom-control custom-checkbox week-days">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checked-2"
                                            id="customCheck2"
                                            name="tuesday"
                                            defaultChecked={
                                              promotion?.rules?.dailyDeal && promotion?.rules?.dailyDeal
                                                .tuesday
                                                ? true
                                                : false
                                            }
                                            {...register("dailyDealTuesday")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck2"
                                          ></label>
                                        </div>
                                        <div className="custom-control custom-checkbox week-days">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checked-3"
                                            id="customCheck3"
                                            name="wednesday"
                                            defaultChecked={
                                              promotion?.rules?.dailyDeal && promotion?.rules?.dailyDeal
                                                .wednesday
                                                ? true
                                                : false
                                            }
                                            {...register("dailyDealWednesday")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck3"
                                          ></label>
                                        </div>
                                        <div className="custom-control custom-checkbox week-days">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checked-4"
                                            id="customCheck4"
                                            name="thursday"
                                            defaultChecked={
                                              promotion?.rules?.dailyDeal && promotion?.rules?.dailyDeal
                                                .thursday
                                                ? true
                                                : false
                                            }
                                            {...register("dailyDealThursday")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck4"
                                          ></label>
                                        </div>
                                        <div className="custom-control custom-checkbox week-days">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checked-5"
                                            id="customCheck5"
                                            name="friday"
                                            defaultChecked={
                                              promotion?.rules?.dailyDeal && promotion?.rules?.dailyDeal.friday
                                                ? true
                                                : false
                                            }
                                            {...register("dailyDealFriday")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck5"
                                          ></label>
                                        </div>
                                        <div className="custom-control custom-checkbox week-days">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checked-6"
                                            id="customCheck6"
                                            name="saturday"
                                            defaultChecked={
                                              promotion?.rules?.dailyDeal && promotion?.rules?.dailyDeal
                                                .saturday
                                                ? true
                                                : false
                                            }
                                            {...register("dailyDealSaturday")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck6"
                                          ></label>
                                        </div>
                                        <div className="custom-control custom-checkbox week-days">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checked-7"
                                            id="customCheck7"
                                            name="sunday"
                                            defaultChecked={
                                              promotion?.rules?.dailyDeal && promotion?.rules?.dailyDeal.sunday
                                                ? true
                                                : false
                                            }
                                            {...register("dailyDealSunday")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck7"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="custom-control custom-checkbox mr-3">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={"validOnlyForDelivery"}
                                      defaultChecked={
                                        promotion &&
                                        promotion?.rules?.validOnlyForDelivery
                                          ? true
                                          : false
                                      }
                                      {...register("validOnlyForDelivery")}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`validOnlyForDelivery`}
                                    >
                                      Válido para Delivery
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-3">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={"validOnlyForTakeAway"}
                                      defaultChecked={
                                        promotion &&
                                        promotion?.rules?.validOnlyForTakeAway
                                          ? true
                                          : false
                                      }
                                      {...register("validOnlyForTakeAway")}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`validOnlyForTakeAway`}
                                    >
                                      Válido para Retirada
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-3">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={"validForDeliveryAndTakeAway"}
                                      defaultChecked={
                                        promotion &&
                                        promotion?.rules
                                          ?.validForDeliveryAndTakeAway
                                          ? true
                                          : false
                                      }
                                      {...register(
                                        "validForDeliveryAndTakeAway"
                                      )}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`validForDeliveryAndTakeAway`}
                                    >
                                      Válido para Delivery/Retirada
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/*  <div className='form-group col-md-12'>
                                <label>Regras</label>
                                <textarea
                                  className='form-control text-black'
                                  rows='4'
                                  defaultValue={
                                    promotion ? promotion.rules : ''
                                  }
                                  {...register('rules')}
                                ></textarea>
                              </div> */}
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <label>Status</label>
                                <select
                                  className="form-control text-black"
                                  defaultValue={
                                    promotion ? promotion.status : ""
                                  }
                                  {...register("status", { required: true })}
                                >
                                  <option value={1}>Ativo</option>
                                  <option value={2}>Desativado</option>
                                </select>
                              </div>
                              {errors.status && (
                                <small className="ml-2 mb-2 text-danger">
                                  {errors.status.type === "required" &&
                                    "Por favor, preencha este campo!"}
                                </small>
                              )}
                            </div>

                            <Button
                              type="submit"
                              className="btn btn-block btn-primary"
                            >
                              Salvar
                            </Button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {progress && <ProgressBarComponent />}
            {notify.visible && (
              <AlertResponses
                title={notify.title}
                message={notify.message}
                type={notify.type}
                visible={notify.visible}
                setNotify={setNotify}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

const styles = {
  image: {
    maxWidth: 330,
    maxHeight: 250,
    width: "100%",
    objectFit: "cover",
  },
};
