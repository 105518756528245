import React, { useState, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { GetAll, GetCategories } from '../../../services/ProductsServices'
import Swal from 'sweetalert2'

export default function CopyVariations (props) {
  const { handleSubmit, control } = useForm()
  const [categories, setCategories] = useState(null)
  const [productsOptions, setProductsOptions] = useState(null)
  const products = useRef()
  const selectProducts = useRef()
  useEffect(async () => {
    let res = await GetCategories(setCategories, true)
    if (res.length > 0) {
      let obj = []
      res.map(cat => {
        obj.push({ label: cat.name, value: cat.id })
      })
      setCategories(obj)
    }
  }, [])

  const onClear = input => {
    if (input.current) input.current.select.clearValue()
  }

  const _GetProducts = async category => {
    let res = await GetAll(null, null, `?categorie=${category}`, true)
    if (res.length > 0) {
      let obj = []

      res.map(prod => {
        obj.push({ label: prod.name, value: prod.id })
      })
      products.current = res
      setProductsOptions(obj)
    }
  }

  const handlePromise = async (data) => {
    try {
      const result = await new Promise((resolve, reject) => {
        let variations = []

        products.current.map(prod => {
          if (prod.id == data.selectProduct.value) {
            if (prod.variations.length === 0) {
              Swal.fire({
                title: 'Oops!',
                text: 'Este produto não possuí variações. Para continuar, selecione outro produto.',
                icon: 'info'
              })
              return reject('Produto sem variações')
            } else {
              prod.variations.map(values => {
                let obj = {
                  title: values.title,
                  requiredChoice: values.requiredChoice,
                  multipleChoice: values.multipleChoice,
                  addVariationValueToProduct: values.addVariationValueToProduct,
                  min: values.min,
                  max: values.max,
                  status: values.status,
                  items: []
                }
                if (values?.items?.length > 0) {
                  values.items.map(item => {
                    obj.items.push({
                      id: item.id,
                      name: item.name,
                      description: item.description,
                      additional_price: parseFloat(item.additional_price),
                      thumb: item.thumb,
                      copy: 1
                    })
                  })
                }
                variations.push(obj)
              })
            }
          }
        })

        props.setShowModal(false)
        props.setNotify({
          title: 'Sucesso!',
          message: 'Variações copiadas!',
          type: 'success',
          visible: true
        })
        props.reset({
          group: variations
        })
        //window.postMessage({ copyVariations: true, variations: JSON.stringify(variations) })
        resolve(true)

      })

    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = data => {
    handlePromise(data)
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='form-row'>
              <div className='form-group col-lg-12'>
                <label>Selecione uma categoria</label>
                <Select
                  name='select-category'
                  options={categories}
                  defaultValue=''
                  onChange={e => {
                    if (productsOptions) onClear(selectProducts)
                    _GetProducts(e.value)
                  }}
                  className='rounded-form-control'
                  placeholder='Selecione uma categoria...'
                  menuPortalTarget={
                    typeof window !== 'undefined' ? document.body : ''
                  }
                  styles={{
                    menuPortal: provided => ({
                      ...provided,
                      zIndex: 999999999
                    })
                  }}
                />
              </div>
              {productsOptions && (
                <div className='form-group col-lg-12'>
                  <label>Selecione um produto</label>

                  <Controller
                    name='selectProduct'
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                    render={({ field }) => (
                      <Select
                        options={productsOptions}
                        className='rounded-form-control'
                        placeholder='Selecione o produto...'
                        menuPortalTarget={
                          typeof window !== 'undefined' ? document.body : ''
                        }
                        styles={{
                          menuPortal: provided => ({
                            ...provided,
                            zIndex: 999999999
                          })
                        }}
                        {...field}
                        ref={selectProducts}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            {products && (
              <div className='col-lg-12'>
                <button
                  id='submit'
                  type='submit'
                  className='btn btn-primary btn-block btn-sm mt-3'
                >
                  Copiar
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
