import { api } from '../api/Middleware'
import { ANALYTICS_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetDataReport = async () => {
    try {
        let response = await api.get(ANALYTICS_URL + 'data_report/');
        if (response.status === 200) return response.data
    } catch (error) {
        return error;
    }
}

