import Swal from 'sweetalert2';
import { api } from '../api/Middleware'
import { VARIATIONS_URL, APP_BASE_URL, VARIATION_OPTIONS_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetAll = async (setVariations, setErrors, setIsLoading, returnData = false) => {

    try {
        let response = await api.get(VARIATIONS_URL);
        if (response.status === 200) {
            if (returnData !== false) return response.data;
            setVariations(response.data);
        }
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
    }

    setIsLoading(false)
}

export const Create = async (data, setVariation, setNotify, setShowPlansModal, returnData=false) => {


    try {
        let response = await api.post(VARIATIONS_URL, data,{
            headers: {
                'Content-Type': "application/json"
            }
        });
        if (response.status === 201) {
            if(returnData) return response.data
            setVariation(response.data);
            setNotify({ title: 'Sucesso!', message: 'Variação cadastrada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }

    } catch (error) {
        if(returnData) return error.response
        if(error.response.status === 400){
            setNotify({ title: 'Oops!', message: error.response.data.message, type: 'error', visible: true });
            return                                                                                                                                                 
        }
        else if (error.response.status === 401) {
            window.location.href = APP_BASE_URL
        } else if (error.response.status === 402) {
            Swal.fire({
                title: "Oops!",
                html: error.response.data,
                icon: "warning",
                buttons: true,
                confirmButtonText: `Fazer upgrade agora!`,
                cancelButtonText: 'Adiar',
                showCancelButton: true,
                confirmButtonColor: 'var(--primary)'
            }).then((value) => {
                if (value.isConfirmed) {
                    setShowPlansModal(true)
                }
            })
        } else {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.variations.create, type: 'error', visible: true });
        }

    }
}
export const Update = async (id, data, setVariation, setNotify, setIsLoading) => {

    try {
        let response = await api.put(VARIATIONS_URL + id + '/', data);
        if (response.status === 200) {
            setVariation(response.data);
            setNotify({ title: 'Sucesso!', message: 'Variação atualizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.variations.update, type: 'error', visible: true });
    }
}

export const ChangeStatus = async (data, setNotify) => {
    try {
        let response = await api.put(VARIATIONS_URL + data.id + '/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: "Status atualizado com sucesso!", type: 'success', visible: true })
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.variations.status, type: 'error', visible: true });
        return false;
    }
}

export const Delete = async (id, setNotify, setProgress) => {
    setProgress(true);

    try {
        let ids = [id];

        let response = await api.delete(VARIATIONS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }

    setProgress(false);
}

export const UpdateSequence = async (id, data) => {
    try {
      let response = await api.put(`${VARIATIONS_URL}update_sequence/${id}/`, data)
      return response.data
    } catch(error) {
        throw error
    }
  }

export const UpdateOptionsSequence = async (id, data) => {
try {
    let response = await api.put(`${VARIATION_OPTIONS_URL}update_sequence/${id}/`, data)
    return response.data
} catch(error) {
    throw error
}
}