import Swal from 'sweetalert2'
import axios from 'axios'
import { api } from '../api/Middleware'
import {
  CELERY_PROGRESS_URL,
  IFOOD_IMAGES_FILE,
  INTEGRATIONS_URL,
  PRODUCTS_URL,
  VARIATION_OPTIONS_URL
} from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const Auth = async data => {
  try {
    let response = await api.post(INTEGRATIONS_URL + 'ifood_auth/', data)
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    if (error.response.status === 403) {
      Swal.fire({
        title: 'Atenção',
        text: 'Você precisa autorizar nosso aplicativo em seu Portal do iFood. \
                Por favor, faça a autorização e tente novamente.',
        icon: 'warning'
      })
    } else {
      Swal.fire({
        title: 'Oops!',
        text: 'Ocorreu uma falha na autenticação com o Ifood, por favor, \
                verifique se o ID da loja que consta no Ifood está correto em sua integração e tente novamente.',
        icon: 'error'
      })
    }
  }
}

export const ListCatalogs = async data => {
  try {
    let response = await api.post(
      INTEGRATIONS_URL + `ifood_list_catalogs/`,
      data
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    Swal.fire({
      title: 'Oops!',
      text: 'Ocorreu uma falha na listagem dos catálogos do ifood, por favor, \
            verifique se o ID da loja que consta no Ifood está correto em sua integração e tente novamente.',
      icon: 'error'
    })
  }
}

export const ListCategories = async data => {
  try {
    let response = await api.post(
      INTEGRATIONS_URL + `ifood_list_categories/`,
      data
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    Swal.fire({
      title: 'Oops!',
      text: 'Ocorreu uma falha na listagem das categorias do ifood, por favor, \
            verifique se o ID da loja que consta no Ifood está correto em sua integração e tente novamente.',
      icon: 'error'
    })
  }
}

export const Init = async data => {
  try {
    let response = await api.post(INTEGRATIONS_URL + `ifood_init/`, data)
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    Swal.fire({
      title: 'Oops!',
      text: 'Ocorreu uma falha ao iniciar a integração com o ifood, por favor, \
            verifique se o ID da loja que consta no Ifood está correto em sua integração e tente novamente.',
      icon: 'error'
    })
  }
}

export const GetProgress = async taskId => {
  try {
    let response = await api.get(CELERY_PROGRESS_URL + taskId + '/')
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {}
}

export const GetIfoodFileImages = async store_id => {
  try {
    let response = await api.get(IFOOD_IMAGES_FILE + store_id + '.json')
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {}
}

export const UpdateImage = async (id, data, dataType, returnData = false) => {
  try {
    let url = dataType === 'product' ? PRODUCTS_URL  + `${id}/` : VARIATION_OPTIONS_URL + 'update_thumb/' 
    
    let response = await api.put(url, data, {
      headers: {
        'Content-Type': `image/jpeg`
      }
    })
    if (response.status === 200) {
      if (returnData) return response.data
      return response.data
    }
  } catch (error) {
    if (returnData) return error
    Swal.fire({
      title: 'Oops!',
      text: 'Ocorreu uma falha ao iniciar a integração com o ifood, por favor, \
            verifique se o ID da loja que consta no Ifood está correto em sua integração e tente novamente.',
      icon: 'error'
    })
  }
}
