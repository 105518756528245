import React from "react";
import ReactApexChart from "react-apexcharts";
import data from "../../bootstrap/alertData";

class ActivityLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: props.title,
          data: props.data[0],
          //radius: 12,
        },
      ],
      options: {
        chart: {
          type: "area",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        colors: ["var(--primary) "],
        dataLabels: {
          enabled: false,
        },
        markers: {
          shape: "circle",
        },

        legend: {
          show: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ["var(--primary) "],
        },

        grid: {
          borderColor: "#eee",
        },
        xaxis: {
          categories: props.categories[0],
          labels: {
            style: {
              colors: "#3e4954",
              fontSize: "13px",
              fontFamily: "Poppins",
              fontWeight: 100,
              cssClass: "apexcharts-xaxis-label",
            },
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            // style: {
            //   colors: "#3e4954",
            //   fontSize: "13px",
            //   fontFamily: "Poppins",
            //   fontWeight: 100,
            //   cssClass: "apexcharts-xaxis-label",
            // },
            formatter: function (value) {
              // var val = Math.abs(value)
              // if (val >= 1000) {
              //   val = (val / 1000).toFixed(0) + ' K'
              // }
              if (props.chartId && props.chartId === 'clientes') {
                return value
              } else {
                return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
              }

            }
          },
        },
        fill: {
          opacity: 1,
          colors: "#FAC7B6",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (props.chartId && props.chartId === 'clientes') {
                return val
              } else {
                return val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
              }
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    );
  }
}

export default ActivityLineChart;
