import React, { useContext, useRef, useCallback, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import NotificationsContext from "../../../contexts/NotificationsContext";
import { ChangeStatus } from "../../../services/OrdersServices";
import { OrderToPrint } from "../../../screens/OrderToPrint";
import AppContext from "../../../contexts/AppContext";
import ModalComponent from "../Modal";
import RequestDriver from "../ModalData/RequestDriver";
import { removeFromCurrentOrders } from "../../../helpers/Helpers";

export default function NewOrder({
  value,
  index,
  handleMoveToNextColumn,
  column,
  setShowOrderModal,
  orderData,
  componentRef,
  handlePrint,
  tracking,
  setTracking,
}) {
  const { currentOrderStatus } = useContext(AppContext);
  const { sendMessage } = useContext(NotificationsContext);
  const [showModalRequestDriver, setShowModalRequestDriver] = useState(false);
  const currentValue = useRef(value);
  const counter = useRef(0);

  const orderTo = {
    delivery: "Entrega",
    take_away: "Retirada",
    table: "Mesa",
  };

  const modalProps = {
    title: "Solicitar entregador",
    content: (
      <RequestDriver
        order={value}
        tracking={tracking}
        setShowModal={setShowModalRequestDriver}
        setTracking={setTracking}
      />
    ),
    btnLabel: "Salvar",
    size: "lg",
    showModal: showModalRequestDriver,
    setShowModal: setShowModalRequestDriver,
    showFooter: false,
  };

  return (
    <>
      <Card className="mb-0" key={index}>
        <Card.Body className="p-3">
          <small className="d-flex justify-content-between">
            <h6 className="d-flex mb-1">
              #{value.order_number}{" "}
              <b className="text-primary text-uppercase">
                | {`${orderTo[value.order_to]} ${value.qrtb ? value.qrtb : ""}`}
              </b>
              {value.order_to === "delivery" && (
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id={`tooltip-right`}>Solicitar entregador</Tooltip>
                  }
                >
                  <span
                    id="new-order-request-driver"
                    onClick={() => setShowModalRequestDriver(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      fill="#fff"
                      width={20}
                    >
                      <path d="M328 56C328 42.75 338.7 32 352 32H393.3C417.1 32 438.3 47.09 446.1 69.6L454.2 92.88L505.2 67.38C509.7 65.16 514.6 64 519.6 64H528C536.8 64 544 71.16 544 80V144C544 152.8 536.8 160 528 160H519.6C514.6 160 509.7 158.8 505.2 156.6L470.3 139.2L500 224.4C503.1 224.1 507.1 224 512 224C555.7 224 595.3 241.5 624.1 269.8C633.6 279.1 633.7 294.3 624.4 303.8C615.1 313.2 599.9 313.4 590.5 304.1C570.2 284.2 542.6 272 512 272C459.3 272 414.1 308.5 403.1 357.6C401.1 366.1 400 374.9 400 384H256C256 437 213 480 160 480C106.1 480 64 437 64 384H32C14.33 384 0 369.7 0 352V320C0 249.3 57.31 192 128 192H224C241.7 192 256 206.3 256 224V288C256 305.7 270.3 320 288 320H365.3C382.2 281.5 413.7 250.8 452.9 235.3L400.8 85.37C399.7 82.16 396.7 80 393.3 80H352C338.7 80 328 69.26 328 56L328 56zM208 384H112C112 410.5 133.5 432 160 432C186.5 432 208 410.5 208 384zM224 96C241.7 96 256 110.3 256 128C256 145.7 241.7 160 224 160H128C110.3 160 96 145.7 96 128C96 110.3 110.3 96 128 96H224zM416 384C416 330.1 458.1 288 512 288C565 288 608 330.1 608 384C608 437 565 480 512 480C458.1 480 416 437 416 384zM512 432C538.5 432 560 410.5 560 384C560 357.5 538.5 336 512 336C485.5 336 464 357.5 464 384C464 410.5 485.5 432 512 432z" />
                    </svg>
                  </span>
                </OverlayTrigger>
              )}
            </h6>
            <span className="badge badge-sm badge-light">
              {new Date(value.created_at).toLocaleString("pt-BR")}
            </span>
          </small>
          <small
            className="badge badge-sm badge-primary badge-semirounded text-white cursor-pointer"
            onClick={() => {
              orderData.current = value;
              setTracking(false);
              setShowOrderModal(true);
            }}
          >
            Ver o pedido
          </small>
          <p className="mb-3">{value.name}</p>
          {value?.order.map((v, i) => {
            return (
              <div key={i} className="d-flex align-items-start my-2">
                <div className="text-primary mb-0" style={{ lineHeight: 1.5 }}>
                  {v.quantity}x {v.name}
                  <br></br>
                  {v.variationData &&
                    v.variationData.map((vv, ii) => {
                      return (
                        <div key={ii} style={{ lineHeight: 1 }}>
                          <small>
                            {vv && vv?.option?.length > 0 ? (
                              <>
                                <span className="font-weight-bold">
                                  {vv.title}:{" "}
                                </span>
                                {vv.option.map((add) => ` ${add.name}, `)}
                              </>
                            ) : (
                              <>
                                <span className="font-weight-bold">
                                  {vv.title}:{" "}
                                </span>
                                {vv?.option?.name}
                              </>
                            )}
                          </small>
                          <br></br>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}

          {column === "column1" && (
            <div className="d-flex justify-content-between align-items-bottom mt-3">
              <button
                className="i-false btn btn-sm btn-secondary"
                style={{ height: 50, alignSelf: "flex-end" }}
                onClick={(e) => {
                  Swal.fire({
                    title: "Você tem certeza?",
                    text: "Deseja realmente recusar este pedido?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "var(--primary)",
                    cancelButtonColor: "var(--subtitles)",
                    confirmButtonText: "Sim, recusar!",
                    cancelButtonText: "Cancelar",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      counter.current = counter.current + 1;

                      ChangeStatus(
                        { id: value.id, status: 5 },
                        null,
                        true
                      ).then(() => {
                       
                        removeFromCurrentOrders(value.id);

                        sendMessage(JSON.stringify({ orderStatus: 5 }));
                        window.postMessage({ pauseSound: true });
                      });
                    }
                  });
                }}
              >
                <span>Recusar pedido</span>
              </button>
              <button
                className="i-false btn btn-sm btn-primary"
                onClick={(e) => {
                  setTimeout(() => {
                    handlePrint();
                  }, 500);
                  handleMoveToNextColumn();
                  ChangeStatus({ id: value.id, status: 2 }, null, true).then(
                    () => {
                      currentOrderStatus(value.id, 2);
                      sendMessage(JSON.stringify({ orderStatus: 2 }));
                      window.postMessage({ pauseSound: true });
                    }
                  );
                }}
              >
                Aceitar Pedido
              </button>
            </div>
          )}
          {column === "column2" && (
            <div className="d-flex justify-content-end align-items-bottom">
              <button
                className="i-false btn btn-sm btn-success mt-1"
                onClick={(e) => {
                  let status = value?.order_to === "take_away" ? 6 : 3
                  handleMoveToNextColumn();
                  currentOrderStatus(value.id, status);
                  ChangeStatus({ id: value.id, status: status }, null, true).then(
                    () => {
                      sendMessage(JSON.stringify({ orderStatus: status }));
                    }
                  );
                }}
              >
                Despachar
              </button>
            </div>
          )}
          {column === "column3" && (
            <div className="d-flex justify-content-end align-items-bottom">
              <button
                className="i-false btn btn-sm btn-info mt-1"
                onClick={(e) => {
                  ChangeStatus({ id: value.id, status: 4 }, null, true).then(
                    () => {
                      sendMessage(JSON.stringify({ orderStatus: 4 }));
                      removeFromCurrentOrders(value.id);
                    }
                  );
                }}
              >
                Finalizar pedido
              </button>
            </div>
          )}
        </Card.Body>
      </Card>
      <div className="d-none">
        <OrderToPrint ref={componentRef} order={currentValue.current} />
      </div>
      {showModalRequestDriver && <ModalComponent props={modalProps} />}
    </>
  );
}
