import { destroyCookie, parseCookies } from 'nookies'
import { api, VerifyIfTokenNotExpired } from '../api/Middleware'
import { LOGIN_URL, USERS_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'
import Swal from 'sweetalert2'

export const HasToken = async (setUser, setLogged, history) => {
  /*
   *Verifica se tem o token e se está válido
   */
  try {
    let user = localStorage.getItem('user')
    if (user) {
      user = JSON.parse(user)
      let { token: token } = parseCookies()
      let tokenExpired = VerifyIfTokenNotExpired(token)

      if (!tokenExpired) {
        setLogged(true)
        setUser(user)
        history.push('/')
      } else {
        localStorage.removeItem('user')
        history.push('/login')
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const Auth = async (data, endpoint, setUser, setToken, setLogged) => {
  try {
    let response = await api.post(LOGIN_URL + endpoint + '/', data)
    if (response.status === 200) return response.data
  } catch (error) {
   
    //Não encontra o usuário então envia para o registro
    return error.response
    //setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
  }
}

export const Logout = () => {
  destroyCookie(null, 'token')
  localStorage.removeItem('user')
  sessionStorage.removeItem('plan_id')
  sessionStorage.removeItem('openStore')
  setTimeout(() => {
    window.location.href = APP_BASE_URL + 'login'
  }, 500);
}
