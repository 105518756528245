import React, { useEffect, useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Dropdown, DropdownButton, Button } from 'react-bootstrap'
import DataTablev2 from '../jsx/components/Datatable'
import { GetAll, Delete, Update } from '../services/ProductsServices'
import { Hash, MoneyFormat } from '../helpers/Helpers'
import DefaultLoader from '../jsx/components/Loader'
import Swal from 'sweetalert2'
import AlertResponses from '../jsx/components/Alert'
import UserContext from '../contexts/UserContext'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'
import Tutorial from '../jsx/components/Tutorial'

export default function Products () {
  const { user } = useContext(UserContext)
  const [products, setProducts] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [selectedRows, setSelectedRows] = useState(null)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [notify, setNotify] = useState({
    title: '',
    message: '',
    type: '',
    visible: false
  })
  const history = useHistory()

  useEffect(() => {
    if (user) {
      GetAll(setProducts, setIsLoading)
    }
  }, [user])

  const deleteRows = state => {
    if (
      state.selectedRows !== (null || undefined) &&
      state.selectedRows.length > 0
    ) {
      setSelectedRows(state.selectedRows)
      setShowDeleteButton(true)
    } else {
      setShowDeleteButton(false)
    }
  }

  const status = {
    0: {
      id: 0,
      label: 'Desativado',
      type: 'light'
    },
    1: {
      id: 1,
      label: 'Ativo',
      type: 'success'
    }
  }

  const BadgeStatus = item => {
    return (
      <DropdownButton
        variant=''
        title={status[item.status]?.label}
        className={`badge badge-${
          status[item.status]?.type
        } badge-sm light badge-delivery-status badge-${item.id}`}
        onSelect={e => {
          Update(item.id, { status: e }, false, setNotify, false, true)
            .then(res => {
              if (res) {
                setNotify({
                  title: 'Sucesso!',
                  message: 'Status atualizado com sucesso!',
                  type: 'success',
                  visible: true
                })
                document
                  .querySelector(`.badge-${item.id}`)
                  .classList.remove(`badge-${status[item.status]?.type}`)
                document
                  .querySelector(`.badge-${item.id}`)
                  .classList.add(`badge-${status[e].type}`)
                document.querySelector(`.badge-${item.id} button`).innerText =
                  status[e].label
              }
            })
            .catch(error => {
              console.log(error)
              setNotify({
                title: 'Oops!',
                message: ERROR_MESSAGES.products.update,
                type: 'error',
                visible: true
              })
            })
        }}
      >
        {Object.values(status).map((value, i) => (
          <Dropdown.Item key={i} eventKey={value.id}>
            {value.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    )
  }

  const actions = row => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant='' className='table-dropdown i-false'>
          <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <rect x='0' y='0' width='24' height='24'></rect>
              <circle fill='#000000' cx='5' cy='12' r='2'></circle>
              <circle fill='#000000' cx='12' cy='12' r='2'></circle>
              <circle fill='#000000' cx='19' cy='12' r='2'></circle>
            </g>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Link
            to={{
              pathname: `/ecom-product-detail/${row.id}`,
              product: row
            }}
            className='text-danger dropdown-item'
          >
            Editar
          </Link>
          <Dropdown.Item onClick={() => ''} className='text-danger'>
            Desativar
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      maxWidth: '5%'
    },
    {
      name: '#',
      selector: row => (
        <>
          <img
            src={
              row?.image
                ? Hash(
                    '100x80',
                    row.image.includes('/images/')
                      ? row.image.replace('/images/', '')
                      : row.image
                  )
                : '/imgs/default_img.png'
            }
            className='products-thumb'
            alt=''
          />
          {row.promotional_price && (
            <span
              className='badge-promotion'
              data-toggle='tooltip'
              title='Produto com desconto'
            >
              <i className='las la-tag text-white cursor-pointer'></i>
            </span>
          )}
        </>
      ),
      sortable: true,
      maxWidth: '10%'
    },
    {
      name: 'Nome',
      selector: row => (
        <Link
          to={{
            pathname: `/ecom-product-detail/${row.id}`,
            product: row
          }}
          className='fs-14'
        >
          <strong> {row.name}</strong>
        </Link>
      ),
      sortable: true,
      maxWidth: '20%'
    },
    {
      name: 'Categoria',
      selector: row => row.category_name,
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Descrição',
      selector: row => row.description,
      maxWidth: '25%'
    },
    {
      name: 'Preço',
      selector: row => MoneyFormat(row.price),
      maxWidth: '10%'
    },
    {
      name: 'Status',
      selector: row => BadgeStatus(row),
      maxWidth: '12.5%',
      allowOverflow: true
    }

    // {
    //   name: '',
    //   selector: (row) => actions(row),
    //   maxWidth: '5%',
    //   allowOverflow: true
    // },
  ]

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <>
          <div className='row'>
            <Tutorial videoId={'MVwBO80XBpI'} padding={'0 30px'} />
            <div className='col-xl-12 col-xxl-12 col-sm-12 page-header'>
              <div className='col-xl-4 col-xxl-5 col-sm-12'>
                <h2>Produtos</h2>
              </div>

              <div className='col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end'>
                {showDeleteButton && (
                  <Button
                    className='i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary'
                    onClick={() =>
                      Swal.fire({
                        title: 'Você tem certeza?',
                        text: 'Todos os arquivos selecionados serão deletados. Esta ação é irreversível!',
                        icon: 'warning',
                        buttons: true,
                        confirmButtonText: `Continuar a ação`,
                        cancelButtonText: `Cancelar`,
                        showCancelButton: true,
                        confirmButtonColor: 'red'
                      }).then(value => {
                        if (value.isConfirmed) Delete(selectedRows, setNotify)
                      })
                    }
                  >
                    <span>DELETAR SELECIONADOS</span>
                  </Button>
                )}

                <Button
                  className='i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary'
                  onClick={() => history.push('/ecom-product-detail')}
                >
                  <span>ADICIONAR</span>
                </Button>
              </div>
            </div>
          </div>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}

          <DataTablev2
            data={products}
            columns={columns}
            title='  '
            selectable={true}
            onSelectedRowsChange={deleteRows}
            showDeleteButton={showDeleteButton}
            filterByField={{
              labels: ['Nome', 'Categoria'],
              fields: ['name', 'category_name']
            }}
          />
        </>
      )}
    </>
  )
}
