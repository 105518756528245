import React, { useRef, useEffect } from "react";
import Wizard from "../jsx/components/Forms/Wizard/Wizard";
import { checkRegisterPlan } from "../helpers/Helpers";

const Register = () => {
  const ws = useRef(null);
  const code = new URLSearchParams(window.location.search).get("liberacao");
  const planCode = useRef(null);

  useEffect(() => {
    let r_storeData = localStorage.getItem("r_storeData");
    planCode.current = checkRegisterPlan(code);

    if (!r_storeData && !planCode.current) {
      window.location.href = "/login";
      return;
    }

    const connectWebSocket = () => {
      // const socketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}notifications/?ct=2&store=1`;
      const socketUrl = `wss://api.pralevar.app/ws/notifications/?ct=2&store=1`;
      
      ws.current = new WebSocket(socketUrl);

      ws.current.onopen = () => {
        ws.current.pingInterval = setInterval(() => {
          ws.current.send(JSON.stringify({ type: "ping" }));
        }, 10000);
      };

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.pix_id) {
          window.postMessage({ pix_id: data.pix_id });
        }
      };

      ws.current.onclose = () => {
        if (ws.current.pingInterval) {
          clearInterval(ws.current.pingInterval);
        }
        setTimeout(() => {
          connectWebSocket();
        }, 5000);
      };

      ws.current.onerror = (error) => {
        ws.current.close();
      };
    };

    connectWebSocket();

    // Limpar intervalos e conexão ao desmontar o componente
    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (ws.current.pingInterval) {
        clearInterval(ws.current.pingInterval);
      }
    };
  }, []);

  return (
    <>
      <div className="container h-100 pt-4">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-12 col-sm-12">
            <Wizard planCode={planCode.current} />
          </div>
          <div className="page-login footer">
            <div className="copyright">
              <p>
                Copyright © Desenvolvidor por{" "}
                <a href="https://pralevar.app" target="_blank">
                  Pralevar.app
                </a>{" "}
                {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
