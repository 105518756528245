import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { CreateStore, UpdateStore } from "../services/ConfigurationsServices";
import { AppSettings, Theme } from "../App";
import { Hash } from "../helpers/Helpers";
import UserContext from "../contexts/UserContext";
import { List } from "../services/StoreServices";
import AlertResponses from "../jsx/components/Alert";
import ModalComponent from "../jsx/components/Modal";
import CopyProducts from "../jsx/components/ModalData/CopyProducts";
import Tutorial from "../jsx/components/Tutorial";
import { AccessStore } from "../services/UsersServices";
import { destroyCookie, setCookie } from "nookies";

const styles = {
  thumb: {
    maxWidth: 250,
    maxHeight: 165,
  },
  imageDefault: {
    height: 200,
    width: 200,
    margin: "auto",
  },
  logo: {
    maxWidth: 200,
    maxHeight: 200,
    margin: "auto",
  },
  splashScreen: {
    width: "100%",
    margin: "auto",
    minHeight: 500,
    objectFit: "contain",
    border: "1px solid #eaeaea",
    boxShadow: "0px 12px 23px 0px rgb(62 63 122 / 4%)",
  },
  info: {
    fontSize: "1.25rem",
    verticalAlign: "sub",
  },
};

export default function Stores() {
  const { user } = useContext(UserContext);
  const [stores, setStores] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const storeData = useRef();

  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const {
    register: registerStore,
    handleSubmit: handleSubmitStore,
    setValue: setValueStore,
    control,
    setError: setErrorStore,

    formState: { errors: errorsStore },
  } = useForm();

  useEffect(() => {
    List(AppSettings.store.url, setStores, setIsLoading, setNotify);
  }, []);

  const imagePreview = (image, set) => {
    if (image?.size > 2000000) {
      Swal.fire('Atenção!', 'Imagem muito pesada! Por favor, insira imagens até 2MB.', 'info')
      return
    }
    let url = URL.createObjectURL(image);
    set(url);
  };

  const status = {
    0: {
      id: 0,
      label: "Desativada",
      type: "light",
    },
    1: {
      id: 1,
      label: "Ativa",
      type: "success",
    },
  };

  const modalProps = {
    title: "Copiar Produtos",
    content: (
      <CopyProducts
        storeData={storeData.current}
        setShowModal={setShowModal}
        setNotify={setNotify}
      />
    ),
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  // const onSubmitStore = (value) => {
  //     let form = new FormData();
  //     let obj = {}

  //     document.querySelectorAll(".input-opening-hours").forEach(input => {
  //         obj[input.name] = input.value
  //     })

  //     form.append("razaoSocial", value.razaoSocial);
  //     form.append("name", value.name);
  //     form.append("email", value.email);
  //     form.append("description", value.description);
  //     form.append("openingHours", JSON.stringify(obj))

  //     if (value.cnpj) {
  //         value.cnpj = value.cnpj.replace(/[^0-9]/g, '');
  //     }

  //     form.append("cnpj", value.cnpj);

  //     /* form.append(
  //       "openingHours",
  //       JSON.stringify({
  //         monday: value.monday,
  //         tuesday: value.tuesday,
  //         wednesday: value.wednesday,
  //         thursday: value.thursday,
  //         friday: value.friday,
  //         saturday: value.saturday,
  //         sunday: value.sunday,
  //         mondayInt2: value.mondayInt2,
  //         tuesdayInt2: value.tuesdayInt2,
  //         wednesdayInt2: value.wednesdayInt2,
  //         thursdayInt2: value.thursdayInt2,
  //         fridayInt2: value.fridayInt2,
  //         saturdayInt2: value.saturdayInt2,
  //         sundayInt2: value.sundayInt2,
  //       })
  //     ); */

  //     let address = [];

  //     if (value.address) {

  //         form.append(
  //             "address",
  //             JSON.stringify({
  //                 street: value.address.address.street,
  //                 number: value.number,
  //                 neighborhood: value.address.address.district,
  //                 city: value.address.address.city,
  //                 state: value.address.address.state,
  //                 complete_address: value.address.address.houseNumber ? value.address.title : `${value.address.address.street}, ${value.number}, ${value.address.address.district}, ${value.address.address.city} - ${value.address.address.stateCode},
  //      ${value.address.address.postalCode ? value.address.address.postalCode : ''}, ${value.address.address.countryName}`,
  //             })
  //         );
  //     }

  //     let phone = document.getElementsByName("phone")[0].value;
  //     let cellPhone = document.getElementsByName("cellPhone")[0].value;

  //     if (phone != ("" || null || undefined)) {
  //         form.append("phone", phone.replace(/[^0-9]/g, ""));
  //     } else {
  //         form.append(
  //             "phone",
  //             value.phone == undefined || "" ? "" : value.phone.replace(/[^0-9]/g, "")
  //         );
  //     }

  //     if (cellPhone != ("" || null || undefined)) {
  //         form.append("cellPhone", cellPhone.replace(/[^0-9]/g, ""));
  //     } else {
  //         form.append(
  //             "cellPhone",
  //             value.cellPhone == undefined || ""
  //                 ? ""
  //                 : value.cellPhone.replace(/[^0-9]/g, "")
  //         );
  //     }

  //     value.image.length > 0 && form.append("image", value.image[0]);
  //     form.append("store", process.env.REACT_APP_DOMAIN);

  //     if (AppSettings.store.id) {
  //         UpdateStore(AppSettings.store.id, form, setNotify);
  //     } else {
  //         CreateStore(form, setNotify);
  //     }
  // };

  const [demoData, setDemoData] = useState(AppSettings.store?.demo_data);
  const closed = useRef(
    AppSettings.store?.closed.closed &&
      AppSettings.store?.closed.date === new Date().toISOString().slice(0, 10)
      ? true
      : false
  );

  const updateMode = (e, store) => {
    if (!store.openingHours) {
      Swal.fire(
        "Oops!",
        "Parece que você ainda não definiu seu horário de funcionamento, por favor, cadastre seus horários para tirar do modo demonstração.",
        "warning"
      );
      return;
    }

    UpdateStore(
      store.id,
      { demo_data: e.target.checked ? 1 : 0 },
      setNotify,
      true
    )
      .then((res) => {
        if (res.status === 400) {
          Swal.fire("Oops!", res.data, "warning");
          setDemoData(1);
          return;
        }

        let message = e.target.checked
          ? (message = "Sua loja está configurada e no ar!")
          : "Sua loja está em modo demonstrativo!";

        setNotify({
          title: "Sucesso!",
          message: message,
          type: "success",
          visible: true,
        });
        setDemoData(e.target.checked);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAccessStore = async (store_id) => {
    let res = await AccessStore({ store_id: store_id });
    console.log(res);
    if (res) {
      destroyCookie(null, "token");
      setCookie(undefined, "token", res.access, {
        maxAge: 60 * 60 * 24 * 7,
      });
      let _user = user;
      _user["store_id"] = store_id;
      localStorage.setItem("user", JSON.stringify(_user));

      window.location.reload();
    }
  };

  return (
    <>
      <Tutorial videoId={"gKyHAE8AGgI"} padding={"0 30px"} />
      {/* <div id="home-top-buttons" className="col-xl-12 col-sm-12">
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Modo demonstrativo"
            checked={demoData}
            defaultChecked={demoData}
            onChange={(e) => updateMode(e)}
          />
        </Form>
        <Form>
          <Form.Check
            type="switch"
            id="close-store"
            label="Fechar Loja"
            //checked={}
            defaultChecked={closed.current ? true : false}
            onChange={(e) => closeStore(e)}
          />
        </Form>
      </div> */}
      <div className="item">
        <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
          <div className="col-xl-4 col-xxl-5 col-sm-12">
            <h2 className="mb-4">Lojas</h2>
          </div>
          {user?.superuser_level == (1 || 2) && user?.plan_id > 3 && (
            <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
              <Button
                className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                href="/store-details"
              >
                <span>ADICIONAR</span>
              </Button>
            </div>
          )}
        </div>
      </div>
      {stores &&
        stores.map((store) => (
          <Col xl="4" key={store.id} className="d-inline-block col-sm-6">
            <Card className="mb-3 align-items-center">
              <img
                className="card-img-top img-fluid mt-4"
                src={Hash("400x400", "store/" + store.image.split("/").pop())}
                style={{
                  width: 220,
                  height: 180,
                  borderRadius: 10,
                  objectFit: "cover",
                }}
              />
              <Card.Header className="m-auto">
                <Card.Title>{store.title}</Card.Title>
              </Card.Header>
              {user?.superuser_level == (1 || 2) && (
                <Card.Body className="">
                  <div className="d-flex justify-content-center align-items-baseline mx-4">
                    {store.id !== AppSettings.store.main && (
                      <DropdownButton
                        variant=""
                        style={styles.dropdown}
                        title={store.status === 1 ? "Ativa" : "Desativada"}
                        className={`badge badge-${
                          store.status === 1 ? "success" : "light"
                        } badge-sm light badge-delivery-status badge-${
                          store.id
                        }`}
                        onSelect={(e) => {
                          UpdateStore(store.id, { status: e }, setNotify).then(
                            (res) => {
                              if (res) {
                                document
                                  .querySelector(`.badge-${store.id}`)
                                  .classList.remove(
                                    `badge-${
                                      store.status === 1 ? "success" : "light"
                                    }`
                                  );
                                document
                                  .querySelector(`.badge-${store.id}`)
                                  .classList.add(`badge-${status[e].type}`);
                                document.querySelector(
                                  `.badge-${store.id} button`
                                ).innerText = status[e].label;
                              }
                            }
                          );
                        }}
                      >
                        {Object.values(status).map((value, i) => (
                          <Dropdown.Item key={i} eventKey={value.id}>
                            {value.label}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    )}

                    <DropdownButton
                      variant=""
                      style={styles.dropdown}
                      title="Ações"
                      className={`badge badge-primary badge-sm light badge-delivery-status ml-4`}
                      onSelect={(e) => {}}
                    >
                      {/* store.id !== AppSettings.store.main */ user?.superuser_level == (1 || 2) && (
                        <Dropdown.Item
                          onClick={() => handleAccessStore(store.id)}
                        >
                          Acessar Loja
                        </Dropdown.Item>
                      )}
                      <Link
                        to={{
                          pathname: `/store-details/${store.id}`,
                          store: store,
                        }}
                        className="dropdown-item"
                      >
                        Editar
                      </Link>

                      {/* {
                                            store.id !== AppSettings.store.main &&
                                            <Link to="#" className="text-danger dropdown-item" onClick={() => { storeData.current = store; setShowModal(true); }}>Copiar Produtos</Link>
                                        } */}
                      {store.id !== AppSettings.store.main && (
                        <Dropdown.Item
                          onClick={() =>
                            Swal.fire({
                              title: "Você tem certeza?",
                              html: "Todos os produtos, promoções e cupons desta categoria <span class='text-primary'>TAMBÉM SERÃO DELETADOS</span>. <br><b class='text-primary'>Esta ação é irreversível!<b></br>",
                              icon: "warning",
                              buttons: true,
                              confirmButtonText: `Continuar ação`,
                              cancelButtonText: `Cancelar`,
                              showCancelButton: true,
                              confirmButtonColor: "red",
                            }).then((value) => {
                              if (value.isConfirmed) {
                                /*  Delete(store.id, setNotify, setProgress) */
                              }
                            })
                          }
                        >
                          Excluir
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>

                  <div className="">
                    <Form className="my-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Modo demonstrativo"
                        defaultChecked={store.demo_data === 1 ? true : false}
                        onChange={(e) => updateMode(e, store)}
                      />
                    </Form>
                  </div>
                </Card.Body>
              )}
            </Card>
          </Col>
        ))}
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
      {/* {showModal && <ModalComponent props={modalProps} />} */}
    </>
  );
}
