import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import MetisMenu from 'metismenujs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AppSettings } from '../../../App';
import UserContext from '../../../contexts/UserContext';
import Tutorial from '../../components/Tutorial';

const MM = React.memo(({ children }) => {
  const mmEl = React.useRef(null);

  useEffect(() => {
    const mm = new MetisMenu(mmEl.current);
    return () => {
      mm.dispose();
    };
  }, []);

  return (
    <div className="mm-wrapper">
      <ul className="metismenu" ref={mmEl}>
        {children}
      </ul>
    </div>
  );
});

const SideBar = () => {
  const { user, setShowPlansModal } = useContext(UserContext);
  const location = useLocation();
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 991 });

  const toggleSidebar = useCallback(() => {
    const wrapper = document.querySelector('#main-wrapper');
    wrapper.classList.toggle('menu-toggle');
    setIsSidebarActive((prev) => !prev);
  }, []);

  useEffect(() => {
    const btn = document.querySelector('.nav-control');
    btn.addEventListener('click', toggleSidebar);
    return () => btn.removeEventListener('click', toggleSidebar);
  }, [toggleSidebar]);

  const handleSidebarMouseEnter = useCallback(() => {
    if (!isMobile) setIsSidebarActive(true);
  }, [isMobile]);

  const handleSidebarMouseLeave = useCallback(() => {
    if (!isMobile) setIsSidebarActive(false);
  }, [isMobile]);

  const closeMenu = useCallback(() => {
    if (isMobile) {
      toggleSidebar();
    }
  }, [isMobile, toggleSidebar]);

  const menuItems = useMemo(() => [
    { path: '/', icon: 'las la-chevron-right', text: 'Início' },
    {
      text: 'Venda',
      icon: 'ti-shopping-cart-full',
      children: [
        { path: '/categories', icon: 'ti-view-grid', text: 'Categorias' },
        { path: '/products', icon: 'ti-shopping-cart-full', text: 'Produtos' },
        { 
          path: user?.plan_id < 3 ? '#' : '/qrcode', 
          icon: 'las la-qrcode', 
          text: 'Gerador de QrCode',
          locked: user?.plan_id < 3
        },
        { path: '/analytics', icon: 'ti-bar-chart', text: 'Desempenho' },
      ],
    },
    {
      text: 'Marketing',
      icon: 'ti-announcement',
      children: [
        { 
          path: user?.plan_id === 1 ? '#' : '/promotions', 
          icon: 'las la-percentage', 
          text: 'Promoções',
          locked: user?.plan_id === 1
        },
        { 
          path: user?.plan_id === 1 ? '#' : '/coupons', 
          icon: 'ti-ticket', 
          text: 'Cupons',
          locked: user?.plan_id === 1
        },
        { 
          path: user?.plan_id < 3 ? '#' : '/campaigns', 
          icon: 'fa fa-paper-plane-o fs-18', 
          text: 'Campanhas',
          locked: user?.plan_id < 3
        },
        { 
          path: user?.plan_id < 3 ? '#' : '/cashback', 
          icon: 'las la-wallet', 
          text: 'Cashback',
          locked: user?.plan_id < 3
        },
        { path: '/general-customers', icon: 'ti-user', text: 'Clientes' },
      ],
    },
    {
      text: 'Configurações',
      icon: 'ti-settings',
      children: [
        { 
          path: user?.plan_id < 3 ? '#' : '/whatsapp', 
          icon: 'lab la-whatsapp', 
          text: 'Whatsapp',
          locked: user?.plan_id < 3
        },
        { path: '/payment-methods', icon: 'ti-credit-card', text: 'Formas de pagamento' },
        { path: '/delivery-fee', icon: 'las la-calculator', text: 'Taxa de entrega' },
        { 
          path: user?.plan_id === 1 ? '#' : '/users', 
          icon: 'ti-id-badge', 
          text: 'Usuários',
          locked: user?.plan_id === 1
        },
        ...(user?.superuser_level === 1 || user?.superuser_level === 2 ? [
          { path: '/customizations', icon: 'ti-wand', text: 'Personalizações' }
        ] : []),
        { path: '/stores', icon: 'ti-home', text: 'Lojas' },
      ],
    },
    { 
      path: user?.plan_id === 1 ? '#' : '/ai', 
      icon: 'las la-brain', 
      text: 'Inteligência Artificial',
      locked: user?.plan_id < 3
    },
    { path: '/integrations', icon: 'las la-share-alt', text: 'Apps & Integrações' },
    { path: '/contact', icon: 'ti-email', text: 'Contatos via site' },
    ...(user?.plan_id === 1 || user?.plan_id === 2 ? [
      { path: '/support', icon: 'ti-headphone-alt', text: 'Suporte' }
    ] : []),
    { 
      path: '#', 
      icon: 'las la-crown', 
      text: 'Meu Plano',
      onClick: () => setShowPlansModal(true)
    },
  ], [user, setShowPlansModal]);

  const renderMenuItem = useCallback((item) => {
    if (item.children) {
      return (
        <li key={item.text} className={location.pathname.includes(item.path) ? 'mm-active' : ''}>
          <Link className="has-arrow ai-icon" to="#">
            <i className={item.icon}></i>
            <span className="nav-text">{item.text}</span>
          </Link>
          <ul>
            {item.children.map(renderMenuItem)}
          </ul>
        </li>
      );
    }

    return (
      <li key={item.path} className={location.pathname === item.path ? 'mm-active' : ''}>
        <Link to={item.path} onClick={item.onClick || closeMenu}>
          <i className={item.icon} style={styles.icon}></i>
          <span className="nav-text">
            {item.locked && <i className="ti-lock mr-2" aria-hidden="true"></i>}
            {item.text}
          </span>
        </Link>
      </li>
    );
  }, [location.pathname, closeMenu]);

  return (
    <div
      className={`deznav ${isSidebarActive ? 'active' : ''}`}
      onMouseEnter={handleSidebarMouseEnter}
      onMouseLeave={handleSidebarMouseLeave}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM>
          <li>
            <a
              href={`${window.location.protocol}//${AppSettings.store?.url}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="ti-link"></i>
              <span className="nav-text">Acessar site</span>
            </a>
          </li>
          {menuItems.map(renderMenuItem)}
          <li>
            <Tutorial
              modalTitle="Conhecendo e configurando sua plataforma"
              videoId="uWZ543aOTvY"
              classes="mt-2 nav-text"
              badgeClasses="w-100"
              dontShowAgain={true}
            />
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default React.memo(SideBar);

const styles = {
  icon: {
    fontSize: '1.6rem',
    marginTop: '-4px',
    marginLeft: '-3px',
  },
};