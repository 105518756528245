import React, { Fragment, useRef,useEffect } from 'react'

import Multistep from 'react-multistep'

import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import Logo from '../../../../images/logo.webp'

const Wizard = ({planCode}) => {
  const user = useRef()
  const store = useRef()
  const storePlan = useRef()
  const customizations = useRef({
    colors: {
      theme: 'light',
      titles: '#212529',
      primary: '#e3b524',
      tertiary: '#f1da92',
      subtitles: '#515151',
      background: '#ffffff',
      primaryHover: '#b6911d'
    },
    layout: {
      title: 'O melhor hamburguer da cidade está aqui!',
      subtitle:
        'Venha ter uma experiência gastronômica diferente de tudo que já experimentou.'
    },
    logo_orientation: 'horizontal'
  })

  const steps = [
    
    { name: 'Dados da empresa', component: <StepTwo store={store} /> },
    { name: 'Dados do administrador', component: <StepOne user={user} /> },
    {
      name: 'Escolha seu domínio',
      component: (
        <StepFour store={store} user={user} customizations={customizations} />
      )
    },
    {
      name: 'Escolha seu plano',
      component: <StepThree storePlan={storePlan} user={user} planCode={planCode}/>
    },
  ]
  const prevStyle = {
    background: '#F1A9BE',
    borderWidth: '0px',
    color: '#fff',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '600',
    padding: '0.55em 2em',
    border: '1px solid ##E5B6C4',
    marginRight: '1rem'
  }
  const nextStyle = {
    background: 'var(--primary) ',
    borderWidth: '0px',
    color: '#fff',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '600',
    padding: '0.55em 2em',
    visibility: "hidden"
  }

  useEffect(() => {
    const Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date()
    ;(function () {
      const s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = 'https://embed.tawk.to/636a5b37b0d6371309cdf338/1ghbn8kkv'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    })()
  }, [])

  return (
    <Fragment>
      {/*  <PageTitle activeMenu="Components" motherMenu="Home" /> */}

      <div className='row'>
        <div className='col-xl-12 col-xxl-12 mt-4'>
          <div className='card'>
            <div className='card-header flex-column'>
              <img
                className='logo-img'
                src={Logo}
                alt='sistema-para-delivery-pralevar'
                width='300'
              />
            </div>
            <div className='card-body step-form-horizontal'>
              <Multistep
                showNavigation={true}
                steps={steps}
                prevStyle={prevStyle}
                nextStyle={nextStyle}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Wizard
