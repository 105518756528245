import React, { useEffect, useContext } from 'react'
import UserContext from '../../contexts/UserContext'

const Footer = () => {
  const { user } = useContext(UserContext)
  const d = new Date()

  useEffect(() => {
    if (user?.plan_id > 2) {
      const Tawk_API = {},
        Tawk_LoadStart = new Date()
      ;(function () {
        const s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0]
        s1.async = true
        s1.src = 'https://embed.tawk.to/636a5b37b0d6371309cdf338/1ghbn8kkv'
        s1.charset = 'UTF-8'
        s1.setAttribute('crossorigin', '*')
        s0.parentNode.insertBefore(s1, s0)
      })()
    }
  }, [user])

  return (
    <div className='footer'>
      <div className='copyright'>
        <p>
          Copyright © Desenvolvidor por{' '}
          <a href='https://pralevar.app' target='_blank'>
            Pralevar.app
          </a>{' '}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  )
}

export default Footer
