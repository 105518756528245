import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import DefaultLoader from "../jsx/components/Loader";
import AlertResponses from "../jsx/components/Alert";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import ModalComponent from "../jsx/components/Modal";
import Tutorial from "../jsx/components/Tutorial";
import { ChangeStatus, Delete, DownloadQrCode, GetQrcodes } from "../services/QrCodeServices";
import DatatablePaginated from "../jsx/components/DatatablePaginated";
import UpsertQrCode from "../jsx/components/ModalData/UpsetQrCode";
import { Hash } from "../helpers/Helpers";
import UserContext from "../contexts/UserContext";

export default function QrCodeGenerator() {
  const { user } = useContext(UserContext);
  const data = useRef(null);
  const qrUrl = useRef(null);
  const [qrcodes, setQrCodes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showQrImageModal, setShowQrImageModal] = useState(false);

  useEffect(() => {
    if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    }

    GetQrcodes(setQrCodes, setTotalRows, setIsLoading);
  }, []);

  const deleteRows = (state) => {
    if (state.selectedRows && state.selectedRows.length > 0) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  };

  const ShowQrcode = () => {
    return(
      <div className="text-center">
        <img src={Hash('400x400', qrUrl.current)} className="w-100" alt=""/>
        <Button type="button" className="btn btn-primary" onClick={() => downloadImage(qrUrl.current)}>Baixar Qrcode</Button>
      </div>
    )
  }

  const modalProps = {
    title: "QrCode",
    content: (
      <UpsertQrCode
        data={data.current}
        setNotify={setNotify}
        setShowModal={setShowModal}
      />
    ),
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const modalImageProps = {
    title: "Meu QrCode",
    content: (
      <ShowQrcode
        setNotify={setNotify}
        setShowModal={setShowModal}
      />
    ),
    btnLabel: "Salvar",
    showModal: showQrImageModal,
    setShowModal: setShowQrImageModal,
    showFooter: false,
  };

  const status = {
    0: {
      id: 0,
      label: "Desativado",
      type: "light",
    },
    1: {
      id: 1,
      label: "Ativo",
      type: "success",
    },
  };

  const qrType = {
    "link": "Link",
    "table": "Mesa",
    "product": "Produto",
    "promotion": "Promoção"
  };

  const handlePageChange = (page) => {
    GetQrcodes(setQrCodes, setTotalRows, setIsLoading, page);
  };

  const BadgeComponentMemo = (row) => {
    return useMemo(() => {
      return (
        <DropdownButton
          variant=""
          style={styles.dropdown}
          title={status[row.status].label}
          className={`badge badge-${
            status[row.status].type
          } badge-sm light badge-custom-status badge-${row.id}`}
          onSelect={(e) => {
            ChangeStatus(
              { id: row.id, status: e },
              setNotify,
              setProgress
            ).then((res) => {
              if (res) {
                document
                  .querySelector(`.badge-${row.id}`)
                  .classList.remove(`badge-${status[row.status].type}`);
                document
                  .querySelector(`.badge-${row.id}`)
                  .classList.add(`badge-${status[e].type}`);
                document.querySelector(`.badge-${row.id} button`).innerText =
                  status[e].label;
              }
            });
          }}
        >
          {Object.values(status).map(
            (value, i) =>
              i !== 5 && (
                <Dropdown.Item key={i} eventKey={value.id}>
                  {value.label}
                </Dropdown.Item>
              )
          )}
        </DropdownButton>
      );
    }, [qrcodes]);
  };

  const downloadImage = async (imageUrl, fileName) => {
    try {
      const response = await DownloadQrCode(imageUrl, setNotify)

      // Cria um link temporário para download do arquivo
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
      const link = document.createElement('a');
      link.href = url;

      // Define o nome do arquivo a ser baixado com extensão .png
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'qrcode.png'; // Nome padrão com extensão .png
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      if (!fileName.endsWith('.png')) {
        fileName += '.png'; // Garante que a extensão seja .png
      }
      link.setAttribute('download', fileName);

      // Adiciona o link ao DOM, clica nele e o remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowQrImageModal(false)
      setNotify({
        title: "Sucesso!",
        message: "Qrcode baixado com sucesso!",
        type: "success",
        visible: true,
      })
    } catch (error) {
      console.error('Erro ao baixar o arquivo', error);
    }
  };

  const columns = [
    {
      name: "Qrcode",
      selector: (row) => (
        <Link
          to="#"
          className="text-capitalize"
          onClick={() => {
            qrUrl.current = row.image;
            setShowQrImageModal(true);
          }}
        >
          <img src={Hash("40x40", row.image)} width={50} height={50} alt="" />
        </Link>
      ),
      sortable: true,
      maxWidth: "35%",
    },
    {
      name: "Nome",
      selector: (row) => <span className="text-capitalize">{row.name}</span>,
      sortable: true,
      maxWidth: "35%",
    },
    {
      name: "Tipo",
      selector: (row) => <span className="text-capitalize"> {qrType[row.type]}</span>,
      sortable: true,
      maxWidth: "10%",
    },
    {
      name: "Mesa",
      selector: (row) => row.table,
      sortable: true,
      maxWidth: "10%",
    },
    {
      name: "Link",
      selector: (row) => row.url,
      sortable: true,
      maxWidth: "30%",
    },
    {
      name: "Criado em",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      maxWidth: "15%",
      allowOverflow: true,
    },
    {
      name: "Status",
      selector: (row) => BadgeComponentMemo(row),
      sortable: true,
      maxWidth: "15%",
      allowOverflow: true,
    },
  ];

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <>
          <Tutorial videoId={"AZc5fnzwj_s"} padding={"0 15px"} />
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
              <div className="col-xl-4 col-xxl-5 col-sm-12">
                <h2>Gerador de QrCode</h2>
              </div>

              <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                {showDeleteButton && (
                  <Button
                    className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                    onClick={() =>
                      Swal.fire({
                        title: "Você tem certeza?",
                        text: "Todos os QrCodes selecionados serão deletados e ficarão indisponíveis. Esta ação é irreversível!",
                        icon: "warning",
                        buttons: true,
                        confirmButtonText: `Tenho certeza, continuar`,
                        cancelButtonText: `Cancelar`,
                        showCancelButton: true,
                        confirmButtonColor: "var(--secondary)",
                        cancelButtonColor: "red",
                      }).then((value) => {
                        if (value.isConfirmed)
                          Delete(selectedRows, setNotify, setProgress);
                      })
                    }
                  >
                    <span>DELETAR SELECIONADOS</span>
                  </Button>
                )}
                <Button
                  className="i-false btn btn-primary mb-4 float-right"
                  onClick={() => {
                    data.current = "";
                    setShowModal(true);
                  }}
                >
                  <span>GERAR QRCODE</span>
                </Button>
              </div>
            </div>
          </div>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}

          <DatatablePaginated
            data={qrcodes}
            columns={columns}
            title="  "
            selectable={true}
            onSelectedRowsChange={deleteRows}
            showDeleteButton={showDeleteButton}
            filterDefaultField={"type"}
            filterByField={{
              labels: ["Tipo", "Nome"],
              fields: ["type", "name"],
            }}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handlePageChange={handlePageChange}
            totalRows={totalRows}
          />

          {progress && <ProgressBarComponent />}
          {showModal && <ModalComponent props={modalProps} />}
          {showQrImageModal && <ModalComponent props={modalImageProps} />}
        </>
      )}
    </>
  );
}

const styles = {
  photoDefault: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    background: "#efefef",
  },
  i: {
    fontSize: "18px",
    color: "#3d4465",
  },
};
