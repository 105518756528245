import React from "react";
import { Hash } from "../../../helpers/Helpers";

const WhatsAppChat = ({ image }) => {
  return (
    <div className="whatsapp-container">
      <div className="chat-area">
        <div className="chat-bubble">
          <div>
            {image && (
              <img
                src={
                  !image.includes('blob')
                    ? Hash(
                        "150x100",
                        image.includes("/images/")
                          ? image.replace("/images/", "")
                          : image
                      )
                    : image
                }
                alt=""
              />
            )}
            <br></br> <span className="text"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppChat;
