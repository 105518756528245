import React, { useEffect, useState, useContext } from "react";

import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import DefaultLoader from "../Loader";
import { CreateNewQrCode } from "../../../services/QrCodeServices";
import UserContext from "../../../contexts/UserContext";
import { GetAll as GetProducts } from "../../../services/ProductsServices";
import { GetAll as GetPromotions } from "../../../services/PromotionsServices";
import { Slugfy } from "../../../helpers/Helpers";
import { AppSettings } from "../../../App";

export default function UpsertQrCode(props) {
  const { user } = useContext(UserContext);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  const handleSelectProductOrPromotion = (e, field, _type) => {
    field.onChange(e);

    let slug = Slugfy(`${e.label}-${e.value}`);
    setValue("url", `https://${AppSettings.store.url}/${slug}`);
    setValue("type", _type);
  };

  const onSubmit = async (data) => {
    Swal.fire({
      title: "Atenção!",
      text: "Não será possível editar este QrCode posteriormente, revise as informações antes de prosseguir.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Entendi, continuar!",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "var(--primary)",
      cancelButtonColor: "var(--secondary)",
    }).then((res) => {
      if (res.isConfirmed) {
        setIsLoading(true);

        const payload = {
          name: data.name,
          type: data.type,
          url: data?.url || "",
          table: data?.table || "",
          status: data.status,
        };

        if (data.table) {
          let tableUrl = `https://${AppSettings.store.url}/?qrtb=${data.table}`;
          payload.url = tableUrl;
        }

        CreateNewQrCode(payload).then((res) => {
          if (res) {
            props.setNotify({
              title: "Sucesso!",
              message: "QrCode criado com sucesso!",
              type: "success",
              visible: true,
            });
            props.setShowModal(false);
            setTimeout(() => {
              window.location.href = "/qrcode";
            }, 3000);
          } else {
            setIsLoading(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    }

    GetProducts(false, false, false, true).then((res) => {
      let options = res.map((product) => {
        return { label: product.name, value: product.id };
      });

      setProducts(options);
    });
    GetPromotions(false, false, false, true).then((res) => {
      let options = res.map((promotion) => {
        return { label: promotion.name, value: promotion.id };
      });

      setPromotions(options);
    });
  }, []);

  return (
    <div id="register-new-ai-agent">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          {console.log(errors)}
          <div className="form-group col-md-12">
            <label>Nome</label>
            <input
              {...register("name", {
                required: true,
                maxLength: 100,
              })}
              className="form-control text-black"
              defaultValue={""}
            />
            {errors.name && (
              <small className="ml-2 text-danger">
                {errors.name.type === "required"
                  ? "Por favor, preencha este campo!"
                  : "Este campo deve conter no máximo 20 caracteres!"}
              </small>
            )}
          </div>

          <div className="form-group col-md-12">
            <label>Tipo</label>
            <select
              className="form-control"
              value={type}
              {...register("type")}
              onChange={handleChangeType}
            >
              <option value="">Selecione um tipo...</option>
              <option value="table">Mesa</option>
              <option value="link">Link</option>
              <option value="product">Produto</option>
              <option value="promotion">Promoção</option>
            </select>
          </div>
          {type === "table" && (
            <div className="form-group col-md-12">
              <label>Mesa</label>
              <input
                {...register("table", { required: true })}
                className="form-control text-black"
                placeholder="Nº ou identificador da mesa"
                defaultValue={""}
              />
              {errors.type && (
                <small className="ml-2 text-danger">
                  {errors.type.type === "required"
                    ? "Por favor, preencha este campo!"
                    : "Este campo deve conter no máximo 20 caracteres!"}
                </small>
              )}
            </div>
          )}
          {type === "link" && (
            <div className="form-group col-md-12">
              <label>Link</label>
              <input
                {...register("link", {
                  required: true,
                  pattern: urlPattern,
                })}
                className="form-control text-black"
                placeholder="exemplo: https://minhaloja.com.br, não utilize www."
                defaultValue={""}
              />
              {errors.link && (
                <small className="ml-2 text-danger">
                  {errors.link.type === "required"
                    ? "Por favor, preencha este campo!"
                    : "Insira uma URL válida! Utilize https:// ao invés de www"}
                </small>
              )}
            </div>
          )}
          {type === "product" && (
            <div className="form-group col-md-12">
              <label>Produto</label>
              <Controller
                name={"option"}
                control={control}
                rules={{ required: true }}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="rounded-form-control w-100"
                    placeholder={`Selecione um produto...`}
                    cacheOptions
                    options={products}
                    onChange={(e) =>
                      handleSelectProductOrPromotion(e, field, "product")
                    }
                    value={field.value}
                  />
                )}
              />
              {errors.option && (
                <small className="ml-2 text-danger">
                  {errors.option.type === "required" &&
                    "Por favor, selecione uma opção!"}
                </small>
              )}
            </div>
          )}
          {type === "promotion" && (
            <div className="form-group col-md-12">
              <label>Promoções</label>
              <Controller
                name={"option"}
                control={control}
                rules={{ required: true }}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="rounded-form-control w-100"
                    placeholder={`Selecione uma promoção...`}
                    cacheOptions
                    options={promotions}
                    onChange={(e) =>
                      handleSelectProductOrPromotion(e, field, "promotion")
                    }
                    value={field.value}
                  />
                )}
              />
              {errors.option && (
                <small className="ml-2 text-danger">
                  {errors.option.type === "required" &&
                    "Por favor, selecione uma opção!"}
                </small>
              )}
            </div>
          )}
          <div className="form-group col-md-12">
            <label>Status</label>
            <select
              className="form-control"
              defaultValue={1}
              {...register("status")}
            >
              <option value="1">Ativo</option>
              <option value="0">Desativado</option>
            </select>
          </div>
        </div>

        <Button
          type="submit"
          className="btn btn-block btn-primary"
          disabled={isLoading ? true : false}
        >
          {isLoading ? <DefaultLoader color={"white"} /> : "Gerar QrCode"}
        </Button>
      </form>
    </div>
  );
}
