import React, { useEffect, createContext, useState, useRef } from "react";
import { parseCookies } from "nookies";
import jwt_decode from "jwt-decode";
import ModalComponent from "../jsx/components/Modal";
import Plans from "../jsx/components/ModalData/Plans";
import { OpenStore } from "../services/StoreServices";

const UserContext = createContext();

export function UserProvider({ children, userName }) {
  const { token: _token } = parseCookies();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [logged, setLogged] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const superuser = {
    1: "Admin",
    2: "Gerente",
    3: "Supervisor",
    4: "Atendente",
  };
  const userPlan = useRef("");

  const plans = {
    1: "Gratuito",
    2: "Básico",
    3: "Profissional",
    4: "Rede",
    5: "Franquia",
  };

  const modalPlansProps = {
    title: "FAÇA UM UPGRADE EM SEU PLANO",
    content: <Plans />,
    btnLabel: "Salvar",
    showModal: showPlansModal,
    setShowModal: setShowPlansModal,
    size: "xl",
    showFooter: false,
  };

  useEffect(() => {
    if (_token) {
      let _user = jwt_decode(_token);
      delete _user.access;
      delete _user.jti;
      delete _user.exp;
      delete _user.token_type;
      userName.current = _user?.first_name;
      userPlan.current = plans[_user.plan_id];
      setUser(_user);
      let openStore = sessionStorage.getItem("openStore");
      if (!openStore) {
        OpenStore()
          .then((res) => sessionStorage.setItem("openStore", 1))
          .catch((error) => sessionStorage.setItem("openStore", 0));
      }
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        logged,
        setLogged,
        token,
        setToken,
        showPlansModal,
        setShowPlansModal,
        superuser,
        userPlan,
      }}
    >
      {children}
      {showPlansModal && <ModalComponent props={modalPlansProps} />}
    </UserContext.Provider>
  );
}

export default UserContext;
