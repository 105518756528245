export const ERROR_MESSAGES = {
    store: {
        get: 'Desculpe, ocorreu um problema inesperado ao tentar recuperar os dados de sua loja. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar os dados de sua loja. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
        open: 'Desculpe, ocorreu um problema inesperado ao tentar abrir sua loja. Por favor, tente novamente. Se o problema persistir, contacte nosso setor de suporte.',
        pauseOrders: 'Desculpe, ocorreu um problema inesperado ao tentar pausar os novos pedidos. Por favor, tente novamente. Se o problema persistir, contacte nosso setor de suporte.'
    },
    orders: {
        get: 'Desculpe, ocorreu um problema inesperado ao tentar listar seus pedidos. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
        status: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar o status do seu pedido. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
    },
    products: {
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar seu produto.Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.'
    },
    categories: {
        get: 'Desculpe, ocorreu um problema inesperado ao tentar listar suas categorias. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar a categoria.Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.'
    },
    promotions: {
        get: "Desculpe, ocorreu um problema inesperado ao tentar listar seus produtos. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte."
    },
    login: {
        auth: "Desculpe, ocorreu um problema inesperado ao tentar logar-se. Por favor, tente novamente mais tarde. Se o problema persistir, contacte nosso suporte.",
        permission: "Usuário sem permissão para acessar esta área!",
        generic: "Usuário não encontrado ou sem permissão!"
    },
    configurations: {
        getStore: "Desculpe, ocorreu um problema inesperado ao tentar recuperar suas informações. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.",
        getCustomizations: "Desculpe, ocorreu um problema inesperado ao tentar recuperar suas configurações. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.",
        updateCustomizations: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar suas configurações.Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.'
    },
    variations: {
        get: 'Desculpe, ocorreu um problema inesperado ao tentar listar suas variações. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
        create: 'Desculpe, ocorreu um problema inesperado ao tentar criar a variação.Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar a variação.Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
        status: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar o status da sua variação. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
    },
    integrations: {
        create: 'Desculpe, ocorreu um problema inesperado ao tentar criar sua integração.Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
        delete: 'Desculpe, ocorreu um problema inesperado ao tentar deletar sua integração. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.'
    },
    dataReport: {
        get: 'Desculpe, ocorreu um problema inesperado ao tentar recuperar seus relatórios. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
    },
    users: {
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar seus dados.Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
    },
    campaignLists: {
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar sua lista. Por favor, tente novamente. Se o problema persistir, contacte nosso suporte.',
    },
    default: "Ocorreu um problema inesperado ao tentar realizar esta ação. Por favor, tente novamente daqui alguns minutos. Se o problema persistir, contacte nosso suporte."
}