import React, { useState, useRef, useEffect } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import NumberFormat from "react-number-format";
import Cards from "react-credit-cards";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Select from "react-select";
import { setCookie } from "nookies";
import jwt_decode from "jwt-decode";

import { GetUserCep } from "../../../services/UsersServices";
import creditCardsAcceptds from "../../../images/cartoes-credito-asaas.png";
import safeEnviroment from "../../../images/safe.png";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../../../helpers/CreditCardUtils";
import "react-credit-cards/es/styles-compiled.css";
import { pixExpirationDate, states } from "../../../helpers/Helpers";
import { GetQrCode, NewPayment } from "../../../services/PaymentServices";
import DefaultLoader from "../Loader";

export default function PaymentForm(props) {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [holderName, setHolderName] = useState("");
  const [number, setNumber] = useState("");
  const [issuer, setIssuer] = useState("");
  const [paymentType, setPaymentType] = useState("payNow");
  const [billingType, setBillingType] = useState("creditCard");
  const [payProccess, setPayProccess] = useState(false);
  const [pixData, setPixData] = useState();
  const [getQrCode, setGetQrCode] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [orderType, setOrderType] = useState(0);
  const [state, setState] = useState("");
  const [_total, setTotal] = useState();
  const [installment, setInstallment] = useState();
  const cep = useRef("");
  const _pixData = useRef();
  const interval = useRef();

  useEffect(() => {
    if (getQrCode) {
      let description = `Pralevar - Plano ${props.planSelected?.title}`;

      let payload = {
        store_id: "01",
        value: _total,
        description: description,
      };

      GetQrCode(payload).then((res) => {
        _pixData.current = res;
        setPixData(true);
      });
    }
  }, [getQrCode]);

  useEffect(() => {
    const eventListener = (event) => {
      if (event.data.pix_id) {
        if (_pixData.current && event.data.pix_id === _pixData.current.id) {
          let pixData = _pixData.current;
          delete pixData.encodedImage;
          delete pixData.payload;

          let obj = {
            payment_method: {
              name: "Pix",
              type: "Pix",
              pay_on: "app",
              recurrency: props.planSelected.recurrencySelected,
              operation: "À vista",
              thumb: `pix.png`,
              value: _total,
              description: `Pralevar Delivery - ${props.planSelected?.title}`,
              plan_id: props.planSelected?.id,
              pix_data: pixData,
            },
          };

          NewPayment(obj, null)
            .then((res) => {
              paymentSuccessMessage(res.token);
            })
            .catch((error) => {
              console.log(error);
              Swal.fire("Oops!", error, "error");
              props.setShowModal(false);
            });

          clearInterval(interval.current);
        }
      }
    };

    setTotal(total());

    window.addEventListener("message", eventListener, false);

    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  const paymentSuccessMessage = (_token) => {
    if (props.from && props.from === "register") {
      setRedirecting(true);
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    } else {
      setTimeout(() => {
        window.location.href = "/";
      }, 4000);
      props.setShowModal(false);
    }

    Swal.fire({
      title: "Success",
      html: "Pagamento recebido com sucesso! <br>O comprovante de seu pagamento foi enviado por e-mail.",
      icon: "success",
    });
    
    let token = jwt_decode(_token);
    delete token.access;
    delete token.jti;
    delete token.exp;
    delete token.token_type;

    localStorage.setItem("user", JSON.stringify(token));
    sessionStorage.setItem("plan_id", props.planSelected?.id);
    setCookie(undefined, "token", _token, {
      maxAge: 60 * 60 * 24 * 7, // 7 dias
    });
  };

  const total = () => {
    let data = props.planSelected.price.filter(
      (item) => item.recurrency === props.planSelected.recurrencySelected
    );

    if (props.planSelected.recurrencySelected === "annual") {
      return Math.round(data[0].price * 12);
    } else if (props.planSelected.recurrencySelected === "semiannual") {
      return Math.round(data[0].price * 6);
    } else {
      return data[0].price;
    }
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "holderName") {
      setHolderName(target.value);
      setValue("holderName", target.value);
    } else if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value, issuer, setIssuer);
      setNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setExpiry(target.value);
      setValue("expiry", target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      setCvc(target.value);
      setValue("cvc", target.value);
    }
    clearErrors(target.name);
  };

  const CopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    props.setNotify({
      title: "Sucesso!",
      message: "Pix copiado com sucesso!",
      type: "success",
      visible: true,
    });
  };

  const onSubmit = async (data) => {
    let obj = {
      payment_method: {
        name: issuer,
        type: "Cartão",
        pay_on: "app",
        recurrency: props.planSelected.recurrencySelected,
        operation: "Crédito",
        thumb: `${issuer}.png`,
        value: _total,
        description: `Pralevar Delivery - Contratação do plano ${props.planSelected?.title}`,
        plan_id: props.planSelected?.id,
        card_data: {
          holder_name: holderName,
          card_number: number,
          card_expiration: expiry,
          card_ccv: cvc,
        },
        holder_address: {
          zipcode: data.cep.replace("-", ""),
          address: data.address,
          house_number: data.houseNumber,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
        },
        installment: data.installment,
      },
    };

    setPayProccess(true);
    try {
      let response = await NewPayment(obj, null);

      paymentSuccessMessage(response.token);
    } catch (error) {
      props.setNotify({
        title: "Oops!",
        message: error,
        type: "error",
        visible: true,
      });
    }
    setPayProccess(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-100 payment-form">
      <div className={`payment-in-process ${!payProccess ? "d-none" : ""}`}>
        <MoonLoader color="#fff" loading={true} />
        <h4>Processando o pagamento, aguarde...</h4>
      </div>
      <div className={`payment-in-process ${!redirecting ? "d-none" : ""}`}>
        <MoonLoader color="#fff" loading={true} />
        <h4>Você está sendo redirecionado para seu painel...</h4>
      </div>
      <div className="safe-enviroment">
        <img
          className="mr-2"
          src={safeEnviroment}
          width={36}
          height={36}
          alt=""
        />
        <h5 className="text-uppercase mb-0" style={{ color: "#1a1a1a" }}>
          Ambiente Seguro
        </h5>
      </div>
      <div>
        <div className="d-flex" style={{ textAlign: "left" }}>
          <div className="custom-control custom-radio mb-2">
            <input
              id="credit-card"
              name="billingType"
              type="radio"
              className="custom-control-input"
              onClick={() => setBillingType("creditCard")}
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="credit-card">
              Cartão de crédito
            </label>
          </div>
          <div className="custom-control custom-radio ml-4 mb-2">
            <input
              id="cash-payment"
              name="billingType"
              type="radio"
              className="custom-control-input"
              onClick={() => {
                setBillingType("cashPayment");
                setGetQrCode(true);
              }}
            />
            <label className="custom-control-label" htmlFor="cash-payment">
              Pix
            </label>
          </div>
        </div>
      </div>
      {billingType === "cashPayment" ? (
        <div className="payByPix">
          {!pixData ? (
            <div className="mt-4">
              <div className={`payment-in-process`}>
                <MoonLoader color="#fff" loading={!pixData} />
                <h4>Gerando QrCode...</h4>
              </div>
              {/* <h6>
                Gerando QrCode{" "}
                <DefaultLoader
                  isLoading={true}
                  cs="display:inline;vertical-align:sub;"
                />
              </h6> */}
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                width="275"
                height="275"
                src={`data:image/png;base64,${_pixData.current.encodedImage}`}
                alt="qrcode"
              />
              <div>
                <h6 className="text-center mb-2 mt-3">Pix Copia e Cola</h6>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control cursor-pointer"
                    value={_pixData.current.payload}
                    onClick={() => CopyToClipboard(_pixData.current.payload)}
                    readOnly
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-primary py-1 px-3"
                      onClick={() => CopyToClipboard(_pixData.current.payload)}
                    >
                      <i className="las la-copy fs-22"></i>
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                  <p className="fs-16 ml-3 mb-0 text-center">
                    Você tem{" "}
                    <span id="pixExpirationDate">
                      {pixExpirationDate(_pixData, interval)}
                    </span>{" "}
                    para realizar o <br></br>
                    pagamento via pix antes de expirar o código qrcode.
                  </p>
                  <small className="mt-3 text-primary">
                    *O pagamento será reconhecido automaticamente após o
                    pagamento.
                  </small>
                </div>

                <div
                  id="pix_not_confirmed"
                  className="col-lg-12 text-center d-none"
                >
                  <small className="text-danger">
                    Pagamento ainda não foi confirmado! Realize o pagamento via
                    pix antes de continuar.
                  </small>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="payBycreditCard">
          <div>
            {props.planSelected.recurrencySelected === "monthly" && (
              <h6 className="mb-3">
                O pagamento do seu plano {props.planSelected?.title} será feito
                pelo modo de recorrência. Mensalmente é debitado o valor de{" "}
                {
                  <NumberFormat
                    displayType={"text"}
                    value={_total}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$"
                  />
                }{" "}
                de seu cartão de crédito, não afetando o total do seu limite e
                podendo ser cancelado a qualquer momento.
              </h6>
            )}
          </div>

          <div style={{ textAlign: "left" }}>
            <img src={creditCardsAcceptds} alt="" width={275} />
          </div>
          <div className="App-payment">
            <Cards
              cvc={cvc}
              expiry={expiry}
              focused={focus}
              name={holderName}
              number={number}
              preview={true}
              issuer={issuer}
              placeholders={{ name: "nome impresso" }}
              locale={{ valid: "validade" }}
            />

            <div className="form-group">
              <input
                type="tel"
                className="form-control"
                placeholder="Número do cartão"
                pattern="[0-9 ]{16,22}"
                {...register("number", {
                  required:
                    !orderType && paymentType === "payNow" ? true : false,
                })}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              {errors.number && (
                <div className="col-lg-12 text-center">
                  <small className="text-danger">
                    {errors.number.type === "required" &&
                      "Por  favor, preencha o número do cartão corretamente!"}
                  </small>
                </div>
              )}
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Nome impresso no cartão"
                {...register("holderName", {
                  required:
                    !orderType && paymentType === "payNow" ? true : false,
                  pattern: /^[a-zA-Z]/,
                })}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              {errors.holderName && (
                <div className="col-lg-12 text-center">
                  <small className="text-danger">
                    {errors.holderName.type === "required" &&
                      "Por  favor, preencha o nome impresso no cartão!"}
                    {errors.holderName.type === "pattern" &&
                      "Por  favor, preencha corretamente este campo!"}
                  </small>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Validade"
                  pattern="\d\d/\d\d"
                  {...register("expiry", {
                    required:
                      !orderType && paymentType === "payNow" ? true : false,
                  })}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
                {errors.expiry && (
                  <div className="col-lg-12 text-center">
                    <small className="text-danger">
                      {errors.expiry.type === "required" &&
                        "Por  favor, preencha a validade do cartão!"}
                    </small>
                  </div>
                )}
              </div>
              <div className="col-6">
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Código de segurança"
                  pattern="\d{3,4}"
                  {...register("cvc", {
                    required:
                      !orderType && paymentType === "payNow" ? true : false,
                  })}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
                {errors.cvc && (
                  <div className="col-lg-12 text-center">
                    <small className="text-danger">
                      {errors.cvc.type === "required" &&
                        "Por  favor, preencha o código de segurança do cartão!"}
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3">
              <h5>Endereço da fatura:</h5>
              <div className={`mb-3 d-block`} style={{ textAlign: "left" }}>
                <label style={{ fontWeight: "normal" }}>CEP</label>
                <NumberFormat
                  {...register("cep", { required: true })}
                  format="#####-###"
                  mask="_"
                  className="form-control p-2"
                  placeholder="Digite seu CEP"
                  ref={(e) => (cep.current = e)}
                  onChange={(e) => {
                    if (
                      e.target.value.length === 9 &&
                      !e.target.value.includes("_")
                    ) {
                      GetUserCep(e.target.value)
                        .then((res) => {
                          setValue("cep", res.cep);
                          setValue("address", res.logradouro);
                          setValue("neighborhood", res.bairro);
                          setValue("city", res.localidade);
                          setValue("state", res.uf);
                          setState({ label: res.uf, value: res.uf });
                          clearErrors("cep");
                          clearErrors("address");
                          clearErrors("neighborhood");
                          clearErrors("city");
                          clearErrors("state");
                        })
                        .catch((error) => {
                          console.log(error);
                          Swal.fire(
                            "Oops!",
                            "Desculpe, não conseguimos localizar seu endereço, verifique se o CEP está correto para continuar.",
                            "warning"
                          );
                        });
                    }
                  }}
                />
                {errors.cep && (
                  <div className="col-lg-12 text-center">
                    <small className="text-danger">
                      {errors.cep.type === "required" &&
                        "Por  favor, preencha o CEP!"}
                    </small>
                  </div>
                )}
                <div>
                  <div
                    className="col-lg-8 col-md-8 col-sm-12 d-inline-block pt-2 pl-0"
                    style={{ textAlign: "left" }}
                  >
                    <label style={{ fontWeight: "normal" }}>Endereço</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      {...register("address", { required: true })}
                    />
                    {errors.address && (
                      <div className="col-lg-12 text-center">
                        <small className="text-danger">
                          {errors.address.type === "required" &&
                            "Por  favor, preencha este campo!"}
                        </small>
                      </div>
                    )}
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 d-inline-block p-1"
                    style={{ textAlign: "left" }}
                  >
                    <label style={{ fontWeight: "normal" }}>Nº</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="houseNumber"
                      {...register("houseNumber", { required: true })}
                    />
                    {errors.houseNumber && (
                      <div className="col-lg-12 text-center">
                        <small className="text-danger">
                          {errors.houseNumber.type === "required" &&
                            "Por  favor, preencha este campo!"}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className="col-lg-5 col-md-5 col-sm-12 d-inline-block p-1"
                    style={{ textAlign: "left" }}
                  >
                    <label style={{ fontWeight: "normal" }}>Bairro</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="neighborhood"
                      {...register("neighborhood", { required: true })}
                    />
                    {errors.neighborhood && (
                      <div className="col-lg-12 text-center">
                        <small className="text-danger">
                          {errors.neighborhood.type === "required" &&
                            "Por  favor, preencha este campo!"}
                        </small>
                      </div>
                    )}
                  </div>
                  <div
                    className="col-lg-5 col-md-5 col-sm-12 d-inline-block p-1"
                    style={{ textAlign: "left" }}
                  >
                    <label style={{ fontWeight: "normal" }}>Cidade</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="city"
                      {...register("city", { required: true })}
                    />
                    {errors.city && (
                      <div className="col-lg-12 text-center">
                        <small className="text-danger">
                          {errors.city.type === "required" &&
                            "Por  favor, preencha este campo!"}
                        </small>
                      </div>
                    )}
                  </div>
                  <div
                    className="col-lg-2 col-md-2 col-sm-12 d-inline-block p-1 align-top"
                    style={{ textAlign: "left" }}
                  >
                    <label style={{ fontWeight: "normal" }}>UF</label>
                    <Select
                      {...register("state", { required: true })}
                      value={state}
                      options={states}
                      instanceId="states-select"
                      placeholder="UF"
                      onChange={(e) => {
                        setState(e);
                        setValue("state", e.value);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: 50,
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div className="col-lg-7 col-sm-12 p-1 d-inline-block">
                <label>Parcelamento</label>
                <select
                  className="form-control"
                  {...register("installment", { required: true })}
                  onChange={(e) => {
                    setInstallment(e.target.value);
                    clearErrors("installment");
                  }}
                  defaultValue={""}
                >
                  <option value={""} disabled>
                    selecione o nº de parcelas...
                  </option>
                  <option value={"1"}>1x R${_total / 1}</option>
                  {props.planSelected.recurrencySelected !== "monthly" && (
                    <>
                      <option value={"2"}>
                        2x R${(_total / 2).toFixed(2)}
                      </option>
                      <option value={"3"}>
                        3x R${(_total / 3).toFixed(2)}
                      </option>
                      <option value={"4"}>
                        4x R${(_total / 4).toFixed(2)}
                      </option>
                      <option value={"5"}>
                        5x R${(_total / 5).toFixed(2)}
                      </option>
                      <option value={"6"}>
                        6x R${(_total / 6).toFixed(2)}
                      </option>
                    </>
                  )}
                  {props.planSelected.recurrencySelected === "annual" && (
                    <>
                      <option value={"7"}>
                        7x R${(_total / 7).toFixed(2)}
                      </option>
                      <option value={"8"}>
                        8x R${(_total / 8).toFixed(2)}
                      </option>
                      <option value={"9"}>
                        9x R${(_total / 9).toFixed(2)}
                      </option>
                      <option value={"10"}>
                        10x R${(_total / 10).toFixed(2)}
                      </option>
                      <option value={"11"}>
                        11x R${(_total / 11).toFixed(2)}
                      </option>
                      <option value={"12"}>
                        12x R${(_total / 12).toFixed(2)}
                      </option>
                    </>
                  )}
                </select>
                {errors.installment && (
                  <div className="col-lg-12 text-center">
                    <small className="text-danger">
                      {errors.installment.type === "required" &&
                        "Por favor, selecione a quantidade de parcelas!"}
                    </small>
                  </div>
                )}
              </div>
              <div className="col-lg-5 col-sm-12 total align-middle d-inline-block ">
                <h5>Total</h5>
                <h4>
                  <NumberFormat
                    displayType={"text"}
                    value={_total}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$"
                  />

                  {installment
                    ? ` em ${installment}x de R$${(
                        _total / parseInt(installment)
                      )
                        .toFixed(2)
                        .replace(".", ",")} sem juros`
                    : ""}
                </h4>
              </div>
            </div>
            <button type="submit" className="btn btn-info ml-3 text-uppercase">
              Finalizar pagamento
            </button>
          </div>
        </div>
      )}
    </form>
  );
}
