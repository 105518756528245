import { api } from '../api/Middleware'
import { STORE_URL, VW_HOME } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

/* export const GetOne = async (id, setOrder, setIsLoading, setErrors) => {
    try {
        let response = await api.get(VW_ORDERS + id + '/');
        if (response.status === 200) setOrder(response.data.data);
    } catch (error) {
        if (error.response.status === 401) {

        }

        if (error.response.status === 404) {
            setErrors({title: 'Oops!', message: 'Produto não encontrado!'})
        }
    }
    setIsLoading(false)
} */

export const Get = async (
    users_total,
    products_total,
    data_orders,
    data_by_month,
    data_by_week,
    data_by_day,
    data_most_orders,
    setErrors,
    setIsLoading) => {

    try {
        let response = await api.get(VW_HOME);
        if (response.status === 200) {
            users_total.current = response.data.users_total;
            data_orders.current = response.data.orders
            products_total.current = response.data.products_total;
          /*   data_by_month.current = response.data.orders_by_month;
            data_by_week.current = response.data.orders_by_week;
            data_by_day.current = response.data.orders_by_day;
            data_most_orders.current = response.data.most_orders; */

        }
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.orders.get })
    }
    finally {
        setIsLoading(false)
    }
}

/* export const ChangeStatus = async (data, setNotify) => {
    try {
        let response = await api.put(ORDERS_URL + 'change_status/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: response.data, type: 'success', visible: true })
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        return false;
    }
}

export const Delete = async (data, setNotify) => {
    try {
        let ids = [];
        data.map(order => ids.push(order.id))
        let response = await api.delete(ORDERS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }
} */