import React, { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import jwt_decode from 'jwt-decode'
import Swal from 'sweetalert2'
import { parseCookies, setCookie } from 'nookies'
import { RemoveAccents, RemoveSpaces, Slugfy } from '../../../../helpers/Helpers'
import { Create as CreateDomain } from '../../../../services/DomainServices'
import DefaultLoader from '../../Loader'
import AlertResponses from '../../Alert'
import { VerifyIfTokenNotExpired } from '../../../../api/Middleware'

const StepFour = props => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    formState: { errors }
  } = useForm()
  const store = useRef()
  const user = useRef()
  const next = useRef()
  const prev = useRef()
  const submit = useRef()
  const [domain, setDomain] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [notify, setNotify] = useState({
    title: '',
    message: '',
    type: '',
    visible: false
  })

  const onSubmit = data => {
    submit.current = document.querySelector('button[type="submit"]')
    submit.current.style.visibility = 'hidden'
    setIsLoading(true)

    data.subdomain = 1
    data.url =
      RemoveAccents(RemoveSpaces(data.url)).toLowerCase() + '.pralevar.app'
    data.store = store.current.id

    CreateDomain(data)
      .then(res => {
        if (res.status >= 400) {
          setError('url', { type: 'invalid', message: res.data })
          submit.current.style.visibility = 'visible'
          setIsLoading(false)
          return
        }
        let _user = jwt_decode(user.current.access)
        setCookie(undefined, 'token', user.current.access, {
          maxAge: 60 * 60 * 24 * 7 // 7 dias
        })
        sessionStorage.setItem('plan_id', 1)
        delete _user.access
        delete _user.jti
        delete _user.exp
        localStorage.setItem('user', JSON.stringify(_user))
        localStorage.removeItem('r_storeData')
        localStorage.removeItem('r_userData')
        next.current.click()
      })
      .catch(err => {
        console.log(err)
        submit.current.innerText = 'Avançar'
        submit.current.removeAttribute('disabled')
        Swal.fire(
          'Oops!',
          'Ocorreu um erro inesperado ao tentar realizar seu domínio, por favor, entre em contato com nosso suporte pelo ícone do Chat.',
          'warning'
        )
        setIsLoading(false)
      })
  }

  useEffect(() => {
    let { token: _token } = parseCookies()
    let _store = localStorage.getItem('r_storeData')
    let _user = localStorage.getItem('r_userData')
    if (_token && !_store && !_user) {
      window.location.pathname = "/"
      window.location.assign('/')
      return
    }
    
    document.querySelectorAll('button').forEach(button => {
      if (button.textContent.includes('Next')) {
        next.current = button
        button.style.visibility = 'hidden'
      }

      if (button.textContent.includes('Prev')) {
        prev.current = button
        button.style.visibility = 'hidden'
      }
    })

    if (_store) {
      store.current = JSON.parse(_store)
      let url = store.current.url.split('.pralevar.app')[0]
      url = Slugfy(url)
      setDomain(url)
      setValue('url', url)
    }

    if (_user) {
      user.current = JSON.parse(_user)
      if (!VerifyIfTokenNotExpired(user.current.access)) {
        localStorage.clear()
        window.location.assign('/cadastro')
      }
    }
  }, [])

  return (
    <section>
      {isLoading ? (
        <div className='text-center mt-4'>
          <span className='d-inline'>
            Aguarde
            <DefaultLoader cs='display: inline !important;vertical-align: sub;margin-top: unset;' />
          </span>
        </div>
      ) : (
        <>
          <h4 className='card-title mb-4 text-center'>Seu domínio</h4>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id='stepOne'
            className='w-100'
          >
            <div className='row'>
              <div className='col-lg-12 col-sm-12 col-12'>
                <div className='form-group'>
                  <label className='text-label'>Nome da sua marca</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    {...register('url', {
                      required: true,
                      pattern: /^[a-zA-Z0-9]/
                    })}
                    onChange={e => {
                      if (/https:\/\/|www.|.com|.com.br/.test(e.target.value)) {
                        setError('url', {
                          type: 'invalid',
                          message: 'Insira apenas o nome da sua marca'
                        })
                      } else {
                        clearErrors('url')
                        setDomain(e.target.value)
                        setValue('url', e.target.value)
                      }
                    }}
                  />
                </div>
                {errors.url && (
                  <small className='text-primary'>
                    {errors.url.type === 'required'
                      ? 'Campo obrigatório!'
                      : errors.url.type === 'invalid'
                      ? `*${errors.url.message}`
                      : 'URL inválida!'}
                  </small>
                )}
                {domain && (
                  <div className='col-lg-12 col-sm-12 text-center'>
                    <label className='text-label'>
                      Seu domínio ficará assim:{' '}
                      <b className='text-primary'>
                        {RemoveAccents(RemoveSpaces(domain)).toLowerCase()}
                        .pralevar.app
                      </b>
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='skip-email text-center pt-5 pb-2'>
                  <p>
                    O nome de sua marca normalmente é utilizado como seu
                    domínio, mas caso deseje, você pode personalizar digitando o
                    nome desejado no campo acima.
                  </p>
                  <button
                    type='button'
                    className='btn btn-secondary ml-3'
                    onClick={() => prev.current.click()}
                  >
                    Voltar
                  </button>
                  <button type='submit' className='btn btn-primary ml-3'>
                    Avançar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
    </section>
  )
}

export default StepFour
