import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import DefaultLoader from "../jsx/components/Loader";
import { GetOne, Update } from "../services/CustomersServices";
import NumberFormat from "react-number-format";
import { useForm } from "react-hook-form";
import AlertResponses from "../jsx/components/Alert";
import { Hash, MoneyFormat } from "../helpers/Helpers";

export default function CustomerDetails() {
  const { id } = useParams();
  const [activeToggle, setActiveToggle] = useState("orders");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const mediumPrice = useRef("");

  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  useEffect(() => {
    if (id) GetOne(id, setData, setValue, setIsLoading);
  }, []);

  const onSubmit = (data) => {
    data["birthday"] = data.birthday.split("/").reverse().join("-");
    data["phone"] = data.phone.replace(/[^0-9]/g, "");
    data["is_active"] = data.is_active ? 1 : 0;
    Update(id, data, setData, setNotify);
  };

  const totalSpent = () => {
    if (data.orders.length === 1) {
      return data.orders[0].total;
    } else if (data.orders.length > 1) {
      let arr = [];
      data.orders.filter((order) => arr.push(order.total));
      let total = arr.reduce(
        (previousValue, currentValue) => previousValue + currentValue
      );
      mediumPrice.current = total / data.orders.length;

      return (
        <NumberFormat
          displayType="text"
          decimalScale={2}
          defaultValue={total}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$"
          fixedDecimalScale={true}
        />
      );
    } else {
      return 0;
    }
  };

  const status = {
    0: <span className="badge badge-sm badge-info" style={{maxWidth: 150}}>Pendente</span>,
    1: <span className="badge badge-sm badge-success" style={{maxWidth: 150}}>Disponível</span>,
    2: <span className="badge badge-sm badge-danger" style={{maxWidth: 150}}>Cancelado</span>,
    3: <span className="badge badge-sm badge-warning" style={{maxWidth: 150}}>Expirado</span>,
    4: <span className="badge badge-sm badge-primary" style={{maxWidth: 150}}>Utilizado</span>,
  };

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="profile-info">
                    <div className="profile-photo">
                      {console.log(data)}
                      {data.user.photo ? (
                        <img
                          src={Hash("100x100", data.user.photo)}
                          className="img-fluid rounded-circle"
                          alt="profile"
                        />
                      ) : (
                        <div style={styles.photoDefault}>
                          <i className="ti-user" style={styles.i}></i>
                        </div>
                      )}
                    </div>
                    <div className="profile-details">
                      <div className="profile-name px-3 pt-2">
                        <h4 className="text-primary mb-0 text-capitalize">
                          {data.user.name}
                        </h4>
                        <p>{data.user.email}</p>
                      </div>

                      {/* 	<Dropdown className="dropdown ml-auto">
													<Dropdown.Toggle
														variant="primary"
														className="btn btn-primary light sharp i-false"
														data-toggle="dropdown"
														aria-expanded="true"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															//    xmlns:xlink="http://www.w3.org/1999/xlink"
															width="18px"
															height="18px"
															viewBox="0 0 24 24"
															version="1.1"
														>
															<g
																stroke="none"
																strokeWidth="1"
																fill="none"
																fillRule="evenodd"
															>
																<rect x="0" y="0" width="24" height="24"></rect>
																<circle fill="#000000" cx="5" cy="12" r="2"></circle>
																<circle fill="#000000" cx="12" cy="12" r="2"></circle>
																<circle fill="#000000" cx="19" cy="12" r="2"></circle>
															</g>
														</svg>
													</Dropdown.Toggle>
													<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
														<Dropdown.Item className="dropdown-item">
															<i className="fa fa-data-circle text-primary mr-2" />
															View profile
														</Dropdown.Item>
														<Dropdown.Item className="dropdown-item">
															<i className="fa fa-datas text-primary mr-2" />
															Add to close friends
														</Dropdown.Item>
														<Dropdown.Item className="dropdown-item">
															<i className="fa fa-plus text-primary mr-2" />
															Add to group
														</Dropdown.Item>
														<Dropdown.Item className="dropdown-item">
															<i className="fa fa-ban text-primary mr-2" />
															Block
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-sm-6 d-inline-block">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-primary text-primary">
                      <i className="ti-receipt"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Pedidos</p>
                      <h4 className="mb-0">{data.orders.length}</h4>
                      {/* <span className="badge badge-primary">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 d-inline-block">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-primary text-primary">
                      <i className="ti-money"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Total Gasto</p>
                      <h4 className="mb-0">{totalSpent()}</h4>
                      {/* <span className="badge badge-primary">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 d-inline-block">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-primary text-primary">
                      <i className="ti-money"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Ticket médio</p>
                      <h4 className="mb-0">
                        {
                          <NumberFormat
                            displayType="text"
                            decimalScale={2}
                            defaultValue={mediumPrice.current}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            fixedDecimalScale={true}
                          />
                        }
                      </h4>
                      {/* <span className="badge badge-primary">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 d-inline-block">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-primary text-primary">
                      {/* <!-- <i className="ti-data"></i> --> */}
                      <svg
                        id="icon-customers"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-data"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Cupons utilizados</p>
                      <h4 className="mb-0">
                        {data.coupons.length > 0 ? data.coupons.length : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="pb-2 text-center">
                      <i className="ti-user mr-3"></i>
                      <strong>Informações Pessoais</strong>
                    </h4>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between pl-0 pr-0">
                        <span className="mb-0 p-0">Nome</span>{" "}
                        <strong className="text-muted text-capitalize">
                          {data.user.name}
                        </strong>
                      </li>
                      <li className="list-group-item d-flex justify-content-between pl-0 pr-0">
                        <span className="mb-0">Telefone</span>{" "}
                        <strong className="text-muted">
                          <NumberFormat
                            displayType="text"
                            format="(##) #####-####"
                            value={data.user.phone}
                          />
                        </strong>
                      </li>
                      <li className="list-group-item d-flex justify-content-between pl-0 pr-0">
                        <span className="mb-0">CPF</span>{" "}
                        <strong className="text-muted">
                          <NumberFormat
                            displayType="text"
                            format="###.###.###-##"
                            value={data.user.cpf}
                          />{" "}
                        </strong>
                      </li>
                      <li className="list-group-item d-flex justify-content-between pl-0 pr-0">
                        <span className="mb-0">Aniversário</span>{" "}
                        <strong className="text-muted">
                          <NumberFormat
                            displayType="text"
                            format="##/##/####"
                            value={new Date(
                              data.user.birthday
                            ).toLocaleDateString()}
                          />{" "}
                        </strong>
                      </li>
                      <li className="list-group-item d-flex justify-content-between pl-0 pr-0">
                        <span className="mb-0">Último acesso</span>{" "}
                        <strong className="text-muted">
                          <NumberFormat
                            displayType="text"
                            format="##/##/####"
                            value={
                              data.user.last_login
                                ? new Date(
                                    data.user.last_login.created_at
                                  ).toLocaleDateString()
                                : ""
                            }
                          />{" "}
                        </strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="pb-2 text-center">
                      <i className="ti-location-pin mr-2"></i>
                      <strong>Endereços</strong>
                    </h4>
                    <ul className="list-group list-group-flush">
                      {data.addresses.map((value) =>
                        value.main === 1 ? (
                          <div key={value.id}>
                            <span
                              className="badge badge-success light mb-2"
                              style={styles.badge}
                            >
                              Principal
                            </span>
                            <li className="list-group-item d-flex justify-content-between p-0 border-none">
                              <strong className="text-muted text-capitalize">
                                {value.complete_address}
                              </strong>
                            </li>
                            <li
                              className={`list-group-item d-flex justify-content-between pl-0 pr-0 ${
                                value.reference !== "" && "pb-0 border-none"
                              }`}
                            >
                              <span className="mb-0 p-0">Complemento</span>
                              <strong className="text-muted text-capitalize">
                                {value.complement}
                              </strong>
                            </li>
                            {value.reference !== "" && (
                              <li className="list-group-item d-flex justify-content-between pl-0 pr-0">
                                <span className="mb-0 p-0">Referência</span>
                                <strong className="text-muted text-capitalize">
                                  {value.reference}
                                </strong>
                              </li>
                            )}
                          </div>
                        ) : (
                          <div key={value.id}>
                            <span
                              className="badge badge-primary light mb-2 mt-3"
                              style={styles.badge}
                            >
                              Outros
                            </span>
                            <li className="list-group-item d-flex justify-content-between p-0 border-none">
                              <span className="mb-0 p-0">
                                <strong>{value.complete_address}</strong>
                              </span>
                            </li>
                            <li
                              className={`list-group-item d-flex justify-content-between pl-0 pr-0 ${
                                value.reference !== "" && "pb-0 border-none"
                              }`}
                            >
                              <span className="mb-0 p-0">Complemento</span>
                              <strong className="text-muted text-capitalize">
                                {value.complement}
                              </strong>
                            </li>
                            {value.reference !== "" && (
                              <li className="list-group-item d-flex justify-content-between pl-0 pr-0">
                                <span className="mb-0 p-0">Referência</span>
                                <strong className="text-muted text-capitalize">
                                  {value.reference}
                                </strong>
                              </li>
                            )}
                          </div>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="card">
                <div className="card-body vh-100 overflow-hidden">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <ul className="nav nav-tabs">
                        <li
                          className="nav-item"
                          onClick={() => setActiveToggle("orders")}
                        >
                          <Link
                            to="#pedidos"
                            data-toggle="tab"
                            className={`nav-link ${
                              activeToggle === "orders" ? "active show" : ""
                            }`}
                          >
                            Pedidos
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => setActiveToggle("coupons")}
                        >
                          <Link
                            to="#coupons"
                            data-toggle="tab"
                            className={`nav-link ${
                              activeToggle === "coupons" ? "active show" : ""
                            }`}
                          >
                            Cupons
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => setActiveToggle("cashback")}
                        >
                          <Link
                            to="#cashback"
                            data-toggle="tab"
                            className={`nav-link ${
                              activeToggle === "cashback" ? "active show" : ""
                            }`}
                          >
                            Cashback
                          </Link>
                        </li>
                        {/* <li className="nav-item">
														<Link to="#profile-settings" data-toggle="tab" onClick={() => setActiveToggle("setting")} className={`nav-link ${activeToggle === "setting" ? "active show" : ""}`}>Dados</Link>
													</li> */}
                      </ul>
                      <div className="tab-content">
                        <div
                          id="pedidos"
                          className={`tab-pane fade ${
                            activeToggle === "orders" ? "active show" : ""
                          }`}
                        >
                          <div className="mt-5">
                            <PerfectScrollbar
                              className="widget-timeline dz-scroll style-1 ps ps--active-y vh-100"
                              style={styles.timeline}
                            >
                              <ul className="timeline">
                                {data.orders.length > 0
                                  ? data.orders.map((item) => (
                                      <li key={item.id}>
                                        <div className="timeline-badge primary"></div>
                                        <Link
                                          className="timeline-panel text-muted"
                                          to={`/order-details/${item.id}`}
                                        >
                                          <span>
                                            {new Date(
                                              item.created_at
                                            ).toLocaleString()}
                                          </span>
                                          <div className="d-flex justify-content-between">
                                            <h6>
                                              <strong className="text-primary">
                                                Nº {item.id}
                                              </strong>
                                            </h6>
                                            <h6 className="d-flex justify-content-between">
                                              Total: &nbsp;
                                              <NumberFormat
                                                displayType="text"
                                                decimalScale={2}
                                                defaultValue={item.total}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix="R$"
                                                fixedDecimalScale={true}
                                                className="h6 font-w700"
                                              />
                                            </h6>
                                          </div>
                                          <div>
                                            <h6 className="mb-0">
                                              {item.order.map((order) => (
                                                <div key={item.order.id}>
                                                  <strong className="text-primary">
                                                    {order.quantity}x{" "}
                                                  </strong>
                                                  {order.name}
                                                  <br></br>
                                                </div>
                                              ))}
                                            </h6>
                                          </div>
                                        </Link>
                                      </li>
                                    ))
                                  : "Nenhum pedido disponível no momento."}
                              </ul>
                            </PerfectScrollbar>
                          </div>
                        </div>
                        <div
                          id="coupons"
                          className={`tab-pane fade ${
                            activeToggle === "coupons" ? "active show" : ""
                          }`}
                        >
                          <div className="profile-store-me">
                            <div className="pt-4 border-bottom-1 pb-3">
                              <PerfectScrollbar
                                className="widget-timeline dz-scroll style-1 ps ps--active-y vh-100"
                                style={styles.timeline}
                              >
                                <ul className="timeline">
                                  {data.coupons.length > 0
                                    ? data.coupons.map((item) => (
                                        <li key={item.id}>
                                          <div className="timeline-badge primary"></div>
                                          <Link
                                            className="timeline-panel text-muted"
                                            to={`/order-id/${item.id}`}
                                          >
                                            <div className="d-flex flex-column">
                                              <h6>
                                                Cupom:{" "}
                                                <strong className="text-primary">
                                                  {item.coupon}
                                                </strong>
                                              </h6>
                                              <h6>
                                                Usado em:{" "}
                                                <strong className="text-primary">
                                                  {new Date(
                                                    item.use_date
                                                  ).toLocaleString()}
                                                </strong>
                                              </h6>
                                              <h6>
                                                Desconto:{" "}
                                                <strong className="text-primary">
                                                  {item.rules?.discount_by_value
                                                    ? `${MoneyFormat(
                                                        item.rules
                                                          ?.discount_by_value
                                                      )}`
                                                    : `${item.rules?.discount_by_percentage}%`}
                                                </strong>
                                              </h6>
                                            </div>
                                          </Link>
                                        </li>
                                      ))
                                    : "Nenhum cupom utilizado até o momento."}
                                </ul>
                              </PerfectScrollbar>
                            </div>
                          </div>
                        </div>
                        <div
                          id="cashback"
                          className={`tab-pane fade ${
                            activeToggle === "cashback" ? "active show" : ""
                          }`}
                        >
                          <div className="mt-5">
                            <PerfectScrollbar
                              className="widget-timeline dz-scroll style-1 ps ps--active-y vh-100"
                              style={styles.timeline}
                            >
                              <ul className="timeline">
                                {data.cashback.length > 0
                                  ? data.cashback.map((item) => (
                                      <li key={item.order__order_number}>
                                        <div className="timeline-badge primary"></div>
                                        <Link
                                          className="timeline-panel text-muted"
                                          to={`/order-details/${item.order__order_number}`}
                                        >
                                          <strong className="text-primary">
                                            {status[item.status]}
                                          </strong>
                                          <span className="mt-2">
                                            {new Date(
                                              item.created_at
                                            ).toLocaleString()}
                                          </span>
                                          <div className="d-flex justify-content-between">
                                            <h6>
                                              <strong className="text-primary">
                                                Pedidoº{" "}
                                                {item.order__order_number}
                                              </strong>
                                            </h6>
                                            <h6 className="d-flex justify-content-between">
                                              Valor: &nbsp;
                                              <NumberFormat
                                                displayType="text"
                                                decimalScale={2}
                                                defaultValue={item.value}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix="R$"
                                                fixedDecimalScale={true}
                                                className="h6 font-w700"
                                              />
                                            </h6>
                                          </div>
                                        </Link>
                                      </li>
                                    ))
                                  : "Nenhum cashback disponível no momento."}
                              </ul>
                            </PerfectScrollbar>
                          </div>
                        </div>
                        <div
                          id="profile-settings"
                          className={`tab-pane fade ${
                            activeToggle === "setting" ? "active show" : ""
                          }`}
                        >
                          <div className="pt-3">
                            <div className="settings-form">
                              <h4 className="text-primary mt-3 mb-4">
                                Dados do cliente
                              </h4>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label>Primeiro Nome</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      className="form-control"
                                      defaultValue={data.user.first_name}
                                      {...register("first_name", {
                                        required: true,
                                      })}
                                    />
                                    {errors.first_name && (
                                      <small className="ml-2 text-danger">
                                        {errors.first_name.type ===
                                          "required" &&
                                          "Por favor, preencha este campo!"}
                                      </small>
                                    )}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Sobrenome</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      className="form-control"
                                      defaultValue={data.user.last_name}
                                      {...register("last_name", {
                                        required: true,
                                      })}
                                    />
                                  </div>
                                  {errors.last_name && (
                                    <small className="ml-2 text-danger">
                                      {errors.last_name.type === "required" &&
                                        "Por favor, preencha este campo!"}
                                    </small>
                                  )}
                                </div>
                                <div className="form-row">
                                  {/* <div className="form-group col-md-6">
																			<label>CPF</label>
																			<NumberFormat format="###.###.###-##" defaultValue={data.user.cpf} className="form-control"
																				{...register("cpf", { required: true })} />
																			{errors.cpf &&
																				<small className="ml-2 text-danger">
																					{errors.cpf.type === 'required' &&
																						'Por favor, preencha este campo!'}</small>}
																		</div> */}

                                  <div className="form-group col-md-6">
                                    <label>Aniversário</label>
                                    <NumberFormat
                                      format="##/##/####"
                                      defaultValue={new Date(
                                        data.user.birthday
                                      ).toLocaleDateString()}
                                      className="form-control"
                                      {...register("birthday", {
                                        required: true,
                                      })}
                                      onChange={(e) =>
                                        setValue("birthday", e.target.value)
                                      }
                                    />
                                    {errors.birthday && (
                                      <small className="ml-2 text-danger">
                                        {errors.birthday.type === "required" &&
                                          "Por favor, preencha este campo!"}
                                      </small>
                                    )}
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label>Telefone</label>
                                    <NumberFormat
                                      format="(##) #####-####"
                                      className="form-control"
                                      defaultValue={data.user.phone}
                                      {...register("phone", { required: true })}
                                      onChange={(e) =>
                                        setValue("phone", e.target.value)
                                      }
                                    />
                                    {errors.phone && (
                                      <small className="ml-2 text-danger">
                                        {errors.phone.type === "required" &&
                                          "Por favor, preencha este campo!"}
                                      </small>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="gridCheck"
                                      defaultValue={data.user.is_active}
                                      defaultChecked={
                                        data.user.is_active == 1 ? true : false
                                      }
                                      {...register("is_active")}
                                    />

                                    <label
                                      className="custom-control-label"
                                      htmlFor="gridCheck"
                                    >
                                      Ativo
                                    </label>
                                  </div>
                                </div>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  Atualizar
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
        </>
      )}
    </>
  );
}

const styles = {
  badge: {
    marginLeft: "-5px",
    padding: "1.5px 7.5px",
    fontSize: "12px",
    width: "70px",
  },
  timeline: {
    paddingBottom: "6rem",
  },
  photoDefault: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100px",
    height: "100px",
    borderRadius: "50px",
    background: "#efefef",
  },
  i: {
    fontSize: "30px",
    color: "#3d4465",
  },
};
