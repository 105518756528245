import React, { useRef } from "react";
import { BrowserRouter } from "react-router-dom";

/// React router dom
import { Switch, Route } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";

import { UserProvider } from "../contexts/UserContext";
import { NotificationsProvider } from "../contexts/NotificationsContext";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Login from "../screens/Login";
import Orders from "../screens/Orders";
import OrderId from "../screens/OrderId";
import GeneralCustomers from "../screens/GeneralCustomers";
import CustomerDetails from "../screens/CustomerDetails";
import Analytics from "../screens/Analytics";
import Reviews from "./components/Dashboard/Reviews";
import Categories from "../screens/Categories";
import CategorieDetails from "../screens/CategorieDetails";
import Promotions from "../screens/Promotions";
import Products from "../screens/Products";
import ProductDetail from "../screens/ProductDetail";
import PromotionDetails from "../screens/PromotionDetails";
import Coupons from "../screens/Coupons";
import CouponDetails from "../screens/CouponDetails";
import PaymentMethods from "../screens/PaymentMethods";
import PaymentMethodsDetails from "../screens/PaymentMethodsDetails";
import Users from "../screens/Users";
import UserDetails from "../screens/UserDetails";
import Customizations from "../screens/Customizations";
import DeliveryFee from "../screens/DeliveryFee";
import Variations from "../screens/Variations";
import VariationDetails from "../screens/VariationDetails";
import IfoodIntegration from "../screens/IfoodIntegration";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Read from "./components/AppsMenu/Email/Read/Read";
import Stores from "../screens/Stores";
import StoreDetails from "../screens/StoreDetails";
import Register from "../screens/Registration";
import Integrations from "../screens/Integrations";
import AvailableIntegrations from "../screens/AvailableIntegrations";
import Contact from "../screens/Contact";
import Campaigns from "../screens/Campaigns";
import Reservations from "../screens/Reservations";
import Support from "../screens/Support";
import { AppProvider } from "../contexts/AppContext";
import CampaignDetails from "../screens/CampaignDetails";
import Whatsapp from "../screens/Whatsapp";
import Ai from "../screens/Ai";
import QrCodeGenerator from "../screens/QrCodeGenerator";
import Cashback from "../screens/Cashback";

const override = css`
  position: fixed;
  width: 150px;
  display: block;
  margin: 0 auto;
  border-color: red;
  top: 50%;
  left: 0;
  right: 0;
`;

const Markup = ({ isLoading, token }) => {
  const userName = useRef();
  const path = window.location.pathname

  const routes = [
    /// Dashboard
    { url: "", component: Orders /* Home */ },
    { url: "login", component: Login },
    { url: "orders", component: Orders },
    { url: "order-details/:id", component: OrderId },
    { url: "analytics", component: Analytics },
    { url: "review", component: Reviews },
    { url: "complements", component: Variations },
    { url: "complements-details", component: VariationDetails },
    { url: "complements-details/:id", component: VariationDetails },
    { url: "products", component: Products },
    { url: "categories", component: Categories },
    { url: "categorie-details", component: CategorieDetails },
    { url: "categorie-details/:id", component: CategorieDetails },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-detail/:id", component: ProductDetail },
    { url: "promotions", component: Promotions },
    { url: "promotion-details", component: PromotionDetails },
    { url: "promotion-details/:id", component: PromotionDetails },
    { url: "general-customers", component: GeneralCustomers },
    { url: "customer-details/:id", component: CustomerDetails },
    { url: "coupons", component: Coupons },
    { url: "coupon-details", component: CouponDetails },
    { url: "coupon-details/:id", component: CouponDetails },
    { url: "payment-methods", component: PaymentMethods },
    { url: "payment-method-details", component: PaymentMethodsDetails },
    { url: "payment-method-details/:id", component: PaymentMethodsDetails },
    { url: "users", component: Users },
    { url: "user-details", component: UserDetails },
    { url: "user-details/:id", component: UserDetails },
    { url: "delivery-fee", component: DeliveryFee },
    { url: "integrations", component: Integrations },
    { url: "available-integrations", component: AvailableIntegrations },
    { url: "customizations", component: Customizations },
    //{ url: "delivery-fee-details/:id", component: UserDetails },
    { url: "ifood-integration", component: IfoodIntegration },

    { url: "email-inbox", component: Inbox },
    { url: "email-compose", component: Compose },
    { url: "email-read", component: Read },
    { url: "stores", component: Stores },
    { url: "store-details", component: StoreDetails },
    { url: "store-details/:id", component: StoreDetails },
    { url: "cadastro", component: Register },
    { url: "contact", component: Contact },
    { url: "campaigns", component: Campaigns },
    { url: "campaign-details", component: CampaignDetails },
    { url: "campaign-details/:id", component: CampaignDetails },
    { url: "whatsapp", component: Whatsapp },
    { url: "ai", component: Ai },
    { url: "qrcode", component: QrCodeGenerator },
    { url: "reservations", component: Reservations },
    { url: "support", component: Support },
    { url: "cashback", component: Cashback },
  ];

  return (
    <BrowserRouter>
      <div
        id={`${token && path !== "/cadastro" ? "main-wrapper" : ""}`}
        className={`${token && path !== "/cadastro" ? "show" : "mh100vh"} ${
          !path.current ? "right-profile" : ""
        }`}
      >
        <NotificationsProvider>
          <AppProvider>
            <UserProvider userName={userName}>
              {token && path !== "/cadastro" && <Nav />}

              <div className={`${token && path !== "/cadastro" ? "content-body" : ""}`}>
                <div
                  className={`${token && path !== "/cadastro" ? "container-fluid" : ""}`}
                  style={{ minHeight: "100vh" }}
                >
                  <Switch>
                    {isLoading ? (
                      <BarLoader
                        color="var(--primary)"
                        loading={isLoading}
                        size={150}
                        css={override}
                      />
                    ) : (
                      routes.map((data, i) => (
                        <Route
                          key={i}
                          exact
                          path={`/${data.url}`}
                          component={data.component}
                        />
                      ))
                    )}
                  </Switch>
                </div>
              </div>

              {token && path !== "/cadastro" && <Footer />}
            </UserProvider>
          </AppProvider>
        </NotificationsProvider>
      </div>
    </BrowserRouter>
  );
};

export default Markup;
