import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import Swal from 'sweetalert2'
import VariationGroups from '../jsx/components/Products/VariationGroups'
import CopyVariations from '../jsx/components/ModalData/CopyVariations'
import ModalComponent from '../jsx/components/Modal'

function VariationDetails (props) {
  const [showModal, setShowModal] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors
  } = useForm({
    defaultValues: {
      group: [
        {
          title: '',
          requiredChoice: 0,
          multipleChoice: 0,
          addVariationValueToProduct: 0,
          min: 0,
          max: 1,
          status: 1,
          items: [
            {
              name: '',
              description: '',
              additional_price: '',
              thumb: ''
            }
          ]
        }
      ]
    }
  })

  useEffect(() => {
    if (props.productCurrentVariations.current) {
      reset({
        group: props.productCurrentVariations.current
      })
    }
  }, [props.productCurrentVariations])

  const onSubmit = data => {
    let validationError = false
    data.group.map(values => {
      if (values.items.length === 0) {
        Swal.fire({
          title: 'Atenção!',
          html: `Você deve cadastrar pelo menos 1 opção em seu grupo de variações "<b>${values.title}</b>" para continuar.`,
          icon: 'warning'
        })
        validationError = true
        return
      }
    })

    if (!validationError) {
      props.setActiveTab(0)
      props.variations_data.current = JSON.stringify(data.group)
      props.setNotify({
        title: 'Sucesso!',
        message: 'Variação salva!',
        type: 'success',
        visible: true
      })
    }
  }

  const modalProps = {
    title: 'Copiar Variação',
    content: (
      <CopyVariations
        reset={reset}
        setShowModal={setShowModal}
        setNotify={props.setNotify}
      />
    ),
    btnLabel: 'Salvar',
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false
  }

  return (
    <div className='basic-form'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-row'>
          <VariationGroups
            id={props.id}
            register={register}
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            setValue={setValue}
            setShowModal={setShowModal}
            setNotify={props.setNotify}
            useFieldArray={useFieldArray}
          />
        </div>
        <button type='submit' className='btn btn-block btn-primary' onClick={() => {
          if(Object.keys(errors).length > 0){
            props.setNotify({
              title: 'Atenção!',
              message: 'Você possuí campos obrigatórios em branco, verifique suas variações para continuar.',
              type: 'error',
              visible: true
            })
          }
        }}>
          Guardar Variação
        </button>
      </form>
      {showModal && <ModalComponent props={modalProps} />}
    </div>
  )
}

export default VariationDetails
