import React, { useEffect, useState, useRef, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "react-bootstrap";

import { AppSettings, Theme } from "../App";
import AlertResponses from "../jsx/components/Alert";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import {
  GetAvailableApps,
  CreateOrUpdateIntegration,
} from "../services/StoreServices";
import NumberFormat from "react-number-format";
import Tutorial from "../jsx/components/Tutorial";
import UserContext from "../contexts/UserContext";
import DefaultLoader from "../jsx/components/Loader";

export default function AvailableApps(props) {
  const { user } = useContext(UserContext);
  const [apps, setApps] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [erros, setErros] = useState(null);
  const [progress, setProgress] = useState(false);
  const [selected, setSelected] = useState(0);
  const dataToEdit = useRef(props.location?.data ? props.location : null);

  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const {
    register,
    handleSubmit,
    setError,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const submitBtn = useRef();

  useEffect(() => {
    GetAvailableApps(setApps, setErros, setIsLoading);
    if (dataToEdit.current) {
      setSelected(dataToEdit.current.data.app_id);
    }
  }, []);

  const onSubmit = (data) => {
    let obj = {
      status: 1,
      store: AppSettings.store.id,
    };

    if (data.googleAnalytics) {
      obj["app_id"] = 1;
      obj["data"] = { id: data.googleAnalytics };
    }

    if (data.googleAds) {
      obj["app_id"] = 5;
      obj["data"] = { id: `AW-${data.googleAds}` };
    }

    if (data.facebookPixel) {
      obj["app_id"] = 2;
      obj["data"] = { id: data.facebookPixel };
    }

    if (data.whatsappPhone && data.whatsappMessage) {
      let url = `https://api.whatsapp.com/send/?phone=55${data.whatsappPhone.replace(
        /[^0-9]/g,
        ""
      )}&text=${encodeURI(
        data.whatsappMessage
      )}&type=phone_number&app_absent=0`;
      obj["app_id"] = 3;
      obj["data"] = {
        phone: data.whatsappPhone.replace(/[^0-9]/g, ""),
        message: data.whatsappMessage,
        link: url,
      };
    }

    if (data.ifoodMerchantId) {
      if (!/^[a-zA-Z0-9-]/.test(data.ifoodMerchantId)) {
        setError("ifoodMerchantId", {
          type: "pattern",
        });
        return;
      }

      obj["app_id"] = 4;
      obj["data"] = {
        id: data.ifoodMerchantId,
        total_products: 0,
        registered_products: 0,
        enabled: 0,
        modules: {
          download_menu: user?.plan_id > 2 && data.downloadMenu ? 1 : 0,
          shipping: data.shipping ? 1 : 0,
        },
      };
    }

    submitBtn.current.setAttribute("disabled", true);
    if (user?.plan_id > 1 || obj["app_id"] === 3 || obj["app_id"] === 5) {
      CreateOrUpdateIntegration(
        props.location?.data ? props.location.data.id : null,
        obj,
        setNotify
      ).finally(() => {
        setTimeout(() => {
          submitBtn.current.removeAttribute("disabled");
        }, 200);
      });
    }
  };

  return (
    <>
      <div className="row">
        {isLoading ? (
          <DefaultLoader isLoading={isLoading} />
        ) : (
          <div className="col-xl-12 col-lg-4 col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <Tutorial
                    title={"Integrações"}
                    videoId={"Zi-NARQ6w4E"}
                    padding={"0 30px"}
                    classes={"my-3"}
                    tutorialTitle={"Tutorial iFood"}
                  />
                  <div className="col-xl-12 col-lg-4 col-md-4 col-sm-12 text-center d-flex flex-wrap">
                    {apps.map(
                      (item, index) =>
                        item.status === 1 && (
                          <div
                            className={`col-xl-4 col-lg-4 col-md-4 col-xxl-4 my-3 ${
                              dataToEdit.current &&
                              dataToEdit.current.data.app_id !== item.id
                                ? "d-none"
                                : "d-block"
                            }`}
                            key={index}
                          >
                            <img
                              src={require(`../images/${item.image}`).default}
                              width="50"
                            />
                            <h5 className="text-center mt-3">{item.name}</h5>
                            <div className="form-check">
                              {user?.plan_id === 1 &&
                                 (item.id === 1 ||
                                  item.id === 2 ||
                                  item.id === 4) && (
                                  <i
                                    className="ti-lock text-primary pr-4"
                                    aria-hidden="true"
                                  ></i>
                                )}
                             
                              <label
                                className="form-check-label"
                                style={{ lineHeight: 1.75 }}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  onClick={() => setSelected(item.id)}
                                  name="app"
                                  defaultChecked={selected === item.id}
                                  disabled={
                                    user?.plan_id === 1 &&
                                    (item.id === 1 ||
                                      item.id === 2 ||
                                      item.id === 4)
                                  }
                                />
                                Selecionar
                              </label>
                            </div>

                            {
                              /* Google Analytics */
                              selected === item.id && item.id === 1 && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group col-md-12 text-left">
                                      <label className="ml-2">ID</label>
                                      <input
                                        {...register("googleAnalytics", {
                                          required: true,
                                          pattern: /^[0-9]/,
                                          maxLength: 12,
                                          minLength: 9,
                                        })}
                                        className="form-control text-black"
                                        placeholder="ex: G-F8HNCE72GK"
                                        maxLength={12}
                                        defaultValue={
                                          dataToEdit.current
                                            ? (dataToEdit.current.data.data.id =
                                                dataToEdit.current.data.data.id.replace(
                                                  "G-",
                                                  ""
                                                ))
                                            : ""
                                        }
                                      />
                                      {errors.googleAnalytics && (
                                        <small className="ml-2 text-danger">
                                          {errors.googleAnalytics.type ===
                                            "required" &&
                                            "Por favor, preencha este campo!"}
                                          {errors.googleAnalytics.type ===
                                            "minLength" &&
                                            `Este campo deve conter no mínimo 9 caracteres!`}
                                          {errors.googleAnalytics.type ===
                                            "maxLength" &&
                                            `Este campo deve conter no máximo 12 caracteres!`}
                                          {errors.googleAnalytics.type ===
                                            "pattern" &&
                                            `Preencha este campo com o ID fornecido pelo Google!`}
                                        </small>
                                      )}
                                    </div>
                                  </div>

                                  <Button
                                    ref={(e) => (submitBtn.current = e)}
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                  >
                                    Salvar
                                  </Button>
                                </form>
                              )
                              /* Google Analytics */
                            }

                            {
                              /* Google ADS */
                              selected === item.id && item.id === 5 && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group col-md-12 text-left">
                                      <label className="ml-2">ID</label>
                                      <input
                                        {...register("googleAds", {
                                          required: true,
                                          pattern: /^[0-9]/,
                                          maxLength: 13,
                                          minLength: 9,
                                        })}
                                        className="form-control text-black"
                                        placeholder="Insira apenas números sem as letras AW-"
                                        maxLength={13}
                                        defaultValue={
                                          dataToEdit.current
                                            ? (dataToEdit.current.data.data.id =
                                                dataToEdit.current.data.data.id.replace(
                                                  "AW-",
                                                  ""
                                                ))
                                            : ""
                                        }
                                      />
                                      {errors.googleAds && (
                                        <small className="ml-2 text-danger">
                                          {errors.googleAds.type ===
                                            "required" &&
                                            "Por favor, preencha este campo!"}
                                          {errors.googleAds.type ===
                                            "minLength" &&
                                            `Este campo deve conter no mínimo 9 caracteres!`}
                                          {errors.googleAds.type ===
                                            "maxLength" &&
                                            `Este campo deve conter no máximo 13 caracteres!`}
                                          {errors.googleAds.type ===
                                            "pattern" &&
                                            `Preencha este campo com o ID fornecido pelo Google!`}
                                        </small>
                                      )}
                                    </div>
                                  </div>

                                  <Button
                                    ref={(e) => (submitBtn.current = e)}
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                  >
                                    Salvar
                                  </Button>
                                </form>
                              )
                              /* Google ADS */
                            }

                            {
                              /* Facebook */
                              selected === item.id && item.id === 2 && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group col-md-12 text-left">
                                      <label className="ml-2">ID</label>
                                      <input
                                        {...register("facebookPixel", {
                                          required: true,
                                          maxLength: 16,
                                          minLength: 14,
                                        })}
                                        className="form-control text-black"
                                        defaultValue={
                                          props.location?.data
                                            ? props.location.data.data.id
                                            : ""
                                        }
                                        placeholder="ex: 272990007337413"
                                        maxLength={16}
                                      />
                                      {errors.facebookPixel && (
                                        <small className="ml-2 text-danger">
                                          {errors.facebookPixel.type ===
                                            "required" &&
                                            "Por favor, preencha este campo!"}
                                          {errors.facebookPixel.type ===
                                            "minLength" &&
                                            `Este campo deve conter no mínimo 14 caracteres!`}
                                          {errors.facebookPixel.type ===
                                            "maxLength" &&
                                            `Este campo deve conter no máximo 16 caracteres!`}
                                        </small>
                                      )}
                                    </div>
                                  </div>

                                  <Button
                                    ref={(e) => (submitBtn.current = e)}
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                  >
                                    Salvar
                                  </Button>
                                </form>
                              )
                              /* Facebook */
                            }
                            {
                              /* Whatsapp Button */
                              selected === item.id && item.id === 3 && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group col-md-12 text-left">
                                      <label className="ml-2">Telefone</label>
                                      <Controller
                                        control={control}
                                        rules={{ required: true }}
                                        name="whatsappPhone"
                                        defaultValue={
                                          dataToEdit.current
                                            ? dataToEdit.current.data.data.phone
                                            : ""
                                        }
                                        render={({
                                          field: {
                                            onChange,
                                            defaultValue,
                                            name,
                                          },
                                        }) => (
                                          <NumberFormat
                                            defaultValue={
                                              dataToEdit.current
                                                ? dataToEdit.current.data.data
                                                    .phone
                                                : ""
                                            }
                                            format="(##) #####-####"
                                            className="form-control text-black"
                                            placeholder="Insira o número"
                                            onChange={(e) => {
                                              if (errors.whatsappPhone) {
                                                clearErrors("whatsappPhone");
                                              }

                                              setValue(
                                                "whatsappPhone",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.whatsappPhone && (
                                        <small className="ml-2 text-danger">
                                          {errors.whatsappPhone.type ===
                                            "required" &&
                                            "Por favor, preencha este campo!"}
                                        </small>
                                      )}

                                      <label className="ml-2 mt-2">
                                        Mensagem
                                      </label>
                                      <input
                                        {...register("whatsappMessage", {
                                          required: true,
                                          maxLength: 70,
                                        })}
                                        className="form-control text-black"
                                        defaultValue={
                                          dataToEdit.current
                                            ? dataToEdit.current.data.data
                                                .message
                                            : ""
                                        }
                                        placeholder="ex: Olá, gostaria de atendimento!"
                                        maxLength={70}
                                      />
                                      {errors.whatsappMessage && (
                                        <small className="ml-2 text-danger">
                                          {errors.whatsappMessage.type ===
                                            "required" &&
                                            "Por favor, preencha este campo!"}
                                          {errors.whatsappMessage.type ===
                                            "maxLength" &&
                                            `Este campo deve conter no máximo 70 caracteres!`}
                                        </small>
                                      )}
                                    </div>
                                  </div>

                                  <Button
                                    ref={(e) => (submitBtn.current = e)}
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                  >
                                    Salvar
                                  </Button>
                                </form>
                              )
                              /* Whatsapp button */
                            }
                            {
                              /* Ifood */
                              selected === item.id && item.id === 4 && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group col-md-12 text-left mt-3">
                                      <label className="ml-2">ID da Loja</label>
                                      <input
                                        {...register("ifoodMerchantId", {
                                          required: true,
                                          maxLength: 50,
                                          minLength: 25,
                                        })}
                                        className="form-control text-black"
                                        placeholder="ex: 3f896d64-02f8-4b06-95a3-1c45d6698d43"
                                        defaultValue={
                                          dataToEdit.current
                                            ? dataToEdit.current.data.data.id
                                            : ""
                                        }
                                      />
                                      {errors.ifoodMerchantId && (
                                        <small className="ml-2 text-danger">
                                          {errors.ifoodMerchantId.type ===
                                            "required" &&
                                            "Por favor, preencha este campo!"}
                                          {errors.ifoodMerchantId.type ===
                                            "minLength" &&
                                            `Este campo deve conter no mínimo 25 caracteres!`}
                                          {errors.ifoodMerchantId.type ===
                                            "maxLength" &&
                                            `Este campo deve conter no máximo 50 caracteres!`}
                                          {errors.ifoodMerchantId.type ===
                                            "pattern" &&
                                            "Por favor, insira o ID disponibilizado pelo Ifood!"}
                                        </small>
                                      )}
                                    </div>
                                    <div className="form-group col-md-12  my-3">
                                      <h6>Habilitar módulos iFood</h6>
                                      <div className="d-flex">
                                        <div className="custom-control custom-checkbox mr-3 my-1">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked={
                                              dataToEdit.current
                                                ? dataToEdit.current.data.data
                                                    .modules.download_menu
                                                : false
                                            }
                                            id={"downloadMenu"}
                                            defaultValue=""
                                            disabled={
                                              user?.plan_id < 3 ? true : false
                                            }
                                            {...register("downloadMenu")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`downloadMenu`}
                                          >
                                            {" "}
                                            {user?.plan_id < 3 && (
                                              <i
                                                className="ti-lock text-primary pr-1"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                            Baixar Cardápio
                                          </label>
                                        </div>
                                        <div className="custom-control custom-checkbox mr-3 my-1">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked={
                                              dataToEdit.current
                                                ? dataToEdit.current.data.data
                                                    .modules.shipping
                                                : false
                                            }
                                            id={"shipping"}
                                            defaultValue=""
                                            {...register("shipping")}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`shipping`}
                                          >
                                            Solicitar Entregadores
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <Button
                                    ref={(e) => (submitBtn.current = e)}
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                  >
                                    Salvar
                                  </Button>
                                </form>
                              )
                              /* Ifood */
                            }
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {progress && <ProgressBarComponent />}
            {notify.visible && (
              <AlertResponses
                title={notify.title}
                message={notify.message}
                type={notify.type}
                visible={notify.visible}
                setNotify={setNotify}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

const styles = {
  image: {
    maxWidth: 330,
    maxHeight: 250,
    width: "100%",
    objectFit: "cover",
  },
};
