import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Hash } from "../../../../helpers/Helpers";
import NumberFormat from "react-number-format";

const OrderIdData = ({ order }) => {
  const [data, setData] = useState(order);
  return (
    <>
      {data.order_to === "delivery" && (
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive order-list card-table">
                <table className="table items-table table-responsive-md">
                  <thead>
                    <tr>
                      <th>#</th>

                      {data.payment_method.type === "Cartão" && (
                        <th className="my-0 px-3 text-black font-w500 fs-16">
                          Identificador
                        </th>
                      )}
                      <th className="my-0 px-3 text-black font-w500 fs-16">
                        Pagamento
                      </th>
                      <th className="my-0 px-3 text-black font-w500 fs-16">
                        Operação
                      </th>
                      {data.payment_method.type !== "Dinheiro" && (
                        <th className="my-0 px-3 text-black font-w500 fs-16">
                          Bandeira
                        </th>
                      )}

                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-3">
                        <img
                          width={30}
                          src={`/imgs/payment_methods/${data.payment_method.thumb}`}
                          alt=""
                        />
                      </td>
                      {data.payment_method.type === "Cartão" && (
                        <td className="px-3">
                          <h5> #{data.payment_method?.id}</h5>
                        </td>
                      )}

                      <td className="px-3">
                        <h5> {data.payment_method.type}</h5>
                      </td>
                      <td className="px-3">
                        <h5> {data.payment_method.operation}</h5>
                      </td>
                      {data.payment_method.type !== "Dinheiro" && (
                        <td className="px-3">
                          <h5> {data.payment_method.name}</h5>
                        </td>
                      )}

                      {data.payment_method.changeFor && (
                        <td className="d-flex px-3">
                          <div className="mr-3">
                            <h5>Troco para:</h5>
                            <h5 className="my-0 text-black font-w600">
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={data.payment_method.changeFor}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />
                            </h5>
                          </div>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body p-0">
            <div className="table-responsive order-list card-table">
              <table className="table items-table table-responsive-md">
                <thead>
                  <tr>
                    <th className="my-0 text-black font-w500 fs-20">
                      Pedido #{data.order_number}
                    </th>
                    <th
                      style={{ width: "10%" }}
                      className="my-0 text-black font-w500 fs-20"
                    >
                      Qtd
                    </th>
                    <th
                      style={{ width: "10%" }}
                      className="my-0 text-black font-w500 fs-20"
                    >
                      Preço
                    </th>

                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.order.map((data, index) => (
                      <tr
                        key={index}
                        className="alert alert-dismissible border-0"
                      >
                        <td>
                          <div className="media">
                            <Link to="ecom-product-detail">
                              <img
                                className="mr-3 img-fluid rounded"
                                width={85}
                                src={Hash("85x85", data.image)}
                                alt="Product5"
                              />
                            </Link>
                            <div className="media-body">
                              <small className="mt-0 mb-1 font-w500">
                                <Link className="text-primary" to="#">
                                  {data.category_name}
                                </Link>
                              </small>
                              <h5 className="mt-0 mb-2 mb-4">
                                <Link
                                  className="text-black mb-2"
                                  to="ecom-product-detail"
                                >
                                  {data.name}
                                </Link>

                                {data.variationData &&
                                  data.variationData.map((variation, i) => (
                                    <div key={i}>
                                      <small>
                                        {variation &&
                                        variation?.option?.length > 0 ? (
                                          <>
                                            {variation.title} :
                                            {variation.option.map(
                                              (add) => ` ${add.name}, `
                                            )}
                                          </>
                                        ) : (
                                          variation.title +
                                          ": " +
                                          variation.option.name
                                        )}
                                      </small>
                                    </div>
                                  ))}
                                {data.observations !== (null || "") && (
                                  <div className="mt-3">
                                    Observações:
                                    <p>{data.observations}</p>
                                  </div>
                                )}
                              </h5>
                              {/* <div className="star-review fs-14">
                              {data.star.map((icon, index) => {
                                return (
                                  <i
                                    key={index}
                                    className={`${
                                      icon === 1
                                        ? "fa fa-star text-orange mr-1"
                                        : "fa fa-star text-gray mr-1"
                                    }`}
                                  />
                                );
                              })}
                              <span className="ml-3 text-dark">
                                ({data.reviews} revies)
                              </span>
                            </div> */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className="my-0 text-black font-w600">
                            {data.quantity}x
                          </h5>
                        </td>
                        <td>
                          <h5 className="my-0 text-black font-w600">
                            <NumberFormat
                              displayType="text"
                              decimalScale={2}
                              defaultValue={data.finalPrice}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="R$"
                              fixedDecimalScale={true}
                            />
                          </h5>
                        </td>
                      </tr>
                    ))}

                  <tr>
                    <td className="d-flex">
                      <div className="mr-3">
                        <h5>Subtotal</h5>
                        <h5 className="my-0 text-black font-w600">
                          <NumberFormat
                            displayType="text"
                            decimalScale={2}
                            defaultValue={data.subtotal}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            fixedDecimalScale={true}
                          />
                        </h5>
                      </div>
                      <div className="mr-3">
                        <h5>Entrega</h5>
                        <h5 className="my-0 text-black font-w600">
                          <NumberFormat
                            displayType="text"
                            decimalScale={2}
                            defaultValue={data.deliveryFee}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            fixedDecimalScale={true}
                          />
                        </h5>
                      </div>
                      {data.coupon && (
                        <div className="mr-3">
                          <h5>
                            Cupom:{" "}
                            <small className="text-primary">
                              {data.coupon.couponName.toUpperCase()}
                            </small>
                          </h5>
                          <h5 className="my-0 text-black font-w600">
                            -
                            <NumberFormat
                              displayType="text"
                              decimalScale={2}
                              defaultValue={data.coupon.discount}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="R$"
                              fixedDecimalScale={true}
                            />
                          </h5>
                        </div>
                      )}
                      {data.cashback_value && (
                        <div>
                          <h5>Cashback: </h5>
                          <h5 className="my-0 text-black font-w600">
                            -
                            <NumberFormat
                              displayType="text"
                              decimalScale={2}
                              defaultValue={data.cashback_value}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="R$"
                              fixedDecimalScale={true}
                            />
                          </h5>
                        </div>
                      )}
                    </td>
                    <td></td>
                    <td>
                      <h5>Total </h5>
                      <h5 className="my-0 text-black font-w600">
                        <NumberFormat
                          displayType="text"
                          decimalScale={2}
                          defaultValue={data.total}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$"
                          fixedDecimalScale={true}
                        />
                      </h5>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderIdData;
