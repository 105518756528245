import React, { useEffect, useState, useContext } from "react";

import Swal from "sweetalert2";
import { Button, Table } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { CreateNewAgent } from "../../../services/AiServices";
import ifoodLogo from "../../../images/ifood.png";
import {
  GetIfoodDeliveryQuote,
  IfoodRequestDriver,
} from "../../../services/DeliveryServices";
import { MoneyFormat } from "../../../helpers/Helpers";
import NotificationsContext from "../../../contexts/NotificationsContext";

export default function RequestDriver(props) {
  const { setNotify } = useContext(NotificationsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [quotes, setQuotes] = useState(false);

  const handleGetQuotes = async () => {
    setIsLoading(true);
    let user_id = props.order.user_id || props.order.user;
    let res = await GetIfoodDeliveryQuote(user_id);
    if (res) {
      setQuotes(res);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleRequestDriver = async () => {
    setIsLoading(true);
    let payload = {
      order_id: props.order.id,
      quote_id: quotes.id,
    };
    let res = await IfoodRequestDriver(payload);
    if (res) {
      let trackings = localStorage.getItem("ifoodTrackings");
      res.orderId = props.order.id;
      if (trackings) {
        trackings = JSON.parse(trackings);
        trackings = [...trackings, res];
        localStorage.setItem("ifoodTrackings", JSON.stringify(trackings));
      } else {
        localStorage.setItem("ifoodTrackings", JSON.stringify([res]));
      }

      setNotify({
        title: "Sucesso!",
        message: "Entrega solicitada com sucesso!",
        type: "success",
        visible: true,
      });
      setIsLoading(false);
      props.setShowModal(false);
      props.setTracking(res)
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(props);
    /* if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    } */
  }, []);

  return (
    <div id="request-driver">
      <div className="form-row">
        <div className="form-group col-md-12 text-center">
          <span id="ifood-request-driver-wrapper">
            <img src={ifoodLogo} width={75} height={50} alt="" />
          </span>
          {quotes && quotes.quote && (
            <div className="quotes mb-3">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="c-titles">Valor</th>
                    <th className="c-titles">Tempo</th>
                    <th className="c-titles">Distância</th>
                    <th className="c-titles">Expiração</th>
                    {/* <th>Ações</th> */}
                  </tr>
                </thead>
                <tbody>
                  <td className="c-subtitles">
                    {MoneyFormat(quotes.quote.netValue)}
                  </td>
                  <td className="c-subtitles">
                    {Math.round(quotes.deliveryTime.min / 60)} à{" "}
                    {Math.round(quotes.deliveryTime.max / 60)}minutos
                  </td>
                  <td className="c-subtitles">{quotes.distance / 1000}km</td>
                  <td className="c-subtitles">
                    {new Date(quotes.expirationAt).toLocaleString()}
                  </td>
                </tbody>
              </Table>
            </div>
          )}
          {!quotes ? (
            <Button
              type="button"
              className="btn btn-sm btn-info"
              disabled={isLoading ? true : false}
              onClick={handleGetQuotes}
            >
              {isLoading ? "Obtendo cotação..." : "Obter cotação iFood"}
            </Button>
          ) : (
            <Button
              type="button"
              className="btn btn-primary"
              onClick={() => handleRequestDriver()}
              disabled={isLoading ? true : false}
            >
              {isLoading ? "Solicitando..." : "Solicitar Entregador"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
