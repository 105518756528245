import React, { useEffect, useState, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Table, ProgressBar } from 'react-bootstrap'
import {
  Auth,
  DeleteIfoodFile,
  GetIfoodFileImages,
  GetProgress,
  Init,
  ListCatalogs,
  ListCategories,
  UpdateImage
} from '../services/IfoodIntegrationServices'
import Swal from 'sweetalert2'
import DefaultLoader from '../jsx/components/Loader'
import axios from 'axios'
import { Update } from '../services/ProductsServices'
import { CreateOrUpdateIntegration } from '../services/StoreServices'

export default function IfoodIntegration (props) {
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm()
  const [progress, setProgress] = useState(0)
  const [enabled, setEnabled] = useState(props.data.data.enabled)
  const [authProgress, setAuthProgress] = useState({
    status: 'Pendente',
    statusText: 'text-secondary'
  })
  const [catalogsProgress, setCatalogsProgress] = useState({
    status: 'Pendente',
    statusText: 'text-secondary'
  })
  const [categoriesProgress, setCategoriesProgress] = useState({
    status: 'Pendente',
    statusText: 'text-secondary'
  })

  const [productsRegisterProgress, setProductsRegisterProgress] = useState({
    status: 'Pendente',
    statusText: 'text-secondary'
  })
  const [downloadImagesRegisterProgress, setDownloadImagesRegisterProgress] =
    useState({
      status: 'Pendente',
      statusText: 'text-secondary'
    })
  let catalogs, data, token
  const interval = useRef()
  const timer = useRef(0)

  const enableIfoodIntegration = async () => {
    let data = props.data.data
    data.enabled = 1
    let obj = {
      data: data,
    }
    
    
    let response = CreateOrUpdateIntegration(props.data.id, obj, false, true)
    if(response){
      setEnabled(1)
      Swal.fire({
        title: "Sucesso",
        html: "Agora você pode realizar o download de seu cardápio do iFood.",
        icon: "success"
      })
    }else{
      Swal.fire({
        title: "Oops!",
        html: "Ocorreu algum problema inesperado, por favor, tente novamente daqui alguns minutos. Se o problema persistir, entre em contato com nosso suporte.",
        icon: "error"
      })
    }
  }

  const handleUpdateImage = async (id, image_url, dataType) => {
    let image = await axios.get(image_url, { responseType: 'arraybuffer' })
    let form = new FormData()

    image_url = image_url.replace(/.*pratos\//, '')
    let mimeType = image_url.split('.')[1]
    let blobFile = new Blob([image.data], {
      type: `image/${mimeType === 'jpg' ? 'jpeg' : mimeType}`
    })

    if (dataType === 'variation') {
      form.append('id', id)
      form.append('thumb', blobFile, image_url)
    } else {
      form.append('image', blobFile, image_url)
    }

    let res = await UpdateImage(id, form, dataType, mimeType, true)
    return res
  }

  const step1 = async () => {
    catalogs = await ListCatalogs({
      merchant_id: props.data.data.id,
      token: token
    })

    if (catalogs && catalogs.length > 0) {
      setCatalogsProgress({
        status: 'Concluído com sucesso',
        statusText: 'text-success'
      })
      setProgress(10)
      step2()
    } else {
      setCatalogsProgress({
        status: 'Falha na listagem',
        statusText: 'text-primary'
      })
      document
        .querySelector("button[type='submit']")
        .removeAttribute('disabled')
    }
  }

  const step2 = async () => {
    data = await ListCategories({
      merchant_id: props.data.data.id,
      catalog_id: catalogs[0].catalogId,
      token: token
    })

    if (data && data.length > 0) {
      setCategoriesProgress({
        status: 'Concluído com sucesso',
        statusText: 'text-success'
      })
      setProgress(15)
      step3()
    } else {
      setCategoriesProgress({
        status: 'Falha na listagem',
        statusText: 'text-primary'
      })
      document
        .querySelector("button[type='submit']")
        .removeAttribute('disabled')
    }
  }
  const step3 = async () => {
    setProductsRegisterProgress({
      status: 'Em andamento...',
      statusText: 'text-info'
    })
    setProgress(20)
    let taskId = await Init({
      data: data
    })

    if (taskId) {
      let _interval = setInterval(() => {
        GetProgress(taskId).then(res => {
          if (res.state === 'PROGRESS') {
            if (res.progress.percent == 100) {
              setProgress(100)
              setProductsRegisterProgress({
                status: 'Concluído com sucesso!',
                statusText: 'text-success'
              })
              step4()
              clearInterval(_interval)
            } else {
              setProgress(res.progress.percent)
            }
          }
          if (res.state === 'SUCCESS') {
            setProgress(100)
            setProductsRegisterProgress({
              status: 'Concluído com sucesso!',
              statusText: 'text-success'
            })
            step4()

            clearInterval(_interval)
          }
          if (res.state === 'FAILURE') {
            Swal.fire({
              title: 'Oops!',
              html: 'Ocorreu um problema inesperado ao tentar baixar seu cardápio do iFood, por favor, <b>tente novamente daqui alguns minutos...</b> Se o problema persistir, contacte nosso setor de suporte.',
              icon: 'warning',
              buttons: true,
              confirmButtonText: `Entendi`,
              cancelButtonText: 'Fechar',
              showCancelButton: true,
              confirmButtonColor: 'var(--primary)'
            }).then(value => {
              if (value.isConfirmed) {
                window.location.assign('/integrations')
              }
            })
            clearInterval(_interval)
          }
        })
      }, 10000)
      localStorage.setItem('ifoodTaskId', taskId)
    }
  }

  const sleep = m => new Promise(r => setTimeout(r, m));

  const step4 = async () => {
    setDownloadImagesRegisterProgress({
      status: 'Em andamento...',
      statusText: 'text-info'
    })
    let response = await GetIfoodFileImages(props.user.store_id)
    let arr = []
    let totalProducts = 0
    let counter = 0
    if (response && response[0].products.length > 0) {
      totalProducts = totalProducts + response[0].products.length
      response[0].products.map(async (value, index) => {
        counter = counter + index
        await sleep(2000);
        let res = handleUpdateImage(value.product_id, value.image, 'product')
        let percent = (counter / totalProducts) * 100
        setProductsRegisterProgress(Math.ceil(percent))
        //arr.append(res)
      })
      if (response[0].options) {
        totalProducts = totalProducts + response[0].options.length
        response[0].options.map(async (value, index) => {
          counter = counter + index
          await sleep(2000);
          let res = handleUpdateImage(value.option_id, value.image, 'variation')
          let percent = (counter / totalProducts) * 100
          setProductsRegisterProgress(Math.ceil(percent))
          //arr.append(res)
        })
      }
    }
    setDownloadImagesRegisterProgress({
      status: 'Sucesso!',
      statusText: 'text-success'
    })
    Swal.fire({
      title: 'Sucesso!',
      html: 'Produtos do iFood cadastrados com sucesso!',
      icon: 'success',
      buttons: true,
      confirmButtonText: `Ver meus produtos`,
      cancelButtonText: 'Fechar',
      showCancelButton: true,
      confirmButtonColor: 'var(--success)'
    }).then(value => {
      if (value.isConfirmed) {
        window.location.href = '/products'
      }
    })
  }

  const onSubmit = async data => {
    let table = document.querySelector('#ifood-progress-table')

    if (table.className.includes('d-none')) {
      table.classList.remove('d-none')
    }

    document
      .querySelector("button[type='submit']")
      .setAttribute('disabled', true)

    interval.current = setInterval(() => {
      timer.current++
      if (timer.current > 90) {
        Swal.fire({
          title: 'Oops!',
          html: 'Parece que a conexão com o Ifood demorou demais para responder... <br> Por favor, tenta novamente daqui alguns minutos.</br>',
          icon: 'info'
        })
        clearInterval(interval.current)
      }
    }, 1000)

    let tk = await Auth({ merchant_id: props.data.data.id })
    clearInterval(interval.current)

    if (tk?.accessToken) {
      token = tk.accessToken
      setAuthProgress({
        status: 'Concluído com sucesso',
        statusText: 'text-success'
      })
      setProgress(5)
      step1()
    } else {
      setAuthProgress({
        status: 'Falha na autenticação',
        statusText: 'text-primary'
      })
      document
        .querySelector("button[type='submit']")
        .removeAttribute('disabled')
    }
  }

  return (
    <>
      <div className='row flex-column'>
        <div className='col-xl-12'>
          <div className='card'>
            <div className='card-body'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-group col-md-12'>
                  {enabled === 0 && (
                    <div className='text-center'>
                      <h4 className='text-center mb-4'>Loja não habilitada!</h4>
                      <p className='text-center mb-5'>
                        Para continuar com o download do cardápio do iFood, você
                        deve primeiramente autorizar nosso app a buscar as
                        informações de sua loja. Enviaremos a solicitação de
                        autorização em até 1h, você será notificado pelo nosso
                        bot via whatsapp. Para autorizar nosso app, basta
                        acessar o menu "Aplicativos" em "Serviços e Produtos",
                        selecionar o aplicativo do Pralevar Plataforma de
                        delivery, clicar no ícone de 3 pontos no canto superior
                        direito e autorizar. <br></br>
                        <b>
                          Assista ao vídeo explicativo caso tenha alguma
                          dificuldade na autorização.
                        </b>
                      </p>
                      <button
                        type='button'
                        className='btn btn-primary mt-4'
                        onClick={() => {
                          Swal.fire({
                            title: 'Atenção!',
                            html: 'Só continue com esta ação, se você realmente concluiu a autorização do nosso aplicativo, caso contrário, poderá ocorrer problemas no download de seus produtos do iFood.',
                            icon: 'warning',
                            buttons: true,
                            confirmButtonText: `Já autorizei, quero continuar`,
                            cancelButtonText: 'Fechar',
                            showCancelButton: true,
                            confirmButtonColor: 'var(--primary)'
                          }).then(value => {
                            if (value.isConfirmed) {
                              enableIfoodIntegration()
                            }
                          })
                        }}
                      >
                        Já autorizei
                      </button>
                    </div>
                  )}
                  {enabled === 1 && (
                    <div className='ifood-integrations'>
                      {props.user.plan_id === 2 && (
                        <div>
                          <h4 className='text-center'>Atenção!</h4>
                          <h6 className='text-center mb-5'>
                            A integração para cadastrar seus produtos do ifood
                            será de acordo com as regras de seu plano que
                            permite até 40 produtos, 3 variações por produto e 4
                            categorias. Vocẽ pode fazer um upgrade caso tenha
                            mais categorias,produtos e variações por produto.{' '}
                          </h6>
                        </div>
                      )}
                      <h4>Cardápio</h4>
                      <div className='d-flex mt-1 ml-2'>
                        <div className='custom-control custom-checkbox mr-3'>
                          <input
                            type='radio'
                            {...register('ifood-menu')}
                            className='custom-control-input'
                            id={'ifood-menu-once'}
                            value={1}
                            defaultChecked={
                              props.user.plan_id === 1 ? false : true
                            }
                            disabled={props.user.plan_id === 1 ? true : false}
                            /* onClick={() => setDiscountBy('value')} */
                          />
                          <label
                            className='custom-control-label'
                            htmlFor={`ifood-menu-once`}
                          >
                            {props.user.plan_id === 1 && (
                              <i
                                className='ti-lock text-primary mr-2'
                                aria-hidden='true'
                              ></i>
                            )}
                            Baixar uma única vez
                          </label>
                        </div>

                        {/* <div className='custom-control custom-checkbox'>
                      <input
                        type='radio'
                        {...register('ifood-menu')}
                        className='custom-control-input'
                        id={'ifood-menu-sync'}
                        value={2}
                        defaultChecked={false}
                        disabled={
                          props.user.plan_id === 1 || props.user.plan_id === 2
                            ? true
                            : false
                        }

                      />
                      <label
                        className='custom-control-label'
                        htmlFor={`ifood-menu-sync`}
                      >
                        {props.user.plan_id === 1 ||
                          (props.user.plan_id === 2 && (
                            <i
                              className='ti-lock text-primary mr-2'
                              aria-hidden='true'
                            ></i>
                          ))}
                        Sincronizar Cardápio
                      </label>
                    </div> */}
                      </div>
                      {props.user.plan_id === 2 &&
                        props.data.data.registered_products ===
                          props.data.data.total_products && (
                          <p className='px-4'>
                            Você já baixou o cardápio do Ifood. Se deseja
                            sincronizar, faça um upgrade para obter todos os
                            benefícios.
                          </p>
                        )}

                      <button
                        className='btn btn-primary mt-4'
                        type='submit'
                        disabled={
                          props.user.plan_id === 1 ||
                          (props.user.plan_id === 2 &&
                            props.data.data.registered_products ===
                              props.data.data.total_products)
                            ? true
                            : false
                        }
                      >
                        Iniciar
                      </button>
                    </div>
                  )}
                </div>
              </form>
              <div id='ifood-progress-table' className='mt-4 d-none'>
                <div
                  className={`text-center ${progress === 0 ? '' : 'd-none'}`}
                >
                  <h5>
                    *Conectando ao Ifood{' '}
                    <DefaultLoader cs='display:inline-block;vertical-align: sub;' />{' '}
                    <br></br>
                    Este processo pode levar alguns segundos até iniciar a
                    integração.
                  </h5>
                </div>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Etapa</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={'text-secondary'}>Autenticação</td>
                      <td className={authProgress.statusText}>
                        {authProgress.status}
                      </td>
                    </tr>
                    <tr>
                      <td className={'text-secondary'}>Listar Catálogos</td>
                      <td className={catalogsProgress.statusText}>
                        {catalogsProgress.status}
                      </td>
                    </tr>
                    <tr>
                      <td className={'text-secondary'}>
                        Cadastrar Produtos e Categorias
                      </td>
                      <td className={productsRegisterProgress.statusText}>
                        {productsRegisterProgress.status}
                      </td>
                    </tr>
                    <tr>
                      <td className={'text-secondary'}>
                        Baixar Imagens dos Produtos
                      </td>
                      <td className={downloadImagesRegisterProgress.statusText}>
                        {downloadImagesRegisterProgress.status}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className='my-3 mx-3'>
                  <h5>
                    {getValues('ifood-menu') == 1
                      ? 'Progresso de download do cardápio'
                      : 'Progresso da sincronização do cardápio'}
                  </h5>
                  <ProgressBar
                    animated
                    label={`${progress}%`}
                    now={progress}
                    variant='info'
                  />
                  <p className='mt-4'>*Aguarde até a finalização do processo, isto pode levar alguns minutos...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
