import Swal from "sweetalert2";
import { api } from "../api/Middleware";
import { USERS_URL, APP_BASE_URL } from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const ValidateCPF = async (data) => {
  try {
    let response = await api.post(USERS_URL + "validate_cpf/", data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const GetOne = async (id, setData, setValue, setIsLoading, reset) => {
  try {
    let response = await api.get(USERS_URL + id + "/");
    if (response.status === 200) {
      reset({
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        email: response.data.email,
        cpf: response.data.cpf,
        birthday: response.data.birthday.split("-").reverse().join("-"),
        phone: response.data.phone,
        is_active: response.data.is_active,
        superuser_level: response.data.superuser_level,
        store_id: response.data.store_id,
      });
    }
  } catch (error) {
    //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
    /*  if(error.status !== 401){
             
             
         }  */
  }
  setIsLoading(false);
};

export const GetAll = async (setUsers, setErrors, setIsLoading) => {
  try {
    let response = await api.get(USERS_URL + "get_superusers/");
    if (response.status === 200) setUsers(response.data);
  } catch (error) {
    setErrors({ title: "Oops!", message: ERROR_MESSAGES.categories.get });
  }

  setIsLoading(false);
};

export const Create = async (
  data,
  setData,
  setNotify,
  setError,
  returnData = false,
  firstAdmin = false,
  setProgress = false
) => {
  try {
    let response = await api.post(
      firstAdmin ? USERS_URL + "create_first_admin/" : USERS_URL,
      data
    );
    if (response.status === 200) {
      if (returnData) {
        return response.data;
      } else {
        //localStorage.setItem('user', JSON.stringify(response.data))
        setData(response.data);
        setNotify({
          title: "Sucesso!",
          message: "Usuário cadastrado com sucesso!",
          type: "success",
          visible: true,
        });
        setTimeout(
          () => {},
          [(window.location.href = APP_BASE_URL + "users")],
          3000
        );
      }
    }
  } catch (error) {
    if (returnData) {
      return error.response;
    } else {
      if (error.response.status === 400) {
        if (
          error.response.data &&
          Object.keys(error.response.data).length > 0
        ) {
          Object.keys(error.response.data).forEach((key, index) => {
            console.log(key);
            console.log(index);
            setError(key, {
              message: error.response.data[key][0],
              type: "custom",
            });
          });
        }
      } else if (error.response.status === 401) {
        window.location.href = APP_BASE_URL;
      } else {
        setNotify({
          title: "Oops!",
          message: error.response?.data,
          type: "error",
          visible: true,
        });
      }
    }
  } finally {
    if (setProgress) setProgress(false);
  }
};

export const Update = async (
  id,
  data,
  setUser,
  setNotify,
  setError = false,
  setProgress = false
) => {
  try {
    let response = await api.put(USERS_URL + id + "/", data);
    if (response.status === 200) {
      if (id === response.data.id) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      setNotify({
        title: "Sucesso!",
        message: "Usuário atualizado com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(
        () => {},
        [(window.location.href = APP_BASE_URL + "users")],
        3500
      );
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    if (error.response.status === 400) {
      if (error.response.data && Object.keys(error.response.data).length > 0) {
        Object.keys(error.response.data).forEach((key, index) => {
          setError(key, {
            message: error.response.data[key][0],
            type: "custom",
          });
        });
      }
    } else {
      setNotify({
        title: "Oops!",
        message: ERROR_MESSAGES.users.update,
        type: "error",
        visible: true,
      });
    }
  } finally {
    if (setProgress) setProgress(false);
  }
};

export const ChangeStatus = async (data, setNotify, setProgress) => {
  setProgress(true);
  try {
    let response = await api.put(USERS_URL + data.id + "/", {
      is_active: parseInt(data.status),
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Status atualizado com sucesso!",
        type: "success",
        visible: true,
      });
      setProgress(false);
      return true;
    }
  } catch (error) {
    setNotify({
      title: "Oops!",
      message: ERROR_MESSAGES.orders.status,
      type: "error",
      visible: true,
    });
    setProgress(false);
    return false;
  }
};

export const Delete = async (id, setNotify, setProgress) => {
  setProgress(true);

  try {
    let ids = [id];

    let response = await api.delete(USERS_URL + "delete_multiple/", {
      data: { ids: ids },
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Operação realizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }

  setProgress(false);
};

export const GetUserCep = async (cep) => {
  try {
    let response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const AccessStore = async (data) => {
  try {
    let response = await api.post(USERS_URL + "access_store/", data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    Swal.fire("Oops", error.response.data, "error");
  }
};

export const FilterUsersBy = async (payload) => {
  try {
    let response = await api.get(USERS_URL + "filter_by/?" + payload);
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
