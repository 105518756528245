
import React from "react"
import "./termsOfUse.css"

export default function TermsOfUse(props) {

    return (
        <div id="terms-of-use">
            <p><b>Estes são nossos termos para uso da ferramenta em caráter Gratuito ou Premium que determinarão nossa relação. Caso não concorde, não avance na criação da sua conta. Antes de utilizar o Pralevar, é necessário que o CONTRATANTE leia, entenda e concorde com estes termos.</b></p>

            <p>Este Contrato de Licença de Uso de Usuário Final (o “CLUF”) é um acordo legal entre o CONTRATANTE (pessoa física ou jurídica) (o "CONTRATANTE") e STECHZ SOLUÇÕES DIGITAIS, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob ​n. 37.403.686/0001-71, neste ato representada na forma de seu Contrato Social, doravante denominada “Pralevar” (a "CONTRATADA"), e define as condições pelas quais o CONTRATANTE poderá acessar e utilizar as funcionalidades do programa de computador denominado Pralevar (a “PLATAFORMA”), disponibilizado em ambiente web sob a modalidade “Software as a Service” (SaaS) e seu uso limitado a estas condições de licenciamento pelo prazo determinado pelo LICENCIADO no ato do licenciamento do SOFTWARE, o que inclui o programa de computador e pode incluir documentação “online” ou eletrônica. Ao utilizar o SOFTWARE, mesmo que parcial ou a título de teste, o licenciado estará vinculado aos termos deste "CLUF", concordando com os mesmos, principalmente CONSENTINDO COM O ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO COM TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do LICENCIADO para a integral execução das funcionalidades ofertadas pelo SOFTWARE. Em caso de discordância dos termos aqui apresentados, a utilização do SOFTWARE deve ser imediatamente interrompida.</p>

            <p> Informações adicionais sobre este CLUF podem ser estabelecidas através de PROPOSTAS que serão partes integrantes deste CLUF.Havendo divergências entre as disposições do presente CLUF e os termos das PROPOSTAS, prevalecerão as disposições do CLUF.</p>

            <p>AO MANIFESTAR SEU “ACEITE” CONCORDANDO COM OS TERMOS ESTABELECIDOS NESTE CLUF o CONTRATANTE TERÁ ACESSO AOS SERVIÇOS DISPONIBILIZADOS ATRAVÉS DESTE INSTRUMENTO, ACATARÁ, SE SUJEITANDO ÀS OBRIGAÇÕES NESTE CLUF PREVIAMENTE ACORDADAS. AO DAR SEU “ACEITE” o CONTRATANTE DECLARA EXPRESSAMENTE QUE LEU, ENTENDEU E COMPREENDEU TODOS OS DIREITOS E OBRIGAÇÕES DETERMINADOS POR ESTE CLUF. CASO NÃO CONCORDE COM QUALQUER DOS TERMOS DESTE CONTRATO, o CONTRATANTE NÃO DEVERÁ AVANÇAR PARA CRIAR UMA CONTA. SE FIZER POR ENGANO, DEVERÁ NOTIFICAR O Pralevar SOBRE A NÃO CONCORDÂNCIA IMEDIATAMENTE, O QUE IMPEDIRÁ A FORMAÇÃO DESTE INSTRUMENTO, E, CONSEQUENTEMENTE A INTERRUPÇÃO DO ACESSO À FERRAMENTA E AOS DADOS NELA GERADOS EM SEU FAVOR DE QUALQUER SERVIÇO DISPONIBILIZADO PELO Pralevar A QUALQUER TEMPO.</p>

            <p><h5><u>1. Objeto.</u></h5></p>
            <p>1.2. Sob os termos e condições deste instrumento, a CONTRATADA prestará ao CONTRATANTE os serviços consistentes na implementação e disponibilização para uso da plataforma denominada Pralevar que possui funcionalidades voltadas para criação de loja virtual para recebimento de pedidos.</p>
            <p>1.2.1​ O Pralevar possui 2 modalidades de planos: Gratuito e Premium 1.2.3.4 Ao escolher o Plano Gratuito o CONTRATANTE aceita as limitações de ultilizar somente subdominio, ter opção de um único template, cadastrar até duas categorias, vinte produtos, dois grupos de variações por produto e a não ter acesso aos recursos Premium como: cupons de desconto, cashback, integração do ifood para cadastro dos produtos, incluir um domínio próprio, campanhas estratégicas via whatsapp, pagamento online, pix automático, bot de whatsapp, integrações premium e Serviços que poderão ter suas condições alteradas a qualquer momento. </p>
            <p>1.3. Para efetuar a contratação dos serviços, o CONTRATANTE deverá preencher um cadastro eletrônico, no qual o CONTRATANTE, além dos dados cadastrais, irá escolher o Plano a ser contratado.</p>
            <p>1.3.1. Todas as informações constantes do cadastro eletrônico fazem parte do presente Termo para todos os fins.</p>
            <p>1.4. Natureza dos Serviços: A conta Pralevar é um sistema voltado para atividades empresariais e tem como objetivo auxiliar o CONTRATANTE a divulgar seus produtos para seus clientes, não sendo garantido ou prometido a esta o alcance de nenhum resultado econômico ou empresarial em seus respectivos negócios presentes ou futuros, a partir do simples uso do Sistema, sendo certo que a prestação dos Serviços configura obrigação de meio e não de resultado.</p>

            <p><h5><u>2. Como usar nossos Serviços </u></h5></p>
            <p>2.2.O CONTRATANTE pode usar nossos serviços somente conforme permitido por lei, inclusive leis e regulamentos.A CONTRATADA PODE suspender ou deixar de fornecer nossos Serviços se O CONTRATANTE descumprir nossos termos ou políticas ou em casos de suspeita de má conduta.</p>
            <p>2.3 O uso dos serviços da CONTRATADA não confere ao CONTRATANTE propriedade sobre direitos de propriedade intelectual sobre os nossos Serviços ou sobre o conteúdo que o CONTRATANTE acessar.O CONTRATANTE não pode usar conteúdos da CONTRATADA a menos que obtenha permissão do proprietário de tais conteúdos ou que o faça por algum meio permitido por lei.Estes termos não conferem a CONTRATANTE o direito de usar quaisquer marcas ou logotipos utilizados em nossos Serviços, sem autorização prévia.Não remova, oculte ou altere quaisquer avisos legais exibidos em ou junto a nossos Serviços com auxílio de programadores.</p>
            <p>2.4 ​Os Serviços da CONTRATADA podem exibir alguns conteúdos que não são de propriedade da CONTRATADA.Esses conteúdos são de exclusiva responsabilidade da entidade que os disponibiliza.Podemos revisar conteúdo para determinar se é ilegal ou se infringe nossas políticas, e podemos remover ou nos recusar a exibir conteúdos que razoavelmente acreditamos violar nossas políticas ou a lei.</p>
            <p>2.5 Em relação com seu uso dos Serviços, podemos enviar - lhe anúncios de serviços, mensagens administrativas e outras informações.o CONTRATANTE pode desativar uma parte dessas comunicações.</p>

            <p><h5><u>3. Sua Conta Pralevar</u></h5></p>

            <p>3.1 ​ Processo de Cadastro: ao se cadastrar, o CONTRATANTE deverá informar os seguintes dados: CPF/CNPJ, nome completo/razão social, endereço completo, cidade/UF, país, e-mail, nome completo do responsável pela contratação, respectivo CPF e e-mail. </p>
            <p>3.1.1 Ao finalizar a contratação, serão emitidas as cobranças dos serviços escolhidos e o CONTRATANTE deverá escolher o meio de pagamento. Caso escolha a modalidade “cartão de crédito”, serão solicitados os dados de seu cartão para finalizar o pagamento. </p>
            <p>3.2.O CONTRATANTE é responsável por manter sempre atualizado seu cadastro empresarial junto à CONTRATADA pelo seu Painel Financeiro dentro do Sistema, bem como pelos demais canais de comunicação da CONTRATADA, comunicando, imediatamente, sempre que houver quaisquer alterações em seus dados, incluindo, mas não se limitando a endereço, telefone e e - mail para contato. </p>
            <p>3.3.Os dados fornecidos pelo CONTRATANTE serão submetidos à previsão da cláusula 12 – Proteção de Dados.3.4.O CONTRATANTE é responsável por todos os atos praticados mediante o uso das credenciais de acesso de todos os usuários vinculados à sua conta. 3.4.1 Para proteger sua conta Pralevar, o CONTRATANTE deve manter a senha em sigilo.A atividade realizada na Conta da Pralevar ou por seu intermédio é de responsabilidade do CONTRATANTE. </p>
            <p>3.4.2 Caso tome conhecimento de uso não autorizado da sua senha ou Conta da Pralevar, o CONTRATANTE deve entrar em contato com o suporte do Pralevar enviando um e-mail para suporte@pralevar.app, informando o problema.</p>

            <p><h5><u>4. Alterações de Plano</u></h5></p>
            <p>4.1. ​Plano Gratuito  para o Premium: Para alteração de Plano, o CONTRATANTE poderá efetuar alteração a qualquer momento, através de nosso Painel Financeiro, escolhendo a opção “Trocar Plano”. A confirmação da alteração se dará a partir da identificação do pagamento da cobrança, sendo que o CONTRATANTE será cobrado o valor integral do novo plano escolhido.</p>
            <p>4.1.1. Ao alterar o plano de Gratuito para o Premium, o CONTRATANTE terá acesso aos recursos disponíveis no plano escolhido. </p>
            <p>4.1.2 As operações de Alteração de Plano e Cancelamento somente serão efetuadas através do CONTRATANTE, mediante o uso de suas credenciais de acesso e envio de e-mail para suporte@pralevar.app formalizando a solicitação.</p>

            <p><h5><u>5.Proteção à Privacidade e aos Direitos Autorais</u></h5></p>
            <p>5.1.Alguns de nossos Serviços permitem que o CONTRATANTE envie conteúdo.Quando o CONTRATANTE faz upload ou de algum modo envia conteúdo a nossos Serviços, o CONTRATANTE concede à CONTRATADA(e àqueles com quem trabalhamos) uma licença mundial para usar, hospedar, armazenar, reproduzir, modificar, criar obras derivadas, comunicar, publicar, executar e exibir publicamente e distribuir tal conteúdo.Os direitos que o CONTRATANTE concede nesta licença são para os fins restritos de operação, promoção e melhoria de nossos Serviços e de desenvolver novos Serviços.Essa licença perdura mesmo que o CONTRATANTE deixe de usar nossos Serviços.Certifique - se de que o CONTRATANTE tem os direitos necessários para nos conceder a licença de qualquer conteúdo que o CONTRATANTE enviar a nossos Serviços de hospedagem. </p>
            <p>5.2.Se o usuário tiver uma Conta do Pralevar, o nome da empresa, logotipo, bem como as ações realizadas em plataformas do Pralevar, poderão aparecer em nossos Serviços, inclusive para exibição em anúncios e em outros contextos comerciais.</p>

            <p><h5><u>6. Seu Conteúdo em nossos Serviços</u></h5></p>
            <p>6.1 O Pralevar concede a o CONTRATANTE uma licença pessoal, mundial, não exclusiva, intransferível e isenta de royalties para o uso do software fornecido pelo Pralevar como parte dos Serviços. Essa licença tem como único objetivo permitir que o CONTRATANTE use e aproveite o benefício dos Serviços da forma permitida por estes termos. o CONTRATANTE não poderá copiar, modificar, distribuir, vender ou alugar qualquer parte de nossos Serviços ou o software incluso, nem poderá fazer engenharia reversa ou tentar extrair o código fonte desse software, exceto nos casos em que a legislação proibir tais restrições, ou quando o CONTRATANTE tiver nossa permissão por escrito.</p>

            <p><h5><u>7.Sobre Software em nossos Serviços</u></h5></p>
            <p>7.1 Estamos constantemente alterando e melhorando nossos Serviços. Podemos incluir ou remover funcionalidades ou recursos e podemos também suspender ou encerrar um item por completo. Acreditamos que o CONTRATANTE seja o proprietário dos seus dados e que é importante preservar seu acesso a esses dados. Se descontinuarmos um Serviço, quando razoavelmente possível, o CONTRATANTE será informado com antecedência razoável e terá a chance de retirar as suas informações daquele Serviço.</p>
            <p>7.2 O Pralevar pode usar o armazenamento de informações (conhecido como cookies) para permitir que o CONTRATANTE use o nosso site mais facilmente e para que possamos manter o controle de determinados dados estatísticos que nos ajudam a melhorar os nossos serviços. A maioria dos navegadores é inicialmente configurado para aceitar cookies. Se desejar, o CONTRATANTE pode instruir o seu navegador de internet para impedir que os cookies sejam usados e apague os cookies existentes. No entanto, note que a alta qualidade dos nossos serviços pode ser reduzida como consequência do mesmo. Consulte o guia de ajuda de seu navegador de internet (browser) para obter mais orientações.</p>

            <p><h5><u>8.Como renovar, modificar e cancelar nossos Serviços</u></h5></p>
            <p>8.1​ O Pralevar se reserva ao direito de rescindir a sua conta e impedir o seu acesso a qualquer tempo, caso seja constatada qualquer violação aos Termos de Uso.Caso ocorra a possibilidade acima descrita, nenhuma taxa ou despesa será reembolsável, a não ser em casos específicos em que houve uma garantia associada à contratação, a ser validada via e-mail por preposto ou sócio do Pralevar. </p>
            <p>8.2 ​Após o vencimento do contrato no período contratado, a renovação é feita de forma automática com o mesmo formato de pagamento utilizado na primeira contratação.Contrato este, que poderá sofrer reajustes nos valores na sua renovação. </p>
            <p>8.3 ​Para cancelamento ou modificação de sua assinatura, A CONTRATANTE deverá fazer sua solicitação por meio do e - mail suporte@pralevar.app. 8.3.1.Quando houver cancelamento do plano mensal, não haverá possibilidade de cancelamento fracionado, sendo a cobrança sempre da mensalidade integral do mês vigente.</p>

            <p><h5><u>9.Nossas Garantias e Isenções de Responsabilidade</u></h5></p>
            <p>9.1.Quando permitido por lei, o Pralevar e os fornecedores ou colaboradores do Pralevar não serão responsáveis por perda de lucros, perda de receita, perda de dados, perdas financeiras ou danos indiretos, especiais, consequenciais, exemplares ou punitivos.</p>
            <p>9.1.1 ​Na medida permitida por lei, a responsabilidade total do Pralevar e de seus fornecedores e colaboradores, para qualquer reclamação sob estes termos, incluindo quaisquer garantias implícitas, limita - se ao valor que o CONTRATANTE pagou ao Pralevar para usar os serviços. </p>
            <p>9.1.2 ​Em todos os casos, o Pralevar e seus fornecedores e colaboradores não serão responsáveis por qualquer perda ou dano que não seja razoavelmente previsível. </p>
            <p>9.2 ​O Pralevar adota todas as medidas adequadas de segurança técnica e organizacional para proteger os seus dados, em conformidade com os melhores padrões da indústria.Entretanto, o CONTRATANTE deve ter plena ciência de que nenhum sistema de segurança oferece total garantia, de forma que o Pralevar não se responsabiliza por vazamento de dados, quando comprovado que o mesmo tenha se dado de forma criminosa, mediante o emprego de recursos tecnológicos superiores aos padrões usualmente aceitáveis pela indústria e que são utilizados pelo Pralevar.</p>

            <p><h5><u>10. Responsabilidade pelos nossos Serviços</u></h5></p>
            <p>10.1 Se o CONTRATANTE estiver usando nossos Serviços em nome de uma empresa, tal empresa aceita estes termos.Ela isentará de responsabilidade e indenizará o Pralevar e suas afiliadas, executivos e colaboradores de qualquer reivindicação, processo ou ação judicial proveniente de ou relacionado ao uso dos Serviços ou à violação destes termos, incluindo qualquer responsabilidade ou despesa resultante de reivindicações, perdas, danos, processos, julgamentos, custos de litígio e honorários advocatícios.</p>
            <p>10.2 O conteúdo de quaisquer websites que o CONTRATANTE acessar a partir dos Nossos Websites e software está totalmente fora do Nosso controle sendo que o acesso e a permanência em tais sites se darão por Sua própria conta e risco.A inclusão destes links nos Websites e software não implica no Nosso endosso ou anuência a quaisquer produtos, serviços, conteúdo, informação ou materiais oferecidos por, ou acessível a o CONTRATANTE nos websites de terceiros.Nós não representamos ou garantimos quaisquer websites de terceiros que o CONTRATANTE venha a acessar através dos Websites e software.</p>
            <p>10.3 Nós não podemos aceitar qualquer responsabilidade ou assumir qualquer obrigação com respeito a qualquer material contido em qualquer outro website.Quaisquer opiniões ou recomendações expressas nos referidos outros websites são unicamente aquelas dos provedores independentes e não são, tampouco representam, as opiniões ou recomendações do Pralevar. </p>
            <p>10.4 O Pralevar trabalha e envia os seus maiores esforços para manter os Websites e os Serviços em funcionamento, contudo, todos os serviços online estão sujeitos a interrupções e paradas ocasionais.O Pralevar não se responsabiliza por nenhuma interrupção ou perda que a CONTRATANTE possa sofrer em decorrência de tais interrupções ou paradas.</p>

            <p><h5><u>11. Usos comerciais de nossos Serviços </u></h5></p>
            <p>11.1. Quaisquer melhorias ou adições aos Websites ou Serviços estarão sujeitos a estes Termos de Uso, a não ser que a CONTRATADA estabeleçamos expressamente de outra forma. Nós podemos introduzir novos conjuntos específicos de termos e condições para Serviços específicos, conforme apropriado, ou emendar termos e condições específicas existentes. </p>
            <p>11.2. ​O Pralevar reserva-se o direito de modificar, suspender, terminar ou descontinuar qualquer aspecto do Website e software a qualquer tempo, incluindo a disponibilidade de quaisquer Serviços, informações, características ou funcionalidades acessíveis por meio dos Websites ou software. Nós também podemos impor limitações a certas características, funcionalidades ou serviços ou restringir Seu acesso a partes ou à totalidade dos Websites e/ou dos Serviços sem prévia notificação e sem que isso implique em qualquer responsabilidade Nossa por qualquer prejuízo que venha a sofrer.</p>
            <p>11.3. ​O Pralevar reserva-se o direito de, a qualquer tempo ou título, controlar e/ou alterar a aparência, o desenvolvimento e/ou operações do Website e software a Nosso exclusivo critério, bem como estabelecer e modificar os procedimentos para o Seu contato Conosco, sem a necessidade de notificação prévia.</p>
            <p>11.4. ​o CONTRATANTE não poderá ceder, sublicenciar, subcontratar, transferir ou dispor de quaisquer direitos e obrigações Seus no âmbito destes Termos de Uso sem o nosso consentimento prévio.</p>

            <p><h5><u>12.Da Lei Geral de Proteção de Dados (LGPD)</u></h5></p>
            <p>12.1​ O CONTRATANTE autoriza a coleta de dados pessoais imprescindíveis a execução deste contrato, tendo sido informado quanto ao tratamento de dados que será realizado pela CONTRATADA, nos termos da Lei n° 13.709/2018, especificamente quanto a coleta dos seguintes dados:12.1.1​ Dados relacionados à sua identificação pessoal, a fim de que se garanta a fiel contratação pelo respectivo titular do contrato; 12.1.2​ Dados relacionados ao endereço do CONTRATANTE tendo em vista a necessidade da CONTRATADA identificar o local de instalação, manutenção dos serviços, envio de documentos/notificações e outras garantias necessárias ao fiel cumprimento do contrato ora assinado; </p>
            <p>12.1.3​ Os dados coletados poderão ser utilizados para identificação de terrorismo, compartilhamento para órgãos de segurança, conforme solicitação legal pertinente, compartilhamento com autoridade administrativa e judicial no âmbito de suas competências com base no extrito cumprimento do dever legal, bem como com os órgãos de proteção ao crédito a fim de garantir a adimplência do CONTRATANTE perante esta CONTRATADA. 12.2​ Os dados coletados com base no legítimo interesse do CONTRATANTE, bem como para garantir a fiel execução do contrato por parte da CONTRATADA, fundamentam-se no artigo 7o da LGPD, razão pela qual as finalidades descritas na cláusula 12.1​ não são exaustivas. </p>
            <p>12.2.1​ A CONTRATADA informa que todos os dados pessoais solicitados coletados são os estritamente necessários para os fins almejados neste contrato; </p>
            <p>12.2.2​ O CONTRATANTE autoriza o compartilhamento de seus dados, para os fins descritos nesta cláusula, com terceiros legalmente legítimos para defender os interesses da CONTRATADA bem como do CONTRATANTE.</p>
            <p>12.3​ O CONTRATANTE possui tempo determinado de 05 (cinco) anos para acesso aos próprios dados armazenados, podendo também solicitar a exclusão de dados que foram previamente coletados com seu consentimento;</p>
            <p>12.3.1​ A exclusão de dados será efetuada sem que haja prejuízo por parte da CONTRATADA, tendo em vista a necessidade de guarda de documentos por prazo determinado de 05 (cinco) anos, conforme lei civil. Para tanto, caso o CONTRATANTE deseje efetuar a revogação de algum dado, deverá preencher uma declaração neste sentido, ciente que a revogação de determinados dados poderá importar em eventuais prejuízos na prestação de serviços;</p>
            <p>12.3.2​ O CONTRATANTE autoriza, neste mesmo ato, a guarda dos documentos(contratos/documentos fiscais/notificações/prototocolos/ordens de serviços) - em que pese eles possuam dados pessoais - por parte da CONTRATADA a fim de que ela cumpra com o determinado nas demais normas que regulam o presente contrato, bem como para o cumprimento da obrigação legal nos termos do artigo 16, inciso I, da Lei Geral de Proteção de Dados.</p>
            <p>12.4​ Em eventual vazamento indevido de dados a CONTRATADA se compromete a comunicar seus CONTRATANTES sobre o ocorrido, bem como sobre qual o dado vertido; </p>
            <p>12.5​ A CONTRATADA informa que a gerência de dados ocorrerá através de um sistema que colherá e tratará os dados na forma da lei; </p>
            <p>12.5.1​A CONTRATADA informa que efetuará a manutenção do registro das operações de tratamento de dados pessoais da forma mencionada na cláusula anterior. </p>
            <p>12.6 ​Rescindido o contrato os dados pessoais coletados serão armazenados pelo tempo deterninado na cláusula 12.7​ Passado o termo de guarda pertinente a CONTRATADA se compromete a efetuar o descarte dos dados adequadamente.</p>

            <p><h5><u>13. Disposições gerais</u></h5></p>

            <p>Estes termos regem a relação entre o Pralevar e CONTRATANTE. Eles não criam quaisquer direitos para terceiros. o Caso o CONTRATANTE não cumpra estes termos e nós não tomemos providências imediatas, isso não significa que estamos renunciando a quaisquer direitos que possamos ter (como tomar providências futuras). Caso uma condição específica destes termos não seja executável, isso não prejudicará quaisquer outros termos. O presente contrato é firmado em caráter irrevogável e irretratável, ressalvado o direito à rescisão na forma e hipóteses previstas neste instrumento, respondendo as partes por si e seus sucessores, na forma da lei. O presente contrato será regido pelas leis brasileiras, ficando eleito o Foro da Comarca de Balneário Camboriú, Estado de Santa Catarina, com expressa renúncia de qualquer outro, por mais privilegiado que seja.</p>

            <button type="button" className="btn btn-sm btn-secondary float-right" style={{ minWidth: 260 }} onClick={() => props.setShowModal(false)}>Fechar</button>

        </div>
    )
}



