import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setCookie } from "nookies";
import jwt_decode from "jwt-decode";

import Logo from "../images/logo.webp";
import { AppSettings } from "../App";
import { Auth } from "../services/LoginServices";
import UserContext from "../contexts/UserContext";
import AlertResponses from "../jsx/components/Alert";
import Swal from "sweetalert2";

const Login = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    setError: setErrorToken,
    clearErrors: clearTokenErrors,
    formState: { errors: errorsToken },
  } = useForm();

  const [numbers, setNumbers] = useState(["", "", "", "", "", ""]);
  const { setUser, setLogged } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const btnContinue = useRef();
  const btnAccess = useRef();
  const userEmail = useRef();
  const storeName = useRef("Pralevar");

  const onPaste = (event) => {
    const pasted = event.clipboardData.getData("text/plain");
    let arr = pasted.split("").slice(0, numbers.length);
    arr.map(
      (value, index) =>
        (document.querySelector(`input[name='number-${index}']`).value = value)
    );
  };

  const clearErrorMessage = () => {
    setTimeout(() => {
      clearTokenErrors("token");
    }, 4000);
  };

  const onSubmit = (obj) => {
    let data = {
      email: obj.email,
      store_name: storeName.current,
      admin: 1,
    };

    userEmail.current = obj.email;

    btnContinue.current.innerText = "Enviando token de acesso...";
    btnContinue.current.disabled = true;

    Auth(data, "login", null, null, null)
      .then((res) => {
        if (res.status === 400 || res.status === 401) {
          Swal.fire({
            title: "Oops!",
            html: "Parece que você ainda não tem uma conta, ou digitou seu email errado. Por favor, <b>caso tenha conta verifique seu e-mail.</b>",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "var(--primary)",
            cancelButtonColor: "var(--subtitles)",
            confirmButtonText: "Entendi!",
            cancelButtonText: "Cancelar",
          });
          btnContinue.current.innerText = "Continuar";
          btnContinue.current.removeAttribute("disabled");
          return;
        }

        if (res.status === 500) {
          if (props.wizardInstance) {
            props.setData({ email: obj.email });
            props.wizardInstance.nextStep();
          }
        } else {
          setStep(2);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitToken = (obj) => {
    let arr = [];
    document.querySelectorAll(".token-input").forEach((input) => {
      if (input.value) arr.push(input.value);
    });

    if (arr.length < 6) {
      setErrorToken("token", {
        type: "invalid",
        message: "Por favor, insira o token corretamente!",
      });
      clearErrorMessage();
      return;
    }
    let access = arr.join("");

    let data = {
      email: userEmail.current,
      token: access,
      store_name: AppSettings.store
        ? AppSettings.store.name
        : storeName.current,
      tokenForAdmin: true,
    };

    Auth(data, "login_verify", null, null, null)
      .then((res) => {
        btnAccess.current.innerText = "Acessando...";
        btnAccess.current.disabled = true;
        if (res.status === 400) {
          setErrorToken("token", {
            type: "invalid",
            message:
              "Token inválido! Tente reenviar o token para seu e-mail novamente.",
          });
          btnAccess.current.innerText = "Acessar";
          btnAccess.current.removeAttribute("disabled");
          clearErrorMessage();
        } else {
          let token = jwt_decode(res.access);
          if (!token.is_superuser) {
            btnAccess.current.innerText = "Acessar";
            btnAccess.current.removeAttribute("disabled");
            setNotify({
              title: "Acesso Negado!",
              message: "Você não tem permissão para acessar esta área.",
              type: "error",
              visible: true,
            });
            return;
          } else {
            setCookie(undefined, "token", res.access, {
              maxAge: 60 * 60 * 24 * 7, // 7 dias
            });
            sessionStorage.setItem("plan_id", token.plan_id);
            delete token.access;
            delete token.jti;
            delete token.exp;
            delete token.token_type;

            localStorage.setItem("user", JSON.stringify(token));
            localStorage.setItem("lastEmailLogged", token.user_email);
            setUser(token);
            setLogged(true);
            window.location.href = "/";
          }
        }
      })
      .catch((error) => {
        btnAccess.current.innerText = "Acessar";
        btnAccess.current.removeAttribute("disabled");
        console.log(error);
        Swal.fire(
          "Oops!",
          "Ocorreu um erro inesperado, por favor, tente novamente. Se o problema persistir, contacte nosso setor de suporte."
        );
      });
  };

  useEffect(() => {
    let email = localStorage.getItem("lastEmailLogged");
    if (email) {
      setValue("email", email);
    }
  }, []);

  return (
    <div className="authentication h-100 p-meddle">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 c-login-background"></div>
          <div className="col-md-6 c-login">
            <div className="row no-gutters h-100">
              <div className="col-xl-12 d-flex h-100">
                <div className="auth-form text-center w-100 justify-content-center">
                  <div className="text-center pt-4">
                    <img
                      className="logo-img"
                      src={Logo}
                      alt="sistema-para-delivery-pralevar"
                      width="300"
                    />
                  </div>
                  <h2 className="text-center mt-3 mb-4 text-secondary h2 mb-5">
                    Portal do Lojista
                  </h2>
                  {step === 1 && (
                    <div className="email-access">
                      {/* <div className="h4 card-title text-center">Acesse com seu e-mail para continuar</div> */}
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Digite seu e-mail"
                            className="form-control"
                            {...register("email", {
                              required: true,
                              minLength: 3,
                              pattern:
                                /^[a-zA-Z0-9\._-]+@[a-zA-Z0-9]+.[a-zA-Z]/,
                            })}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email.type === "required"
                                ? "Campo obrigatório!"
                                : "Digite corretamente seu e-mail!"}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-50 mt-0"
                            ref={(e) => (btnContinue.current = e)}
                          >
                            Continuar
                          </button>
                        </div>
                      </form>

                      <hr className="mt-5"></hr>
                      {/*  <h6
                        className='text-center mt5'
                        style={{ lineHeight: 1.5 }}
                      >
                        Ainda não tem cadastro? <br></br>
                        <Link to='/cadastro' className='text-primary'>
                          Clique aqui e cadastre sua loja
                        </Link>
                      </h6> */}
                    </div>
                  )}
                  {step === 2 && (
                    <div className="token-access">
                      <div className="h4 card-title text-center">
                        Token de acesso:
                      </div>
                      <form onSubmit={handleSubmit(onSubmitToken)}>
                        <div className="form-group d-flex my-4 mx-auto">
                          {numbers.map((value, key) => (
                            <input
                              type="text"
                              key={key}
                              defaultValue=""
                              onPaste={onPaste}
                              onKeyUp={(e) => {
                                if (
                                  e.key === "Backspace" ||
                                  e.key === "Delete"
                                ) {
                                  if (e.target.value) {
                                    e.target.value = null;
                                  }
                                }
                                if (e.target.nextSibling)
                                  e.target.nextSibling.focus();
                              }}
                              className="form-control token-input mx-1"
                              maxLength={1}
                              {...register(`number-${key}`)}
                              data-value={key}
                            />
                          ))}
                        </div>
                        {errorsToken.token && (
                          <p className="text-danger">
                            {errorsToken.token.type == "invalid" &&
                              errorsToken.token.message}
                          </p>
                        )}
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-50 mt-0"
                            ref={(e) => (btnAccess.current = e)}
                          >
                            Acessar
                          </button>
                          <Link
                            to="#"
                            className="d-block mt-3"
                            onClick={() => {
                              setStep(1);
                            }}
                          >
                            Voltar
                          </Link>
                        </div>
                      </form>

                      <p
                        className="text-center mt-4 fs-16"
                        style={{ lineHeight: 1.5 }}
                      >
                        Enviamos seu token de acesso por e-mail e whatsapp.{" "}
                        <br></br>Para continuar, insira o token enviado no campo
                        acima.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="page-login footer">
              <div className="copyright">
                <p>
                  Copyright © Desenvolvidor por{" "}
                  <a href="https://pralevar.app" target="_blank">
                    Pralevar.app
                  </a>{" "}
                  {new Date().getFullYear()}
                </p>
              </div>
            </div>
            {notify.visible && (
              <>
                <AlertResponses
                  title={notify.title}
                  message={notify.message}
                  type={notify.type}
                  visible={notify.visible}
                  setNotify={setNotify}
                  pageLogin={true}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
