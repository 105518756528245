import React, { useRef } from "react";

import { Button } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { Picker } from "emoji-picker-element";
import { UpdateTemplate } from "../services/WaBottyServices";
import { decodeText } from "../helpers/Helpers";

export default function WhatsappTemplatesUpdate(props) {
  const id = props.data?.id;
  const picker = useRef(new Picker());

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const emojiPicker = (e) => {
    if (e.detail) {
      document.querySelector("#message").value += e.detail.unicode;
      removeEmojiPicker();
    }
    //
  };

  const removeEmojiPicker = () => {
    let element = document.querySelector("emoji-picker");
    if (element) {
      element.remove();
    }
    picker.current.removeEventListener("emoji-click", emojiPicker);
  };

  const handleEmojiPicker = () => {
    picker.current.addEventListener("emoji-click", emojiPicker);

    let element = document.querySelector("emoji-picker");
    if (element) {
      removeEmojiPicker();
    } else {
      document.querySelector(".picker-wrapper").append(picker.current);
    }
  };

  const onSubmit = async (data) => {
    let btn = document.querySelector("button[type=submit]");
    btn.setAttribute("disabled", "true");

    let payload = {
      uid: props.data.uid,
      aid: props.data.id,
      keywords: data.keywords,
      match: data.match,
      message: data.message,
      message_type: data.message_type,
      name: data.name,
      pause_bot: data.pause_bot,
      action: props.data.action,
    };

    payload.id = id;
    let response = await UpdateTemplate(payload, props.setNotify);
   
    props.setNotify({
      title: "Sucesso!",
      message: "Notificação atualizada com sucesso!",
      type: "success",
      visible: true,
    });
    setTimeout(() => {
      window.location.href = "/whatsapp";
    }, 1000);

    btn.removeAttribute("disabled");
  };

  return (
    <div>
      <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Nome da ação</label>
            <input
              {...register("name", {
                required: true,
              })}
              className="form-control text-black"
              defaultValue={props.data.name}
              placeholder=""
            />
            {errors.name && (
              <small className="ml-2 text-danger">
                {errors.name.type === "required" && "Por favor, insira o nome!"}
              </small>
            )}
          </div>

          <div className="form-group col-md-12 picker-wrapper">
            <label className="d-flex justify-content-between">
              Mensagem{" "}
              <span
                className="emoji-picker-handle cursor-pointer"
                onClick={handleEmojiPicker}
              >
                😄
              </span>
            </label>
            <textarea
              id="message"
              className="form-control"
              rows={8}
              {...register("message", {
                required: true,
              })}
              defaultValue={decodeText(props.data.format)}
              placeholder="Obaa! Seu pedido saiu para entrega..."
            ></textarea>

            {errors.message && (
              <small className="ml-2 text-danger">
                {errors.message.type === "required" &&
                  "Por favor, insira uma resposta!"}
              </small>
            )}
          </div>
          <small className="pl-2">
            <span className="text-primary">Shortcodes</span>: <br></br>
            <b>{"{{pralevar_nome_cliente}}"}</b>: envia o nome do cliente na
            mensagem. <br></br>
    
            <b>{"\\n"}</b>: para quebra de linha.<br></br>
            <b>{"*"}</b>: asteriscos no início e no fim do texto para destacar em
            negrito.<br></br>
          </small>
        </div>
        <div className="col-lg-4 mx-auto mt-4">
          <Button type="submit" className="btn btn-primary w-100">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
