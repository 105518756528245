import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import DefaultLoader from "../jsx/components/Loader";
import Swal from "sweetalert2";
import AlertResponses from "../jsx/components/Alert";
import UserContext from "../contexts/UserContext";

import ModalComponent from "../jsx/components/Modal";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import CashbackProgram from "../jsx/components/ModalData/CashbackProgram";
import {
  DisableCashbackProgram,
  GetCashbackProgram,
} from "../services/CashbackServices";
import Tutorial from "../jsx/components/Tutorial";

export default function Cashback() {
  const { user } = useContext(UserContext);
  const [program, setProgram] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const rules = {
    all_orders: "Cashback em todos os pedidos",
    progressive: "Cashback progressivo",
    specific_payment: "Cashback para pagamento específico",
  };

  const status = {
    1: <span className="badge badge-success">Ativo</span>,
    0: <span className="badge badge-dark">Desativado</span>,
  };

  const modalConnectProps = {
    title: "Programa de cashback",
    content: <CashbackProgram program={program} setShowModal={setShowModal} />,
    size: "lg",
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const handleDisableProgram = async () => {
    let disableMessage =
      "Os valores que os clientes já possuem de cashback serão mantidos até a data de validade definida. Porém, não serão aplicados cashback em suas próximas compras.";
    let activeMessage = "Deseja ativar o programa de cashback novamente?";
    Swal.fire({
      title: "Você tem certeza?",
      text: program.status === 0 ? activeMessage : disableMessage,
      icon: program.status === 0 ? "question" : "warning",
      buttons: true,
      confirmButtonText:
        program.status === 0 ? "Quero ativar!" : "Quero desativar!",
      cancelButtonText: `Cancelar`,
      showCancelButton: true,
      confirmButtonColor: "red",
    }).then((value) => {
      if (value.isConfirmed) {
        let payload = {
          status: program.status === 0 ? 1 : 0,
        };
        let res = DisableCashbackProgram(program.id, payload);
        if (res) {
          setNotify({
            title: "Sucesso!",
            message:
              program.status === 0
                ? "Programa ativado com sucesso!"
                : "Programa desativado com sucesso!",
            type: "success",
            visible: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    });
  };

  useEffect(() => {
    if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    }

    GetCashbackProgram(setProgram, setIsLoading);
  }, []);

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <section id="whatsapp">
          <Tutorial videoId={"Ffb0Gn4KjDw"} padding={"0 15px"}/>
          <div className="col-xxl-12 pl-0 mb-2 ml-4 d-flex justify-content-between">
            <h2>Cashback</h2>
            {!program && (
              <Button
                className="i-false btn-primary mr-3 mb-4"
                onClick={() => setShowModal(true)}
              >
                Criar programa de cashback
              </Button>
            )}
          </div>
          {program ? (
            <Col lg={12}>
              <Card>
                <Card.Header>
                  <Card.Title>Meu programa de cashback</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="c-titles">Nome</th>
                        <th className="c-titles">Regra</th>

                        <th className="c-titles">Criado em</th>
                        {/* <th className="c-titles">Valido até</th> */}
                        <th className="c-titles">Status</th>
                        <th className="c-titles">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="c-subtitles">{program.program_name}</td>
                        <td className="c-subtitles">{rules[program.rule]}</td>
                        <td className="c-subtitles">
                          {new Date(program.created_at).toLocaleDateString()}
                        </td>
                        {/* <td className="c-subtitles">
                          {new Date(program.validity).toLocaleString()}
                        </td> */}
                        <td className="c-subtitles">
                          {status[program.status]}
                        </td>
                        <td className="c-subtitles">
                          <div className="d-flex">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Editar</Tooltip>
                              }
                            >
                              <Button
                                type="button"
                                className="btn btn-info shadow btn-xs sharp mr-1"
                                onClick={() => setShowModal(true)}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>
                                  {program.status === 0
                                    ? "Ativar programa"
                                    : "Desativar programa"}
                                </Tooltip>
                              }
                            >
                              <Button
                                type="button"
                                className={`btn ${
                                  program.status === 0
                                    ? "btn-success"
                                    : "btn-primary"
                                } shadow btn-xs sharp`}
                                onClick={handleDisableProgram}
                              >
                                {program.status === 0 ? (
                                  <i className="fa fa-play"></i>
                                ) : (
                                  <i className="fa fa-pause"></i>
                                )}
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            <h4>Nenhum programa de cashback disponível...</h4>
          )}
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
          {showModal && <ModalComponent props={modalConnectProps} />}
          {progress && <ProgressBarComponent />}
        </section>
      )}
    </>
  );
}
