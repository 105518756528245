import { api } from '../api/Middleware'
import { PAYMENT_METHODS_URL, PAYMENT_GATEWAY_ACCOUNTS_URL, APP_BASE_URL, PAYMENTS_URL, CUSTOMER_PAYMENTS_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'
import Swal from 'sweetalert2'

export const GetOne = async (id, setPaymentMethods, setIsLoading) => {
    try {
        let response = await api.get(PAYMENT_METHODS_URL + id + '/');
        if (response.status === 200) {
            setPaymentMethods([response.data]);
        }
    } catch (error) {
        //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
        /*  if(error.status !== 401){
             
             
         }  */
    }
    finally {
        setIsLoading(false);
    }


}

export const GetAll = async (setPaymentMethods, setErrors, setIsLoading, returnData = false) => {

    try {
        let response = await api.get(`${PAYMENT_METHODS_URL}`);
        if (response.status === 200) {
            if (returnData) {
                return response.data;
            }
            setPaymentMethods(response.data);
        }
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
    }

    setIsLoading(false)
}

export const Create = async (data, setProduct, setNotify, setError, setProgress) => {
    setProgress(true);
    try {
        let response = await api.post(PAYMENT_METHODS_URL, data);
        if (response.status === 200 || response.status === 201) {
            setProduct(response.data);
            setNotify({ title: 'Sucesso!', message: 'Forma de pagamento cadastrada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.assign('/payment-methods')
            }, 2000)
        }

    } catch (error) {
        if (error.response.status === 400) {
            setError("name", {
                "message": error.response.data,
                "type": "custom"
            })
        } else if (error.response.status === 401) {
            window.location.href = APP_BASE_URL
        } else {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.categories.update, type: 'error', visible: true });
        }
        //if(error.status !== 401) throw error.response;

    }
    setProgress(false);
}

export const Update = async (id, data, setProduct, setNotify, setProgress) => {
    setProgress(true);
    try {
        let response = await api.put(PAYMENT_METHODS_URL + id + '/', data);
        if (response.status === 200) {
            setProduct(response.data);
            setNotify({ title: 'Sucesso!', message: 'Forma de pagamento atualizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.history.back()
            }, 2000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }
    setProgress(false);
}

export const ChangeStatus = async (data, setNotify) => {
    try {
        let response = await api.put(PAYMENT_METHODS_URL + data.id + '/', { "status": parseInt(data.status) });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: "Status atualizado com sucesso!", type: 'success', visible: true })
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        return false;
    }
}

export const Delete = async (id, setNotify, setProgress) => {
    setProgress(true);

    try {
        let ids = [id];

        let response = await api.delete(PAYMENT_METHODS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            await api.get(`${PAYMENT_METHODS_URL}clear_cache/`)
            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }

    setProgress(false);
}

export const EnableOnlinePayments = async (data, setNotify, setError, setProgress) => {
    setProgress(true);
    try {
        let response = await api.post(PAYMENT_GATEWAY_ACCOUNTS_URL, data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Pagamentos online habilitado com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.href = '/payment-methods'
            }, 3000)
        }

    } catch (error) {

        if (error.response.status >= 400 && error.response.status < 500) {
            // setError("name", {
            //     "message": error.response.data,
            //     "type": "custom"
            // })
            //setNotify({ title: 'Oops!', message: error.response.data.errors[0].description, type: 'error', visible: true });
            Swal.fire('Ooops!', error.response.data, 'warning')
        }

    }
    setProgress(false);
}

export const NewPayment = async (data, setNotify) => {
    try {
        let response = await api.post(CUSTOMER_PAYMENTS_URL, data);
        if (response.status === 200 || response.status === 201) {
            return response.data
        }

    } catch (error) {
        throw error.response.data || "Não foi possível processar o pagamento. Por favor, contate nosso suporte."
    }
}

export const ActivatePixPayment = async (data) => {
    try {
        let response = await api.post(PAYMENT_METHODS_URL + 'activate_pix_payment/', data);
        if (response.status === 200 || response.status === 201) {
            return response.data
        }

    } catch (error) {
        throw error.response.data || "Não foi possível ativa o pagamento online via pix. Por favor, contate nosso suporte."
    }
}

export const GetQrCode = async (data) => {

    try {
        let response = await api.post(PAYMENTS_URL + 'generate_pix_qrcode/', data);
        if (response.status === 200) return response.data;
    } catch (error) {
        console.log(error);
        throw error.response.data || 'Desculpe, mas ocorreu um problema com sua solicitação. Por favor, tente novamente em alguns minutos, se o problema persistir, contate nossa loja.'
    }
}
