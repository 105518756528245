import React, { useEffect, useState, useRef, useContext } from "react";
import { Create, Update } from "../services/CouponsServices";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Button } from "react-bootstrap";
import DefaultLoader from "../jsx/components/Loader";
import MetarialDateAndTime from "../jsx/components/Forms/Pickers/MetarialDateAndTime";
import { DateTimeToISOFormat } from "../helpers/Helpers";
import UserContext from "../contexts/UserContext";

export default function CouponDetails(props) {
  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const [coupon, setCoupon] = useState(props.data ? props.data : null);
  //const [categories, setCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [discountBy, setDiscountBy] = useState("value");
  const [priceAbove, setPriceAbove] = useState(false);
  const [firstPurchase, setFirstPurchase] = useState(false);

  //const options = useRef([]);

  const MAX_DISCOUNT = 100;

  useEffect(() => {
    if (user?.plan_id === 1) {
      window.location.assign("/");
      return;
    }

    if (props.data) {
      setCoupon(props.data);
    }

    if (props.data.rules) {
      setDiscountBy(props.data.rules.discount_by);
      setCoupon(props.data);
      setValue("discountBy", props.data.rules.discount_by);
      if (props.data.rules.price_above) setPriceAbove(true);
      if (props.data.rules.first_purchase){
        setValue("firstPurchase", props.data.rules.first_purchase);
        setFirstPurchase(true);
      }
    }
  }, []);

  const maxDiscount = ({ value }) => value <= MAX_DISCOUNT;

  const onSubmit = (data) => {
    // let percent = document.querySelector('input[name="discount"]').value;
    let expiration = document.querySelector('input[name="expiration"]').value;

    // if (percent === '' || percent === null) {
    //   setError("discount", {
    //     "message": 'Por favor, preencha este campo!',
    //     "type": "required"
    //   })
    //   return;
    // }

    if (expiration === "" || expiration === null) {
      setError("expiration", {
        message: "Por favor, preencha corretamente este campo!",
        type: "required",
      });
      return;
    }

    let priceAboveChecked = document.querySelector("#priceAbove").checked;

    if (data?.priceAbove && typeof data?.priceAbove === "string")
      data.priceAbove = parseFloat(
        data.priceAbove.replace("R$", "").replace(",", ".")
      );
    if (data?.discountByValue && typeof data?.discountByValue === "string")
      data.discountByValue = parseFloat(
        data.discountByValue.replace("R$", "").replace(",", ".")
      );

    let form = new FormData();
    let rules = JSON.stringify({
      discount_by: data.discountBy,
      discount_by_value: data?.discountByValue,
      discount_by_percentage:
        data.discountBy !== "value" && data.discountByPercentage
          ? parseFloat(data.discountByPercentage)
          : "",
      price_above:
        data?.priceAbove && priceAboveChecked ? data?.priceAbove : "",
      cash_payment: data.cashPayment ? 1 : 0,
      first_purchase: firstPurchase ? 1 : 0,
    });

    if (firstPurchase) data.limit = -1;

    if (data.name)
      form.append("name", data.name.toUpperCase().replace(" ", ""));

    form.append("expiration", DateTimeToISOFormat(expiration));
    form.append("status", 1);
    form.append("limit", parseInt(data.limit));
    form.append("available", parseInt(data.limit));
    form.append("rules", rules);

    //data.discount = percent.replace('%', '')
    //data.category = category_selected.current

    if (props.data.id !== undefined) {
      Update(
        props.data.id,
        form,
        setCoupon,
        props.setNotify,
        setIsLoading,
        props.setShowModal
      );
    } else {
      form.append("store_id", user.store_id);
      Create(form, props.setNotify, setError, setIsLoading, props.setShowModal);
    }
  };

  return (
    <div className="basic-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Nome</label>
            <input
              {...register("name", {
                required: coupon ? false : true,
                maxLength: 20,
              })}
              className="form-control text-black"
              defaultValue={coupon ? coupon.name : ""}
              disabled={coupon ? true : false}
            />
            {errors.name && (
              <small className="ml-2 text-danger">
                {errors.name.type === "required"
                  ? "Por favor, preencha este campo!"
                  : "Este campo deve conter no máximo 20 caracteres!"}
              </small>
            )}
          </div>

          {/* <div className="form-group col-md-12">
              <label>Categoria</label>
              {
                coupon.id ?
                  <input type="text" className="form-control" value={coupon.category__name} disabled={true} style={{ backgroundColor: "#eee" }} />
                  :
                  <Select
                    name="category"
                    options={options.current}
                    defaultValue={coupon && coupon.category !== undefined ? { value: coupon.category, label: coupon.category_name } : ''}
                    onChange={(e) => {
                      category_selected.current = e.value
                    }}
                    className="rounded-form-control"
                    placeholder="Selecione uma opção..."
                  />
              }

            </div> */}
          <div className="form-group col-md-12">
            {!coupon && (
              <>
                <label>Desconto por:</label>
                <div className="d-flex mt-1 ml-2">
                  <div className="custom-control custom-checkbox mr-3">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id={"value"}
                      defaultValue="value"
                      checked={discountBy === "value" ? true : false}
                      {...register("discountBy", { required: true })}
                      onClick={() => setDiscountBy("value")}
                    />
                    <label className="custom-control-label" htmlFor={`value`}>
                      Valor
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id={"percentage"}
                      {...register("discountBy", { required: true })}
                      defaultValue="percentage"
                      checked={discountBy === "percentage" ? true : false}
                      onClick={() => setDiscountBy("percentage")}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`percentage`}
                    >
                      Porcentagem
                    </label>
                  </div>
                </div>
              </>
            )}

            {discountBy === "value" && (
              <>
                <Controller
                  name="discountByValue"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={coupon?.rules?.discount_by_value}
                  render={({ field }) => (
                    <NumberFormat
                      decimalSeparator=","
                      prefix="R$"
                      placeholder="Valor do desconto"
                      className="form-control text-black mt-3"
                      {...field}
                    />
                  )}
                />

                {errors.discountByValue && (
                  <small className="ml-2 mb-2 text-danger">
                    {errors.discountByValue.type === "required" &&
                      "Por favor, preencha este campo!"}
                  </small>
                )}
              </>
            )}
            {discountBy === "percentage" && (
              <>
                <Controller
                  name="discountByPercentage"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    coupon && coupon?.rules?.discount_by_percentage
                      ? coupon.rules.discount_by_percentage
                      : ""
                  }
                  render={({ field }) => (
                    <NumberFormat
                      decimalSeparator=","
                      suffix="%"
                      isAllowed={maxDiscount}
                      placeholder="Percentual de desconto"
                      className="form-control text-black mt-3"
                      {...field}
                    />
                  )}
                />

                {errors.discountByPercentage && (
                  <small className="ml-2 mb-2 text-danger">
                    {errors.discountByPercentage.type === "required" &&
                      "Por favor, preencha este campo!"}
                  </small>
                )}
              </>
            )}
          </div>

          <div className="form-group col-md-12">
            <label>Regras</label>
            <div
              className="d-flex flex-wrap mt-1 ml-2 mb-2"
              style={{ gap: 15 }}
            >
              <div className="custom-control custom-checkbox mr-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"firstPurchase"}
                  defaultChecked={
                    coupon && coupon?.rules?.first_purchase ? true : false
                  }
                  onClick={() => {
                    if (firstPurchase) {
                      setFirstPurchase(false);
                    } else {
                      setFirstPurchase(true);
                    }
                  }}
                  {...register("firstPurchase")}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`firstPurchase`}
                >
                  Primeira compra
                </label>
              </div>
              <div className="custom-control custom-checkbox mr-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"priceAbove"}
                  defaultValue="priceAbove"
                  defaultChecked={
                    coupon && coupon?.rules?.price_above ? true : false
                  }
                  onClick={() => {
                    if (priceAbove) {
                      setPriceAbove(false);
                    } else {
                      setPriceAbove(true);
                    }
                  }}
                />
                <label className="custom-control-label" htmlFor={`priceAbove`}>
                  Pedido mínimo
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"cashPayment"}
                  {...register("cashPayment")}
                  defaultValue="cashPayment"
                  defaultChecked={
                    coupon && coupon?.rules?.cash_payment ? true : false
                  }
                />
                <label className="custom-control-label" htmlFor={`cashPayment`}>
                  Pagamento em dinheiro ou Pix
                </label>
              </div>
            </div>
            {priceAbove && (
              <>
                <Controller
                  name="priceAbove"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    coupon && coupon?.rules?.price_above
                      ? coupon.rules.price_above
                      : ""
                  }
                  render={({ field }) => (
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      prefix="R$"
                      placeholder="Pedido mínimo para usar o cupom"
                      className="form-control text-black mt-3"
                      {...field}
                    />
                  )}
                />

                {errors.priceAbove && (
                  <small className="ml-2 mb-2 text-danger">
                    {errors.priceAbove.type === "required" &&
                      "Por favor, preencha este campo!"}
                  </small>
                )}
              </>
            )}
          </div>
          {!firstPurchase && (
            <div className="form-group col-md-12">
              <label>Total de cupons</label>
              <input
                {...register("limit")}
                className="form-control text-black"
                defaultValue={coupon ? coupon.limit : ""}
                type="number"
                placeholder="Nº total de cupons"
              />
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Válido até:</label>
            <MetarialDateAndTime
              props={{
                name: "expiration",
                date: coupon ? coupon.expiration : undefined,
              }}
            />
          </div>
          {errors.status && (
            <small className="ml-2 mb-2 text-danger">
              {errors.expiration.type === "required" &&
                "Por favor, preencha corretamente este campo!"}
            </small>
          )}
        </div>

        <Button type="submit" className="btn btn-block btn-primary">
          {isLoading ? <DefaultLoader color={"white"} /> : "Salvar"}
        </Button>
      </form>
    </div>
  );
}
