import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import Swal from "sweetalert2";
import { Get, SearchOrders } from "../../../services/OrdersServices";

const DataTablev2 = ({
  data,
  columns,
  title,
  selectable,
  onSelectedRowsChange,
  filterOn,
  filterByField,
  filterDefaultField,
  totalRows,
  isLoading,
  setIsLoading,
  handlePageChange,
  setOrders,
  setTotalRows,
  setNotify
}) => {
  const [selectableRow, setSelectableRow] = useState(selectable);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState(
    filterDefaultField !== undefined ? filterDefaultField : "order_number"
  );
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const FilterComponent = ({ filterText, onFilter, onClear, setFilter }) => (
    <>
      <div className="col-auto search-order-wrapper" style={{ zIndex: 1 }}>
        <div className="input-group mb-2">
          <input
            id="input-order-search"
            type={filterBy === 'order_number' ? 'number' : 'string'}
            className="form-control"
            placeholder={`Filtrar por ${
              filterBy === filterByField.fields[0]
                ? filterByField.labels[0]
                : filterByField.labels[1]
            }`}
            // value={filterText}
            // onChange={onFilter}
            style={styles.inputSearch}
            // autoFocus
          />

          <div className="dropdown">
            <DropdownButton
              id="dropdown-button-drop-right"
              variant=""
              title=""
              style={styles.dropdownArrow}
              onSelect={setFilter}
            >
              <Dropdown.Item eventKey={filterByField.fields[0]}>
                Filtrar por {filterByField.labels[0]}
              </Dropdown.Item>
              {filterByField.fields.length > 1 && (
                <Dropdown.Item eventKey={filterByField.fields[1]}>
                  Filtrar por {filterByField.labels[1]}
                </Dropdown.Item>
              )}

              {/* <Dropdown.Item eventKey="categorie_name">Filtrar por Categoria</Dropdown.Item> */}
            </DropdownButton>
          </div>
          {/* <div className="input-group-append cursor-pointer" onClick={onClear}>
                        <div className="input-group-text">
                            <i className="ti-close"></i>
                        </div>
                    </div> */}
        </div>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Clique aqui para buscar o{" "}
              {filterBy === filterByField.fields[0]
                ? filterByField.labels[0]
                : filterByField.labels[1]}{" "}
              do cliente.
            </Tooltip>
          }
        >
          <Button className="btn btn-sm" onClick={handleClickSearch}>
            <i className="ti-search"></i>
          </Button>
        </OverlayTrigger>
      </div>
    </>
  );

  const handleClickSearch = async () => {
    let value = document.querySelector("#input-order-search").value
    if(!value){
        return Swal.fire("Oops!", "Insira o nº do pedido ou nome do cliente para realizar a busca!", "info")
    }
    let params = `&searchBy=${filterBy}&searchValue=${value}`
        
    let res = await Get(setOrders, false, false,setTotalRows, 1, params)
    
    if (!res) {
      return Swal.fire("Oops!", "Nenhum resultado encontrado!", "info");
    }
    setNotify({
        title: "Sucesso!",
        message: "Busca realizada com sucesso!",
        type: "success",
        visible: true,
      });
   
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilterBy(
          filterDefaultField !== undefined ? filterDefaultField : "name"
        );
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        setFilter={(e) => setFilterBy(e)}
      />
    );
  }, [filterText, filterBy]);

  createTheme("solarized", {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  });

  const options = {
    title: title,
    columns: columns,
    /*  data: filterText ? data.filter(item => item[filterBy] && item[filterBy].toLowerCase().includes(filterText.toLowerCase())) :
            data, */
    data: data,
    pagination: true,
    paginationServer: true,
    fixedHeader: true,
    subHeader: true,
    subHeaderComponent: subHeaderComponentMemo,
    paginationResetDefaultPage: resetPaginationToggle,
    onSelectedRowsChange: onSelectedRowsChange,
    selectableRowsVisibleOnly: false,
    progressPending: isLoading,
    paginationTotalRows: totalRows,
    onChangePage: handlePageChange,
  };

  if (selectableRow !== undefined) options["selectableRows"] = true;

  return (
    <div className="container-fluid h-100">
      <DataTable {...options} />
    </div>
  );
};

const styles = {
  inputSearch: {
    paddingLeft: "2.25rem",
    borderTopLeftRadius: "1.25rem",
    borderBottomLeftRadius: "1.25rem",
  },
  icon: {
    position: "absolute",
    left: "2.5%",
    top: "28%",
    bottom: 0,
    zIndex: 10,
    margin: "auto",
  },
  dropdownArrow: {
    position: "absolute",
    right: "0",
    maxWidth: "40px",
    zIndex: "1000",
  },
};

export default DataTablev2;
