import React, { useState, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import VariationItems from './VariationsItems'
import GroupOptions from './GroupOptions'
import { DeleteVariationGroup } from '../../../services/ProductsServices'
import { UpdateSequence } from '../../../services/VariationsServices'

export default function VariationGroups ({
  id,
  register,
  control,
  errors,
  clearErrors,
  setValue,
  setShowModal,
  setNotify,
  useFieldArray
}) {
  const [collapsed, setCollapsed] = useState('0')
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'group'
  })
 
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let sequence = result.destination.index + 1

    UpdateSequence(result.draggableId, { sequence: sequence }).then(res => {
      setTimeout(() => {
        setNotify({
          title: 'Sucesso!',
          message: 'Ordem alterada com sucesso!',
          type: 'success',
          visible: true
        })
      }, 500)
    })
    move(result.source.index, result.destination.index)
  }

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#f9f9f9' : '#fff'
  })

  return (
    <div className='col-lg-12 form-group'>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        {!id && (
          <button
            type='button'
            className='btn btn-sm btn-outline-primary align-self-end'
            onClick={() => setShowModal(true)}
          >
            Copiar de outro produto
          </button>
        )}
        <button
          type='button'
          className='btn btn-outline-primary'
          style={{ fontWeight: 400 }}
          onClick={() =>
            append({
              title: '',
              requiredChoice: 0,
              multipleChoice: 0,
              addVariationValueToProduct: 0,
              min: 0,
              max: 1,
              status: 1,
              items: [
                {
                  name: '',
                  description: '',
                  additional_price: '',
                  thumb: ''
                }
              ]
            })
          }
        >
          <i className='ti-plus'></i> Novo grupo de variações
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className='accordion__item'
            >
              {fields.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Accordion
                      ref={provided.innerRef}
                      className='accordion accordion-active-header mb-2'
                      defaultActiveKey={`0`}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Accordion.Toggle
                        as={Card.Text}
                        className={`accordion__header ${
                          collapsed != `${index}` ? 'collapsed' : ''
                        } accordion__header--primary p-2 mb-1`}
                        eventKey={`${index}`}
                        onClick={() => setCollapsed(index.toString())}
                      >
                        <span className='accordion__header--icon'></span>
                        <span
                          className={`accordion__header--text accordion-text-${index}`}
                        >
                          <i
                            className='las la-grip-vertical align-middle px-3'
                            style={{ cursor: 'grab' }}
                          ></i>
                          {item && item.title ? item.title : 'Novo grupo'}
                        </span>
                        <span className='accordion__header--indicator '></span>
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        className='accordion__body'
                        eventKey={`${index}`}
                      >
                        <Card.Body className='text-left pt-3'>
                          <div className='form-group col-md-12 pl-0'>
                            <div className='d-flex justify-content-between'>
                              <label>Nome do grupo</label>

                              {index > 0 && !id && (
                                <button
                                  type='button'
                                  className='btn btn-outline-primary btn-xxs mb-2'
                                  onClick={() => remove(index)}
                                >
                                  Excluir grupo
                                </button>
                              )}
                              {id && item.title && (
                                <button
                                  type='button'
                                  className='btn btn-outline-primary btn-xxs mb-2'
                                  onClick={() => {
                                    Swal.fire({
                                      title: 'Você tem certeza?',
                                      html: 'Deseja realmente deletar este grupo e todas suas opções? Está ação não poderá ser revertida!',
                                      icon: 'warning',
                                      buttons: true,
                                      confirmButtonText: `Continuar ação`,
                                      cancelButtonText: `Cancelar`,
                                      showCancelButton: true,
                                      confirmButtonColor: 'red'
                                    }).then(value => {
                                      if (value.isConfirmed) {
                                        DeleteVariationGroup(
                                          item.id,
                                          setNotify
                                        ).then(res => {
                                          remove(index)
                                        })
                                      }
                                    })
                                  }}
                                >
                                  Excluir grupo
                                </button>
                              )}
                            </div>

                            <input
                              {...register(`group.${index}.title`, {
                                required: true
                              })}
                              className='form-control text-black'
                              onChange={e => {
                                if (
                                  errors?.group &&
                                  errors?.group[index] &&
                                  errors?.group[index].title &&
                                  e.target.value.length > 3
                                ) {
                                  clearErrors(`group.${index}`)
                                }
                                document.querySelector(
                                  `.accordion-text-${index}`
                                ).innerHTML = e.target.value
                              }}
                              maxLength={80}
                              placeholder='ex: tamanhos, sabores, combo e etc...'
                            />

                            {errors?.group &&
                              errors?.group[index] &&
                              errors?.group[index].title && (
                                <small className='ml-2 text-danger'>
                                  {errors?.group[index].title.type ===
                                    'required' &&
                                    'Por favor, preencha este campo!'}
                                </small>
                              )}
                          </div>
                          <GroupOptions
                            index={index}
                            setValue={setValue}
                            item={item}
                          />
                          <VariationItems
                            productId={id}
                            nestIndex={index}
                            register={register}
                            control={control}
                            errors={errors}
                            clearErrors={clearErrors}
                            setValue={setValue}
                            setNotify={setNotify}
                            useFieldArray={useFieldArray}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Accordion>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
