import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Tab, Tabs, OverlayTrigger, Tooltip } from "react-bootstrap";
import DefaultLoader from "../jsx/components/Loader";
import Swal from "sweetalert2";
import AlertResponses from "../jsx/components/Alert";
import UserContext from "../contexts/UserContext";
import {
  DeleteCampaignLists,
  DeleteCampaigns,
  GetCampaignLists,
  GetCampaigns,
  ResendCampaign,
  UpdateCampaignList,
} from "../services/CampaignServices";
import CampaignLists from "./CampaignLists";
import ModalComponent from "../jsx/components/Modal";
import DatatablePaginated from "../jsx/components/DatatablePaginated";
import { FilterUsersBy } from "../services/UsersServices";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import CampaignAnalytics from "./CampaignAnalytics";
import WhatsappCampaignsTerms from "../jsx/components/WhatsappCampaignsTerms";
import Tutorial from "../jsx/components/Tutorial";

export default function Campaigns() {
  // let { token: token } = parseCookies();

  const { user } = useContext(UserContext);
  const [campaigns, setCampaigns] = useState("");
  const [campaignLists, setCampaignLists] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [listSelectedRows, setListSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showListDeleteButton, setShowListDeleteButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);
  const [progress, setProgress] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const campaignData = useRef(null);
  const campaignsTotalRows = useRef(1);

  const listData = useRef(null);
  const listTotalRows = useRef(1);

  const deleteRows = (state) => {
    if (state.selectedRows && state.selectedRows.length > 0) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  };

  const listDeleteRows = (state) => {
    if (state.selectedRows && state.selectedRows.length > 0) {
      setListSelectedRows(state.selectedRows);
      setShowListDeleteButton(true);
    } else {
      setShowListDeleteButton(false);
    }
  };

  const mediaType = {
    text: "Texto",
    media: "Mídia",
    product: "Produto",
    promotion: "Promoção",
    coupon: "Cupom",
  };

  const columns = [
    {
      name: "Campanha",
      selector: (row) => (
        <Link
          to={{
            pathname: `/campaign-details/${row.id}`,
            campaign: row,
          }}
          className=""
        >
          {row.name}
        </Link>
      ),
      maxWidth: "25%",
    },
    {
      name: "Lista",
      selector: (row) => row.campaign_list__name,
      sortable: true,
      maxWidth: "20%",
    },
    {
      name: "Clientes",
      selector: (row) => row.campaign_list__customers_total,
      sortable: true,
      maxWidth: "10%",
    },
    {
      name: "Tipo",
      selector: (row) => mediaType[row.media_type],
      sortable: true,
      maxWidth: "10%",
    },
    {
      name: "Criada em",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      maxWidth: "20%",
    },
    {
      name: "Relatório",
      selector: (row) => analytics(row),
      maxWidth: "5%",
    },
    {
      name: "Ações",
      selector: (row) => campaignActions(row),
      maxWidth: "5%",
      allowOverflow: true,
    },
  ];

  const handleUpdateList = async (data) => {
    document.querySelector(`#sync-icon-${data.id}`).classList.add("fa-spin");
    document.querySelector(`#btn-${data.id}`).setAttribute("disabled", true);

    let filterData = {
      filter_one: data.filter_one.value,
      filter_two: data?.filter_two.value || "",
      average_ticket_value: data.extra_data?.average_ticket || "",
      consumed_more_than: data.extra_data?.consumed_more_than || "",
      consumed_less_than: data.extra_data?.consumed_less_than || "",
      orders_above: data.extra_data?.orders_above || "",
      neighborhood: data.extra_data?.neighborhood || "",
      city: data.extra_data?.city || "",
      state: data.extra_data?.state || "",
    };
    let queryString = new URLSearchParams(filterData).toString();

    let response = await FilterUsersBy(queryString);

    let date = new Date();
    let gmtMinus3Date = new Date(date.getTime() - 3 * 60 * 60 * 1000);
    let payload = {
      id: data.id,
      name: data.name,
      filter_one: data.filter_one,
      filter_two: data?.filter_two || "",
      customers: response.data,
      customers_total: response.total,
      extra_data: data.extra_data,
      last_update: gmtMinus3Date,
      store: user.store_id,
    };

    let responseUpdate = await UpdateCampaignList(payload);

    if (responseUpdate) {
      document
        .querySelector(`#sync-icon-${data.id}`)
        .classList.remove("fa-spin");
      document.querySelector(`#btn-${data.id}`).removeAttribute("disabled");

      setNotify({
        title: "Sucesso!",
        message: "Lista atualizada com sucesso!",
        type: "success",
        visible: true,
      });

      setTimeout(() => {
        window.location.href = "/campaigns";
      }, 4000);
    }
  };

  const handleResendCampaign = async (row) => {
    await Swal.fire({
      title: "Reenviar campanha",
      input: "select",
      inputOptions: {
        all: "Reenviar para todos",
        failed: "Reenviar apenas os que falharam",
      },
      inputPlaceholder: "selecione uma opção...",
      confirmButtonText: "Reenviar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      customClass: {
        input: "form-control",
      },
      inputValidator: (value) => {
        Swal.showLoading();
        return new Promise((resolve) => {
          let payload = {
            cid: row.id,
            bt: user.botty_token,
            wa_account_unique: row.wa_account_unique,
            media_type: row.media_type,
            message: row.message,
            resend: 1,
            resend_type: value,
            store: row.store,
          };
          if (row.image_url) {
            payload.image_url = row.image_url;
          } else if (row.image) {
            payload.image_path = row.image;
          }

          ResendCampaign(payload)
            .then((res) => {
              console.log(res);
              setNotify({
                title: "Sucesso!",
                message: "Campanha reenviada com sucesso!",
                type: "success",
                visible: true,
              });
              resolve();
            })
            .catch((error) => console.log(error));
        });
      },
    });
    // if (fruit) {
    //   Swal.fire(`You selected: ${fruit}`);
    // }
  };

  const campaignActions = (row) => {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-bottom`}>Reenviar campanha</Tooltip>}
      >
        <button
          id={`btn-${row.id}`}
          className="btn btn-success light sharp"
          onClick={() => handleResendCampaign(row)}
        >
          <i
            id={`sync-icon-${row.id}`}
            className="fa fa-paper-plane-o fs-18"
          ></i>
        </button>
      </OverlayTrigger>
    );
  };
  const actions = (row) => {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-bottom`}>Atualizar Lista</Tooltip>}
      >
        <button
          id={`btn-${row.id}`}
          className="btn btn-info light sharp"
          onClick={() => handleUpdateList(row)}
        >
          <i
            id={`sync-icon-${row.id}`}
            className="las la-sync font-weight-bold fs-18"
          ></i>
        </button>
      </OverlayTrigger>
    );
  };

  const analytics = (row) => {
    return (
      <button
        id={`btn-${row.id}`}
        className="btn btn-info light sharp"
        onClick={() => {
          campaignData.current = row;
          setShowAnalyticsModal(true);
        }}
      >
        <i
          id={`analytics-icon-${row.id}`}
          className="ti-bar-chart font-weight-bold fs-18"
        ></i>
      </button>
    );
  };

  const listColumns = [
    {
      name: "Nome da lista",
      selector: (row) => (
        <Link
          to="#"
          onClick={() => {
            listData.current = row;
            setShowModal(true);
          }}
        >
          {row.name}
        </Link>
      ),
      sortable: true,
      maxWidth: "25%",
    },
    {
      name: "Filtro principal",
      selector: (row) => row.filter_one.label,
      sortable: true,
      maxWidth: "20%",
    },
    {
      name: "Filtro secundário",
      selector: (row) => row.filter_two?.label || "",
      maxWidth: "20%",
    },
    {
      name: "Clientes",
      selector: (row) => row.customers_total,
      maxWidth: "5%",
    },
    {
      name: "Última atualização",
      selector: (row) =>
        row?.last_update
          ? new Date(row.last_update).toLocaleString()
          : new Date(row.created_at).toLocaleString(),
      maxWidth: "20%",
    },
    {
      name: "Criado em",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      maxWidth: "20%",
    },

    {
      name: "Ações",
      selector: (row) => actions(row),
      maxWidth: "5%",
      allowOverflow: true,
    },
  ];

  const modalProps = {
    title: "Lista de clientes",
    content: (
      <CampaignLists
        data={listData.current}
        setNotify={setNotify}
        setShowModal={setShowModal}
        listData={listData}
      />
    ),
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const modalAnalytics = {
    title: "Relatório da campanha",
    content: (
      <CampaignAnalytics
        data={campaignData.current}
        setNotify={setNotify}
        setShowModal={setShowAnalyticsModal}
      />
    ),
    btnLabel: "Salvar",
    showModal: showAnalyticsModal,
    setShowModal: setShowAnalyticsModal,
    showFooter: false,
  };

  const handleCampaignPageChange = (page) => {
    GetCampaigns(
      setCampaigns,
      campaignsTotalRows,
      `?page=${page}`,
      isLoading,
      setIsLoading
    );
  };

  const handleListPageChange = (page) => {
    GetCampaignLists(setCampaignLists, listTotalRows, `?page=${page}`);
  };

  useEffect(() => {
    if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    }

    GetCampaigns(
      setCampaigns,
      campaignsTotalRows,
      `?page=1`,
      isLoading,
      setIsLoading
    );

    GetCampaignLists(setCampaignLists, listTotalRows, `?page=1`);
  }, []);

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <section id="campaigns">
          <Tutorial videoId={"cXFzFydySxA"} padding={"0 15px"}/>
          <div className="col-xxl-12 pl-0 mb-4 d-flex justify-content-between">
            <h2>Campanhas</h2>
            <WhatsappCampaignsTerms
              modalTitle="Termos de uso"
              classes="mt-2 nav-text"
              dontShowAgain={true}
            />
          </div>
          <Tabs id="campaign-tab" defaultActiveKey="whatsapp" className="mb-3">
            <Tab
              eventKey="whatsapp"
              title={
                <span>
                  <i className="lab la-whatsapp"></i> Whatsapp
                </span>
              }
            >
              <div className="row mt-4">
                <div className="col-xl-12 col-xxl-12 col-sm-12 page-header d-flex justify-content-end">
                  {showDeleteButton && (
                    <Button
                      className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() =>
                        Swal.fire({
                          title: "Você tem certeza?",
                          text: "Todos as campanhas selecionadas serão deletadas. Esta ação é irreversível!",
                          icon: "warning",
                          buttons: true,
                          confirmButtonText: `Continuar a ação`,
                          cancelButtonText: `Cancelar`,
                          showCancelButton: true,
                          confirmButtonColor: "red",
                        }).then((value) => {
                          if (value.isConfirmed) {
                            DeleteCampaigns(
                              selectedRows,
                              setNotify,
                              setProgress
                            );
                          }
                        })
                      }
                    >
                      <span>DELETAR SELECIONADOS</span>
                    </Button>
                  )}

                  <Link
                    className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                    to={"/campaign-details"}
                  >
                    <span>Nova Campanha</span>
                  </Link>
                </div>
              </div>

              <DatatablePaginated
                data={campaigns}
                columns={columns}
                title="  "
                selectable={true}
                onSelectedRowsChange={deleteRows}
                showDeleteButton={showDeleteButton}
                filterDefaultField={"name"}
                filterByField={{ labels: ["Campanha"], fields: ["name"] }}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                handlePageChange={handleCampaignPageChange}
                totalRows={campaignsTotalRows.current}
              />
            </Tab>
            <Tab eventKey="list" title="Listas">
              <div className="row mt-4">
                <div className="col-xl-12 col-xxl-12 col-sm-12 page-header d-flex justify-content-end">
                  {showListDeleteButton && (
                    <Button
                      className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() =>
                        Swal.fire({
                          title: "Você tem certeza?",
                          text: "Todos as listas selecionadas serão deletadas. Esta ação é irreversível!",
                          icon: "warning",
                          buttons: true,
                          confirmButtonText: `Continuar a ação`,
                          cancelButtonText: `Cancelar`,
                          showCancelButton: true,
                          confirmButtonColor: "red",
                        }).then((value) => {
                          if (value.isConfirmed) {
                            DeleteCampaignLists(
                              listSelectedRows,
                              setNotify,
                              setProgress
                            );
                          }
                        })
                      }
                    >
                      <span>DELETAR SELECIONADOS</span>
                    </Button>
                  )}
                  <Button
                    className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                    onClick={() => {
                      listData.current = null;
                      setShowModal(true);
                    }}
                  >
                    <span>Nova Lista</span>
                  </Button>
                </div>
              </div>

              <DatatablePaginated
                data={campaignLists}
                columns={listColumns}
                title="  "
                selectable={true}
                onSelectedRowsChange={listDeleteRows}
                showDeleteButton={showDeleteButton}
                filterDefaultField={"name"}
                filterByField={{ labels: ["Campanha"], fields: ["name"] }}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                handlePageChange={handleListPageChange}
                totalRows={listTotalRows.current}
              />
            </Tab>
          </Tabs>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
          {showModal && <ModalComponent props={modalProps} />}
          {showAnalyticsModal && <ModalComponent props={modalAnalytics} />}

          {progress && <ProgressBarComponent />}
        </section>
      )}
    </>
  );
}
