import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { pricing } from "./plans/data";
import PaymentForm from "../PaymentForm";
import NotificationsContext from "../../../contexts/NotificationsContext";
import UserContext from "../../../contexts/UserContext";
import DefaultLoader from "../Loader";

const paymentSubtitles = {
  annual: "em até 12x de ",
  semiannual: "em até 6x de ",
};

const useFilteredPlans = (pricing, planCode, storePlan) => {
  console.log(planCode);
  return useCallback(() => {
    return pricing.monthly.filter((plan) => {
      if (planCode) {
        return planCode !== "free" ? plan.id === 2 || plan.id === 3 : true;
      } else {
        return plan.id >= storePlan;
      }
    });
  }, [pricing.monthly, planCode, storePlan]);
};

const Price = ({ plan, recurrencySelected }) => {
  return useMemo(() => {
    const data = plan.price.find(
      (item) => item.recurrency === recurrencySelected
    );
    const x = recurrencySelected === "annual" ? 12 : 6;
    const cashPayment = data.price * x;

    if (plan.id === 1) {
      return (
        <div className="text-center mb-2">
          <h2 className="font-weight-bold mb-0">
            <span>R$0</span>
          </h2>
        </div>
      );
    }

    return (
      <div className="text-center mb-2">
        <h2 className="font-weight-bold mb-0">
          <span>
            {recurrencySelected !== "monthly" && (
              <small className="fs-18 font-weight-bold">
                {paymentSubtitles[recurrencySelected]}
              </small>
            )}
            {" R$"}
            {data.price}
            {recurrencySelected === "monthly" && (
              <small className="fs-18 font-weight-bold">/mês</small>
            )}
          </span>
        </h2>
        {recurrencySelected !== "monthly" && (
          <div className="d-flex justify-content-center align-items-center">
            ou
            <h3 className="font-weight-bold px-1">R${cashPayment}</h3>à vista
          </div>
        )}
      </div>
    );
  }, [plan, recurrencySelected]);
};

export default function Plans({
  from,
  planCode: initialPlanCode,
  selectButton,
}) {
  const { setShowPlansModal } = useContext(UserContext);
  const { setNotify } = useContext(NotificationsContext);
  const [storePlan, setStorePlan] = useState(from === "register" ? 0 : null);
  const [planSelected, setPlanSelected] = useState();
  const [recurrencySelected, setRecurrencySelected] = useState("annual");
  const modalBody = useRef();
  const filteredPlans = useFilteredPlans(pricing, initialPlanCode, storePlan);

  useEffect(() => {
    if (!from) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        setStorePlan(user?.plan_id);
      }
    }

    const prevArrow = document.querySelector(".slick-arrow.slick-prev");
    const nextArrow = document.querySelector(".slick-arrow.slick-next");
    if (prevArrow) prevArrow.setAttribute("type", "button");
    if (nextArrow) nextArrow.setAttribute("type", "button");
  }, [from]);

  const handlePayNow = (plan) => {
    setPlanSelected({ ...plan, recurrencySelected });
    if (modalBody.current) {
      modalBody.current.style.display = "none";
    }
  };

  const handleChangeRecurrency = (event) => {
    const buttons = document.querySelectorAll(".plans-button-group");
    buttons.forEach((b) => b.classList.remove("active"));
    event.target.classList.add("active");
    setRecurrencySelected(event.target.id);
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <button className="slide-arrow prev-arrow">
        <i className="las la-angle-right"></i>
      </button>
    ),
    nextArrow: (
      <button className="slide-arrow next-arrow">
        <i className="las la-angle-left"></i>
      </button>
    ),
    dots: false,
    responsive: [
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (storePlan === null || storePlan === undefined) {
    return <DefaultLoader isLoading={true} />;
  }

  return (
    <>
      <div id="plansModal" ref={modalBody}>
        <div className="text-center mb-2">
          <div className="btn-group">
            <button
              id="annual"
              type="button"
              className="btn btn-primary plans-button-group active"
              onClick={handleChangeRecurrency}
            >
              Anual
            </button>
            <button
              id="monthly"
              type="button"
              className="btn btn-primary plans-button-group"
              onClick={handleChangeRecurrency}
            >
              Mensal
            </button>
          </div>
        </div>
        <Slider {...settings}>
          {filteredPlans().map((item) => (
            <div key={`card--key${item.id}`}>
              <div className="card-header justify-content-start">
                <h3 className="mb-0">{item.title}</h3>
                {item.id === storePlan && (
                  <span className="badge badge-sm badge-best-seller">
                    <i
                      className="las la-crown pr-2"
                      style={{ fontSize: 20 }}
                    ></i>{" "}
                    Meu Plano
                  </span>
                )}
              </div>
              <div className="card-body">
                <ul className="feature-list">
                  {item.features.map((feature, index) => (
                    <li
                      key={`feature--key${index}`}
                      className={`feature--key${item.id}`}
                      style={{ fontWeight: 500 }}
                    >
                      <span className={`information key${feature.id}`}>
                        {feature.label}
                      </span>
                      {feature.text}
                      {feature.soon && (
                        <small
                          className="text-info font-weight-bold"
                          style={{ marginLeft: 35 }}
                        >
                          *Em breve
                        </small>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              {(storePlan === 0 || storePlan !== 6) && (
                <div className="card-footer">
                  {item.id > 3
                    ? item.id === 6 && (
                        <small className="text-center d-block mb-2">
                          *Franquias acima de 6 lojas
                        </small>
                      )
                    : storePlan !== item.id && (
                        <Price
                          plan={item}
                          recurrencySelected={recurrencySelected}
                        />
                      )}
                  {!from || from !== "register" ? (
                    item.id > 3 ? (
                      <a
                        className="btn btn-primary d-block"
                        target="_blank"
                        rel="noreferrer"
                        href={`https://api.whatsapp.com/send?phone=5547991600107&text=Ol%C3%A1%2C%20gostaria%20de%20fazer%20um%20upgrade%20para%20o%20plano%20${item.title}`}
                      >
                        Falar com o comercial
                      </a>
                    ) : (
                      storePlan !== item.id && (
                        <button
                          type="button"
                          className="btn btn-primary btn-block d-block"
                          onClick={() => handlePayNow(item)}
                        >
                          Fazer Upgrade
                        </button>
                      )
                    )
                  ) : (
                    selectButton(item, recurrencySelected)
                  )}
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
      {planSelected && (
        <PaymentForm
          setShowModal={setShowPlansModal}
          planSelected={planSelected}
          setNotify={setNotify}
          from={from}
        />
      )}
    </>
  );
}
