import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Button,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import DefaultLoader from "../jsx/components/Loader";
import Swal from "sweetalert2";
import AlertResponses from "../jsx/components/Alert";
import UserContext from "../contexts/UserContext";

import ModalComponent from "../jsx/components/Modal";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import { GetAgents } from "../services/AiServices";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";
import {
  DeleteAccount,
  GetAccount,
  PauseOrActiveAccount,
} from "../services/WaBottyServices";
import WhatsappConnect from "./WhatsappConnect";
import NewAIAgent from "../jsx/components/ModalData/NewAIAgent";
import Tutorial from "../jsx/components/Tutorial";

export default function Ai() {
  const { user } = useContext(UserContext);
  const [agents, setAgents] = useState(false);
  const [account, setAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showNewAgentModal, setShowNewAgentModal] = useState(false);
  const [progress, setProgress] = useState(false);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const connectOrReconnect = useRef({ connect: false, reconnect: false });

  const WAStatus = {
    connected: <span className="badge badge-success">Conectado</span>,
    connecting: <span className="badge badge-info">Conectando</span>,
    disconnecting: <span className="badge badge-primary">Desconectado</span>,
    unknown: <span className="badge badge-dark">Desconhecido</span>,
  };

  const requestStatus = {
    0: <span className="badge badge-info">Pendente</span>,
    1: <span className="badge badge-success">Aguardando liberação</span>,
    2: <span className="badge badge-success">Liberado</span>,
  };

  const modalConnectProps = {
    title: !showModal.reconnect ? "Conectar Whatsapp" : "Reconectar Whatsapp",
    content: (
      <WhatsappConnect
        data={account}
        connectOrReconnect={connectOrReconnect}
        setNotify={setNotify}
        setShowModal={setShowModal}
      />
    ),
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const modalRegisterAgent = {
    title: "Novo Agente de IA",
    content: (
      <NewAIAgent
        data={account}
        setNotify={setNotify}
        setShowModal={setShowNewAgentModal}
      />
    ),
    btnLabel: "Salvar",
    showModal: showNewAgentModal,
    setShowModal: setShowNewAgentModal,
    showFooter: false,
  };

  const handleDeleteAccount = () => {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você está prestes a excluir a conexão do seu Agente de IA no Whatsapp! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir Conta",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAccount({
          uid: account.uid,
          unique: account.unique,
        })
          .then((result) => {
            if (result) {
              setNotify({
                title: "Sucesso!",
                message: "Conexão com whatsApp excluída com sucesso!",
                type: "success",
                visible: true,
              });
              setTimeout(() => {
                window.location.href = "/ai";
              }, 3500);
            }
          })
          .catch((error) => {
            setNotify({
              title: "Oops!",
              message:
                "Ocorreu um erro ao excluir sua conexão com o Whatsapp. Por favor, tente novamente daqui alguns minutos. Se o problema persistir, contacte nosso suporte.",
              type: "error",
              visible: true,
            });
          });
      }
    });
  };

  const handlePauseOrActive = () => {
    Swal.fire({
      title: "Você tem certeza?",
      html:
        account.receive_chats === 1
          ? "Você está prestes a pausar o funcionamento do seu agente de IA nesta conta para todos os clientes!"
          : "Você tem certeza que deseja ativar o agente de IA nesta conta para todos os clientes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor:
        account.receive_chats === 1 ? "#515151" : "var(--success)",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText:
        account.receive_chats === 1 ? "Pausar o Agente!" : "Ativar Agente",
    }).then((result) => {
      if (result.isConfirmed) {
        PauseOrActiveAccount({
          aid: account.id,
          unique: account.unique,
          status: account.receive_chats === 1 ? 2 : 1,
        })
          .then((result) => {
            if (result) {
              setNotify({
                title: "Sucesso!",
                message:
                  account.receive_chats === 1
                    ? "Conta pausada com sucesso!"
                    : "Conta ativada com sucesso!",
                type: "success",
                visible: true,
              });
              setTimeout(() => {
                window.location.href = "/ai";
              }, 3500);
            } else {
              setNotify({
                title: "Oops!",
                message:
                  "Ocorreu um erro ao pausar o agente, por favor, tente novamente daqui alguns minutos.",
                type: "error",
                visible: true,
              });
            }
          })
          .catch(() => {
            setNotify({
              title: "Oops!",
              message:
                "Ocorreu um erro ao pausar o agente, por favor, tente novamente daqui alguns minutos.",
              type: "error",
              visible: true,
            });
          });
      }
    });
  };

  const getAiStatus = (agent) => {
    if (agent.status !== 2) {
      return requestStatus[agent.status];
    } else if (account && account.receive_chats === 2) {
      return <span className="badge badge-info">Pausado</span>;
    } else if (account) {
      return WAStatus[account.status];
    } else if (!account) {
      return <span className="badge badge-dark">Desconectado</span>;
    }
  };

  useEffect(() => {
    if (user?.plan_id < 3) {
      window.location.assign("/");
      return;
    }

    GetAccount()
      .then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          let created = new Date(res.data[0].created * 1000);
          created.setHours(created.getHours() + 3);
          res.data[0].createdAt = created.toLocaleString();
          setAccount(res.data[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    GetAgents()
      .then((res) => {
        if (res && res.length > 0) {
          setAgents(res);
        }
      })
      .catch((error) => {
        console.log(error);
        setNotify({
          title: "Oops!",
          message: ERROR_MESSAGES.default,
          type: "error",
          visible: true,
        });
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <section id="whatsapp">
          <Tutorial videoId={"wmW482XwybY"} padding={"0 15px"} />
          <div className="col-xxl-12 pl-0 mb-2 d-flex justify-content-between">
            <h2>Inteligência Artificial</h2>
          </div>
          <Tabs defaultActiveKey="whatsapp" className="mb-3">
            <Tab eventKey="whatsapp" title={"Whatsapp"}>
              <div className="row mt-4">
                <div className="col-xl-12 col-xxl-12 col-sm-12 page-header d-flex justify-content-end">
                  {agents && agents[0].status === 2 ? (
                    <Button
                      className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() => {
                        connectOrReconnect.current = "connect";
                        setShowModal(true);
                      }}
                    >
                      <span>Conectar Whatsapp</span>
                    </Button>
                  ) : (
                    <Button
                      className="i-false btn btn-primary mr-3 mb-4"
                      onClick={() => {
                        setShowNewAgentModal(true);
                      }}
                    >
                      <span>Solicitar Agente</span>
                    </Button>
                  )}
                </div>
              </div>
              {agents && agents.length > 0 ? (
                <Col lg={12}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Agente</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Nome do Agente</th>
                            <th>Recurso</th>
                            {account ? <th>Conta</th> : ""}
                            <th>Status</th>
                            <th>
                              {account
                                ? "Conectado em"
                                : agents && agents[0].status !== 2
                                ? "Data da solicitação"
                                : "Desconectado"}
                            </th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {agents.map((agent, index) => (
                            <tr key={index}>
                              <td className="text-capitalize">
                                {agent.agent_name}
                              </td>

                              <td>Whatsapp</td>
                              {account ? <td>{account.phone}</td> : ""}
                              <td>{getAiStatus(agent)}</td>
                              <td>
                                {account
                                  ? account.createdAt
                                  : agent.status !== 2
                                  ? new Date(agent.created_at).toLocaleString()
                                  : ""}
                              </td>
                              {account ? (
                                <td>
                                  <div className="d-flex">
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {account.receive_chats === 1
                                            ? "Pausar IA"
                                            : "Ativar IA"}
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        type="button"
                                        className="btn btn-info shadow btn-xs sharp mr-1"
                                        onClick={handlePauseOrActive}
                                      >
                                        {account.receive_chats === 1 ? (
                                          <i className="fa fa-pause"></i>
                                        ) : (
                                          <i className="fa fa-play"></i>
                                        )}
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          Reconectar
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        type="button"
                                        className="btn btn-success shadow btn-xs sharp mr-1"
                                        onClick={() => {
                                          connectOrReconnect.current =
                                            "reconnect";
                                          setShowModal(true);
                                        }}
                                        disabled={
                                          agent.status === 0 ? true : false
                                        }
                                      >
                                        <i className="fa fa-refresh"></i>
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          Deletar conta
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        type="button"
                                        className="btn btn-primary shadow btn-xs sharp"
                                        onClick={handleDeleteAccount}
                                        disabled={
                                          agent.status === 0 ? true : false
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <h4>Nenhum agente disponível...</h4>
              )}
            </Tab>
          </Tabs>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}
          {showModal && <ModalComponent props={modalConnectProps} />}
          {showNewAgentModal && <ModalComponent props={modalRegisterAgent} />}
          {/* {showAnalyticsModal && <ModalComponent props={modalAnalytics} />} */}
          {progress && <ProgressBarComponent />}
        </section>
      )}
    </>
  );
}
