import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import NumberFormat from 'react-number-format';

import DataTablev2 from '../jsx/components/Datatable'
import { GetAll, Delete, ChangeStatus } from '../services/PromotionsServices'
import { Hash } from '../helpers/Helpers'
import DefaultLoader from '../jsx/components/Loader'
import AlertResponses from '../jsx/components/Alert'
import ProgressBarComponent from '../jsx/components/ProgressBar'
import Tutorial from "../jsx/components/Tutorial";
import UserContext from "../contexts/UserContext";

export default function Promotions() {
  const { user } = useContext(UserContext)
  const [promotions, setPromotions] = useState('');
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if(user?.plan_id === 1){
      window.location.assign('/')
      return
    }
    GetAll(setPromotions, setErrors, setIsLoading);
  }, [])

  const deleteRows = (state) => {
    if (state.selectedRows !== (null || undefined) && state.selectedRows.length > 0) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  }

  const status = {
    1: {
      id: 1,
      label: "Ativa",
      type: "success"
    },
    2: {
      id: 2,
      label: "Desativada",
      type: "light"
    },
    3: {
      id: 3,
      label: "Encerrada",
      type: "danger"
    },
  }

  const BadgeStatus = (item) => {
    return (
      <DropdownButton
        variant=''
        title={status[item.status].label}
        className={`badge badge-${status[item.status].type} badge-sm light badge-delivery-status badge-${item.id}`}
        onSelect={(e) => {
          ChangeStatus({ id: item.id, status: e }, setNotify, setProgress).then(res => {
            if (res) {
              document.querySelector(`.badge-${item.id}`).classList.remove(`badge-${status[item.status].type}`)
              document.querySelector(`.badge-${item.id}`).classList.add(`badge-${status[e].type}`)
              document.querySelector(`.badge-${item.id} button`).innerText = status[e].label
            }
          })

        }}
      >
        {Object.values(status).map((value, i) =>
          <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
      </DropdownButton>

    )
  }

  /* const actions = (row) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="" className="table-dropdown i-false">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>

          <Link to={{
            pathname: `/promotion-details/${row.id}`,
            product: row,
          }} className="text-danger dropdown-item">Editar</Link>
          <Dropdown.Item onClick={() => ''} className="text-danger">
            Desativar
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  } */

  const columns = [
    {
      name: '#',
      selector: (row) => <img src={Hash('75x75', row.image)} className="promotions-thumb" style={styles.thumb}/>,
      sortable: true,
      maxWidth: '5%'
    },
    {
      name: 'Nome',
      selector: (row) => <Link to={{ pathname: `promotion-details/${row.id}` }}>{row.name}</Link>,
      sortable: true,
      maxWidth: '20%'
    },
    {
      name: 'Descrição',
      selector: (row) => row.description,
      maxWidth: '15%'
    },
    {
      name: 'Valor',
      selector: (row) => <NumberFormat
        displayType={'text'}
        value={row.price}
        thousandSeparator="."
        decimalSeparator=","
        prefix="R$"
      />,
      maxWidth: '10%'
    },
    {
      name: 'Categoria',
      selector: (row) => row.categorie__name,
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Criado em',
      selector: (row) => new Date(row.created_at).toLocaleString('pt-BR'),
      maxWidth: '15%',
      sortable: true,
      allowOverflow: true
    },
    {
      name: 'Status',
      selector: (row) => BadgeStatus(row),
      maxWidth: '10%',
      allowOverflow: true
    }
  ];

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
            <Tutorial videoId={"Mq9nuLDebAQ"} padding={"0 15px"}/>
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                <div className="col-xl-4 col-xxl-5 col-sm-12">
                  <h2>Promoções</h2>
                </div>

                <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                  {showDeleteButton &&

                    <Button className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() =>
                        Swal.fire({
                          title: "Você tem certeza?",
                          text:
                            "Todos os arquivos selecionados serão deletados. Esta ação é irreversível!",
                          icon: "warning",
                          buttons: true,
                          confirmButtonText: `Continuar a ação`,
                          cancelButtonText: `Cancelar`,
                          showCancelButton: true,
                          confirmButtonColor: 'red'
                        }).then((value) => {
                          if (value.isConfirmed){
                            Delete(selectedRows, setNotify, setProgress)
                          }
                        })
                      }
                    >
                      <span>DELETAR SELECIONADOS</span>
                    </Button>
                  }
                  <Button className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                    href="/promotion-details">
                    <span>ADICIONAR</span>
                  </Button>
                </div>

              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }

            <DataTablev2
              data={promotions}
              columns={columns}
              title="  "
              selectable={true}
              onSelectedRowsChange={deleteRows}
              showDeleteButton={showDeleteButton}
              filterByField={{ labels: ['Nome', 'Categoria'], fields: ['name',] }}
            />

            {progress && <ProgressBarComponent />}
          </>
      }
    </>
  );
}

const styles = {
  thumb: {
    height: 75,
    width: 75,
    objectFit: 'contain'
  }
}