export const pricing = {
  slogan: "NOSSOS PLANOS",
  title: "Escolha o melhor plano para o seu negócio",
  monthly: [
    {
      id: 1,
      title: "Gratuito",
      description: "",
      suggested: true,
      price: [
        {
          recurrency: "annual",
          price: 0,
        },
       
        {
          recurrency: "monthly",
          price: 0,
        },
      ],
      /* trail: 'Plano Semestral', */
      /* trailLink: '#', */
      features: [
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> <b>1 Loja</b>
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Site de delivery
              personalizado
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Domínio
              próprio
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Subdomínio
              exclusivo
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Bot para
              Whatsapp
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pagamento na
              entrega
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Pagamento
              online no cartão
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Pix
              automático
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 1 Usuário
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 20 Produtos
            </>
          ),
        },

        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 2 Grupos de
              variações
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 2 Categorias
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pedidos para
              retirada
            </>
          ),
          soon: false,
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Cupons de
              desconto
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Promoções
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Baixar
              cardápio do Ifood
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Ifood entrega fácil
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Cashback
            </>
          ),
          soon: false,
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Reserva de
              mesas/produtos
            </>
          ),
          soon: true,
        },
        /* {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Programa de
              fidelidade
            </>
          ),
          soon: true,
        }, */
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Feedback
              automatizado
            </>
          ),
          soon: true,
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Campanhas
              estratégicas por Whatsapp
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Google ADS
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Facebook
              Pixel
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> CRM Completo
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Relatórios
              simples
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Relatórios
              avançados
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Tema claro e
              escuro
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Formulário de
              contato via site
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Suporte via
              ticket
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 1 Modelo de site
            </>
          ),
        },
      ],
    },
    {
      id: 2,
      title: "Básico",
      description: "",
      suggested: true,
      price: [
        {
          recurrency: "annual",
          price: 119,
        },
       
        {
          recurrency: "monthly",
          price: 169,
        },
      ],
      /*   trail: 'Plano Anual',
            trailLink: '#', */
      features: [
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> <b>1 Loja</b>
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Site de delivery
              personalizado
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Domínio
              próprio
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Subdomínio
              exclusivo
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Bot para
              Whatsapp
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pagamento na
              entrega
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pagamento online
              no cartão
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pix automático
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 2 Usuário
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 40 Produtos
            </>
          ),
        },

        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 4 Grupos de
              variações
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 4 Categorias
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pedidos para
              retirada
            </>
          ),
          soon: false,
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Cupons de
              desconto
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Promoções
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Baixar
              cardápio do Ifood
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Ifood entrega fácil
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Cashback
            </>
          ),
          soon: false,
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Reserva de
              mesas/produtos
            </>
          ),
          soon: true,
        },
        /* {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Programa de
              fidelidade
            </>
          ),
          soon: true,
        }, */
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Feedback
              automatizado
            </>
          ),
          soon: true,
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Campanhas
              estratégicas por Whatsapp
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Google ADS
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Facebook Pixel
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> CRM Completo
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Relatórios
              simples
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-times-circle not-included"></i> Relatórios
              avançados
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Tema claro e
              escuro
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Formulário de
              contato via site
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Suporte via
              ticket
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 1 Modelo de site
            </>
          ),
        },
      ],
    },
    {
      id: 3,
      title: "Profissional",
      description: "",
      suggested: true,
      price: [
        {
          recurrency: "annual",
          price: 249,
        },
        
        {
          recurrency: "monthly",
          price: 329,
        },
      ],
      /* trail: 'Plano Semestral', */
      /* trailLink: '#', */
      features: [
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> <b>1 Loja</b>
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Site de delivery
              personalizado
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Domínio próprio
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Subdomínio
              exclusivo
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Bot para Whatsapp
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pagamento na
              entrega
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pagamento online
              no cartão
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pix automático
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i>Usuário ilimitados
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i>Produtos
              ilimitados
            </>
          ),
        },

        {
          text: (
            <>
              <i className="las la-check-circle included"></i>Grupos de
              variações ilimitados
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i>Categorias
              ilimitadas
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Pedidos para
              retirada
            </>
          ),
          soon: false,
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Cupons de
              desconto
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Promoções
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Baixar cardápio
              do Ifood
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Ifood entrega fácil
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Cashback
            </>
          ),
          soon: false,
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Reserva de
              mesas/produtos
            </>
          ),
          soon: true,
        },
       /*  {
          text: (
            <>
              <i className="las la-check-circle included"></i> Programa de
              fidelidade
            </>
          ),
          soon: true,
        }, */
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Feedback
              automatizado
            </>
          ),
          soon: true,
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Campanhas
              estratégicas por Whatsapp
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Google ADS
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Facebook Pixel
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> CRM Completo
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Relatórios
              simples
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Relatórios
              avançados
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Tema claro e
              escuro
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Formulário de
              contato via site
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Suporte via chat
              e whatsapp
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> 3 Modelos de site
            </>
          ),
        },
      ],
    },
    {
      id: 4,
      title: "Expansão",
      description: "",
      suggested: true,
      price: null,
      /* trail: 14, */
      /* trailLink: '#', */
      features: [
        {
          text: (
            <>
              <i className="las la-check-circle included"></i>{" "}
              <b>Até 3 Lojas</b>
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i>{" "}
              <b>Todas as funcionalidades incluídas</b>
            </>
          ),
        },
      ],
    },
    {
      id: 5,
      title: "Rede",
      description: "",
      suggested: false,
      price: null,
      /* trailLink: '#', */
      features: [
        {
          text: (
            <>
              <i className="las la-check-circle included"></i>{" "}
              <b>Até 6 Lojas</b>
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i>{" "}
              <b>Todas as funcionalidades incluídas</b>
            </>
          ),
        },
      ],
    },
    {
      id: 6,
      title: "Franquias",
      description: "",
      price: null,
      suggested: false,

      /* trail: 14, */
      /* trailLink: '#', */
      features: [
        {
          text: (
            <>
              <i className="las la-check-circle included"></i> Lojas ilimitadas
            </>
          ),
        },
        {
          text: (
            <>
              <i className="las la-check-circle included"></i>{" "}
              <b>Todas as funcionalidades incluídas</b>
            </>
          ),
        },
      ],
    },
  ],
  // annualy: [
  //   {
  //     id: 1,
  //     title: 'Pro Master',
  //     description: 'For Small teams or office',
  //     suggested: true,
  //     price: 999,
  //     trail: 14,
  //     trailLink: '#',
  //     features: [
  //       {
  //         id: 1,
  //         text: 'Drag & Drop Builder',
  //       },
  //       {
  //         id: 2,
  //         text: "1,000's of Templates",
  //       },
  //       {
  //         id: 3,
  //         text: 'Blog Support Tools',
  //       },
  //       {
  //         id: 4,
  //         text: 'eCommerce Store ',
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: 'Enterprise',
  //     description: 'For Best opportunities',
  //     suggested: false,
  //     price: 1299,
  //     trail: 30,
  //     trailLink: '#',
  //     features: [
  //       {
  //         id: 1,
  //         text: 'Drag & Drop Builder',
  //       },
  //       {
  //         id: 2,
  //         text: "1,000's of Templates",
  //       },
  //       {
  //         id: 3,
  //         text: 'Blog Support Tools',
  //       },
  //       {
  //         id: 4,
  //         text: 'eCommerce Store ',
  //       },
  //     ],
  //   },
  // ],
};
