import { api } from "../api/Middleware";
import { NEW_ORDERS_URL, ORDERS_URL } from "../api/Urls";
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

export const GetOne = async (id, setOrder, setIsLoading, setErrors) => {
  try {
    let response = await api.get(ORDERS_URL + id + "/");
    if (response.status === 200) {
      if (!response.data[0]) {
        window.location.href = "/orders";
        return;
      }
      setOrder(response.data[0]);
    }
  } catch (error) {
    if (error.response.status === 401) {
    }

    if (error.response.status === 404) {
      setErrors({ title: "Oops!", message: "Produto não encontrado!" });
    }
  }
  setIsLoading(false);
};

export const Get = async (
  setOrders,
  setErrors = false,
  setIsLoading = false,
  setTotalRows = false,
  page = 1,
  params = false
) => {
  try {
    let response = await api.get(
      ORDERS_URL + "list_orders/?page=" + page + (params ? params : "")
    );
    if (response.status === 200) {
      setOrders(response.data.results);
      if (setTotalRows) setTotalRows(response.data.count);
      return true;
    }
  } catch (error) {
    console.log(error);
    if (setErrors)
      setErrors({ title: "Oops!", message: ERROR_MESSAGES.orders.get });
    return false;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};

export const ChangeStatus = async (data, setNotify, returnData = false) => {
  try {
    let response = await api.put(ORDERS_URL + "change_status/", data);
    if (response.status === 200) {
      if (returnData) {
        return response.data;
      }
      //setNotify({ title: 'Sucesso!', message: response.data, type: 'success', visible: true })
      return true;
    }
  } catch (error) {
    //setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
    return false;
  }
};

export const Delete = async (data, setNotify) => {
  try {
    let ids = [];
    data.map((order) => ids.push(order.id));
    let response = await api.delete(ORDERS_URL + "delete_multiple/", {
      data: { ids: ids },
    });
    if (response.status === 200) {
      setNotify({
        title: "Sucesso!",
        message: "Operação realizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } catch (error) {
    //if(error.status !== 401) throw error.response;
    setNotify({
      title: "Oops!",
      message: error.response.data,
      type: "error",
      visible: true,
    });
  }
};

export const GetNewOrders = async (setNewOrders, returnData, store_id) => {
  try {
    let response = await api.get(NEW_ORDERS_URL + `store_${store_id}.json`);
    if (returnData) {
      return response.data;
    } else {
      setNewOrders(response.data);
    }
  } catch (error) {
    //console.log(error)
  }
};

export const SearchOrders = async (searchBy, value) => {
  try {
    let response = await api.get(
      ORDERS_URL + `?search_by=${searchBy}&value=${value}`
    );
    return response.data;
  } catch (error) {
    return false;
  }
};
