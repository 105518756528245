import { api } from '../api/Middleware'
import { CONTACT_URL } from "../api/Urls";
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetContacts = async (setContacts, setIsLoading, setErrors, setTotalRows, page = 1) => {
    try {
        let response = await api.get(`${CONTACT_URL}?page=${page}`);
        if (response.status === 200) {
            setContacts(response.data.results);
            setTotalRows(response.data.count);
        }
    } catch (error) {
        setErrors({ title: "Oops!", message: ERROR_MESSAGES.store.get })
    }
    finally {
        setIsLoading(false);
    }
}

export const ChangeStatus = async (data, setNotify) => {
    try {
        let response = await api.put(CONTACT_URL + data.id + '/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Status alterado com sucesso!', type: 'success', visible: true })
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        return false;
    }
}

export const Delete = async (data, setNotify) => {
    try {
        let ids = [];
        data.map(product => ids.push(product.id))
        let response = await api.delete(CONTACT_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }
}