import { IMAGES_BASE_URL, THUMBOR_URL } from "../api/Urls";
import CryptoES from "crypto-es";

export const Hash = (size, url) => {
  if (!url) {
    return "/imgs/default_img.png";
  } else if (url.includes("default_categorie_image")) {
    return "/imgs/default_img.png";
  } else if (!url.includes("s3.amazonaws")) {
    if (url.includes("/images")) url = url.replace("/images/", "");
    url = IMAGES_BASE_URL + url;
  }

  let hash = CryptoES.HmacSHA1(
    `fit-in/${size}/smart/${url}`,
    "3Start3Seu6Delivery3"
  )
    .toString(CryptoES.enc.Base64)
    .replace(/\//g, "_")
    .replace(/\+/g, "-");

  let hashed = THUMBOR_URL + hash + `/fit-in/${size}/smart/${url}`;
  return hashed;
};

export const DateTimeToISOFormat = (dateTimeString) => {
  let [date, time] = dateTimeString.split(" ");

  let [DD, MM, YYYY] = date.split("/");

  let [HH, mm] = time.split(":");
  return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
};

export const RemoveSpaces = (string) => {
  return string.replace(" ", "");
};

export const RemoveAccents = (string) => {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const CleanCacheInUrl = (url) => {
  window.history.pushState(null, "", url + "?cc=1");
};

export const MoneyFormat = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export const RGBToHex = (r, g, b) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return "#" + r + g + b;
};

export const states = [
  { value: "AC", label: "AC" },
  { value: "AL", label: "AL" },
  { value: "AM", label: "AM" },
  { value: "AP", label: "AP" },
  { value: "BA", label: "BA" },
  { value: "CE", label: "CE" },
  { value: "DF", label: "DF" },
  { value: "ES", label: "ES" },
  { value: "GO", label: "GO" },
  { value: "MA", label: "MA" },
  { value: "MT", label: "MT" },
  { value: "MS", label: "MS" },
  { value: "MG", label: "MG" },
  { value: "PA", label: "PA" },
  { value: "PB", label: "PB" },
  { value: "PR", label: "PR" },
  { value: "PE", label: "PE" },
  { value: "PI", label: "PI" },
  { value: "RJ", label: "RJ" },
  { value: "RN", label: "RN" },
  { value: "RO", label: "RO" },
  { value: "RS", label: "RS" },
  { value: "RR", label: "RR" },
  { value: "SC", label: "SC" },
  { value: "SE", label: "SE" },
  { value: "SP", label: "SP" },
  { value: "TO", label: "TO" },
];

export const nameOfStates = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AM", label: "Amazonas" },
  { value: "AP", label: "Amapá" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
];

export const Slugfy = (str) => {
  str = str
    .toString()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "")
    .replace(/-+/g, "-");

  return str;
};

export const resizeImage = async (
  url,
  mimeType,
  options = {
    maxWidth: 200,
    maxHeight: 150,
    quality: 0.7,
  }
) => {
  const calculateSize = (img) => {
    let w = img.width,
      h = img.height;
    if (w > h) {
      if (w > options.maxWidth) {
        h = Math.round((h * options.maxWidth) / w);
        w = options.maxWidth;
      }
    } else {
      if (h > options.maxHeight) {
        w = Math.round((w * options.maxHeight) / h);
        h = options.maxHeight;
      }
    }
    return [w, h];
  };

  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onerror = function () {
      URL.revokeObjectURL(this.src);
    };
    img.onload = function () {
      URL.revokeObjectURL(this.src);
      const [newWidth, newHeight] = calculateSize(
        img,
        options.maxWidth,
        options.maxHeight
      );
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      const resultUrl = canvas.toDataURL(options.mimeType, options.quality),
        result = {
          url: resultUrl,
          contentType: resultUrl.match(/^data\:([^\;]+)\;base64,/im)[1] || "",
          b64: resultUrl.replace(/^data\:([^\;]+)\;base64,/gim, ""),
        };

      canvas.toBlob(
        (blob) => {
          result.size = blob.size;
          resolve(result);
        },
        options.mimeType,
        options.quality
      );
    };
  });
};

export const pixExpirationDate = (_pixData, interval) => {
  try {
    let expiration = new Date(_pixData.current.expirationDate).getTime();
    let timeDifference = Math.max(expiration - Date.now(), 0);
    let remainingTimeInSeconds = Math.ceil(timeDifference / 1000);

    interval.current = setInterval(() => {
      let div = document.querySelector("#pixExpirationDate");

      remainingTimeInSeconds--;
      if (remainingTimeInSeconds <= 0) {
        clearInterval(interval.current);
      } else {
        let minutes = Math.floor(remainingTimeInSeconds / 60);
        let seconds = remainingTimeInSeconds % 60;

        if (div) {
          div.textContent = `${minutes} minutos e ${seconds} segundos`;
        }
      }
    }, 1000);
  } catch (error) {
    return new Date(_pixData.current.expirationDate).toLocaleTimeString();
  }
};

export const timer = (duration, element) => {
  let display = document.querySelector(element);

  let timer = duration,
    seconds;
  const interval = setInterval(function () {
    seconds = parseInt(timer, 10);

    display.textContent = seconds;

    if (--timer < 0) {
      clearInterval(interval);
      display.textContent = "QrCode expirado.";
      display.classList.add("fs-20");
    }
  }, 1000);
};

export const decodeText = (text) => {
  return decodeURIComponent(JSON.parse('"' + text.replace(/\"/g, "") + '"'));
};

export const encodeBase64 = (str) => {
  const encoder = new TextEncoder();

  const uint8Array = encoder.encode(str);

  const base64String = Buffer.from(uint8Array).toString("base64");

  return base64String;
};

export const removeFromCurrentOrders = (id) => {
  let currentOrders = localStorage.getItem("currentOrders");
  if (currentOrders) {
    currentOrders = JSON.parse(currentOrders);
    let newCurrentOrders = currentOrders.filter((order) => order.id !== id);
    let item = document.getElementById(`${id}`);

    if (item) {
      item.classList.add("d-none");
    }
    if (newCurrentOrders.length > 0) {
      localStorage.setItem("currentOrders", JSON.stringify(newCurrentOrders));
    } else {
      localStorage.removeItem("currentOrders");
    }
  }
};

export const stringToDecimal = (str) => {
  let newValue = str.replace("R$", "").replace(/\./g, "").replace(",", ".");

  newValue = parseFloat(newValue);
  return newValue.toFixed(2);
};

export const checkRegisterPlan = (code) => {
  let planSelected = null;

  switch (code) {
    case process.env.REACT_APP_FREE_PLAN_TOKEN:
      planSelected = "free";
      break;

    case process.env.REACT_APP_BASIC_PLAN_TOKEN:
      planSelected = "basic";
      break;
    case process.env.REACT_APP_PRO_PLAN_TOKEN:
      planSelected = "pro";
      break;
    default:
      return false;
  }
  return planSelected;
};
