import React, { useEffect, useState, useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import DefaultLoader from '../jsx/components/Loader'
import UserContext from "../contexts/UserContext";
import { IMAGES_URL } from "../api/Urls";
import { AppSettings } from "../App";
import { UpdateCustomizations } from "../services/ConfigurationsServices";
import { GetOne } from "../services/StoreServices";

export default function LayoutDetails(props) {
  const { user } = useContext(UserContext)
  const [layout, setLayout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [erros, setErros] = useState(null)
  const [banner, setBanner] = useState(null);
  const { register, handleSubmit, watch, setError, setValue, formState: { errors } } = useForm();
  const image = useRef()

  useEffect(() => {
    GetOne(user.store_id, null, setErros, null, 1, true)
      .then(res => {
        image.current = res.layout_banner
        setLayout(res.customization.layout)
        if (res.customization?.layout?.title) setValue('title', res.customization.layout.title)
        if (res.customization?.layout?.subtitle) setValue('subtitle', res.customization.layout.subtitle)
        if (res.layout_banner) setValue('layout_banner', res.layout_banner)
      })
  }, []);

  const imagePreview = (image, set) => {
    let url = URL.createObjectURL(image);
    console.log(url)
    set(url);
  };

  const onSubmit = (data) => {

    let form = new FormData();

    if (!data.layout_banner && data.banner.length === 0) {
      setError('banner', { "type": "required" })
      return
    }
    if (data.banner.length > 0) {
      if (parseInt((data.banner[0].size / (1024 * 1024)).toFixed(2)) >= 5) {
        setError('banner', { "type": "size" })
        return
      }

      form.append("layout_banner", data.banner[0]);
    }

    let obj = {
      colors: AppSettings.store.customization.colors,
      layout: {
        title: data.title,
        subtitle: data.subtitle
      },
      layoutMobile: props.layoutMobile
    }

    form.append("customization", JSON.stringify(obj))



    UpdateCustomizations(AppSettings.store.id, form, props.setNotify, true, true, true)
      .then(res => {
        props.setShowModal(false)
        props.setNotify({ title: 'Sucesso!', message: 'Layout atualizado com sucesso!', type: 'success', visible: true });
        setTimeout(() => {
          window.location.reload()
        }, 2500);
      })

  }


  return (
    <div className="basic-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group col-md-12 text-center">
            {image.current || banner ? (
              <img
                className="img-fluid"
                src={
                  !banner ? `${process.env.REACT_APP_IMAGES_BASE_URL_PROTOCOL}${image.current}` : banner
                }
                alt="image"
                style={styles.banner}
              />
            ) : (
              <div
                className="image-default "
                style={styles.imageDefault}
              >
                <i className="ti-image"></i>
              </div>
            )}
            <label className="d-block mt-3 ml-2">Imagem do banner principal</label>
            <div className="input-group">

              <div className="input-group-prepend">
                <span className="input-group-text">Upload</span>
              </div>
              <div className="custom-file">
                <input
                  accept="image/*"
                  type="file"
                  className="custom-file-input"
                  ref={register}
                  {...register("banner")}
                  onChange={(e) => imagePreview(e.target.files[0], setBanner)}
                />
                <label className="custom-file-label text-left">
                  Selecione...
                </label>
              </div>
            </div>
            {errors.banner && (
              <small className="mt-3 text-danger d-block">
                {errors.banner.type === "required" &&
                  "Por favor, selecione uma imagem!"}
                {errors.banner.type === "size" &&
                  "Por favor, insira uma imagem até 5MB!"}
              </small>
            )}
          </div>
          <div className="form-group col-md-12">
            <label>Título</label>
            <input
              {...register("title", { required: true, minLength: 20, maxLength: 60 })}
              className="form-control text-black"
              defaultValue={layout?.title}
              placeholder="Título central do banner"
            />
            {errors.title &&
              <small className="ml-2 text-danger">
                {errors.title.type === 'required' && 'Por favor, preencha este campo!'}
                {errors.title.type === 'minLength' && 'Este campo deve conter no mínimo 20 caracteres!'}
                {errors.title.type === 'maxLength' && 'Este campo deve conter no máximo 55 caracteres!'}

              </small>}
          </div>

          <div className="form-group col-md-12">
            <label>Subtítulo</label>
            <input
              {...register("subtitle", { minLength: 20, maxLength: 200 })}
              className="form-control text-black"
              defaultValue={layout?.subtitle}
              placeholder="Texto abaixo do título do banner"
            />
            {errors.subtitle &&
              <small className="ml-2 text-danger">
                {errors.subtitle.type === 'minLength' && 'Este campo deve conter no mínimo 20 caracteres!'}
                {errors.subtitle.type === 'maxLength' && 'Este campo deve conter no máximo 60 caracteres!'}

              </small>}
          </div>




          <Button type="submit" className="btn btn-block btn-primary">
            {isLoading ? <DefaultLoader color={'white'} /> : 'Salvar'}
          </Button>
        </div>
      </form>
    </div>
  );
};


const styles = {
  thumb: {
    maxWidth: 250,
    maxHeight: 165,
  },
  imageDefault: {
    height: 200,
    width: 200,
    margin: "auto",
    borderRadius: "20px"
  },
  banner: {
    maxWidth: '100%',
    maxHeight: 200,
    margin: "auto",
    borderRadius: "20px",
  },
  info: {
    fontSize: "1.25rem",
    verticalAlign: "sub",
  },
};
