import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Form,
  Button,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";

import {
  CreateNewCashbackProgram,
  UpdateCashbackProgram,
} from "../../../services/CashbackServices";
import NotificationsContext from "../../../contexts/NotificationsContext";
import { GetAll as GetProducts } from "../../../services/ProductsServices";

export default function CashbackProgramForm({ program, setShowModal }) {
  const { setNotify } = useContext(NotificationsContext);
  const [products, setProducts] = useState([]);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      programName: program ? program.program_name : "",
      description: program ? program.description : "",
      validity: program ? program.validity : "",
      productsWithoutCashback: program ? program.products_without_cashback : [],
      status: program ? program.status : "1",
      cashbackOnPromotions: program ? program.cashback_on_promotions : "0",
      rule: program ? program.rule : "",
      discountByPercentage: program ? program.discount_by_percentage : "",
      progressiveRules: program ? program.progressive_rules || [] : [],
      specific_payment: program ? program.specific_payment || [] : [],
    },
  });
  const selectedRule = watch("rule");
  const [isLoading, setIsLoading] = useState(false);
  const description = useRef();

  const handleEditorChange = (content, editor) => {
    description.current = content;
  };

  const ProgressiveCashbackRules = ({ control }) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: "progressiveRules", // Nome do campo no form
    });

    return (
      <div className="mb-3">
        {fields.map((field, index) => (
          <Row key={field.id}>
            <Col sm={4} className="pr-0">
              <Form.Group controlId={`progressiveRules[${index}].minValue`}>
                <Form.Label>Compras acima de:</Form.Label>
                <Controller
                  control={control}
                  name={`progressiveRules[${index}].minValue`}
                  defaultValue={field.minValue}
                  render={({ field }) => (
                    <Form.Control
                      type="number"
                      placeholder="Valor mínimo"
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col sm={4} className="pr-0">
              <Form.Group
                controlId={`progressiveRules[${index}].cashbackPercentage`}
              >
                <Form.Label>Forma de Pagamento</Form.Label>
                <Controller
                  control={control}
                  name={`progressiveRules[${index}].paymentType`}
                  rules={{ required: true }}
                  defaultValue={field.value || ""}
                  render={({ field }) => (
                    <select
                      className="form-control"
                      value={field.value || ""}
                      {...field}
                    >
                      <option value="">Selecione uma opção...</option>
                      <option value="cash">Dinheiro/Pix</option>
                      <option value="card">Cartão</option>
                    </select>
                  )}
                />
              </Form.Group>
            </Col>

            <Col sm={3} className="pr-0">
              <Form.Group
                controlId={`progressiveRules[${index}].cashbackPercentage`}
              >
                <Form.Label>Cashback</Form.Label>
                <Controller
                  control={control}
                  name={`progressiveRules[${index}].cashbackPercentage`}
                  defaultValue=""
                  render={({ field }) => (
                    <NumberFormat
                      decimalSeparator=","
                      suffix="%"
                      isAllowed={({ value }) => value <= 100}
                      value={field.value}
                      placeholder="Percentual"
                      className="form-control text-black"
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col sm={1} className="d-flex align-items-end">
              <Form.Group>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>Remover</Tooltip>}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => remove(index)}
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                </OverlayTrigger>
              </Form.Group>
            </Col>
          </Row>
        ))}

        <Button
          variant="info"
          size="sm"
          onClick={() => append({ minValue: "", cashbackPercentage: "" })}
        >
          Adicionar Regra
        </Button>
      </div>
    );
  };

  const SpecificPaymentRules = ({ program, control }) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: "specific_payment",
    });

    return (
      <div className="mb-3">
        {fields.map((field, index) => (
          <Row key={field.id}>
            <Col sm={6} className="pr-0">
              <Form.Group controlId={`specific_payment[${index}].paymentType`}>
                <Form.Label>Forma de pagamento</Form.Label>
                <Controller
                  control={control}
                  name={`specific_payment[${index}].paymentType`}
                  rules={{ required: true }}
                  defaultValue={field.type || ""}
                  render={({ field }) => (
                    <select
                      className="form-control"
                      value={field.value || ""}
                      {...field}
                    >
                      <option value="">Selecione uma opção...</option>
                      <option value="cash">Dinheiro/Pix</option>
                      <option value="card">Cartão</option>
                    </select>
                  )}
                />
              </Form.Group>
            </Col>

            <Col sm={5} className="pr-0">
              <Form.Group
                controlId={`specific_payment[${index}].cashbackPercentage`}
              >
                <Form.Label>Cashback:</Form.Label>
                <Controller
                  control={control}
                  name={`specific_payment[${index}].cashbackPercentage`}
                  defaultValue={field.cashbackPercentage}
                  render={({ field }) => (
                    <NumberFormat
                      decimalSeparator=","
                      suffix="%"
                      isAllowed={({ value }) => value <= 100}
                      placeholder="Percentual"
                      className="form-control text-black"
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col sm={1} className="d-flex align-items-end">
              <Form.Group>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>Remover</Tooltip>}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => remove(index)}
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                </OverlayTrigger>
              </Form.Group>
            </Col>
          </Row>
        ))}

        <Button
          variant="info"
          size="sm"
          onClick={() => append({ paymentType: "", cashbackPercentage: "" })}
        >
          Adicionar Regra
        </Button>
      </div>
    );
  };

  const onSubmit = async (data) => {
    if (data.rule === "all_orders" && typeof data.discountByPercentage === 'string') {
      data.discount_by_percentage = data.discountByPercentage.replace("%", "");
    }
    if (data.progressiveRules && data.progressiveRules.length > 0) {
      data.progressiveRules = data.progressiveRules.map((rule) => {
        rule.cashbackPercentage = rule.cashbackPercentage.replace("%", "");
        return rule;
      });
    }
    if (data.specific_payment && data.specific_payment.length > 0) {
      data.specific_payment = data.specific_payment.map((rule) => {
        rule.cashbackPercentage = rule.cashbackPercentage.replace("%", "");
        return rule;
      });
    }
   

    let payload = {
      program_name: data.programName,
      rule: data.rule,
      discount_by_percentage: data.discount_by_percentage || null,
      progressive_rules: data.progressiveRules || null,
      specific_payment: data.specific_payment || null,
      description: description.current || null,
      validity: data.validity,
      status: data.status,
      products_without_cashback: data.productsWithoutCashback,
      cashback_on_promotions: data.cashbackOnPromotions
    };

   
    setIsLoading(true);
    if (program) {
      let res = await UpdateCashbackProgram(program.id, payload);
      if (res) {
        setNotify({
          title: "Sucesso!",
          message: "Programa atualizado com sucesso!",
          type: "success",
          visible: true,
        });
        setShowModal(false);
        setTimeout(() => {
          window.location.href = "/cashback";
        }, 3000);
      }
    } else {
      let res = await CreateNewCashbackProgram(payload);
      if (res) {
        setNotify({
          title: "Sucesso!",
          message: "Programa criado com sucesso!",
          type: "success",
          visible: true,
        });

        setShowModal(false);
        setTimeout(() => {
          window.location.href = "/cashback";
        }, 3000);
      }
    }

    setIsLoading(false);
  };

  const handleSelectProductOrPromotion = (e, field, _type) => {
    field.onChange(e);
    
    setValue("productsWithoutCashback", e)
  };

  useEffect(() => {
    GetProducts(false, false, false, true).then((res) => {
      let options = res.map((product) => {
        return { label: product.name, value: product.id };
      });

      setProducts(options);
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group
        className="d-flex flex-column programName"
        as={Row}
        controlId="programName"
      >
        <Form.Label column>Nome do Programa:</Form.Label>
        <Col sm={12}>
          <Form.Control
            type="text"
            placeholder="Digite o nome do programa"
            {...register("programName", { required: true })}
          />
          <small className="ml-2 text-danger d-none">
            Por favor, informe o nome do programa!
          </small>
        </Col>
      </Form.Group>

      <Form.Group className="d-flex flex-column" as={Row} controlId="rule">
        <Form.Label column>Regra de Cashback:</Form.Label>
        <Col sm={12}>
          <Form.Control as="select" {...register("rule", { required: true })}>
            <option value="">Selecione uma regra</option>
            <option value="all_orders">Cashback em todos os pedidos</option>
            <option value="progressive">Cashback progressivo</option>
            <option value="specific_payment">
              Cashback para pagamento específico
            </option>
          </Form.Control>
        </Col>
      </Form.Group>

      {selectedRule === "all_orders" && (
        <Form.Group
          className="d-flex flex-column"
          as={Row}
          controlId="percentage"
        >
          <Form.Label column>Percentual</Form.Label>
          <Col sm={12}>
            <Controller
              name="discountByPercentage"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <NumberFormat
                  decimalSeparator=","
                  suffix="%"
                  isAllowed={({ value }) => value <= 100}
                  placeholder="Percentual"
                  className="form-control text-black"
                  {...field}
                />
              )}
            />
          </Col>
        </Form.Group>
      )}

      {selectedRule === "progressive" && (
        <ProgressiveCashbackRules program={program} control={control} />
      )}

      {selectedRule === "specific_payment" && (
        <Form.Group
          className="d-flex flex-column"
          as={Row}
          controlId="paymentMethod"
        >
          <Col sm={12}>
            <SpecificPaymentRules program={program} control={control} />
          </Col>
        </Form.Group>
      )}

      <Form.Group
        className="d-flex flex-column validity"
        as={Row}
        controlId="validity"
      >
        <Form.Label column>Validade para utilização</Form.Label>
        <Col sm={12}>
          <Controller
            control={control}
            name="validity"
            rules={{ required: true }}
            render={({ field }) => (
              <Form.Control
                type="datetime-local"
                placeholder="Selecione a validade"
                {...field}
              />
            )}
          />
          <small className="ml-2 text-danger d-none">
            Por favor, informe uma data de validade!
          </small>
        </Col>
      </Form.Group>

      <Form.Group
        className="d-flex flex-column"
        as={Row}
        controlId="restrictions"
      >
        <Form.Label column>Descrição do programa</Form.Label>
        <Col sm={12}>
          <Editor
            apiKey="kcr3ptya1v4c2agur79afwoz6zjguh0ta02ztbjgpn6a43hn"
            initialValue={program.description || ""}
            init={{
              height: 350,
              menubar: false,
              placeholder:
                "Informações úteis sobre o programa, essas informações serão apresentadas no site...",
              plugins: [
                "advlist autolink lists link charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect |link | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help ",
            }}
            {...register("description")}
            onEditorChange={handleEditorChange}
          />
          {/* <Form.Control
              as="textarea"
              rows={5}
              placeholder="Informações úteis sobre o programa, essas informações serão apresentadas no site..."
              {...register("description")}
            /> */}
        </Col>
      </Form.Group>
      <Form.Group className="d-flex flex-column" as={Row} controlId="products">
        <Form.Label column>Produtos que não ganham cashback</Form.Label>
        <Controller
          name={"productsWithoutCashback"}
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <Select
              className="rounded-form-control  mx-3"
              placeholder={`Selecione um produto...`}
              cacheOptions
              options={products}
              isMulti
              onChange={(e) =>
                handleSelectProductOrPromotion(e, field, "product")
              }
              value={field.value}
            />
          )}
        />
        
      </Form.Group>
      <Form.Group
        className="d-flex flex-column"
        as={Row}
        controlId="restrictions"
      >
        <Form.Label column>Cashback em promoções</Form.Label>
        <Col sm={12}>
          <select className="form-control" {...register("cashbackOnPromotions")}>
            <option value="0">Não</option>
            <option value="1">Sim</option>
          </select>
        </Col>
      </Form.Group>
      <Form.Group
        className="d-flex flex-column"
        as={Row}
        controlId="restrictions"
      >
        <Form.Label column>Status</Form.Label>
        <Col sm={12}>
          <select className="form-control" {...register("status")}>
            <option value="1">Ativo</option>
            <option value="0">Desativado</option>
          </select>
        </Col>
      </Form.Group>

      <Button
        variant="primary"
        className="btn-block"
        type="submit"
        disabled={!isLoading ? false : true}
      >
        {!isLoading && !program
          ? "Criar Programa de Cashback"
          : program
          ? "Atualizar"
          : "Aguarde..."}
      </Button>
    </Form>
  );
}
