import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTablev2 from "../jsx/components/Datatable";
import DefaultLoader from "../jsx/components/Loader";
import AlertResponses from "../jsx/components/Alert";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import ModalComponent from "../jsx/components/Modal";
import Tutorial from "../jsx/components/Tutorial";
import { GetTemplates } from "../services/WaBottyServices";
import WhatsappTemplatesUpdate from "./WhatsappTemplatesUpdate";
import { decodeText } from "../helpers/Helpers";

export default function WhatsappTemplates(props) {
  const [whatsappTemplates, setWhatsappTemplates] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const data = useRef();

  useEffect(() => {
    let payload = `?uid=${props.account.uid}`;
    GetTemplates(payload).then((templates) => {
      setWhatsappTemplates(templates);
      setIsLoading(false);
    });
  }, []);

  const modalProps = {
    title: "Atualizar notificações",
    content: (
      <WhatsappTemplatesUpdate
        data={data.current}
        setNotify={setNotify}
        setShowModal={setShowModal}
      />
    ),
    size: "xl",
    btnLabel: "Salvar",
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  };

  const actions = (row) => {
    return (
      <div className="d-flex">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-bottom`}>Editar</Tooltip>}
        >
          <Button
            type="button"
            className="btn btn-info shadow btn-xs sharp mr-1"
            onClick={() => {
              data.current = row;
              setShowModal(true);
            }}
          >
            <i className="fa fa-pencil"></i>
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  const columns = [
    {
      name: "Nome",
      selector: (row) => (
        <Link
          to="#"
          onClick={() => {
            data.current = row;
            setShowModal(true);
          }}
        >
          {row.name}
        </Link>
      ),
      sortable: true,
      maxWidth: "20%",
    },

    {
      name: "Mensagem",
      selector: (row) => decodeText(row.format),
      maxWidth: "60%",
    },

    {
      name: "Ações",
      selector: (row) => actions(row),
      maxWidth: "10%",
      allowOverflow: true,
    },
  ];

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <>
          <Tutorial videoId={"FsYXmvwkpTM"} padding={"0 15px"} />
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
              <div className="col-sm-12 col-md-8">
                <h2 className="pl-4">Notificações</h2>
              </div>
            </div>
          </div>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}

          <DataTablev2
            data={whatsappTemplates}
            columns={columns}
            title="  "
            selectable={false}
            filterByField={{
              labels: ["Nome"],
              fields: ["name"],
            }}
          />

          {progress && <ProgressBarComponent />}

          {showModal && <ModalComponent props={modalProps} />}
        </>
      )}
    </>
  );
}
