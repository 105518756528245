import { api } from '../api/Middleware'
import { STORE_URL, CUSTOMIZATIONS_URL, PLACES_API, FRONT_REVALIDATE_DATA } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'
import { AppSettings } from '../App'


export const GetData = async (store, setValue, setNotify, customizations) => {
    try {
        let response = await api.get(STORE_URL);

        if (response.status === 200) {
            if (response.data.length > 0) {
                store.current = response.data[0]
                if (setValue !== null) {
                    setValue('phone', response.data[0].phone);
                    setValue('cellPhone', response.data[0].cellPhone);
                    setValue('monday', response.data[0].monday === 'Fechado' ? '' : response.data[0].openingHours.monday);
                    setValue('tuesday', response.data[0].tuesday === 'Fechado' ? '' : response.data[0].openingHours.tuesday);
                    setValue('wednesday', response.data[0].wednesday === 'Fechado' ? '' : response.data[0].openingHours.wednesday);
                    setValue('thursday', response.data[0].thursday === 'Fechado' ? '' : response.data[0].openingHours.thursday);
                    setValue('friday', response.data[0].friday === 'Fechado' ? '' : response.data[0].openingHours.friday);
                    setValue('saturday', response.data[0].saturday === 'Fechado' ? '' : response.data[0].openingHours.saturday);
                    setValue('sunday', response.data[0].sunday === 'Fechado' ? '' : response.data[0].openingHours.sunday);
                }

            }
        }

    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.configurations.getStore, type: 'error', visible: true });
    }

    if (customizations !== null) {
        try {
            let response = await api.get(CUSTOMIZATIONS_URL);
            if (response.status === 200) {
                customizations.current = response.data[0]
            }

        } catch (error) {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.configurations.getCustomizations, type: 'error', visible: true });
        }
    }

}


export const CreateStore = async (data, setNotify, returnData = false, mainStore = false) => {
    try {
        let response = await api.post(mainStore ? STORE_URL + 'create_main_store/' : STORE_URL, data);
        if (response.status === 200) {
            if (returnData) {
                return response.data;
            } else {
                setNotify({ title: 'Sucesso!', message: 'Informações cadastradas com sucesso!', type: 'success', visible: true });
                setTimeout(() => {
                    window.location.href = window.location.origin + '/stores'
                }, 3000)
            }
        }

    } catch (error) {
        if(returnData) throw error
        if (error?.response?.status === 401) {
            setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
        } else {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
        }

    }

}

export const UpdateStore = async (id, data, setNotify, returnData = false, cleanFrontCache = false, redirectTo=false) => {
    try {
        let url = cleanFrontCache ? STORE_URL + id + '/?cc=1' : STORE_URL + id + '/'
        let response = await api.put(url, data);
        if (response.status === 200) {
            if (returnData) return response
            setNotify({ title: 'Sucesso!', message: 'Dados atualizados com sucesso!', type: 'success', visible: true });

            
            setTimeout(() => {
                window.location.href =  window.location.origin + (redirectTo ? redirectTo : '/stores')
            }, 2000)
        }

    } catch (error) {
        if (returnData) return error
        return ERROR_MESSAGES.products.update;
        // setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }
}

export const CreateCustomization = async (data, setNotify, returnData = false) => {
    try {
        let response = await api.post(STORE_URL, data);
        if (response.status === 200 || response.status === 201) {
            if (returnData) {
                return response.data;
            } else {
                setNotify({ title: 'Sucesso!', message: 'Configurações cadastradas com sucesso!', type: 'success', visible: true });
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            }

        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }

}

export const GetCustomizations = async (customizations) => {
    try {
        let response = await api.get(CUSTOMIZATIONS_URL);
        if (response.status === 200) {
            return response.data[0]
        }

    } catch (error) {

    }

}

export const UpdateCustomizations = async (id, data, setNotify, returnData = false, cleanCache = false, cleanFrontCache = false) => {
    try {
        let url = cleanCache ? STORE_URL + id + '/?cc=1' : STORE_URL + id + '/'
        let response = await api.put(url, data);
        if (response.status === 200) {

            //store/get_front_data/?url=layout2.pralevar.app&cc=1
            if (cleanFrontCache) await api.get(FRONT_REVALIDATE_DATA + AppSettings.store.id);

            if (returnData) {
                return response.data;
            } else {
                setNotify({ title: 'Sucesso!', message: 'Dados atualizados com sucesso!', type: 'success', visible: true });
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            }
        }

    } catch (error) {
        if (returnData) {
            return error;
        } else {
            //if(error.status !== 401) throw error.response;
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.configurations.updateCustomizations, type: 'error', visible: true });
        }
    }
}

export const FindPlace = async (text, setNotify) => {
    try {
        let userLatLng = ''
        if(window.userLatLng) userLatLng = `at=${window.userLatLng}&`
        let response = await api.get(`${PLACES_API}${userLatLng}lang=pt&in=countryCode:BRA&limit=5&apiKey=${process.env.REACT_APP_HERE_API_KEY}&q=${text}`);

        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        console.log(error)
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
        if (error.response.status !== 401) {
            //return Alert.alert('Oops!', ERROR_MESSAGES.generic);
        }
    }
}