import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import MoonLoader from "react-spinners/MoonLoader";
import ModalComponent from "../../Modal";
import Plans from "../../ModalData/Plans";
import PaymentForm from "../../PaymentForm";
import AlertResponses from "../../Alert";
import Swal from "sweetalert2";

const StepThree = (props) => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const next = useRef();
  const prev = useRef();
  const submit = useRef();
  const [showModal, setShowModal] = useState(false);
  const [planSelected, setPlanSelected] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  const modalProps = {
    title: "Pagamento",
    content: (
      <PaymentForm
        setShowModal={setShowModal}
        planSelected={planSelected}
        setNotify={setNotify}
        from={"register"}
      />
    ),
    btnLabel: "Salvar",
    showModal: true,
    setShowModal: setShowModal,
    showFooter: false,
    size: "xl",
  };

  const selectButton = (plan, recurrencySelected) => {
    return (
      <div className="d-flex justify-content-center mt-1 ml-2">
        <div className="custom-control custom-radio mr-3">
          <input
            type="radio"
            id={`plan-${plan.id}`}
            defaultValue={plan.id}
            className="custom-control-input"
            {...register("planSelected", { required: true })}
            onClick={() => {
              if (plan.id !== 1 && plan.id !== 4 && plan.id !== 5) {
                //setShowModal(true);
                plan.recurrencySelected = recurrencySelected
                setPlanSelected(plan);
                setShowModal(true)
              }
            }}
          />
          <label className="custom-control-label" htmlFor={`plan-${plan.id}`}>
            Quero este plano
          </label>
        </div>
      </div>
    );
  };

  const onSubmit = (data) => {
    if (data.planSelected == 1) {
      localStorage.removeItem("r_storeData");
      localStorage.removeItem("r_userData");
      setRedirecting(true);
      setTimeout(() => {
        window.location.pathname = "/"
        window.location.href = "/";
      }, 3000);
      return;
    } else if (data.planSelected == 4 || data.planSelected == 5) {
      Swal.fire({
        title: "Ótima escolha!!",
        html:
          "Nossa equipe comercial entrará em contato com você em até 1hora. <br> " +
          "<b>Enquanto isto, você será redirecionado ao painel com o plano Gratuito.</b>",
        icon: "success",
        buttons: true,
        confirmButtonText: `Entendi`,
        showCancelButton: false,
        confirmButtonColor: "#2bc155",
      }).then((value) => {
        if (value.isConfirmed) {
          localStorage.removeItem("r_storeData");
          localStorage.removeItem("r_userData");
          setRedirecting(true);
          setTimeout(() => {
            window.location.pathname = "/"
            window.location.href = "/";
          }, 2500);
        }
      });
    }
  };

  useEffect(() => {
    document.querySelectorAll("button").forEach((button) => {
      if (button.textContent.includes("Next")) {
        next.current = button;
        button.style.visibility = "hidden";
      }
      if (button.textContent.includes("Prev")) {
        prev.current = button;
        button.style.visibility = "hidden";
      }
    });
  }, []);

  return (
    <section>
      <div className={`payment-in-process ${!redirecting ? "d-none" : ""}`}>
        <MoonLoader color="#fff" loading={true} />
        <h4>Você está sendo redirecionado para seu painel...</h4>
      </div>
      <h4 className="card-title mb-4 text-center">
        Escolha o melhor plano para o seu negócio
      </h4>
      <div className="row w-100">
        <form onSubmit={handleSubmit(onSubmit)} id="stepOne" className="w-100">
          <Plans
            planSelected={planSelected}
            setPlanSelected={setPlanSelected}
            setShowModal={setShowModal}
            from={"register"}
            selectButton={selectButton}
            planCode={props.planCode}
          />
          {errors.planSelected && (
            <small className="text-danger ml-4">
              {errors.planSelected.type === "required" &&
                "Por favor, selecione um plano para continuar."}
            </small>
          )}
          <div className="col-lg-12 text-center">
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={() => prev.current.click()}
            >
              Voltar
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-3"
              ref={(e) => (submit.current = e)}
            >
              Avançar
            </button>
          </div>
        </form>
      </div>
       {showModal && <ModalComponent props={modalProps} />}
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
    </section>
  );
};

export default StepThree;
