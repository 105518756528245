import React, { useMemo, useState, useContext, useRef } from "react";
import { Create, Update } from "../services/DeliveryServices";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Button } from "react-bootstrap";
import DefaultLoader from "../jsx/components/Loader";
import UserContext from "../contexts/UserContext";
import DeliveryFeePerAreaDrawing from "./DeliveryFeePerAreaDrawing";
import DeliveryFeeBySearchArea from "./DeliveryFeeBySearchArea";
import Swal from "sweetalert2";

export default function DeliveryFeeDetails(props) {
  const { user } = useContext(UserContext);
  const optionSelected = props.data?.drawn_area
    ? Object.values(props.data.drawn_area).length
    : false;
  const [deliveryFeeBy, setDeliveryFeeBy] = useState(
    optionSelected ? "drawing" : "search"
  );
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const deliveryArea = useRef(
    props.data && props.data.delivery_area ? props.data.delivery_area : []
  );
  const drawnAreaObject = useRef(
    props.data && props.data.drawn_area ? props.data.drawn_area : []
  );
  const deliveryCity = useRef(
    props.data && props.data.delivery_city ? props.data.delivery_city : null
  );

  const onSubmit = (value) => {
    setIsLoading(true);
    let fee = document.querySelector('input[name="fee"]').value;

    if (!fee) {
      setError("fee", {
        message: "Por favor, preencha corretamente este campo!",
        type: "required",
      });
      return;
    }

    if (!deliveryArea.current.length) {
      Swal.fire("Atenção!", "Selecione uma area de entrega válida.", "warning");
      return;
    }

    if (value.fee) {
      value.fee = value.fee.replace("R$", "").replace(",", ".");
    } else {
      value.fee = props.data.fee;
    }

    let data = {
      area_name: value.areaName,
      delivery_city: deliveryCity.current,
      delivery_area: deliveryArea.current,
      fee: value.fee,
      store_id: user.store_id,
    };

    if (drawnAreaObject.current.length > 0)
      data["drawn_area"] = drawnAreaObject.current;

    if (props.data.id) {
      Update(props.data.id, data, props.setNotify, props.setShowModal, setIsLoading);
    } else {
      Create(data, props.setNotify, setError, setIsLoading, props.setShowModal);
    }
  };

  const DeliveryFeeBySearchAreaMemo = useMemo(() => {
    return (
      <DeliveryFeeBySearchArea
        data={props.data}
        deliveryCity={deliveryCity}
        deliveryArea={deliveryArea}
        setValue={setValue}
      />
    );
  }, []);

  const DeliveryFeePerAreaDrawingMemo = useMemo(() => {
    return (
      <DeliveryFeePerAreaDrawing
        data={props.data}
        deliveryCity={deliveryCity}
        deliveryArea={deliveryArea}
        drawnAreaObject={drawnAreaObject}
        setNotify={setNotify}
      />
    );
  }, []);

  return (
    <div className="basic-form" style={{ minHeight: 650 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group col-md-12">
          <div className="form-group col-md-12 px-0">
            <div className="custom-control custom-radio mb-2">
              <input
                id="drawing"
                name="inputRadio"
                type="radio"
                className="custom-control-input"
                defaultChecked={optionSelected ? true : false}
                onClick={() => user.plan_id > 1 && setDeliveryFeeBy("drawing")}
                disabled={user.plan_id < 2 ? true : false}
              />
              <label className="custom-control-label" htmlFor="drawing">
                Desenhar área de entrega{" "}
                {user.plan_id < 2 ? (
                  <i
                    className="ti-lock text-primary ml-2"
                    aria-hidden="true"
                  ></i>
                ) : (
                  ""
                )}
              </label>
            </div>
            <div className="custom-control custom-radio mb-2">
              <input
                id="search"
                name="inputRadio"
                type="radio"
                className="custom-control-input"
                defaultChecked={!optionSelected ? true : false}
                onClick={() => setDeliveryFeeBy("search")}
              />
              <label className="custom-control-label" htmlFor="search">
                Buscar bairro ou cidade..
              </label>
            </div>
          </div>
          <div className="form-group col-md-12 px-0">
            <input
              className="form-control text-black mr-3"
              placeholder="Nome da área"
              defaultValue={props.data?.area_name ? props.data.area_name : ""}
              {...register("areaName", { required: true })}
            />
          </div>
          {errors.areaName && (
            <small className="ml-2 mb-2 text-danger">
              {errors.areaName.type === "required" &&
                "Por favor, defina um nome para esta área de entrega!"}
            </small>
          )}
          <div className="form-group col-md-12 pl-0 mt-2 d-flex">
            <NumberFormat
              defaultValue={
                (props.data && props.data.fee !== null) || props.data.fee !== ""
                  ? props.data.fee
                  : ""
              }
              decimalSeparator=","
              prefix="R$"
              placeholder="Digite o valor..."
              className="col-md-8 col-sm-12 form-control text-black mr-3"
              {...register("fee")}
            />

            <Button
              type="submit"
              className="col-md-4 col-sm-12 btn btn-block btn-primary"
              disabled={isLoading}
            >
              {isLoading ? <DefaultLoader color={"white"} /> : "Salvar"}
            </Button>
          </div>
          {errors.fee && (
            <small className="ml-2 mb-2 text-danger">
              {errors.fee.type === "required" &&
                "Por favor, insira a taxa de entrega!"}
            </small>
          )}
        </div>
      </form>
      {deliveryFeeBy === "drawing"
        ? DeliveryFeePerAreaDrawingMemo
        : DeliveryFeeBySearchAreaMemo}
    </div>
  );
}
