import React from "react";
import ReactApexChart from "react-apexcharts";

export default function ThreeCharts({ label1, label2, label3, label4, label5, data1, data2, data3, data4, data5 }) {

  const options = {
    series: [
      {
        name: label1,
        data: data1
      },
      {
        name: label2,
        data: data2
      },
      {
        name: label3,
        data: data3
      },
      {
        name: label4,
        data: data4
      },
      {
        name: label5,
        data: data5
      }
    ],
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: false
    },

    stroke: {
      width: [2, 3, 4],

    },

    legend: {
      tooltipHoverFormatter: function (val, opts) {

        let calc = opts.w.globals.series[opts.seriesIndex].reduce((p, c) => (p && c) !== (0 || null) && p + c) ? opts.w.globals.series[opts.seriesIndex].reduce((p, c) => (p && c) !== (0 || null) && p + c) : 0
        return val + ' - ' + calc + ''
      }
    },
    markers: {
      shape: "circle",
    },
    xaxis: {
      categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      labels: {
        style: {
          colors: "#3e4954",
          fontSize: "13px",
          fontFamily: "Poppins",
          fontWeight: 100,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          // var val = Math.abs(value)
          // if (val >= 1000) {
          //   val = (val / 1000).toFixed(0) + ' K'
          // }
          return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        }
      }
    },

    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val
            }
          }
        }
        ,
        {
          title: {
            formatter: function (val) {
              return val
            }
          }
        }
        ,
        {
          title: {
            formatter: function (val) {
              return val
            }
          }
        }
      ]
    },


  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={options.series}
        type="area"
        height={350}
      />
    </div>
  );
}