import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import DataTablev2 from '../jsx/components/Datatable'
import { GetAll, Delete, ChangeStatus } from '../services/DeliveryServices'
import NumberFormat from 'react-number-format';
import Swal from "sweetalert2";
import DefaultLoader from '../jsx/components/Loader'
import AlertResponses from '../jsx/components/Alert'
import ProgressBarComponent from '../jsx/components/ProgressBar'
import ModalComponent from '../jsx/components/Modal'
import DeliveryFeeDetails from './DeliveryFeeDetails'
import Tutorial from "../jsx/components/Tutorial";

export default function DeliveryFee() {

  const data = useRef(null);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const [erros, setErros] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    GetAll(setDeliveryFee, setErros, setIsLoading);
  }, [])


  const deleteRows = (state) => {
    if (state.selectedRows && state.selectedRows.length > 0) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  }

  const modalProps = {
    title: 'Taxa de Entrega',
    content: <DeliveryFeeDetails data={data.current} setNotify={setNotify} setShowModal={setShowModal} />,
    btnLabel: 'Salvar',
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
    size: "xl"
  }

  const status = {
    0: {
      id: 0,
      label: "Desativado",
      type: "light"
    },
    1: {
      id: 1,
      label: "Ativo",
      type: "success"
    },
  }

  const BadgeComponentMemo = (row) => {
    return useMemo(() => {
      return (
        <DropdownButton
          variant=''
          style={styles.dropdown}
          title={status[row.status].label}
          className={`badge badge-${status[row.status].type} badge-sm light badge-delivery-status badge-${row.id}`}
          onSelect={(e) => {
            ChangeStatus({ id: row.id, status: e }, setNotify, setProgress).then(res => {
              if (res) {
                document.querySelector(`.badge-${row.id}`).classList.remove(`badge-${status[row.status].type}`)
                document.querySelector(`.badge-${row.id}`).classList.add(`badge-${status[e].type}`)
                document.querySelector(`.badge-${row.id} button`).innerText = status[e].label
              }
            })

          }}
        >
          {Object.values(status).map((value, i) => i !== 5 &&
            <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
        </DropdownButton>
      )
    }, [deliveryFee])
  }

  const columns = [
    {
      name: 'Valor',
      selector: (row) => <Link to="#" onClick={() => {
        data.current = row
        setShowModal(true)
      }}>
        <NumberFormat
          displayType="text"
          decimalScale={2}
          defaultValue={row.fee}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$"
          fixedDecimalScale={true}
        /></Link>,
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Nome da área',
      selector: (row) => <Link to="#" onClick={() => {
        data.current = row
        setShowModal(true)
      }}> {row.area_name}</Link>,
      sortable: true,
      maxWidth: '70%'
    },
   
    {
      name: 'Status',
      selector: (row) => BadgeComponentMemo(row),
      sortable: true,
      maxWidth: '15%',
      allowOverflow: true
    },
  ];

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
          <Tutorial videoId={'F9-8Sx3NFIc'} padding={'0 15px'}/>
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                <div className="col-xl-4 col-xxl-5 col-sm-12">
                  <h2>Taxa de entrega</h2>
                </div>

                <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                  {showDeleteButton &&

                    <Button className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() =>
                        Swal.fire({
                          title: "Você tem certeza?",
                          text:
                            "Todos os usuários selecionados serão deletados. Esta ação é irreversível!",
                          icon: "warning",
                          buttons: true,
                          confirmButtonText: `Continuar a ação`,
                          cancelButtonText: `Cancelar`,
                          showCancelButton: true,
                          confirmButtonColor: 'red'
                        }).then((value) => {
                          if (value.isConfirmed) Delete(selectedRows, setNotify, setProgress)
                        })
                      }
                    >
                      <span>DELETAR SELECIONADOS</span>
                    </Button>
                  }
                  <Button className="i-false btn btn-primary mb-4 float-right"
                    onClick={() => { data.current = ''; setShowModal(true) }}>
                    <span>ADICIONAR</span>
                  </Button>
                </div>

              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }

            <DataTablev2
              data={deliveryFee}
              columns={columns}
              title="  "
              selectable={true}
              onSelectedRowsChange={deleteRows}
              showDeleteButton={showDeleteButton}
              filterDefaultField="city"
              filterByField={{ labels: ['Cidade', 'Bairro'], fields: ['city', 'neighborhood'] }}
            />
            {progress && <ProgressBarComponent />}
            {showModal && <ModalComponent props={modalProps} />}
          </>
      }
    </>
  );
}

const styles = {
  photoDefault: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    background: '#efefef'
  },
  i: {
    fontSize: '18px',
    color: '#3d4465'
  },
}