import React, { useEffect, useState } from "react";
import DefaultLoader from "../jsx/components/Loader";
import { GetBottyReports } from "../services/CampaignServices";
import ReactApexChart from "react-apexcharts";

export default function CampaignAnalytics({ data, setNotify, setShowModal }) {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState({ series: [], labels: [] });

  const options = {
    chart: {
      type: "donut",
    },
    labels: reports.labels,
    colors: ["#20c997", "#ff0a38"],
    legend: {
      show: false,
      position: "bottom",
    },
   
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const sumResendData = (arr) => {
    const sum = arr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    return sum
  }

  useEffect(() => {
    if (data) {
      GetBottyReports(data.id, null, true).then((res) => {
        let seriesResend0 = res
          .filter((item) => item.resend === 0)
          .map((item) => item.count);

        let seriesResend1 = res
          .filter((item) => item.resend === 1)
          .map((item) => item.count);

        let labels = res
          .filter((item) => item.resend === 0)
          .map((item) => (item.status === 3 ? "Sucesso" : "Falha"));

        setReports({
          labels: labels,
          seriesResend0: seriesResend0,
          seriesResend1: seriesResend1,
        });
        setIsLoading(false);
      });
    }
  }, []);

  return isLoading ? (
    <div>
      Buscando dados da campanha{" "}
      <DefaultLoader
        isLoading={true}
        cs="display:inline;vertical-align: sub;"
      />
    </div>
  ) : (
    <div id="chart">
      <div>
        <div className="row align-items-center">
          <div className="col-sm-6">
            <h6 className="text-center">Mensagens enviadas</h6>
            <ReactApexChart
              options={options}
              series={reports.seriesResend0}
              height={215}
              type="donut"
            />
          </div>
          <div className="col-sm-6 mb-sm-0 mb-3 text-center">
            <h3 className="fs-28 text-black font-w600">
              {data.campaign_list__customers_total}
            </h3>
            <p className="fs-14">Clientes na lista</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 mb-md-0 mb-3">
            <div className="p-3 border rounded">
              <h3 className="fs-32 text-black font-w600 mb-1">
                {reports.seriesResend0[0] || 0}
              </h3>
              <span className="fs-16">Sucesso</span>
            </div>
          </div>
          <div className="col-sm-6 mb-md-0 mb-3">
            <div className="p-3 border rounded">
              <h3 className="fs-32 text-black font-w600 mb-1">
                {reports.seriesResend0[1] || 0}
              </h3>
              <span className="fs-16">Falha</span>
            </div>
          </div>
        </div>
      </div>
      {
        reports.seriesResend1 && reports.seriesResend1.length > 0 &&
        <div className="mt-4">
          <hr></hr>
        <div className="row align-items-center">
          {console.log(reports)}
          <div className="col-sm-6">
          <h6 className="text-center">Mensagens Reenviadas</h6>
            <ReactApexChart
              options={options}
              series={reports.seriesResend1}
              height={215}
              type="donut"
            />
          </div>
          <div className="col-sm-6 mb-sm-0 mb-3 text-center">
           
          <h3 className="fs-28 text-black font-w600">
              {sumResendData(reports.seriesResend1)}
            </h3>
            <p className="fs-14">Total de mensagens</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 mb-md-0 mb-3">
            <div className="p-3 border rounded">
              <h3 className="fs-32 text-black font-w600 mb-1">
                {reports.seriesResend1[0] || 0}
              </h3>
              <span className="fs-16">Sucesso</span>
            </div>
          </div>
          <div className="col-sm-6 mb-md-0 mb-3">
            <div className="p-3 border rounded">
              <h3 className="fs-32 text-black font-w600 mb-1">
                {reports.seriesResend1[1] || 0}
              </h3>
              <span className="fs-16">Falha</span>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
}
