import React, { useState, useMemo} from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';

const DataTablev2 = ({ data, columns, title, selectable, onSelectedRowsChange, filterOn, filterByField, filterDefaultField }) => {

    const [selectableRow, setSelectableRow] = useState(selectable);
    const [filterText, setFilterText] = useState('');
    const [filterBy, setFilterBy] = useState(filterDefaultField !== undefined ? filterDefaultField : 'name');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const names = ['name', 'first_name', 'user__first_name', 'last_name', 'user__last_name']

    const FilterComponent = ({ filterText, onFilter, onClear, setFilter }) => (

        <>

            <div className="col-auto" style={{ zIndex: 1 }}>
                <div className="input-group mb-2">
                    <i className="ti-search" style={styles.icon}></i>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={`Filtrar por ${filterBy === filterByField.fields[0] || names.includes(filterByField.fields[0]) ? filterByField.labels[0] : filterByField.labels[1]}`}
                        value={filterText}
                        onChange={onFilter}
                        style={styles.inputSearch}
                        autoFocus
                    />
                    <div className="dropdown">
                        <DropdownButton
                            id='dropdown-button-drop-right'
                            variant=''
                            title=''
                            style={styles.dropdownArrow}
                            onSelect={setFilter}
                        >
                            <Dropdown.Item eventKey={filterByField.fields[0]}>Filtrar por {filterByField.labels[0]}</Dropdown.Item>
                            {
                                filterByField.fields.length > 1 &&
                                <Dropdown.Item eventKey={filterByField.fields[1]}>Filtrar por {filterByField.labels[1]}</Dropdown.Item>
                            }

                            {/* <Dropdown.Item eventKey="categorie_name">Filtrar por Categoria</Dropdown.Item> */}
                        </DropdownButton>
                    </div>
                    {/* <div className="input-group-append cursor-pointer" onClick={onClear}>
                        <div className="input-group-text">
                            <i className="ti-close"></i>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setFilterBy(filterDefaultField !== undefined ? filterDefaultField : 'name');
            }
        };

        return <FilterComponent
            onFilter={e => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            setFilter={e => setFilterBy(e)} />;
    }, [filterText, filterBy]);

    createTheme('solarized', {
        text: {
            primary: '#268bd2',
            secondary: '#2aa198',
        },
        background: {
            default: '#002b36',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    });

    const customNoDataComponent = () => {
        return (
          <div className="mb-4">Nenhuma informação disponível no momento...</div>
        );
      };

    const options = {
        title: title,
        columns: columns,
        data: filterText ? data.filter(item => item[filterBy] && item[filterBy].toLowerCase().includes(filterText.toLowerCase())) :
            data,
        pagination: true,
        fixedHeader: true,
        subHeader: true,
        subHeaderComponent: subHeaderComponentMemo,
        paginationResetDefaultPage: resetPaginationToggle,
        selectableRowsVisibleOnly: true,
        onSelectedRowsChange: onSelectedRowsChange,
        noDataComponent: customNoDataComponent(),
    }

    if (selectableRow) options['selectableRows'] = selectable;

    return (
        <div className="container-fluid h-100 pt-0">
            <DataTable
                {...options}
                noDataText={"Nenhum registro disponível no momento..."}
            />
        </div>
    )
}

const styles = {
    inputSearch: {
        paddingLeft: '2.25rem',
        borderTopLeftRadius: '1.25rem',
        borderBottomLeftRadius: '1.25rem',
    },
    icon: {
        position: 'absolute',
        left: '2.5%',
        top: '28%',
        bottom: 0,
        zIndex: 10,
        margin: 'auto',
    },
    dropdownArrow: {
        position: 'absolute',
        right: '0',
        maxWidth: '40px',
        zIndex: '1000'
    }
}

export default DataTablev2;
