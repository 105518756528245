import React, { useState, useRef } from "react";

import {
  Accordion,
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { useForm } from "react-hook-form";
import { Picker } from "emoji-picker-element";
import { AddAutoReply, UpdateAutoReply } from "../services/WaBottyServices";

export default function WhatsappActionsUpsert(props) {
  const id = props.data?.id;
  const messageData =
    id && props.insertOrUpdate.current === "update"
      ? JSON.parse(props.data.message)
      : false;

  const picker = useRef(new Picker());

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const emojiPicker = (e) => {
    if (e.detail) {
      document.querySelector("#message").value += e.detail.unicode;
      removeEmojiPicker();
    }
    //
  };

  const removeEmojiPicker = () => {
    let element = document.querySelector("emoji-picker");
    if (element) {
      element.remove();
    }
    picker.current.removeEventListener("emoji-click", emojiPicker);
  };

  const handleEmojiPicker = () => {
    picker.current.addEventListener("emoji-click", emojiPicker);

    let element = document.querySelector("emoji-picker");
    if (element) {
      removeEmojiPicker();
    } else {
      document.querySelector(".picker-wrapper").append(picker.current);
    }
  };

  const onSubmit = async (data) => {
    let btn = document.querySelector("button[type=submit]");
    btn.setAttribute("disabled", "true");

    let payload = {
      uid: props.data.uid,
      keywords: data.keywords,
      match: data.match,
      message: data.message,
      message_type: data.message_type,
      name: data.name,
      pause_bot: data.pause_bot,
    };

    if (props.insertOrUpdate.current === "insert") {
      payload.aid = props.data.id;

      let response = await AddAutoReply(payload, props.setNotify);

      props.setNotify({
        title: "Sucesso!",
        message: "Resposta automática cadastrada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.href = "/whatsapp";
      }, 1000);
    } else if (id) {
      payload.id = id;
      payload.aid = props.data.account;
      let response = await UpdateAutoReply(payload, props.setNotify);

      props.setNotify({
        title: "Sucesso!",
        message: "Resposta automática atualizada com sucesso!",
        type: "success",
        visible: true,
      });
      setTimeout(() => {
        window.location.href = "/whatsapp";
      }, 1000);
    }
    btn.removeAttribute("disabled");
  };

  return (
    <div>
      <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Nome da ação</label>
            <input
              {...register("name", {
                required: true,
              })}
              className="form-control text-black"
              defaultValue={id ? props.data.name : ""}
              placeholder="ex: Boas vindas"
            />
            {errors.name && (
              <small className="ml-2 text-danger">
                {errors.name.type === "required" && "Por favor, insira o nome!"}
              </small>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>Detecção por</label>
            <select
              className="form-control"
              defaultValue={id ? props.data.match : "3"}
              {...register("match", {
                required: true,
              })}
            >
              <option value={"3"}>Contém as palavras chaves</option>
              <option value={"4"}>Expressão regular</option>
            </select>
            {errors.match && (
              <small className="ml-2 text-danger">
                {errors.match.type === "required" &&
                  "Por favor, selecione uma opção!"}
              </small>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>Tipo de mensagem</label>
            <select
              className="form-control"
              defaultValue={
                id && messageData ? messageData.message_type : "text"
              }
              {...register("message_type", {
                required: true,
              })}
            >
              <option value={"text"}>Texto</option>
              {/*  <option value={"media"}>Mídia</option>
              <option value={"document"}>Documento</option> */}
            </select>
            {errors.match && (
              <small className="ml-2 text-danger">
                {errors.match.message_type === "required" &&
                  "Por favor, selecione uma opção!"}
              </small>
            )}
          </div>
          <div className="form-group col-md-6">
            <OverlayTrigger
              container={document.querySelector(".modal-body")}
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  Pausar bot por 10 minutos quando o cliente lhe enviar as
                  palavras chaves definidas nesta ação.
                </Tooltip>
              }
            >
              <label className="cursor-pointer">
                Pausar bot
                <i className="las la-info-circle ml-2"></i>
              </label>
            </OverlayTrigger>

            <select
              className="form-control"
              defaultValue={id ? props.data.pause_bot : "0"}
              {...register("pause_bot")}
            >
              <option value={"0"}>Não</option>
              <option value={"1"}>Sim</option>
            </select>
            {errors.pause_bot && (
              <small className="ml-2 text-danger">
                {errors.pause_bot.type === "required" &&
                  "Por favor, selecione uma opção!"}
              </small>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>Palavras chaves</label>
            <textarea
              className="form-control"
              rows={8}
              {...register("keywords", {
                required: true,
              })}
              defaultValue={id ? props.data.keywords : ""}
              placeholder="*Utilize cada palavra ou frase separado por vírgula. Não deixe vírgulas perdidas ao final pois pode prejudicar o funcionamento do bot. Ex: cardápio, cardapio, pedir, fazer um pedido "
            ></textarea>
            {errors.keywords && (
              <small className="ml-2 text-danger">
                {errors.keywords.type === "required" &&
                  "Por favor, insira as palavras chaves!"}
              </small>
            )}
          </div>
          <div className="form-group col-md-6 picker-wrapper">
            <label className="d-flex justify-content-between">
              Resposta{" "}
              <span
                className="emoji-picker-handle cursor-pointer"
                onClick={handleEmojiPicker}
              >
                😄
              </span>
            </label>
            <textarea
              id="message"
              className="form-control"
              rows={8}
              {...register("message", {
                required: true,
              })}
              defaultValue={id && messageData ? messageData.text : ""}
              placeholder="ex: Nosso cardápio está disponível em nosso site, vou te passar o link aí você pode fazer o pedido por lá e vamos te avisando por aqui sobre o status do seu pedido 😁
                            Faça seu pedido clicando aqui:
                            🔗 {{pralevar_site_url}}"
            ></textarea>

            {errors.message && (
              <small className="ml-2 text-danger">
                {errors.message.type === "required" &&
                  "Por favor, insira uma resposta!"}
              </small>
            )}
          </div>
        </div>
        <div className="col-md-12 col-sm-12">
          <Accordion className="accordion" defaultActiveKey="1">
            <Accordion.Toggle
              as={Card.Text}
              eventKey={"0"}
              className={`accordion__header bg-light collapsed`}
            >
              <span className="accordion__header--text">Shortcodes</span>
              <span className="accordion__header--indicator"></span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"0"}>
              <div className="accordion__body--text">
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_nome}}"}
                  </span>
                  : Nome da loja.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_telefone}}"}
                  </span>
                  : Telefone da loja.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_email}}"}
                  </span>
                  : E-mail da loja.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_horario_atendimento}}"}
                  </span>
                  : Horário de atendimento da loja.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_endereco}}"}
                  </span>
                  : Endereço da loja.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_site_url}}"}
                  </span>
                  : Site de pedidos.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_formas_pagamento}}"}
                  </span>
                  : Formas de pagamento da loja.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_tempo_entrega}}"}
                  </span>
                  : Tempo de entrega.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_promocoes}}"}
                  </span>
                  : Promoções disponíveis.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_cupons}}"}
                  </span>
                  : Cupons disponíveis.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_loja_taxa_entrega}}"}
                  </span>
                  : Taxa de entrega pelo cep.
                </p>
                <p>
                  <span className="text-primary">
                    {"{{pralevar_cliente_ajuda_pedido}}"}
                  </span>
                  : Buscar pedido quando cliente solicitar ajuda.
                </p>
              </div>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="col-lg-4 mx-auto mt-4">
          <Button type="submit" className="btn btn-primary w-100">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
