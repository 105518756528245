import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Hash } from "../helpers/Helpers";
import { Create, Update, GetOne } from "../services/UsersServices";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { AppSettings, Theme } from "../App";
import AlertResponses from "../jsx/components/Alert";
import { useForm, Controller } from "react-hook-form";
import UserContext from "../contexts/UserContext";
import { List } from "../services/StoreServices";
import Swal from "sweetalert2";
import ProgressBarComponent from "../jsx/components/ProgressBar";

export default function UserDetails(props) {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [data, setData] = useState(props?.location?.data ? props.location.data : null);
  const [imageFile, setImageFile] = useState(null);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [stores, setStores] = useState();
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (user?.plan_id < 2 && user?.user_id !== parseInt(id)) {
      window.location.assign("/");
      return;
    }
    if (id) {
      GetOne(id, setData, setValue, setIsLoading, reset);
    } else {
      setData("");
      setIsLoading(false);
    }
    if (AppSettings.store?.url) {
      List(AppSettings.store.url, setStores, false, false, true);
    }
  }, []);

  const override = css`
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 20%;
  `;

  const imagePreview = (image) => {
    if (image?.size > 2000000) {
      Swal.fire(
        "Atenção!",
        "Imagem muito pesada! Por favor, insira imagens até 2MB.",
        "info"
      );
      return;
    }
    let url = URL.createObjectURL(image);
    setImageFile(url);
  };

  const onSubmit = (data) => {
    setProgress(true);
    var form = new FormData();
    let birthday = data.birthday
      .split(data.birthday.includes("/") ? "/" : "-")
      .reverse()
      .join("-");
    form.append("first_name", data.first_name);
    form.append("last_name", data.last_name);

    form.append("birthday", birthday);
    form.append("phone", data.phone.replace(/[^0-9]/g, ""));
    data.photo.length > 0 && form.append("photo", data.photo[0]);
    form.append("superuser_level", data.superuser_level);
    form.append("is_active", parseInt(data.is_active));
    form.append("is_superuser", 1);
    form.append("plan_id", user.plan_id);
    form.append("store_id", data?.store_id || user.store_id);
    form.append("main_store", AppSettings.store.main);

    if (id) {
      form.append("id", id);
      Update(id, form, setData, setNotify, setError, setProgress);
    } else {
      form.append("email", data.email);
      form.append("cpf", data.cpf.replace(/[^0-9]/g, ""));
      Create(form, setData, setNotify, setError, false, false, setProgress);
    }
  };

  return (
    <>
      <div className="row">
        {isLoading ? (
          <BeatLoader
            color={Theme.colors?.primary}
            loading={isLoading}
            css={override}
            size={12}
          />
        ) : (
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-2 col-lg-4 col-md-4 col-xxl-4">
                    {(data && data.photo) || imageFile ? (
                      <img
                        className="p-us user-image img-fluid  mt-4"
                        src={
                          !imageFile
                            ? Hash("400x400", data.photo, true)
                            : imageFile
                        }
                        alt="image"
                      />
                    ) : (
                      <div className="p-us image-default">
                        <i className="ti-image"></i>
                      </div>
                    )}

                    <div className="input-group mt-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Upload</span>
                      </div>
                      <div className="custom-file">
                        <input
                          accept="image/*"
                          type="file"
                          className="custom-file-input"
                          ref={register}
                          {...register("photo")}
                          onChange={(e) => {
                            if (e.target.files[0].size > 4000000) {
                              setNotify({
                                title: "Oops!",
                                message: "Por favor, insira imagens até 4MB!",
                                type: "warning",
                                visible: true,
                              });
                            } else {
                              imagePreview(e.target.files[0]);
                            }
                          }}
                        />
                        <label className="custom-file-label">
                          Selecione...
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="text-capitalize">
                          {getValues("first_name")
                            ? getValues("first_name")
                            : "Novo Usuário"}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="basic-form">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Primeiro nome</label>
                                <input
                                  {...register("first_name", {
                                    required: true,
                                  })}
                                  className="form-control text-black"
                                  defaultValue={""}
                                />
                                {errors.first_name && (
                                  <small className="ml-2 text-danger">
                                    {errors.first_name.type === "required" &&
                                      "Por favor, preencha este campo!"}
                                  </small>
                                )}
                              </div>
                              <div className="form-group col-md-6">
                                <label>Sobrenome</label>
                                <input
                                  {...register("last_name", { required: true })}
                                  className="form-control text-black"
                                  defaultValue={""}
                                />
                                {errors.last_name && (
                                  <small className="ml-2 text-danger">
                                    {errors.last_name.type === "required" &&
                                      "Por favor, preencha este campo!"}
                                  </small>
                                )}
                              </div>
                              <div className="form-group col-md-12">
                                <label>E-mail</label>
                                <input
                                  {...register("email", {
                                    required: true,
                                    pattern:
                                      /^[a-zA-Z0-9\_\-\.]+@[a-zA-Z0-9]+.[a-zA-Z]/,
                                  })}
                                  className="form-control text-black"
                                  placeholder="insira um e-mail"
                                  defaultValue={""}
                                  disabled={id ? true : false}
                                />
                                {errors.email && (
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.email.type === "required"
                                      ? "Por favor, preencha este campo!"
                                      : "Por favor, insira um e-mail válido!"}
                                    {errors.email.type === "custom" &&
                                      errors.email.message}
                                  </small>
                                )}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Aniversário</label>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="birthday"
                                  defaultValue={""}
                                  render={({ field }) => (
                                    <NumberFormat
                                      format="##/##/####"
                                      className="form-control text-black"
                                      {...field}
                                    />
                                  )}
                                />

                                {errors.birthday && (
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.birthday.type === "required" &&
                                      "Por favor, preencha este campo!"}
                                    {errors.birthday.type === "custom" &&
                                      errors.birthday.message}
                                  </small>
                                )}
                              </div>
                              <div className="form-group col-md-6">
                                <label>CPF</label>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="cpf"
                                  defaultValue={""}
                                  render={({ field }) => (
                                    <NumberFormat
                                      disabled={id ? true : false}
                                      format="###.###.###-##"
                                      className="form-control text-black"
                                      {...field}
                                    />
                                  )}
                                />

                                {errors.cpf && (
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.cpf.type === "required" &&
                                      "Por favor, preencha este campo!"}
                                    {errors.cpf.type === "custom" &&
                                      errors.cpf.message}
                                  </small>
                                )}
                              </div>
                              <div className="form-group col-md-6">
                                <label>Telefone</label>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="phone"
                                  defaultValue={""}
                                  render={({ field }) => (
                                    <NumberFormat
                                      format="(##) #####-####"
                                      className="form-control text-black"
                                      onChange={(e) =>
                                        setValue("phone", e.target.value)
                                      }
                                      {...field}
                                    />
                                  )}
                                />

                                {errors.phone && (
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.phone.type === "required" &&
                                      "Por favor, preencha este campo!"}
                                    {errors.phone.type === "custom" &&
                                      errors.phone.message}
                                  </small>
                                )}
                              </div>
                              <div className="form-group col-md-6">
                                <label>Nível</label>

                                <select
                                  defaultValue={""}
                                  className="form-control text-black"
                                  {...register("superuser_level", {
                                    required: true,
                                  })}
                                >
                                  {user.superuser_level === 1 && (
                                    <option value="1">Administrador</option>
                                  )}

                                  <option value="2">Gerente</option>
                                  <option value="3">Supervisor</option>
                                  <option value="4">Atendente</option>
                                </select>
                                {errors.superuser_level && (
                                  <small className="ml-2 text-danger">
                                    {errors.superuser_level.type ===
                                      "required" &&
                                      "Por favor, preencha este campo!"}
                                  </small>
                                )}
                              </div>
                            </div>
                            {stores &&
                              stores.length > 1 &&
                              AppSettings.store?.main === user.store_id &&
                              user.superuser_level === 1 && (
                                <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label>Loja</label>
                                    <select
                                      className="form-control text-black"
                                      {...register("store_id", {
                                        required: true,
                                      })}
                                      defaultValue=""
                                    >
                                      <option value={"0"} disabled>
                                        Selecione uma loja para o usuário...
                                      </option>
                                      {stores.map((store, index) => (
                                        <option key={index} value={store.id}>
                                          {store.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {errors.store_id && (
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.is_active.type === "required" &&
                                        "Por favor, selecione uma loja para o usuário!"}
                                    </small>
                                  )}
                                </div>
                              )}
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <label>Status</label>
                                <select
                                  className="form-control text-black"
                                  defaultValue={""}
                                  {...register("is_active", { required: true })}
                                >
                                  <option value={1}>Ativo</option>
                                  <option value={0}>Desativado</option>
                                </select>
                              </div>
                              {errors.is_active && (
                                <small className="ml-2 mb-2 text-danger">
                                  {errors.is_active.type === "required" &&
                                    "Por favor, preencha este campo!"}
                                </small>
                              )}
                            </div>

                            <button
                              type="submit"
                              className="btn btn-block btn-primary"
                            >
                              Salvar
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {progress && <ProgressBarComponent />}
            {notify.visible && (
              <AlertResponses
                title={notify.title}
                message={notify.message}
                type={notify.type}
                visible={notify.visible}
                setNotify={setNotify}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
