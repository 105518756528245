import React, { useEffect, useState, useRef} from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import DataTablev2 from '../jsx/components/Datatable'
import { GetSupportTickets } from '../services/StoreServices'
import DefaultLoader from '../jsx/components/Loader'
import AlertResponses from '../jsx/components/Alert'
import ModalComponent from '../jsx/components/Modal'
import SupportTicket from '../jsx/components/ModalData/SupportTicket'

export default function Support () {
  const [showSupportModal, setShowSupportModal] = useState(false)
  const [supportTickets, setSupportTickets] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [notify, setNotify] = useState({
    title: '',
    message: '',
    type: '',
    visible: false
  })

  const data = useRef()

  useEffect(() => {
    GetSupportTickets(setSupportTickets, setNotify, setIsLoading)
  }, [])

  const deleteRows = state => {}

  const modalSupportProps = {
    title: 'Suporte',
    content: <SupportTicket data={data.current}/>,
    btnLabel: 'Salvar',
    showModal: showSupportModal,
    setShowModal: setShowSupportModal,
    size: 'xl',
    showFooter: false
  }

  const status = {
    1: {
      id: 1,
      label: 'Recebido',
      type: 'info'
    },
    2: {
      id: 2,
      label: 'Verificando',
      type: 'primary'
    },
    3: {
      id: 3,
      label: 'Finalizado',
      type: 'success'
    }
  }

  const BadgeStatus = item => {
    return (
      <span
        className={`badge badge-${status[item.status].type} badge-sm badge-${
          item.id
        }`}
      >
        {status[item.status].label}
      </span>
    )
  }

  const columns = [
    {
      name: '#',
      selector: row => row.id,
      maxWidth: '5%'
    },
    {
      name: 'Assunto',
      selector: row => (
        <Link to='#' onClick={() => {
          data.current = row
          setShowSupportModal(true)
        }}>
          {row.subject}
        </Link>
      ),
      sortable: true,
      maxWidth: '40%'
    },

    {
      name: 'Criado em',
      selector: row => new Date(row.created_at).toLocaleString('pt-BR'),
      maxWidth: '20%',
      sortable: true
    },
    {
      name: 'Última atualização',
      selector: row => new Date(row.last_update).toLocaleString('pt-BR'),
      maxWidth: '20%',
      sortable: true
    },
    {
      name: 'Status',
      selector: row => BadgeStatus(row),
      maxWidth: '10%',
      allowOverflow: true
    }
  ]

  return (
    <>
      {isLoading ? (
        <DefaultLoader isLoading={isLoading} />
      ) : (
        <>
          <div className='row mt-4'>
            <div className='col-xl-12 col-xxl-12 col-sm-12 page-header'>
              <div className='col-xl-4 col-xxl-5 col-sm-12'>
                <h2>Suporte</h2>
              </div>

              <div className='col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end'>
                <Button
                  className='i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary'
                  onClick={() => setShowSupportModal(true)}
                >
                  <span>Criar Ticket</span>
                </Button>
              </div>
            </div>
          </div>
          {notify.visible && (
            <AlertResponses
              title={notify.title}
              message={notify.message}
              type={notify.type}
              visible={notify.visible}
              setNotify={setNotify}
            />
          )}

          <DataTablev2
            data={supportTickets}
            columns={columns}
            title='Meus Tickets'
            selectable={false}
            onSelectedRowsChange={deleteRows}
            showDeleteButton={showDeleteButton}
            filterDefaultField="subject"
            filterByField={{
              labels: ['Assunto'],
              fields: ['subject']
            }}
          />
          {showSupportModal && <ModalComponent props={modalSupportProps} />}
        </>
      )}
    </>
  )
}

const styles = {}
