import React, { useState, createContext, useRef, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { parseCookies } from "nookies";
import AlertResponses from "../jsx/components/Alert";
import ModalComponent from "../jsx/components/Modal";
import sound from "./sound3.mp3";
import { Button } from "react-bootstrap";

const NotificationsContext = createContext();

export function NotificationsProvider({ children }) {
  let { token: _token } = parseCookies();
  const token = useRef(_token);
  const helpData = useRef();
  const helpSound = useRef();
  const [showHelpModal, setShowHelpModal] = useState(false);
  // const socketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}notifications/?token=${token.current}`;
  const socketUrl = `wss://api.pralevar.app/ws/notifications/?token=${token.current}`;
  
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });

  useEffect(() => {
    if (showHelpModal) {
      helpSound.current = new Audio(sound);
      helpSound.current.loop = true;
      helpSound.current.play();
    } else {
      if (helpSound.current) {
        helpSound.current.pause();
        helpSound.current = false;
      }
    }

    return () => {
      if (helpSound.current) {
        helpSound.current.pause();
      }
    };
  }, [showHelpModal]);

  const ModalContent = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h4>
              {helpData.current?.order_id
                ? `Pedido #${helpData.current.order_id}`
                : "Não informado"}{" "}
            </h4>
            <p>Cliente solicitou ajuda pelo whatsapp.</p>
            <div className="form-group">
              <label className="fs-15">Mensagem</label>
              <textarea
                className="form-control p-3"
                readOnly
                rows={5}
                defaultValue={helpData.current?.message || ""}
              />
            </div>
            {helpData.current?.order_id && (
              <a
                href={`/order-details/${helpData.current.order_id}`}
                className="btn btn-info btn-sm btn-block"
              >
                {" "}
                Ver Pedido
              </a>
            )}
          </div>
        </div>
      </div>
    );
  };

  const modalProps = {
    title: "Ajuda com um pedido",
    content: <ModalContent />,
    showModal: showHelpModal,
    setShowModal: setShowHelpModal,
    showFooter: false,
  };

  return (
    <NotificationsContext.Provider
      value={{
        sendMessage,
        lastMessage,
        readyState,
        notify,
        setNotify,
        helpData,
        showHelpModal,
        setShowHelpModal,
      }}
    >
      {children}
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
      {showHelpModal && <ModalComponent props={modalProps} />}
    </NotificationsContext.Provider>
  );
}

export default NotificationsContext;
