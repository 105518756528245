import React, { useEffect, useRef, useState, useContext } from 'react'
import { Button, Card, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { ChromePicker } from 'react-color'
import Values from 'values.js'
import {
  UpdateStore,
  UpdateCustomizations
} from '../services/ConfigurationsServices'
import {  RGBToHex } from '../helpers/Helpers'
import DefaultLoader from '../jsx/components/Loader'
import AlertResponses from '../jsx/components/Alert'
import ProgressBarComponent from '../jsx/components/ProgressBar'
import Layout1 from '../images/layout1.png'
import Layout2 from '../images/layout2.png'
import Layout3 from '../images/layout3.png'

import { AppSettings, Theme } from '../App'
import { IMAGES_URL } from '../api/Urls'
import ModalComponent from '../jsx/components/Modal'
import LayoutDetails from './LayoutDetails'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'
import UserContext from '../contexts/UserContext'
import Tutorial from '../jsx/components/Tutorial'

export default function Configurations () {
  const { user } = useContext(UserContext)
  const seoSubmit = useRef()
  const [erros, setErros] = useState(null)
  const [notify, setNotify] = useState({
    title: '',
    message: '',
    type: '',
    visible: false
  })
  const [progress, setProgress] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const {
    register: registerLogo,
    handleSubmit: handleSubmitLogo,
    setError: setErrorLogo,
    clearErrors: clearErrorsLogo,
    formState: { errors: errorsLogo }
  } = useForm()

  const {
    register: registerSocialMedia,
    handleSubmit: handleSubmitSocialMedia,
    setValue: setValueSocialMedia,
    setError: setErrorSocialMedia,
    formState: { errors: errorsSocialMedia }
  } = useForm()

  const {
    register: registerSeo,
    handleSubmit: handleSubmitSeo,
    setValue: setValueSeo,
    setError: setErrorSeo,
    formState: { errors: errorsSeo }
  } = useForm()
  const {
    register: registerLayout,
    handleSubmit: handleSubmitLayout,
    setValue: setValueLayout,
    setError: setErrorLayout,
    formState: { errors: errorsLayout }
  } = useForm()

  const data = useRef()
  const layoutMobile = useRef()
  const colorPrimaryHover = useRef(
    AppSettings.store?.customization?.colors?.primaryHover
      ? AppSettings.store.customization.colors.primaryHover
      : '#dd032b'
  )
  const colorTertiary = useRef(
    AppSettings.store?.customization?.colors?.tertiary
      ? AppSettings.store.customization.colors.tertiary
      : '#f9d1d1'
  )
  const [logo, setLogo] = useState(null)
  const [logoDarkTheme, setLogoDarkTheme] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [checked, setChecked] = useState(false)
  const [templateMobile, setTemplateMobile] = useState()
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [primaryColor, setPrimaryColor] = useState(
    AppSettings.store?.customization?.colors?.primary
      ? AppSettings.store.customization.colors.primary
      : '#ff0a38'
  )

  const handleChangeColor = color => {
    if (!document.querySelector('.chrome-picker .btn-close')) {
      let button = document.createElement('button')
      button.className =
        'btn btn-sm btn-block btn-close btn-primary rounded-0 font-weight-bold'
      button.innerText = 'Fechar'
      button.onclick = function () {
        setShowColorPicker(false)
      }
      document.querySelector('.chrome-picker').appendChild(button)
    }

    const colors = new Values(color.hex)
    let tertiary = colors.tint(90).rgb
    let primaryHover = colors.shade(20).rgb
    colorTertiary.current = RGBToHex(tertiary[0], tertiary[1], tertiary[2])
    colorPrimaryHover.current = RGBToHex(
      primaryHover[0],
      primaryHover[1],
      primaryHover[2]
    )
    setPrimaryColor(color.hex)
  }

  const status = {
    0: {
      id: 0,
      label: 'Desativada',
      type: 'light'
    },
    1: {
      id: 1,
      label: 'Ativa',
      type: 'success'
    }
  }

  const onSubmitColors = value => {
    setProgress(true)
    let values = value.colors.split(',')

    let colors = {
      theme: values[0],
      background: values[1],
      primary: primaryColor,
      primaryHover: colorPrimaryHover.current,
      tertiary: colorTertiary.current,
      titles: values[5],
      subtitles: values[6]
    }

    let data = {
      customization: {
        ...AppSettings.store.customization,
        colors: colors
      }
    }

    UpdateCustomizations(
      AppSettings.store.id,
      data,
      setNotify,
      false,
      true,
      true
    ).finally(() =>  setProgress(false))
    localStorage.setItem('colors', JSON.stringify(colors))
  }

  const onSubmitLogo = value => {
    setProgress(true)
    if (
      (!value.logo && !AppSettings.logo) ||
      (value.logo.length === 0 && !AppSettings.logo)
    ) {
      setErrorLogo('logo', {
        type: 'required'
      })

      setTimeout(() => {
        clearErrorsLogo('logo')
      }, 3500)

      if (
        (!value.logoDarkTheme && !AppSettings.logoDarkTheme) ||
        (value.logoDarkTheme.length === 0 && !AppSettings.logoDarkTheme)
      ) {
        setLogoDarkTheme('logoDarkTheme', {
          type: 'required'
        })
      }

      setTimeout(() => {
        clearErrorsLogo('logoDarkTheme')
      }, 3500)
      return
    }

    let form = new FormData()
    if (!value.logo || value.logo.length !== 0) {
      let newImage = new File(
        [value.logo[0]],
        `logo-${Math.floor(Math.random() * 99999999)}.${
          value.logo[0].type.split('/')[1]
        }`,
        { type: value.logo[0].type }
      )
      form.append('logo', newImage)
    }
    if (!value.logoDarkTheme || value.logoDarkTheme.length !== 0) {
      let newImage = new File(
        [value.logoDarkTheme[0]],
        `logo-dark-${Math.floor(Math.random() * 99999999)}.${
          value.logoDarkTheme[0].type.split('/')[1]
        }`,
        { type: value.logoDarkTheme[0].type }
      )
      form.append('logo_dark', newImage)
    }

    let customization = {
      ...AppSettings.store.customization,
      logo_orientation: value.logoOrientation
    }

    form.append('customization', JSON.stringify(customization))

    UpdateCustomizations(
      AppSettings.store.id,
      form,
      setNotify,
      false,
      true,
      true
    ).finally(() =>  setProgress(false))
  }

  const onSubmitSocialMedia = data => {
    setProgress(true)
    let form = new FormData()

    form.append('store', window.location.host)
    form.append(
      'social_media',
      JSON.stringify({
        facebook: data.facebook,
        instagram: data.instagram,
        tripadvisor: data.tripadvisor
      })
    )

    if (AppSettings.store.id) {
      UpdateStore(AppSettings.store.id, form, setNotify, true, true)
        .then(res => {
          setNotify({
            title: 'Sucesso!',
            message: 'Mídias sociais atualizadas com sucesso!',
            type: 'success',
            visible: true
          })
          window.location.reload()
        })
        .catch(error => {
          setNotify({
            title: 'Oops!',
            message: ERROR_MESSAGES.store.update,
            type: 'error',
            visible: true
          })
        }).finally(() =>  setProgress(false))
    }
  }

  const onSubmitSeo = data => {
    setProgress(true)
    seoSubmit.current.disabled = true
    seoSubmit.current.innerText = 'Aguarde...'

    let form = new FormData()
    form.append(
      'seo',
      JSON.stringify({
        site_title: data.title,
        site_description: data.description
      })
    )

    if (AppSettings.store.id) {
      UpdateStore(AppSettings.store.id, form, setNotify, true, true)
        .then(res => {
          setNotify({
            title: 'Sucesso!',
            message: 'SEO atualizado com sucesso!',
            type: 'success',
            visible: true
          })
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
        .catch(error => {
          setNotify({
            title: 'Oops!',
            message: ERROR_MESSAGES.store.update,
            type: 'error',
            visible: true
          })
          seoSubmit.current.innerText = 'Salvar'
          seoSubmit.current.removeAttribute('disabled')
        }).finally(() =>  setProgress(false))
    }
  }

  const onSubmitLayout = data => {
    setProgress(true)
    let payload = {
      customization: {
        ...AppSettings.store.customization,
        layout: data.layout,
        layoutMobile: data.layoutMobile
      }
    }

    if (AppSettings.store.id) {
      UpdateStore(AppSettings.store.id, payload, setNotify, true, true)
        .then(res => {
          setNotify({
            title: 'Sucesso!',
            message: 'Layout atualizado com sucesso!',
            type: 'success',
            visible: true
          })
        })
        .catch(error => {
          setNotify({
            title: 'Oops!',
            message: ERROR_MESSAGES.store.update,
            type: 'error',
            visible: true
          })
        }).finally(() =>  setProgress(false))
    }
  }

  const modalProps = {
    title: 'Configurações do Layout',
    content: (
      <LayoutDetails
        data={data.current}
        setNotify={setNotify}
        setShowModal={setShowModal}
        layoutMobile={layoutMobile.current}
      />
    ),
    btnLabel: 'Salvar',
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false
  }

  const imagePreview = (image, set) => {
    let url = URL.createObjectURL(image)
    set(url)
  }

  useEffect(() => {
    if (AppSettings.store) {
      if (typeof AppSettings.store.customization.layout === 'object') {
        setChecked('layout3')
      } else {
        setChecked(AppSettings.store.customization.layout)
      }
      setIsLoading(false)
    }
  }, [AppSettings.store])

  if (isLoading) {
    return <DefaultLoader />
  }

  return (
    <>
      <div className='item'>
        <div className='col-xl-12 col-xxl-12 col-sm-12 page-header'>
          <Tutorial
            title={'Personalizações'}
            videoId={'Dbh88Lp5YlI'}
            padding={'0 15px'}
            classes={'my-3'}
          />
        </div>
      </div>
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
      <>
        <Col xl='4' className='d-inline-block align-top'>
          <Card className='mb-3 align-items-center'>
            <Card.Header className='m-auto flex-column pb-2'>
              <Card.Title>Logo</Card.Title>
              <h5 className='mt-2'>Tema Claro</h5>
            </Card.Header>
            <Card.Body className='card-body w-100 p-2 d-flex flex-column align-items-center'>
              <form
                onSubmit={handleSubmitLogo(onSubmitLogo)}
                className='m-auto text-center'
              >
                <div>
                {AppSettings.logo || logo ? (
                  <img
                    className='img-fluid'
                    src={
                      logo === null
                        ? `https://${IMAGES_URL}${AppSettings.logo}`
                        : logo
                    }
                    alt='image'
                    style={styles.logo}
                  />
                ) : (
                  <div
                    className='image-default rounded-circle'
                    style={styles.imageDefault}
                  >
                    <i className='ti-image'></i>
                  </div>
                )}
                <div className='input-group mt-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>Upload</span>
                  </div>
                  <div className='custom-file'>
                    <input
                      accept='image/*'
                      type='file'
                      className='custom-file-input'
                      ref={registerLogo}
                      {...registerLogo('logo')}
                      onChange={e => imagePreview(e.target.files[0], setLogo)}
                    />
                    <label className='custom-file-label text-left'>
                      Selecione...
                    </label>
                  </div>
                </div>
                {errorsLogo.logo && (
                  <small className='mt-3 text-danger d-block'>
                    {errorsLogo.logo.type === 'required' &&
                      'Por favor, selecione uma imagem!'}
                  </small>
                )}
                </div>
                <div className='mt-5'>
                <Card.Title>Logo</Card.Title>
              <h5 className='mt-2'>Tema Escuro</h5>
                {AppSettings.logo_dark || logoDarkTheme ? (
                  <img
                    className='img-fluid'
                    src={
                      logoDarkTheme === null
                        ? `https://${IMAGES_URL}${AppSettings.logo_dark}`
                        : logoDarkTheme
                    }
                    alt='image'
                    style={{...styles.logo, background: '#1a1a1a'}}
                  />
                ) : (
                  <div
                    className='image-default rounded-circle'
                    style={styles.imageDefault}
                  >
                    <i className='ti-image'></i>
                  </div>
                )}
                <div className='input-group mt-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>Upload</span>
                  </div>
                  <div className='custom-file'>
                    <input
                      accept='image/*'
                      type='file'
                      className='custom-file-input'
                      ref={registerLogo}
                      {...registerLogo('logoDarkTheme')}
                      onChange={e => imagePreview(e.target.files[0], setLogoDarkTheme)}
                    />
                    <label className='custom-file-label text-left'>
                      Selecione...
                    </label>
                  </div>
                  <small className='text-primary d-block pt-2'>*Utilize o logo com fundo transparente no formato .png para melhor adequação ao tema escuro.</small>
                </div>
                {errorsLogo.logoDarkTheme && (
                  <small className='mt-3 text-danger d-block'>
                    {errorsLogo.logoDarkTheme.type === 'required' &&
                      'Por favor, selecione uma imagem!'}
                  </small>
                )}
                </div>
                <div className='d-flex flex-column align-items-center pt-4'>
                  <label>Orientação da sua Logo</label>
                  <div className='d-flex flex-row'>
                    <div className='mx-2'>
                      <input
                        type='radio'
                        className='mr-2'
                        id='horizontal'
                        {...registerLogo('logoOrientation', { required: true })}
                        defaultChecked={
                          AppSettings.store.customization.logo_orientation ===
                          'horizontal'
                            ? true
                            : false
                        }
                        value='horizontal'
                      />
                      <label htmlFor='horizontal'>Horizontal</label>
                    </div>
                    <div className='mx-2'>
                      <input
                        type='radio'
                        className='mr-2'
                        id='vertical'
                        {...registerLogo('logoOrientation', { required: true })}
                        defaultChecked={
                          AppSettings.store.customization.logo_orientation ===
                          'vertical'
                            ? true
                            : false
                        }
                        value='vertical'
                      />
                      <label htmlFor='vertical'>Vertical</label>
                    </div>
                  </div>
                </div>
                {errorsLogo.logoOrientation && (
                  <small className='mt-3 text-danger d-block'>
                    {errorsLogo.logoOrientation.type === 'required' &&
                      'Por favor, selecione uma orienteção!'}
                  </small>
                )}
                
                <Button type='submit' className='btn btn-primary w-50 mt-3'>
                  Salvar
                </Button>
              </form>
            </Card.Body>
          </Card>

          <Card className='mb-3 align-items-center'>
            <Card.Header className='m-auto flex-column'>
              <Card.Title>Redes Sociais</Card.Title>
            </Card.Header>
            <Card.Body className='card-body w-100 p-2 d-flex flex-column align-items-center'>
              <form
                onSubmit={handleSubmitSocialMedia(onSubmitSocialMedia)}
                className='m-auto text-center'
              >
                <div className='input-group icon-left mt-3'>
                  <i className='ti-facebook'></i>
                  <input
                    className='form-control text-black p-placeholder'
                    placeholder='URL Facebook'
                    defaultValue={
                      AppSettings.store.social_media &&
                      AppSettings.store.social_media.facebook
                        ? AppSettings.store.social_media.facebook
                        : ''
                    }
                    {...registerSocialMedia('facebook', {
                      pattern: /^https:\/\/(?:www.)?facebook.com\//
                    })}
                  />
                </div>

                {errorsSocialMedia.facebook && (
                  <small className='mt-3 text-danger d-block'>
                    {errorsSocialMedia.facebook.type === 'pattern' &&
                      'Por favor, insira a URL do facebook corretamente!'}
                  </small>
                )}

                <div className='input-group icon-left mt-3'>
                  <i className='ti-instagram'></i>
                  <input
                    className='form-control text-black p-placeholder'
                    placeholder='URL Instagram'
                    defaultValue={
                      AppSettings.store.social_media &&
                      AppSettings.store.social_media.instagram
                        ? AppSettings.store.social_media.instagram
                        : ''
                    }
                    {...registerSocialMedia('instagram', {
                      pattern: /^https:\/\/(?:www.)?instagram.com\//
                    })}
                  />
                </div>

                {errorsSocialMedia.instagram && (
                  <small className='mt-3 text-danger d-block'>
                    {errorsSocialMedia.instagram.type === 'pattern' &&
                      'Por favor, insira a URL do instagram corretamente!'}
                  </small>
                )}

                <div className='input-group icon-left mt-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 64 64'
                    aria-labelledby='title'
                    height={18}
                    width={18}
                    aria-describedby='desc'
                    role='img'
                    xlink='http://www.w3.org/1999/xlink'
                  >
                    <title>Tripadvisor</title>
                    <desc>A solid styled icon from Orion Icon Library.</desc>
                    <path
                      data-name='layer4'
                      d='M39.1 13.516a50.1 50.1 0 0 1 5.3 1.209 32.624 32.624 0 0 1 8.492 3.929 1.435 1.435 0 0 0 .7.2h10.189v.1a4.39 4.39 0 0 0-.4.705 16.853 16.853 0 0 0-2.5 5.239.477.477 0 0 0 .1.6 15.734 15.734 0 0 1 2.5 13.3 15.315 15.315 0 0 1-7.094 9.772 15.97 15.97 0 0 1-8.487 2.422 15.691 15.691 0 0 1-3.8-.5 16.211 16.211 0 0 1-7.893-4.634 9.057 9.057 0 0 1-.9-1.007c-1.1 1.713-2.3 3.425-3.4 5.138-1.2-1.713-2.3-3.425-3.4-5.037-.1 0-.1 0-.1.1l-.1.1a15.544 15.544 0 0 1-9.891 5.641 14.656 14.656 0 0 1-6.594-.4 15.314 15.314 0 0 1-7.793-4.936 15.784 15.784 0 0 1-3.8-8.16 13.951 13.951 0 0 1 .3-6.347 13.547 13.547 0 0 1 2.4-5.339.76.76 0 0 0 .1-.5 21.114 21.114 0 0 0-2.2-4.836 7.687 7.687 0 0 0-.8-1.108v-.1h9.9c.1 0 .3-.1.4-.1a34.036 34.036 0 0 1 7.194-3.526 50.8 50.8 0 0 1 5.6-1.511 33.995 33.995 0 0 1 5.6-.705 41.028 41.028 0 0 1 10.377.291zm-4 21.458a12.789 12.789 0 1 0 12.6-12.895 12.8 12.8 0 0 0-12.593 12.895zM15.924 22.079a12.846 12.846 0 1 0 12.788 12.895 12.706 12.706 0 0 0-12.788-12.895zm.7-3.324a15.746 15.746 0 0 1 10.091 4.231 16.211 16.211 0 0 1 5.2 9.772A16.351 16.351 0 0 1 37 23.087a15.491 15.491 0 0 1 10-4.231 36.237 36.237 0 0 0-14.187-3.022 38.507 38.507 0 0 0-16.19 2.921z'
                      fill='#202020'
                    ></path>
                    <path
                      data-name='layer3'
                      d='M47.8 26.814a7.963 7.963 0 0 1 .3 15.917 7.962 7.962 0 1 1-.3-15.917zm5.2 7.959a5.146 5.146 0 1 0-10.291-.1 5.245 5.245 0 0 0 5.2 5.239 5.012 5.012 0 0 0 5.082-5.139zm-37.176-7.959a7.966 7.966 0 0 1 .4 15.917 7.971 7.971 0 0 1-8.293-7.959 7.756 7.756 0 0 1 7.893-7.958zm5.1 7.959a5.036 5.036 0 0 0-5.2-5.138 5.24 5.24 0 0 0 .1 10.477 5.316 5.316 0 0 0 5.095-5.339z'
                      fill='#202020'
                    ></path>
                    <path
                      data-name='layer2'
                      d='M47.8 32.153a2.591 2.591 0 0 1 2.6 2.619 2.656 2.656 0 0 1-2.6 2.619 2.591 2.591 0 0 1-2.6-2.619 2.656 2.656 0 0 1 2.6-2.619z'
                      fill='#202020'
                    ></path>
                    <path
                      data-name='layer1'
                      d='M15.724 32.153a2.677 2.677 0 0 1 2.7 2.619 2.763 2.763 0 0 1-2.7 2.72 2.67 2.67 0 1 1 0-5.339z'
                      fill='#202020'
                    ></path>
                  </svg>
                  <input
                    className='form-control text-black p-placeholder'
                    placeholder='URL Tripadvisor'
                    defaultValue={
                      AppSettings.store.social_media &&
                      AppSettings.store.social_media.tripadvisor
                        ? AppSettings.store.social_media.tripadvisor
                        : ''
                    }
                    {...registerSocialMedia('tripadvisor', {
                      pattern: /^https:\/\/(?:www.)?tripadvisor.com.br\//
                    })}
                  />
                </div>

                {errorsSocialMedia.tripadvisor && (
                  <small className='mt-3 text-danger d-block'>
                    {errorsSocialMedia.tripadvisor.type === 'pattern' &&
                      'Por favor, insira a URL do tripadvisor corretamente!'}
                  </small>
                )}
                <Button type='submit' className='btn btn-primary w-50 mt-3'>
                  Salvar
                </Button>
              </form>
            </Card.Body>
          </Card>
          {/* <Card className='mb-3 align-items-center'>
                  <Card.Header className="m-auto flex-column">
                    <Card.Title>Splash screen</Card.Title>
                    <small className="pt-2">Tela de abertura do App</small>
                  </Card.Header>
                  <Card.Body className="card-body w-100 p-2 d-flex flex-column align-items-center">
                    <form onSubmit={handleSubmitSplash(onSubmitSplashScreen)} className="m-auto text-center">
                      
                      {
                        customizations.current != (null && undefined) && customizations.current.splashscreen !== null || splashScreen !== null ?
                          <img className="img-fluid rounded-lg"
                            src={splashScreen === null ? Hash('400x400', 'customizations/' + customizations.current.splashscreen.split('/').pop()) : splashScreen} alt="image"
                            style={styles.splashScreen} />
                          : <div className="image-default rounded-lg" style={styles.splashScreen}>
                            <i className="ti-image"></i>
                          </div>
                      }
                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Upload</span>
                        </div>
                        <div className="custom-file">
                          <input accept="image/*" type="file" className="custom-file-input"
                            ref={registerSplash} {...registerSplash("splashScreen")}
                            onChange={(e) => imagePreview(e.target.files[0], setSplashScreen)} />
                          <label className="custom-file-label text-left">Selecione...</label>
                        </div>


                      </div>
                      {errorsSplash.splashScreen &&
                        <small className="mt-3 text-danger d-block">
                          {errorsSplash.splashScreen.type === 'required' && 'Por favor, selecione uma imagem!'}</small>}
                      <Button type="submit" className="btn btn-primary w-50 mt-3">Salvar</Button>
                    </form>
                  </Card.Body>
                </Card> */}
        </Col>
        <Col xl='8' className='d-inline-block'>
          <Card className='mb-3 align-items-center'>
            <Card.Header className='m-auto flex-column'>
              <Card.Title>SEO </Card.Title>
              <small className='d-block text-center mt-2'>
                Informações que aparecerão nos buscadores como Google, Bing,
                Yahoo, que visam alcançar bons rankings orgânicos gerando
                tráfego e autoridade para um site.
              </small>
            </Card.Header>
            <Card.Body className='card-body w-100 p-2 d-flex align-items-baseline'>
              <form
                onSubmit={handleSubmitSeo(onSubmitSeo)}
                className='m-auto w-100 px-5'
              >
                <div className='form-group'>
                  <label className='d-block mt-4'>Título do site</label>
                  <textarea
                    className='form-control text-black'
                    placeholder='ex: Pra levar Hamburgueria artesanal e Almoço executivo'
                    rows={2}
                    defaultValue={
                      AppSettings.store.seo && AppSettings.store.seo.site_title
                        ? AppSettings.store.seo.site_title
                        : ''
                    }
                    {...registerSeo('title', {
                      required: true,
                      minLength: 30,
                      maxLength: 60
                    })}
                  />
                </div>

                {errorsSeo.title && (
                  <small className='mt-3 text-danger d-block'>
                    {errorsSeo.title.type === 'required' &&
                      'Por favor, insira o título do site corretamente!'}
                    {errorsSeo.title.type === 'minLength' &&
                      'Por favor, insira pelo menos 30 caracteres!'}

                    {errorsSeo.title.type === 'maxLength' &&
                      'Por favor, insira no máximo 60 caracteres!'}
                  </small>
                )}

                <div className='form-group'>
                  <label className='d-block'>Descrição do site</label>
                  <textarea
                    className='form-control text-black'
                    placeholder='ex: Venha experimentar nossos incríveis Hambúrguers artesanais e pratos executivos, faça seu pedido pelo site ou conheça uma de nossas unidades.'
                    rows={4}
                    defaultValue={
                      AppSettings.store.seo &&
                      AppSettings.store.seo.site_description
                        ? AppSettings.store.seo.site_description
                        : ''
                    }
                    {...registerSeo('description', {
                      required: true,
                      minLength: 60,
                      maxLength: 155
                    })}
                  />
                </div>

                {errorsSeo.description && (
                  <small className='mt-3 text-danger d-block'>
                    {errorsSeo.description.type === 'required' &&
                      'Por favor, insira a descrição do site!'}
                    {errorsSeo.description.type === 'minLength' &&
                      'Por favor, insira pelo menos 60 caracteres!'}

                    {errorsSeo.description.type === 'maxLength' &&
                      'Por favor, insira no máximo 150 caracteres!'}
                  </small>
                )}
                <div className='col-lg-12 m-auto text-center'>
                  <Button
                    type='submit'
                    className='btn btn-primary w-50 mt-3'
                    ref={e => (seoSubmit.current = e)}
                  >
                    Salvar
                  </Button>
                </div>
              </form>
            </Card.Body>
          </Card>

          <Card className='mb-3 align-items-center'>
            <Card.Header className='m-auto flex-column'>
              <Card.Title>Template Versão Desktop</Card.Title>
            </Card.Header>
            <Card.Body className='card-body w-100 p-2 d-flex flex-column align-items-center'>
              <form
                onSubmit={handleSubmitLayout(onSubmitLayout)}
                className='m-auto text-center'
              >
                <div className='col-lg-6 col-sm-12 mb-2 mt-4 d-inline-block'>
                  <Link
                    to={{ pathname: 'https://layout1.pralevar.app' }}
                    target='_blank'
                    className='d-block mb-1'
                  >
                    <img
                      src={Layout1}
                      width='100%'
                      height={300}
                      loading='lazy'
                      style={{ objectFit: 'contain' }}
                    />
                  </Link>
                  <div className='custom-control custom-checkbox mb-3 mt-3'>
                    <input
                      type='radio'
                      className='custom-control-input'
                      id={'layout1'}
                      defaultValue='layout1'
                      disabled={user.plan_id < 3 ? true : false}
                      {...registerLayout(`layout`)}
                      defaultChecked={checked === 'layout1' ? true : false}
                    />
                    <Link
                      to={{ pathname: 'https://layout1.pralevar.app' }}
                      target='_blank'
                      className='d-block mb-3'
                    >
                      Clique para visualizar
                    </Link>
                    <label
                      className='custom-control-label'
                      htmlFor={`layout1`}
                      disabled={user.plan_id < 3 ? true : false}
                    >
                      {user.plan_id < 3 && (
                        <i
                          className='ti-lock text-primary mr-2'
                          aria-hidden='true'
                        ></i>
                      )}
                      Quero o Layout 1
                    </label>
                  </div>
                </div>
                <div className='col-lg-6 col-sm-12 mb-2 mt-4 d-inline-block'>
                  <Link
                    to={{ pathname: 'https://layout2.pralevar.app' }}
                    target='_blank'
                    className='d-block mb-1'
                  >
                    <img
                      src={Layout2}
                      width='100%'
                      height={300}
                      loading='lazy'
                      style={{ objectFit: 'contain' }}
                    />
                  </Link>
                  <div className='custom-control custom-checkbox mb-3 mt-3'>
                    <input
                      type='radio'
                      className='custom-control-input'
                      id={'layout2'}
                      defaultValue='layout2'
                      {...registerLayout(`layout`)}
                      defaultChecked={checked === 'layout2' ? true : false}
                    />
                    <Link
                      to={{ pathname: 'https://layout2.pralevar.app' }}
                      target='_blank'
                      className='d-block mb-3'
                    >
                      Clique para visualizar
                    </Link>
                    <label className='custom-control-label' htmlFor={`layout2`}>
                      Quero o Layout 2
                    </label>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-lg-6 col-sm-12 mb-2 mt-4 d-inline-block'>
                    <Link
                      to={{ pathname: 'https://layout3.pralevar.app' }}
                      target='_blank'
                      className='d-block mb-1'
                    >
                      <img
                        src={Layout3}
                        width='100%'
                        height={300}
                        loading='lazy'
                        style={{ objectFit: 'contain' }}
                      />
                    </Link>
                    <div className='custom-control custom-checkbox mb-3 mt-3'>
                      <input
                        type='radio'
                        className='custom-control-input'
                        id={'layout3'}
                        defaultValue='layout3'
                        disabled={user.plan_id < 3 ? true : false}
                        {...registerLayout(`layout`)}
                        defaultChecked={checked === 'layout3' ? true : false}
                        onClick={() => {
                          layoutMobile.current = document.querySelector(
                            'input[name="layoutMobile"]:checked'
                          ).value
                          setShowModal(true)
                        }}
                      />
                      {checked === 'layout3' ? (
                        <Link
                          to='#'
                          onClick={() => setShowModal(true)}
                          className='d-block mb-3'
                        >
                          Clique para Editar
                        </Link>
                      ) : (
                        <Link
                          to={{ pathname: 'https://layout3.pralevar.app' }}
                          target='_blank'
                          className='d-block mb-3'
                        >
                          Clique para visualizar
                        </Link>
                      )}

                      <label
                        className='custom-control-label'
                        htmlFor={`layout3`}
                        disabled={user.plan_id < 3 ? true : false}
                      >
                        {user.plan_id < 3 && (
                          <i
                            className='ti-lock text-primary mr-2'
                            aria-hidden='true'
                          ></i>
                        )}
                        Quero o Layout 3
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 mt-4'>
                  <h4>Template Versão Mobile</h4>
                  <div className='d-flex justify-content-center'>
                   {/*  <div className='custom-control custom-checkbox mb-3 mt-3 pr-3'>
                      <input
                        type='radio'
                        className='custom-control-input'
                        id={'classicApp'}
                        defaultValue='1'
                        {...registerLayout(`layoutMobile`)}
                        defaultChecked={templateMobile === '1' ? true : false}
                      />

                      <label
                        className='custom-control-label'
                        htmlFor={`classicApp`}
                      >
                        App clássico
                      </label>
                    </div> */}
                    <div className='custom-control custom-checkbox mb-3 mt-3'>
                      <input
                        type='radio'
                        className='custom-control-input'
                        id={'modernApp'}
                        defaultValue='2'
                        {...registerLayout(`layoutMobile`)}
                        defaultChecked={
                          !templateMobile || templateMobile === '2'
                            ? true
                            : false
                        }
                      />
                      <label
                        className='custom-control-label'
                        htmlFor={`modernApp`}
                      >
                        App moderno
                      </label>
                    </div>
                  </div>
                </div>
                <Button
                  type='submit'
                  className='btn btn-primary w-50 mt-3'
                  disabled={user.plan_id < 3 ? true : false}
                >
                  Salvar
                </Button>
              </form>
            </Card.Body>
          </Card>

          <Card className='mb-3 align-items-center'>
            <Card.Header className='m-auto flex-column'>
              <Card.Title>Cores </Card.Title>
              <small className='d-block'>
                Background, Primária, Secundária, Terciária, Títulos &
                Subtítulos
              </small>
            </Card.Header>
            <Card.Body className='card-body w-100 p-2 d-flex align-items-baseline'>
              <form
                onSubmit={handleSubmit(onSubmitColors)}
                className='text-center m-auto'
              >
                <div className='row m-auto'>
                  <div className='col-sm-6 d-inline-block align-items-center p-0 px-2 text-center'>
                    {showColorPicker && (
                      <ChromePicker
                        color={primaryColor}
                        onChangeComplete={color => handleChangeColor(color)}
                      />
                    )}
                    <label className='d-block text-black pb-1'>
                      Tema Light
                    </label>
                    <span
                      className='d-inline-block border border-dark'
                      style={{
                        background: '#fff',
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: primaryColor,
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: colorPrimaryHover.current,
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: colorTertiary.current,
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: '#212529',
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: '#515151',
                        height: 30,
                        width: 30
                      }}
                    ></span>

                    <div className='radio my-2'>
                      <label className='pl-2'>
                        <input
                          type='radio'
                          defaultValue={`light,#ffffff,${primaryColor},${colorPrimaryHover.current},${colorTertiary.current},#212529,#515151`}
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.theme === 'light'
                                ? true
                                : false
                              : false
                          }
                          {...register('colors', { required: true })}
                          //WHITELABEL APENAS PLANO FRANQUIA
                          /* onChange={(e) => {
                          let color = e.target.value.split(",");
                          document.documentElement.style.setProperty(
                            "--background",
                            `#515151`
                          );
                          document.documentElement.style.setProperty(
                            "--primary",
                            primaryColor
                          );
                          document.documentElement.style.setProperty(
                            "--secondary",
                            colorPrimaryHover.current
                          );
                          document.documentElement.style.setProperty(
                            "--tertiary",
                            colorTertiary.current
                          );
                          document.documentElement.style.setProperty(
                            "--titles",
                            `#1a1a1a`
                          );
                          document.documentElement.style.setProperty(
                            "--subtitles",
                            `#515151`
                          );
                        }} */
                        />
                      </label>
                    </div>
                  </div>
                  <div className='col-sm-6 d-inline-block align-items-center p-0 px-2 text-center'>
                    <label className='d-block text-black pb-1'>Tema Dark</label>
                    <span
                      className='d-inline-block border border-dark'
                      style={{
                        background: '#1a1a1a',
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: primaryColor,
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: colorPrimaryHover.current,
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: colorTertiary.current,
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block border border-dark'
                      style={{
                        background: '#ffffff',
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <span
                      className='d-inline-block'
                      style={{
                        background: '#dedede',
                        height: 30,
                        width: 30
                      }}
                    ></span>
                    <div className='radio'>
                      <label className='pl-2'>
                        <input
                          type='radio'
                          defaultValue={`dark,#1a1a1a,${primaryColor},${colorPrimaryHover.current},${colorTertiary.current},#ffffff,#dedede`}
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.theme === 'dark'
                                ? true
                                : false
                              : false
                          }
                          {...register('colors', { required: true })}
                          //WHITELABEL APENAS PLANO FRANQUIA
                          /* onChange={(e) => {
                          let color = e.target.value.split(",");
                          document.documentElement.style.setProperty(
                            "--background",
                            `#1a1a1a`
                          );
                          document.documentElement.style.setProperty(
                            "--primary",
                            primaryColor
                          );
                          document.documentElement.style.setProperty(
                            "--secondary",
                            colorPrimaryHover.current
                          );
                          document.documentElement.style.setProperty(
                            "--tertiary",
                            colorTertiary.current
                          );
                          document.documentElement.style.setProperty(
                            "--titles",
                            `#ffffff`
                          );
                          document.documentElement.style.setProperty(
                            "--subtitles",
                            `#dedede`
                          );
                        }} */
                        />
                      </label>
                    </div>
                  </div>

                  <div className='w-100 text-center mb-3'>
                    <Link to='#' onClick={() => setShowColorPicker(true)}>
                      Editar cor principal
                    </Link>
                  </div>
                </div>
                {errors.colors && (
                  <small className='ml-2 mb-2 text-danger d-block'>
                    {errors.colors.type === 'required' &&
                      'Por favor, selecione uma opção!'}
                  </small>
                )}
                <Button type='submit' className='btn btn-primary w-50'>
                  Salvar
                </Button>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </>
      {showModal && <ModalComponent props={modalProps} />}
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
      {progress && <ProgressBarComponent />}
    </>
  )
}

const styles = {
  thumb: {
    maxWidth: 250,
    maxHeight: 165
  },
  imageDefault: {
    height: 200,
    width: 200,
    margin: 'auto'
  },
  logo: {
    maxWidth: 200,
    maxHeight: 200,
    margin: 'auto',
    borderRadius: '20px'
  },
  splashScreen: {
    width: '100%',
    margin: 'auto',
    minHeight: 500,
    objectFit: 'contain',
    border: '1px solid #eaeaea',
    boxShadow: '0px 12px 23px 0px rgb(62 63 122 / 4%)'
  },
  info: {
    fontSize: '1.25rem',
    verticalAlign: 'sub'
  }
}
