import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    window.beamer_config = {
      product_id: "IWVGERhL70488", 

    };

    const script = document.createElement("script");
    script.src = "https://app.getbeamer.com/js/beamer-embed.js";
    script.type = "text/javascript";
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []); 

  return (
    <div className="nav-header">
      <div className="magic-bell beamerTrigger"><i className="fa fa-bell-o text-primary"></i></div>
      <Link to="/" className="brand-logo px-2"></Link>

      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
