import React, { useRef, forwardRef, useEffect } from "react";
import NumberFormat from "react-number-format";

export const OrderToPrint = forwardRef((props, ref) => {
  // const order = useRef(props.order);

  return (
    <div
      className="col-xl-4 col-xxl-4"
      id="orderToPrint"
      ref={ref}
      style={{ width: "8cm" }}
    >
      <div className="row flex-column border-to-print">
        <h2 className="text-center">
          {props.order.qrtb
            ? `MESA ${props.order.qrtb}`
            : props.order.order_to === "delivery"
            ? "ENTREGA"
            : "RETIRADA"}
        </h2>
        <hr></hr>
        <h3>
          <small>Pedido:</small> #{props.order.order_number}
        </h3>
        <h6>{new Date(props.order.created_at).toLocaleString()}</h6>
        <h6 className="text-capitalize">
          <b>
            {props.order.qrtb ? props.order.customer_name : props.order.name}
          </b>
        </h6>
        {!props.order.qrtb && (
          <h6>
            Telefone:{" "}
            <NumberFormat
              format="(##) #####-####"
              mask="_"
              displayType="text"
              defaultValue={props.order.user__phone}
            />
          </h6>
        )}
      </div>
      {props.order.order_to === "delivery" && (
        <div className="row flex-column border-to-print">
          <h4 className="mb-3 mt-3 text-center">ENDEREÇO</h4>
          <h6>
            <b>{props.order.address.complete_address}</b>
          </h6>
          <h6>
            Complemento: <b>{props.order.address.complement}</b>
          </h6>
          {props.order.address.reference && (
            <h6>
              Referência: <b>{props.order.address.reference}</b>
            </h6>
          )}
          {props.order.observations && (
            <h6>
              Observações: <b>{props.order.observations}</b>
            </h6>
          )}
        </div>
      )}

      <div className="row flex-column border-to-print">
        <h4 className="mb-3 mt-3 text-center">ITENS</h4>
        {props.order.order.map((data, index) => (
          <div key={index}>
            <h6>
              {data.quantity} {data.name}
            </h6>
            {data.variationData &&
              data.variationData.map((variation, i) => (
                <div key={i}>
                  <small>
                    {variation && variation?.option?.length > 0 ? (
                      <>
                        {variation.title} :
                        {variation.option.map((add) => ` ${add.name}, `)}
                      </>
                    ) : (
                      variation.title + ": " + variation.option.name
                    )}
                  </small>
                </div>
              ))}
            {data.observations && (
              <h6 className="mt-2 mb-3">Observações: {data.observations} </h6>
            )}
            <h6 className="mt-2 mb-4 d-flex justify-content-between">
              Valor:{" "}
              {
                <NumberFormat
                  displayType="text"
                  decimalScale={2}
                  defaultValue={data.finalPrice}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$"
                  fixedDecimalScale={true}
                />
              }{" "}
            </h6>
          </div>
        ))}
      </div>
      <div className="row flex-column border-to-print">
        <h4 className="mb-3 mt-3 text-center">PAGAMENTO</h4>
        {Object.keys(props.order.payment_method).length > 0 && (
          <>
            <h6 className="text-center mb-4">
              *
              {props.order.payment_method.pay_on === "delivery"
                ? "PAGAR NA ENTREGA"
                : "PAGO PELO SITE"}
              *
            </h6>
            <h6 className="d-flex justify-content-between">
              <small>Forma de pagamento:</small>{" "}
              {props.order.payment_method.type}
            </h6>
            <h6 className="d-flex justify-content-between">
              <small>Operação: </small>
              {props.order.payment_method.operation}
            </h6>
            {props.order.payment_method.type === "Cartão" && (
              <h6 className="d-flex justify-content-between">
                <small>Bandeira: </small>
                {props.order.payment_method.name}
              </h6>
            )}
          </>
        )}

        {props.order.order_to === "take_away" && (
          <h6 className="d-flex justify-content-between">
            * Pagamento na retirada *
          </h6>
        )}
      </div>
      {props.order.coupon && (
        <div className="row flex-column border-to-print">
          <h4 className="mb-3 mt-3 text-center">CUPOM DE DESCONTO</h4>
          <h6 className="mt-1 mb-1 d-flex justify-content-between">
            {props.order.coupon.couponName} (-)
            {
              <NumberFormat
                displayType="text"
                decimalScale={2}
                defaultValue={props.order.coupon.discount}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$"
                fixedDecimalScale={true}
              />
            }{" "}
          </h6>
        </div>
      )}

      <div className="row flex-column border-to-print">
        <h4 className="mb-3 mt-3 text-center">TOTAL DA COMPRA</h4>

        <h6 className="mt-1 mb-1 d-flex justify-content-between mb-4">
          Troco para (=)
          {
            <NumberFormat
              displayType="text"
              decimalScale={2}
              defaultValue={props.order.payment_method.changeFor}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$"
              fixedDecimalScale={true}
            />
          }{" "}
        </h6>

        <h6 className="mt-1 mb-1 d-flex justify-content-between">
          Subtotal (=)
          {
            <NumberFormat
              displayType="text"
              decimalScale={2}
              defaultValue={props.order.subtotal}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$"
              fixedDecimalScale={true}
            />
          }{" "}
        </h6>
        <h6 className="mt-1 mb-1 d-flex justify-content-between">
          Entrega (+){" "}
          {
            <NumberFormat
              displayType="text"
              decimalScale={2}
              defaultValue={props.order.deliveryFee}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$"
              fixedDecimalScale={true}
            />
          }{" "}
        </h6>
        {props.order.coupon && (
          <h6 className="mt-1 mb-1 d-flex justify-content-between">
            Desconto (-)
            {
              <NumberFormat
                displayType="text"
                decimalScale={2}
                defaultValue={props.order.coupon.discount}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$"
                fixedDecimalScale={true}
              />
            }{" "}
          </h6>
        )}
        {props.order.cashback_value && (
          <h6 className="mt-1 mb-1 d-flex justify-content-between">
            Cashback (-)
            {
              <NumberFormat
                displayType="text"
                decimalScale={2}
                defaultValue={props.order.cashback_value}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$"
                fixedDecimalScale={true}
              />
            }{" "}
          </h6>
        )}
        <h6 className="mt-1 mb-1 d-flex justify-content-between">
          Total (=){" "}
          {
            <NumberFormat
              displayType="text"
              decimalScale={2}
              defaultValue={props.order.total}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$"
              fixedDecimalScale={true}
            />
          }{" "}
        </h6>
      </div>
    </div>
  );
});

const styles = {
  photoDefault: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "130px",
    height: "130px",
    borderRadius: "75px",
    background: "#efefef",
    margin: "auto",
  },
  i: {
    fontSize: "32px",
    color: "#3d4465",
  },
};
